

import { FormGroup, FormControl, ValidatorFn, AbstractControl, Validators, ValidationErrors } from '@angular/forms';
import { AddressService } from '../services/address.service';
import { tap, distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { AppConstants } from '../shared/constants/constants';
import { MatSelect } from '@angular/material/select';
import { AddressByPostCode } from './address-server-response';
import { noWhitespace } from '../validators/no-whitespace.validator';

function postcodenotfound(address1Control: any, address3Control: any, isAddressFound: any): ValidatorFn {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
        if (isAddressFound && isAddressFound.value) {
            return null;
        }
        if (c.value && address1Control.value && address3Control.value) {
            return null;
        }
        return { 'postcodenotfound': true };
    };
}

export class AddressInfoFormControlModel extends FormGroup {

    private _enabled = true;
    private possibleAddressesComponent?: MatSelect;
    public addressLookupInFlight$ = new BehaviorSubject(false);
    public possibleAddresses: AddressByPostCode[] = [];

    constructor(
        private readonly addressDetails: AddressByPostCode,
        private readonly addressService?: AddressService) {
        super({
            postcode: new FormControl(addressDetails?.postCode),
            address1: new FormControl(addressDetails?.addressLine1),
            address2: new FormControl(addressDetails?.addressLine2),
            address3: new FormControl(addressDetails?.addressLine3),
            address4: new FormControl(addressDetails?.addressLine4),
            address5: new FormControl(addressDetails?.addressLine5),
            manualEntry: new FormControl(true),
            isAddressFound: new FormControl(true)
        });

        if (addressService) {
            addressService.getAddressFromPostcode(this.postcode.valueChanges.pipe(
                debounceTime(AppConstants.delayBefore),
                tap(() => {
                    this.addressLookupInFlight$.next(true);
                    this.address1.setValue('');
                    this.address2.setValue('');
                    this.address3.setValue('');
                    this.address4.setValue('');
                    this.address5.setValue('');
                })
            )).pipe(
                tap(() => this.addressLookupInFlight$.next(false))
            ).subscribe(({ requestWasSuccessful, responseData }) => {
                if (requestWasSuccessful && responseData) {
                    this.isAddressFound.setValue(true);
                    this.possibleAddresses = responseData.map((x) => {
                        return new AddressByPostCode(x)
                    });

                    if (this.possibleAddressesComponent) {
                        this.possibleAddressesComponent.focus();
                        setTimeout(() => {
                            if (this.possibleAddressesComponent) {
                                this.possibleAddressesComponent.open();
                            }
                        }, 0);
                    }
                }
                else {
                    this.isAddressFound.setValue(false);
                    this.possibleAddresses = [];
                    this.postcode.setErrors({
                        ...this.postcode.errors || {},
                        addressNotFound: true
                    } as ValidationErrors);
                }
            });
        }
    }

    enable(opts?: { emitEvent: boolean }) {
        this._enabled = true;
        this.postcode.enable(opts);
        this.type.enable(opts);
    }

    disable(opts?: { emitEvent: boolean }) {
        this._enabled = false;
        super.disable(opts);
    }

    setPossibleAddressesComponent(possibleAddressesComponent: MatSelect) {
        this.possibleAddressesComponent = possibleAddressesComponent;
        this.possibleAddressesComponent.valueChange.subscribe((newValue: AddressByPostCode) => {
            this.address1.setValue(newValue.addressLine1);
            this.address1.markAsDirty();
            this.address1.updateValueAndValidity();
            this.address2.setValue(newValue.addressLine2);
            this.address2.markAsDirty();
            this.address2.updateValueAndValidity();
            this.address3.setValue(newValue.addressLine3);
            this.address3.markAsDirty();
            this.address3.updateValueAndValidity();
            this.address4.setValue(newValue.addressLine4);
            this.address4.markAsDirty();
            this.address4.updateValueAndValidity();
            this.address5.setValue(newValue.addressLine5);
            this.address5.markAsDirty();
            this.address5.updateValueAndValidity();
            this.postcode.setValue(newValue.postCode, { emitEvent: false });
        });
    }

    setValidationRules(value: boolean) {
        if (value === true) {
            this.postcode.setValidators([Validators.required,Validators.maxLength(10), noWhitespace, postcodenotfound(this.address1, this.address3, this.isAddressFound)]);
            this.address1.setValidators([Validators.required, Validators.maxLength(250), noWhitespace]);
            this.address2.setValidators([Validators.maxLength(250)]);
            this.address3.setValidators([Validators.required, Validators.maxLength(250), noWhitespace]);
            this.address4.setValidators([Validators.maxLength(250)]);
            this.address5.setValidators([Validators.maxLength(250)]);
            combineLatest(this.isAddressFound.valueChanges,
                this.address1.valueChanges, this.address3.valueChanges).pipe(distinctUntilChanged(([preIsAddressFound, preAddress1, preAddress3], [nextIsAddressFound, nextAddress1, nextAddress3]) => {
                    return preIsAddressFound == nextIsAddressFound && preAddress1 == nextAddress1 && preAddress3 == nextAddress3;
                })).subscribe(val => {
                    this.postcode.updateValueAndValidity({ emitEvent: false });
                });
        } else {
            this.postcode.clearValidators();
            this.address1.clearValidators();
            this.address2.clearValidators();
            this.address3.clearValidators();
            this.address4.clearValidators();
            this.address5.clearValidators();
            this.town.clearValidators();
        }
        this.postcode.updateValueAndValidity({ emitEvent: false });
        this.address1.updateValueAndValidity();
        this.address2.updateValueAndValidity();
        this.address3.updateValueAndValidity();
        this.address4.updateValueAndValidity();
        this.address5.updateValueAndValidity();
    }

    get enabled() {
        return this._enabled;
    }

    get disabled() {
        return !this._enabled;
    }

    get postcode() {
        return this.get('postcode')!;
    }

    get address1() {
        return this.get('address1')!;
    }

    get address2() {
        return this.get('address2')!;
    }

    get address3() {
        return this.get('address3')!;
    }

    get address4() {
        return this.get('address4')!;
    }

    get address5() {
        return this.get('address5')!;
    }

    get town() {
        return this.get('town')!;
    }

    get type() {
        return this.get('type')!;
    }

    get isAddressFound() {
        return this.get('isAddressFound')!;
    }
}
