import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { CollectionViewer } from '@angular/cdk/collections';
import { catchError, finalize } from 'rxjs/operators';
import { Broker } from './broker';
import { BrokerService } from '../services/broker.service';
import { PaginationResponse } from './pagination-response';


export class BrokerDataSource implements DataSource<Broker> {

    private BrokerSubject = new BehaviorSubject<Broker[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);
    public totalRecords:number;

    public loading$ = this.loadingSubject.asObservable();
    public brokerSubject$ = this.BrokerSubject;

    constructor(private brokerService: BrokerService) { }

    connect(collectionViewer: CollectionViewer): Observable<Broker[]> {
        return this.BrokerSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.BrokerSubject.complete();
        this.loadingSubject.complete();
    }

    getBrokerList(searchString: string, isIncludeInactive: boolean, sortField: string, sortDirection = 'asc', pageIndex = 1, pageSize = 5) {

        this.loadingSubject.next(true);

        this.brokerService.getBrokerList(searchString, isIncludeInactive, sortField, sortDirection, pageIndex, pageSize).pipe(
            catchError(() => of([])),
            finalize(() => this.loadingSubject.next(false))
        )
            .subscribe(st => {
              var rs = st as PaginationResponse<Broker>;
              this.totalRecords = rs.total;
                this.BrokerSubject.next((rs.items) as any);
            });
    }
}
