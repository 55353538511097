import { Component, Input, OnInit, ViewChild, Injector, Output, EventEmitter, ElementRef } from '@angular/core';
import { HttpBaseService } from 'src/app/shared/services/http-base.service';
import { ApiEndPoints } from 'src/app/shared/config/api-end-points';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FilterResponse, InsurerFilterResponse } from 'src/app/models/declartion-report-responses/declaration-report-response';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { AppConstants, LeiConfig, TitleMessages } from 'src/app/shared/constants/constants';
import { Utility } from 'src/app/utils/utility';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Provider } from 'src/app/models/enums/provider.enum';
import { ReportService } from 'src/app/services/report.service';
import { MatOption } from '@angular/material/core';
import { ReportHistoryService } from 'src/app/services/report-history.service';

@Component({
  selector: 'app-upload-report',
  templateUrl: './upload-report.component.html',
  styleUrls: ['./upload-report.component.scss']
})
export class UploadReportComponent implements OnInit {

  @Input() id: string;
  @Input() title: string;
  @Output() reload= new EventEmitter<string>();

  public formGroup!: FormGroup;
  allOptionTxt: string = 'All';
  actualSelectedCover: string = '';
  actualInsurer: string = '';
  actualProduct: string = '';
  allInsurerOption: InsurerFilterResponse = { name: this.allOptionTxt, ids: [], dateFrom : new Date(), dateTo: new Date()};
  currentInsurer: InsurerFilterResponse[];
  currentProducts: string[];

  currentInsurerValues: InsurerFilterResponse[] = [];
  currentProductValues: string[] = [];
  currentCoverValues: string[] = [];

  insurerNames: InsurerFilterResponse[] = [];
  productsList: FilterResponse[] = [];
  coversList: FilterResponse[] = [];
  providerList: FilterResponse[] = [];
  isLPGDriversClub: boolean = false;
  productNames: string = '';
  sourceDialog: any;

  uploadFile: any;

  @ViewChild('allSelectedCover') allSelected: MatOption;
  @ViewChild('matSelectCover')  matSelectCover;

  @ViewChild('allProvider') allProvider: MatOption;
  @ViewChild('matProvider') matProvider;

  @ViewChild('allInsurer') allInsurer: MatOption;
  @ViewChild('matInsurer') matInsurer;

  @ViewChild('allProduct') allProduct: MatOption;
  @ViewChild('matProduct') matProduct;

  @ViewChild("file") fileInput: ElementRef;


  public appConstants = AppConstants;
  isDialog: boolean = false;
  private dialogRef = null;
  private data;
  constructor(private readonly formBuilder: FormBuilder,
    private readonly _reportService: ReportService,
    private readonly httpService: HttpBaseService,
    private readonly _reportHistoryService: ReportHistoryService,
    private readonly _api: ApiEndPoints,
    private readonly dialogService: DialogService,
    private injector: Injector) {
      this.dialogRef = this.injector.get(MatDialogRef, null);
      this.data = this.injector.get(MAT_DIALOG_DATA, null);

  }

  public get provider() {
    return this.formGroup.get('provider') as AbstractControl;
  }

  public get insurerName() {
    return this.formGroup.get('insurerName') as AbstractControl;
  }

  public get product() {
    return this.formGroup.get('product') as AbstractControl;
  }

  public get cover() {
    return this.formGroup.get('cover') as AbstractControl;
  }

  public get file() {
    return this.formGroup.get('file') as AbstractControl;
  }

  get isValidSubmit() {
    return !this.formGroup.invalid;
  }

  ngOnInit(): void {

    this.formGroup = this.formBuilder.group({
      provider: ['', [Validators.required]],
      insurerName: ['', [Validators.required]],
      product: ['', [Validators.required]],
      cover: [null, [Validators.required]],
      file: ['', [Validators.required]],

    });

    this.provider.valueChanges.subscribe(value => {
      this.clearCover();
      this.clearProduct();
      this.clearInsurer();

      if (value === Provider.LegalProtectionGroup) {
        this.isLPGDriversClub = true;
      }
      else {
        this.isLPGDriversClub = false;
      }

      let arrProvider: string[] = [];
      arrProvider.push(this.provider.value)
      this._reportService.getInsurerDeclarations(arrProvider).subscribe(insurerName => {
        this.insurerNames = insurerName;
        if (this.insurerNames.length > 0) {
          this.allInsurerOption.dateFrom = insurerName[0].dateFrom;
          this.allInsurerOption.dateTo = insurerName[0].dateTo;
        }
      });

    });

  }

  async ngAfterViewInit() {
    this.providerList = await this._reportHistoryService.getProvider();
  }

  onSelectFile(files: FileList) {
    if (files.length === 0) {
      return;
    }
    const fileToUpload = files.item(0);
    const fileReader: FileReader = new FileReader();
    fileReader.readAsDataURL(fileToUpload);
    fileReader.onload = (event: any) => {
      this.file.setValue(fileToUpload.name);
      this.uploadFile = fileToUpload;
    };
  }
  onCloseInsurer() {
    let arrInsurerIds: string[] = [];
    if (this.insurerName.value) {
      let ids = this.insurerName.value.map((x: { ids: string[] }) => x.ids);
      ids.forEach((x: string[]) => arrInsurerIds = arrInsurerIds.concat(x));
      if (JSON.stringify(this.currentInsurer) != JSON.stringify(this.insurerName?.value)) {
       this.clearCover();
       this.clearProduct();
        if (arrInsurerIds.length > 0) {
          this._reportService.getInsurerProductNames(arrInsurerIds).subscribe(products => {
            this.productsList = products;
          });
        }
      }
    }
    this.currentInsurer = this.insurerName.value;
  }

  onCloseProduct() {
    if (this.product.value) {
      if (JSON.stringify(this.currentProducts) != JSON.stringify(this.product.value)) {
        let arrInsurerIds: string[] = [];
        if (this.insurerName.value) {
          let ids = this.insurerName.value.map((x: { ids: string[] }) => x.ids);
          ids.forEach((x: string[]) => arrInsurerIds = arrInsurerIds.concat(x));
          let arrProducts: string[] = [];
          arrProducts = this.product.value;
          arrProducts = arrProducts.filter(x => x != this.allOptionTxt);
          this.coversList = [];
          this.currentCoverValues = [];
          this.cover.setValue('', { emitEvent: false });
          this.actualSelectedCover = '';
          if (arrProducts.length > 0 && arrInsurerIds.length > 0) {
            this._reportService.getSubProductDeclarations(arrProducts, arrInsurerIds).subscribe(covers => {
              this.coversList = covers;
            })
          }
        }
      }
    }
    this.currentProducts = this.product.value
    this.productNames = (this.productsList.filter(x => this.currentProducts.includes(x.id)).map(x => x.name)).join(", ");
  }


  async onSubmit() {
    if (this.formGroup.valid ) {

      let covers = this.cover.value.filter((x: string) => x != this.allOptionTxt);
      let arrInsurerIds: string[] = [];
      let ids = this.insurerName.value.map((x: { ids: string[] }) => x.ids);
      ids.forEach((x: string[]) => arrInsurerIds = arrInsurerIds.concat(x));
      let arrProductIds = this.product.value.filter((x: string) => x != this.allOptionTxt);

      if (covers.length > 0) {
        let url = this._api.uploadDeclarationReport(this.id, covers, this.provider.value, arrInsurerIds, arrProductIds);
          try {
            await this.httpService.uploadAsync<string>(url, this.uploadFile);
            this.dialogService.openSuccessDialogConfirm({ title: TitleMessages.successTitle, message: "Document has been uploaded successfully" }).subscribe(async result => {
              if (result) {
                this.reload.emit();
              }
            });
          }
          catch (err) {
            if (err.status === 400) {
              let mss = "<div><b>Data is not valid, please follow below error to resolve:</b><div>";
              err.error.forEach(element => {
                mss +="<div>Row: " +  element.row +
                      ", Column: " +  element.column +
                      ", Error: " +  element.error + "</div>";
              });
              this.dialogService.openErrorDialog({ title: TitleMessages.warningTitle, htmlContent: mss });
            }
            this.uploadFile = null;
            this.file.setValue("");
            this.fileInput.nativeElement.value = "";


          }
      }
    }
  }
  toggleSelectionCover(values: string[]) {
    let selected = (values.filter(x => !this.currentCoverValues.includes(x))).concat(this.currentCoverValues.filter(x => !values.includes(x)));
    if (selected.includes(this.allOptionTxt)) {
      if (this.allSelected.selected) {
        let ids = this.coversList.map(({ id }) => id)
        this.cover.setValue([this.allOptionTxt, ...ids]);
        this.actualSelectedCover = this.allOptionTxt;
      } else {
        this.cover.setValue([]);
        this.actualSelectedCover = this.matSelectCover.triggerValue;
      }
    }
    else {
      let ids: string[] = this.cover.value;
      const index = ids.indexOf(this.allOptionTxt);
      if (index > -1) {
        ids.splice(index, 1);
        this.cover.setValue([...ids]);
      }

      if (ids.length == this.coversList.length) {
        this.cover.setValue([this.allOptionTxt, ...ids]);
        this.actualSelectedCover = this.allOptionTxt;
      }
      else {
        this.actualSelectedCover = this.matSelectCover.triggerValue;
      }
    }
    this.currentCoverValues = this.cover.value
  }

  toggleSelectionInsurer(values: InsurerFilterResponse[]) {
    let selected = (values.filter(x => !this.currentInsurerValues.includes(x))).concat(this.currentInsurerValues.filter(x => !values.includes(x)));
    if (selected.includes(this.allInsurerOption)) {
      if (this.allInsurer.selected) {
        let insurerValues = this.insurerNames.filter(x => true);
        this.insurerName.setValue([this.allInsurerOption, ...insurerValues]);
        this.actualInsurer = this.allOptionTxt;
      } else {
        this.insurerName.setValue([]);
        this.actualInsurer = this.matInsurer.triggerValue;
      }
    }
    else {
      let insurerValues: InsurerFilterResponse[] = this.insurerName.value.filter(x => x.name != this.allOptionTxt);
      if (insurerValues.length == this.insurerNames.length) {
        this.insurerName.setValue([this.allInsurerOption, ...insurerValues]);
        this.actualInsurer = this.allOptionTxt;
      }
      else {
        this.insurerName.setValue([...insurerValues]);
        this.actualInsurer = this.matInsurer.triggerValue;
      }
    }
    this.currentInsurerValues = this.insurerName.value;
  }

  toggleSelectionProduct(values: string[]) {
    let selected = (values.filter(x => !this.currentProductValues.includes(x))).concat(this.currentProductValues.filter(x => !values.includes(x)));
    if (selected.includes(this.allOptionTxt)) {
      if (this.allProduct.selected) {
        let productValues = this.productsList.map(({ id }) => id)
        this.product.setValue([this.allOptionTxt, ...productValues]);
      } else {
        this.product.setValue([]);
      }

      if (this.matProduct.triggerValue.includes(this.allOptionTxt)) {
        this.actualProduct = this.allOptionTxt;
      }
      else {
        this.actualProduct = this.matProduct.triggerValue;
      }
    }
    else {
      let productValues: string[] = this.product.value;
      const index = productValues.indexOf(this.allOptionTxt);
      if (index > -1) {
        productValues.splice(index, 1);
        this.product.setValue([...productValues]);
      }

      if (productValues.length == this.productsList.length) {
        this.product.setValue([this.allOptionTxt, ...productValues]);
        this.actualProduct = this.allOptionTxt;
      }
      else {
        this.actualProduct = this.matProduct.triggerValue;
      }
    }
    this.currentProductValues = this.product.value;
  }

  onCloseDialog() {
    this.dialogRef.close();
  }

  async upload(files: FileList, id) {
    if (files.length === 0) {
      return;
    }
    const fileToUpload = files.item(0);
    const fileReader: FileReader = new FileReader();
    fileReader.readAsDataURL(fileToUpload);

    fileReader.onload = (event: any) => {
      this.uploadFile = fileToUpload;
    };
  }

  clearInsurer() {
    this.insurerNames = [];
    this.currentInsurer = [];
    this.currentInsurerValues = [];
    this.insurerName.setValue('', { emitEvent: false });
    this.actualInsurer = '';
  }

  clearProduct() {
    this.productsList = [];
    this.currentProducts = [];
    this.currentProductValues = [];
    this.product.setValue('', { emitEvent: false });
    this.actualProduct = '';
  }

  clearCover() {
    this.coversList = [];
    this.currentCoverValues = [];
    this.cover.setValue('', { emitEvent: false });
    this.actualSelectedCover = '';
  }


}
