import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogService } from '../../services/dialog.service';
import { isValidEmail } from 'src/app/validators/valid-email.validator';
import { TitleMessages } from '../../constants/constants';

@Component({
  selector: 'app-resend-option-popup',
  templateUrl: './resend-option-popup.component.html',
  styleUrls: ['./resend-option-popup.component.scss']
})
export class ResendOptionPopupComponent implements OnInit {

  maxLength: number = 250;
  optionForm: FormGroup;
  isShowEmail: boolean = false;

  constructor(public _dialogService: DialogService, private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ResendOptionPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.createForm();
  }

  ngOnInit(): void {
    this.options.valueChanges.subscribe(option => {
      this.isShowEmail = option == 1;
    });
  }

  createForm(): void {
    this.optionForm = this.formBuilder.group({
      options: [0],
      emailAddress: ['', [Validators.required, isValidEmail, Validators.email, Validators.maxLength(this.maxLength)]],
    });
  }

  get emailAddress() {
    return this.optionForm.get("emailAddress") as AbstractControl;
  }

  get options() {
    return this.optionForm.get("options") as AbstractControl;
  }

  onCloseDialog() {
    if (this.isShowEmail && !this.optionForm.pristine && !this.optionForm.valid) {
      this._dialogService.openConfirmationDialog(
        {
          title: TitleMessages.confirmationTitle,
          primaryButtons: 'Yes',
          secondaryButtons: 'No',
          message: "Are you sure you want to continue. Your changes will be lost?"
        }
      ).subscribe(
        (result) => {
          if (result) {
            this.closeDialog();
          }
        });
    }
    else {
      this.closeDialog();
    }
  }

  closeDialog() {
    let email: string = this.isShowEmail && this.optionForm.valid ? this.emailAddress.value : '';
    this.dialogRef.close({ email: email });
  }
}
