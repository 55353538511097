import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-ie-inline-edit',
  templateUrl: './ie-inline-edit.component.html',
  styleUrls: ['./ie-inline-edit.component.scss']
})
export class IeInlineEditComponent implements OnInit {

  title: string = 'Edit';
  // maximumRetailRate: number = 0;
  get value(): any { return this._value; }
  set value(x: any) {
    this.newValue = this._value = x;
  }
  private _value: any;

  /** Form model for the input. */
  newValue: any;

  inputControl = new FormControl(0, []);

  // get maxErrorMessage() {
  //   return `The value you have entered exceeds the maximum retail rate ${Utility.moneytary(this.maximumRetailRate)}. Please enter a new premium and click Save.`;
  // }

  constructor(public dialogRef: MatDialogRef<IeInlineEditComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.title = data.title;
    // this.maximumRetailRate = data.element.getMaximumRetailRate;
    this.value = data.element.originalGrossPriceToCustomer;
  }

  ngOnInit() {
    // if (this.maximumRetailRate === 0) {
    //   this.maximumRetailRate = 1000000000; // one billion that means unlimitted
    // }
    this.inputControl.setValidators([
      Validators.required,
      Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$'),
      // Validators.max(this.maximumRetailRate)]
    ]);
  }

  onSubmit() {
    this.dialogRef.close({ newValue: this.newValue });
  }

  onCancel() {
    this.dialogRef.close();
  }
}
