
<mat-sidenav-container>
  <mat-sidenav style="margin-top: 62px !important" #appDrawer mode="over" opened="false">
    <mat-nav-list *ngIf="userRoles && userRoles.length>0">
      <app-menu-list-item  *ngFor='let item of navItems$ | async' [item]="item" ></app-menu-list-item>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content >
    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
