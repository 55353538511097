import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import moment from 'moment';
import { debounceTime, distinctUntilChanged} from 'rxjs';
import { ExportDeclarationReportResponse, FilterResponse, InsurerFilterResponse } from 'src/app/models/declartion-report-responses/declaration-report-response';
import { ReportService } from 'src/app/services/report.service';
import { AppConstants, LeiConfig, } from 'src/app/shared/constants/constants';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { Utility } from 'src/app/utils/utility';
import { Provider } from 'src/app/models/enums/provider.enum';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LpgDeclarationsReportComponent } from './lpg-declarations-report/lpg-declarations-report.component';
import { AragDeclarationsReportComponent } from './arag-declarations-report/arag-declarations-report.component';
import { ExportLPGDeclarationReportResponse } from 'src/app/models/declartion-report-responses/lpg-drivers-club-declaration-report-response';

@Component({
  selector: 'app-declarations',
  templateUrl: './declarations-report.component.html',
  styleUrls: ['./declarations-report.component.scss']
})
export class DeclarationsReportComponent implements OnInit  {

  public formGroup!: FormGroup;
  allOptionTxt: string = 'All';
  actualSelectedCover: string = '';
  actualInsurer: string = '';
  actualProduct: string = '';
  allInsurerOption: InsurerFilterResponse = { name: this.allOptionTxt, ids: [], dateFrom : new Date(), dateTo: new Date()};
  currentInsurer: InsurerFilterResponse[];
  currentProducts: string[] = [];

  currentInsurerValues: InsurerFilterResponse[] = [];
  currentProductValues: string[] = [];
  currentCoverValues: string[] = [];

  insurerNames: InsurerFilterResponse[] = [];
  productsList: FilterResponse[] = [];
  coversList: FilterResponse[] = [];
  providerList: FilterResponse[] = [];
  maxDateToError: string = 'Date is over 60 days in the future.'

  isLPGDriversClub: boolean = false;
  productNames: string = '';
  sourceDialog: ExportDeclarationReportResponse | ExportLPGDeclarationReportResponse;
  historyItemId: string;

  //report date controls min max dates
  minDateFrom: Date = new Date(1999, 0, 1);
  maxDateFrom: Date = Utility.addDays(new Date(), 60);

  minDateTo: Date = new Date(1999, 0, 1);
  maxDateTo: Date = Utility.addDays(new Date(), 60);

  @ViewChild('allSelectedCover') allSelected: MatOption;
  @ViewChild('matSelectCover')  matSelectCover;

  @ViewChild('allProvider') allProvider: MatOption;
  @ViewChild('matProvider') matProvider;

  @ViewChild('allInsurer') allInsurer: MatOption;
  @ViewChild('matInsurer') matInsurer;

  @ViewChild('allProduct') allProduct: MatOption;
  @ViewChild('matProduct') matProduct;

  @ViewChild(AragDeclarationsReportComponent) declartionTable!: AragDeclarationsReportComponent;
  @ViewChild(LpgDeclarationsReportComponent) lpgdeclartionTable!: LpgDeclarationsReportComponent;


  public appConstants = AppConstants;
  isDialog: boolean = false;
  private dialogRef = null;
  private data: { isDialog: any; source: any; historyItemId: string; isLPG: boolean; };

  constructor(private readonly formBuilder: FormBuilder,
    private readonly _reportService: ReportService,
    private readonly dialogService: DialogService,
    private injector: Injector) {
      this.dialogRef = this.injector.get(MatDialogRef, null);
      this.data = this.injector.get(MAT_DIALOG_DATA, null);

  }

  public get provider() {
    return this.formGroup.get('provider') as AbstractControl;
  }

  public get insurerName() {
    return this.formGroup.get('insurerName') as AbstractControl;
  }

  public get product() {
    return this.formGroup.get('product') as AbstractControl;
  }

  public get cover() {
    return this.formGroup.get('cover') as AbstractControl;
  }

  public get dateFrom() {
    return this.formGroup.get('dateFrom') as AbstractControl;
  }

  public get dateTo() {
    return this.formGroup.get('dateTo') as AbstractControl;
  }

  get isValidSubmit() {
    return !this.formGroup.invalid;
  }

  ngOnInit(): void {

    const defaultDateFrom = moment(new Date().setHours(0,0,0,0));
    const defaultDateTo = moment(new Date().setHours(0,0,0,0));

    this.formGroup = this.formBuilder.group({
      provider: ['', [Validators.required]],
      insurerName: ['', [Validators.required]],
      product: ['', [Validators.required]],
      cover: [null, [Validators.required]],
      dateFrom: [defaultDateFrom, [Validators.required]],
      dateTo: [defaultDateTo, [Validators.required]],

    });

    if (this.data && this.data.isDialog) {
      this.isDialog = true;
      this.sourceDialog = this.data.source;
      this.historyItemId = this.data.historyItemId;
      this.isLPGDriversClub = this.data.isLPG;
      this.dateFrom.setValue(this.sourceDialog.dateFrom);
      this.dateTo.setValue(this.sourceDialog.dateTo);

      this.provider.disable({ emitEvent: false });
      this.insurerName.disable({ emitEvent: false });
      this.product.disable({ emitEvent: false });
      this.cover.disable({ emitEvent: false });
      this.dateFrom.disable({ emitEvent: false });
      this.dateTo.disable({ emitEvent: false });
    }
    this._reportService.getProviderDeclarations().subscribe(result => {
      this.providerList = result;
    })

    this.insurerName.valueChanges.pipe(
      debounceTime(LeiConfig.delayBeforeSearchingWithAutoSearchFields),
      distinctUntilChanged()
    ).subscribe(selectedInsurerName => {
      if (this.insurerName.value) {
        let defaultDate = Utility.addDays(new Date(), 60);
        if (this.insurerName.value.length > 0) {
          let maxDateTo = selectedInsurerName[0].dateTo;
          let minDateFrom = selectedInsurerName[0].dateFrom;
          selectedInsurerName.forEach(x => {
            if (new Date(minDateFrom) > new Date(x.dateFrom)) {
              minDateFrom = x.dateFrom
            }
            if (new Date(maxDateTo) < new Date(x.dateTo)) {
              maxDateTo = x.dateTo
            }
          })

          this.minDateFrom = minDateFrom;
          this.maxDateToError = 'Date is after the effective date to of selected insurer.'

          if (new Date(maxDateTo) < defaultDate) {
            this.maxDateTo = maxDateTo;
            this.maxDateFrom = maxDateTo;
            this.maxDateToError = 'Date is after the effective date to of selected insurer.'
          }
          else {
            this.maxDateTo = defaultDate;
            this.maxDateToError = 'Date is over 60 days in the future.'
          }
        }
      }
    });

    this.cover.valueChanges.subscribe(x => this.resetTable());
    this.dateFrom.valueChanges.subscribe(x => this.resetTable());
    this.dateTo.valueChanges.subscribe(x => this.resetTable());

    this.provider.valueChanges.subscribe(value => {
      this.resetTable();
      this.clearInsurer();
      this.clearProduct();
      this.clearCover();

      if (value === Provider.LegalProtectionGroup) {
        this.isLPGDriversClub = true;
      }
      else {
        this.isLPGDriversClub = false;

      }

      let arrProvider: string[] = [];
      arrProvider.push(this.provider.value)
      this._reportService.getInsurerDeclarations(arrProvider).subscribe(insurerName => {
        this.insurerNames = insurerName;
        if (this.insurerNames.length > 0) {
          this.allInsurerOption.dateFrom = insurerName[0].dateFrom;
          this.allInsurerOption.dateTo = insurerName[0].dateTo;
        }
      });

    });

  }

  onCloseInsurer() {
    let arrInsurerIds: string[] = [];
    if (this.insurerName.value) {
      let ids = this.insurerName.value.map((x: { ids: string[] }) => x.ids);
      ids.forEach((x: string[]) => arrInsurerIds = arrInsurerIds.concat(x));
      if (JSON.stringify(this.currentInsurer) != JSON.stringify(this.insurerName?.value)) {
        this.resetTable();
        this.clearProduct();
        this.clearCover();
        this.dateFrom.reset();
        this.dateTo.reset();
        if (arrInsurerIds.length > 0) {
          this._reportService.getInsurerProductNames(arrInsurerIds).subscribe(products => {
            this.productsList = products;
          });
        }
      }
    }
    this.currentInsurer = this.insurerName.value;
  }

  onCloseProduct() {
    if (this.product.value) {
      if (JSON.stringify(this.currentProducts) != JSON.stringify(this.product.value)) {
        this.resetTable();
        let arrInsurerIds: string[] = [];
        if (this.insurerName.value) {
          let ids = this.insurerName.value.map((x: { ids: string[] }) => x.ids);
          ids.forEach((x: string[]) => arrInsurerIds = arrInsurerIds.concat(x));
          let arrProducts: string[] = [];
          arrProducts = this.product.value;
          arrProducts = arrProducts.filter(x => x != this.allOptionTxt);
          this.clearCover();
          if (arrProducts.length > 0 && arrInsurerIds.length > 0) {
            this._reportService.getSubProductDeclarations(arrProducts, arrInsurerIds).subscribe(covers => {
              this.coversList = covers;
            })
          }
        }
      }
    }
    this.currentProducts = this.product.value
    this.productNames = (this.productsList.filter(x => this.currentProducts.includes(x.id)).map(x => x.name)).join(", ");
  }

  onSubmit() {
    if (this.formGroup.valid && this.validDates()){
      if(this.isLPGDriversClub)
      {
        this.lpgdeclartionTable.onSubmit();
      }
      else
      {
        this.declartionTable.onSubmit();
      }
    }
  }

  resetTable() {
    this.declartionTable?.resetTable();
    this.lpgdeclartionTable?.resetTable();
  }

  toggleSelectionCover(values: string[]) {
    let selected = (values.filter(x => !this.currentCoverValues.includes(x))).concat(this.currentCoverValues.filter(x => !values.includes(x)));
    if (selected.includes(this.allOptionTxt)) {
      if (this.allSelected.selected) {
        let ids = this.coversList.map(({ id }) => id)
        this.cover.setValue([this.allOptionTxt, ...ids]);
        this.actualSelectedCover = this.allOptionTxt;
      } else {
        this.cover.setValue([]);
        this.actualSelectedCover = this.matSelectCover.triggerValue;
      }
    }
    else {
      let ids: string[] = this.cover.value;
      const index = ids.indexOf(this.allOptionTxt);
      if (index > -1) {
        ids.splice(index, 1);
        this.cover.setValue([...ids]);
      }

      if (ids.length == this.coversList.length) {
        this.cover.setValue([this.allOptionTxt, ...ids]);
        this.actualSelectedCover = this.allOptionTxt;
      }
      else {
        this.actualSelectedCover = this.matSelectCover.triggerValue;
      }
    }
    this.currentCoverValues = this.cover.value
  }

  toggleSelectionInsurer(values: InsurerFilterResponse[]) {
    let selected = (values.filter(x => !this.currentInsurerValues.includes(x))).concat(this.currentInsurerValues.filter(x => !values.includes(x)));
    if (selected.includes(this.allInsurerOption)) {
      if (this.allInsurer.selected) {
        let insurerValues = this.insurerNames.filter(x => true);
        this.insurerName.setValue([this.allInsurerOption, ...insurerValues]);
        this.actualInsurer = this.allOptionTxt;
      } else {
        this.insurerName.setValue([]);
        this.actualInsurer = this.matInsurer.triggerValue;
      }
    }
    else {
      let insurerValues: InsurerFilterResponse[] = this.insurerName.value.filter(x => x.name != this.allOptionTxt);
      if (insurerValues.length == this.insurerNames.length) {
        this.insurerName.setValue([this.allInsurerOption, ...insurerValues]);
        this.actualInsurer = this.allOptionTxt;
      }
      else {
        this.insurerName.setValue([...insurerValues]);
        this.actualInsurer = this.matInsurer.triggerValue;
      }
    }
    this.currentInsurerValues = this.insurerName.value;
  }

  toggleSelectionProduct(values: string[]) {
    let selected = (values.filter(x => !this.currentProductValues.includes(x))).concat(this.currentProductValues.filter(x => !values.includes(x)));
    if (selected.includes(this.allOptionTxt)) {
      if (this.allProduct.selected) {
        let productValues = this.productsList.map(({ id }) => id)
        this.product.setValue([this.allOptionTxt, ...productValues]);
      } else {
        this.product.setValue([]);
      }

      if (this.matProduct.triggerValue.includes(this.allOptionTxt)) {
        this.actualProduct = this.allOptionTxt;
      }
      else {
        this.actualProduct = this.matProduct.triggerValue;
      }
    }
    else {
      let productValues: string[] = this.product.value;
      const index = productValues.indexOf(this.allOptionTxt);
      if (index > -1) {
        productValues.splice(index, 1);
        this.product.setValue([...productValues]);
      }

      if (productValues.length == this.productsList.length) {
        this.product.setValue([this.allOptionTxt, ...productValues]);
        this.actualProduct = this.allOptionTxt;
      }
      else {
        this.actualProduct = this.matProduct.triggerValue;
      }
    }
    this.currentProductValues = this.product.value;
  }

  onCloseDialog() {
    this.dialogRef.close();
  }

  validDates(){
    let valid = false;
    if (this.dateFrom.value && this.dateTo.value) {
      if (moment(this.dateFrom.value) > moment(this.dateTo.value)) {
        const errorMessage = 'The Date From is greater than the Date To. Please check and re-enter the dates.';
        this.dialogService.openErrorDialog({ title: 'Error', message: errorMessage });
      } else {
        valid = true;
      }
    }
    return valid;
  }

  getMaxDateToError() {
    return this.maxDateToError;
  }

  clearInsurer() {
    this.insurerNames = [];
    this.currentInsurer = [];
    this.currentInsurerValues = [];
    this.insurerName.setValue('', { emitEvent: false });
    this.actualInsurer = '';
  }

  clearProduct() {
    this.productsList = [];
    this.currentProducts = [];
    this.currentProductValues = [];
    this.product.setValue('', { emitEvent: false });
    this.actualProduct = '';
  }

  clearCover() {
    this.coversList = [];
    this.currentCoverValues = [];
    this.cover.setValue('', { emitEvent: false });
    this.actualSelectedCover = '';
  }

}
