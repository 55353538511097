<ng-container [formGroup]="addressForm">
  <section>
    <mat-form-field>
      <mat-label>Post Code</mat-label>
      <input [required]="true" data-test="postcodeInput" spellcheck="false" autocomplete="new-password"
        oninput="this.value = this.value?.toUpperCase()" matInput placeholder="Post Code" formControlName="postcode">
        <mat-error *ngIf="addressForm?.postcode?.errors?.maxlength && !addressForm?.postcode?.errors?.postcodenotfound">Postcode can be max 10 characters long</mat-error>
      <mat-error *ngIf="addressForm?.postcode?.invalid && !addressForm?.postcode?.value">Post Code is required
      </mat-error>
      <mat-error
        *ngIf="addressForm?.postcode?.value && addressForm?.postcode?.errors?.postcodenotfound && !addressForm?.postcode?.errors?.specialCharacter">
        Post Code not found</mat-error>
      <mat-error *ngIf="addressForm?.postcode?.value && addressForm?.postcode?.errors?.specialCharacter">
        {{addressForm?.postcode?.errors?.specialCharacter?.message}}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label class="label-address">Select address from dropdown</mat-label>
      <mat-select data-test="possibleAddressSelect" #possibleAddressesComponent
        placeholder="Select address from dropdown">
        <mat-option *ngFor="let address of addressForm?.possibleAddresses" [value]="address">
          {{address.addressLine1}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </section>
  <section>
    <mat-form-field>
      <mat-label>Address Line 1</mat-label>
      <input [required]="true" data-test="addressLine1" spellcheck="false" matInput
        TitleCase placeholder="Address Line 1" formControlName="address1">
      <mat-error *ngIf="addressForm?.address1?.errors?.required || addressForm?.address1?.errors?.whitespace">Address Line 1 is required</mat-error>
      <mat-error *ngIf="addressForm?.address1?.errors?.maxlength">Address Line 1 can be max 250 characters long</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Address Line 2</mat-label>
      <input spellcheck="false" data-test="addressLine2" matInput placeholder="Address Line 2"
      TitleCase formControlName="address2">
      <mat-error *ngIf="addressForm?.address2?.errors?.maxlength">Address Line 2 can be max 250 characters long</mat-error>
    </mat-form-field>

  </section>
  <section>
    <mat-form-field>
      <mat-label>Address Line 3</mat-label>
      <input [required]="true" spellcheck="false" data-test="addressLine3" matInput placeholder="Address Line 3"
      TitleCase formControlName="address3">
      <mat-error *ngIf="addressForm?.address3?.errors?.required || addressForm?.address3?.errors?.whitespace">Address Line 3 is required</mat-error>
      <mat-error *ngIf="addressForm?.address3?.errors?.maxlength">Address Line 3 can be max 250 characters long</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Address Line 4</mat-label>
      <input spellcheck="false" data-test="addressLine4" matInput placeholder="Address Line 4"
      TitleCase formControlName="address4">
      <mat-error *ngIf="addressForm?.address4?.errors?.maxlength">Address Line 4 can be max 250 characters long</mat-error>
    </mat-form-field>
  </section>
  <section>
    <mat-form-field>
      <mat-label>Address Line 5</mat-label>
      <input spellcheck="false" data-test="addressLine5" matInput placeholder="Address Line 5"
      TitleCase formControlName="address5">
      <mat-error *ngIf="addressForm?.address5?.errors?.maxlength">Address Line 5 can be max 250 characters long</mat-error>
    </mat-form-field>
  </section>
</ng-container>
