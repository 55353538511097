<main >
  <mat-card appearance="outlined">
    <mat-card-header class="card-header">
      <mat-card-title><strong>Users</strong></mat-card-title>
      <mat-card-subtitle>
        Create and maintain users here
      </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content class="display-flex flex-direction-column">
      <div class="display-flex flex-direction-row">
        <button mat-button class="btn-table-row btn-create" (click)="createUser()" matTooltip="Add New User">
          <mat-icon>add</mat-icon>
        </button>
        <div [formGroup]="filterForm">
          <div></div>
          <div>
            <mat-form-field class="select-type-roleId">
              <mat-label>Role</mat-label>
              <mat-select data-test="roleId" formControlName="roleId" placeholder="Role">
                <mat-option value=''>All</mat-option>
                <mat-option *ngFor="let p of roles" [value]="p.id">
                  {{p.description}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div [formGroup]="searchForm" class="search-group">
          <div class="section">
            <app-shared-search (onSearch)="search()" (onValueChange)="onValueChange($event)"
              [placeholderText]="'Search by Username, Broker branch or Email '">
            </app-shared-search>
          </div>
          <div class="include-inactive">
            <label id="include-inactive-items" class="label-include"><span><strong>Include Inactive
                  Items:</strong></span>
            </label>
            <mat-radio-group formControlName="isIncludedInactive">
              <mat-radio-button data-test="noRadio" value="false" [checked]="isIncludedInactive.value === false">No
              </mat-radio-button>
              <mat-radio-button data-test="yesRadio" value="true" [checked]="isIncludedInactive.value === true">Yes
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </div>

      <table mat-table [dataSource]="dataSource" matSort matSortActive="userName" matSortDirection="asc"
        matSortDisableClear>

        <ng-container matColumnDef="userName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
          <td mat-cell *matCellDef="let row">{{row.userName}}</td>
        </ng-container>

        <ng-container matColumnDef="role">
          <th mat-header-cell *matHeaderCellDef>Role</th>
          <td mat-cell *matCellDef="let row">{{row.role.description}}</td>
        </ng-container>

        <ng-container matColumnDef="brokerBranch">
          <th mat-header-cell *matHeaderCellDef>Broker Branch</th>
          <td mat-cell *matCellDef="let row">{{row.brokerBranch?.name}}</td>
        </ng-container>


        <ng-container matColumnDef="phoneNumber">
          <th mat-header-cell *matHeaderCellDef>Telephone</th>
          <td mat-cell *matCellDef="let row">{{formatPhoneNumber(row.phoneNumber)}}
          </td>
        </ng-container>

        <ng-container matColumnDef="emailAddress">
          <th mat-header-cell *matHeaderCellDef>Email Address</th>
          <td mat-cell *matCellDef="let row">{{row.emailAddress}}</td>
        </ng-container>

        <ng-container matColumnDef="isActive">
          <th mat-header-cell *matHeaderCellDef>Active</th>
          <td mat-cell *matCellDef="let row">
            <mat-icon *ngIf="row.isActive" svgIcon="check" class="colour-success"></mat-icon>
            <mat-icon *ngIf="!row.isActive" svgIcon="close" class="colour-danger"></mat-icon>
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Edit</th>
          <td mat-cell *matCellDef="let row">
            <button mat-button class="btn-action" (click)="editUser(row)" matTooltip="Edit">
              <mat-icon svgIcon="pencil"></mat-icon>
            </button>
          </td>
        </ng-container>
        <ng-container matColumnDef="resend">
          <th mat-header-cell *matHeaderCellDef>Resend Registration</th>
          <td mat-cell *matCellDef="let row">
            <button mat-mini-fab class="full-width" (click)="resendRegistration(row)" [disabled]="!row.isActive"
              matTooltip="Resend Registration">
              <mat-icon *ngIf="row.isActive" svgIcon="send" style="font-weight: bolder; color: white"></mat-icon>

              <mat-icon *ngIf="!row.isActive" svgIcon="send" style="font-weight: bolder"></mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

      <div class="flex-direction-row">
        <span class="not-found-text">{{notFoundMessage}}</span>
        <mat-paginator [length]=" dataSource.totalRecords" [pageIndex]="pagingConstant.pageIndex"
          [pageSize]="pagingConstant.pageSizeLarger" [pageSizeOptions]="pageSizeOptions" showFirstLastButtons>
        </mat-paginator>
      </div>
    </mat-card-content>
  </mat-card>

</main>
