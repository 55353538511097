import { Component, ViewChild, AfterViewInit, Input } from '@angular/core';
import * as _ from 'lodash';
import { ApiEndPoints } from 'src/app/shared/config/api-end-points';
import { HttpBaseService } from 'src/app/shared/services/http-base.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { Product } from 'src/app/models/product';
import { SubProduct } from 'src/app/models/sub-product';
import { SubProductPopupComponent } from './sub-product-popup/sub-product-popup.component';
import { SubProductDocumentControlComponent } from './sub-product-document-control/sub-product-document-control.component';
import { SubProductDocumentComponent } from './sub-product-document/sub-product-document.component';
import { BehaviorSubject } from 'rxjs';
import { DocumentType } from 'src/app/models/document';
import { tap } from 'rxjs/operators';
import { MatDialogConfig } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-sub-product',
  templateUrl: './sub-product.component.html',
  styleUrls: ['./sub-product.component.scss']
})

export class SubProductComponent implements AfterViewInit {

  displayedColumns: string[] = ['name', 'active', 'edit', 'document-control', 'at_a_glance', 'IPID', 'master_certificate', 'FVA'];

  @Input() product$: BehaviorSubject<Product>;
  product: Product;

  subProducts: SubProduct[] = [];

  configSubProductDialog = new MatDialogConfig();
  configDocumentControlDialog = new MatDialogConfig();
  configDocumentDialog = new MatDialogConfig();

  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private readonly _api: ApiEndPoints,
    private readonly _httpService: HttpBaseService,
    private readonly _dialogService: DialogService) {

    this.configDocumentDialog.disableClose = true;
    this.configDocumentDialog.width = '800px';

    this.configSubProductDialog.disableClose = true;
    this.configSubProductDialog.width = '1000px';

    this.configDocumentControlDialog.disableClose = true;
    this.configDocumentControlDialog.width = '1280px';
    this.configDocumentControlDialog.height = '800px';
  }

  ngAfterViewInit() {
    this.product$ && this.product$.subscribe(p => {
      if (p) {
        this.product = p;
        this.subProducts = _.orderBy(this.product.subProducts, 'order');
      }
    });

    if (this.sort) {
      this.sort.sortChange
        .pipe(
          tap(() => this.sortSubProducts())
        )
        .subscribe();
    }
  }

  sortSubProducts() {
    this.subProducts = _.orderBy(this.subProducts, this.sort.active, [this.sort.direction === 'asc' ? 'asc' : 'desc']);
  }

  async reloadProduct() {
    this.product = await this._httpService.getDataAsync<Product>(this._api.getProduct(this.product.id));
    this.subProducts = _.orderBy(this.product.subProducts, 'order');
  }

  editSubProduct(subProduct: SubProduct) {
    this._dialogService.openComponentDialog(SubProductPopupComponent, { product: this.product, subProduct }, this.configSubProductDialog).subscribe(async result => {
      if (result) {
        this.reloadProduct();
      }
    });
  }

  createSubProduct() {
    this._dialogService.openComponentDialog(SubProductPopupComponent, { product: this.product }, this.configSubProductDialog).subscribe(async result => {
      if (result) {
        this.reloadProduct();
      }
    });
  }

  controlDocument(subProduct: SubProduct) {
    this._dialogService.openComponentDialog(SubProductDocumentControlComponent, { product: this.product, subProduct }, this.configDocumentControlDialog).subscribe(async result => {
      if (result) {
        this.reloadProduct();
      }
    });
  }

  openSubProductView(subProduct: SubProduct) {
    this._dialogService.openComponentDialog(SubProductDocumentComponent, { product: this.product, subProduct }, this.configDocumentDialog).subscribe(async result => {
      if (result) {
        this.reloadProduct();
      }
    });
  }

  invalidAddSubProduct() {
    return this.product === undefined || this.product.id === undefined;
  }

  isAtAGlanceDocument(subProduct: SubProduct) {
    return _.find(subProduct.documents, (x) => x.documentType === DocumentType.AtAGlance && x.isActive) !== undefined;
  }

  isIPID(subProduct: SubProduct) {
    return _.find(subProduct.documents, (x) => x.documentType === DocumentType.IPID && x.isActive) !== undefined;
  }

  isMasterCertificate(subProduct: SubProduct) {
    return _.find(subProduct.documents, (x) => x.documentType === DocumentType.MasterCertificate && x.isActive) !== undefined;
  }

  isFVA(subProduct: SubProduct) {
    return _.find(subProduct.documents, (x) => x.documentType === DocumentType.FVA && x.isActive) !== undefined;
  }
}

