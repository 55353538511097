import { Component, OnInit, Inject } from '@angular/core';
import { SellableLevelOfCover } from 'src/app/models/sellable-level-of-cover';
import { Policy } from 'src/app/models/insurance-policy';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-loc-rechoice',
    templateUrl: 'loc-rechoice.component.html',
    styleUrls: ['./loc-rechoice.component.scss']
})

export class LevelOfCoverRechoiceComponent implements OnInit {

    policy: Policy;
    originalLevelOfCovers: SellableLevelOfCover[];
    newLevelOfCovers: SellableLevelOfCover[];
    displayedColumns: string[] = ['lovDisplayName', 'brokerNet', 'iptRate',  'gross', 'select'];

    selectedLoC: SellableLevelOfCover = null;
    currentLoC: SellableLevelOfCover;
    vehicleRegistration: string = '';

    constructor(
        public dialogRef: MatDialogRef<LevelOfCoverRechoiceComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    ngOnInit() {
        this.policy = this.data.policy;
        this.newLevelOfCovers = (this.data.newLevelOfCovers as SellableLevelOfCover[]).map( loc => this.calculateMissingData(loc));
        this.currentLoC = this.data.currentLoC;
        this.originalLevelOfCovers = [this.currentLoC];
        this.vehicleRegistration = this.currentLoC.vehicle.registration;
    }

    onSelectLevelOfCover(item: SellableLevelOfCover) {
        this.selectedLoC = item;
        this.selectedLoC.isNewLoc = true;
    }

    onSave() {
        this.selectedLoC.policyCoverDetailId = this.currentLoC.policyCoverDetailId;

        let policyLoCs: SellableLevelOfCover[] = [];
        this.policy.levelOfCovers.forEach(newLoc=>{
            if(newLoc.vehicle.registration === this.vehicleRegistration){
                newLoc = this.selectedLoC;
            }
            policyLoCs.push(newLoc);
        });

        this.policy.levelOfCovers = policyLoCs;

        this.dialogRef.close(true);
    }

    onCancel() {
        this.dialogRef.close(false);
      }

    calculateMissingData(loc: SellableLevelOfCover) {
        loc.customBrokerNet = loc.brokerNet;
        loc.iptAmount = loc.customIptAmount = loc.iptRate / 100 * loc.brokerNet;
        loc.clientGrossPremium = loc.customClientGrossPremium = loc.iptRate / 100 * loc.brokerNet + loc.brokerNet;
        return loc;
    }

    moneytary(num: any) {
        return `£${parseFloat(num).toFixed(2)}`;
    }
}
