import { AfterViewInit, Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { AddressInfoFormControlModel } from '../../../models/address-info-form-control-model';

@Component({
  selector: 'app-address-info',
  templateUrl: './address-info.component.html',
  styleUrls: ['./address-info.component.scss']
})
export class AddressInfoComponent implements AfterViewInit, OnChanges {
  @Input() addressForm: AddressInfoFormControlModel;
  @Input() required: boolean;

  @ViewChild('possibleAddressesComponent') possibleAddressesComponent: MatSelect;

  constructor() { }
  ngOnChanges(changes: SimpleChanges): void {
    this.addressForm.setValidationRules(this.required);
  }

  ngAfterViewInit() {
    this.addressForm.setPossibleAddressesComponent(this.possibleAddressesComponent);
  }

  uppercase(value: string) {
    return value.toUpperCase();
  }
}
