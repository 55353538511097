import { Component, OnInit, ViewChild } from '@angular/core';
import * as _ from "lodash";
import { AuthService } from 'src/app/services/auth.service';
import { map, take } from 'rxjs/operators';
import { Roles } from 'src/app/models/enums/roles';
import { MasterSettingComponent } from './master-setting/master-setting.component';
import { IptRateSettingComponent } from './ipt-rate-setting/ipt-rate-setting.component';
import { ComponentCanDeactivate } from 'src/app/shared/can-deactivate/component-can-deactivate';
import {MatTabChangeEvent} from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { TitleMessages } from 'src/app/shared/constants/constants';
import { MatTabGroup } from '@angular/material/tabs';

@Component({
  selector: 'app-system-setting',
  templateUrl: './system-setting.component.html',
  styleUrls: ['./system-setting.component.scss']
})
export class SystemSettingComponent extends ComponentCanDeactivate implements OnInit {
  @ViewChild('editMasterSetting')
  editMasterSetting: MasterSettingComponent;
  @ViewChild(MatTabGroup) tabGroups: MatTabGroup;
  @ViewChild('editIPTRate')
  editIPTRate: IptRateSettingComponent;
  tabs = [
    null, // 'ipt rates',
    'email-setting',
    'master-setting'
  ];
  tabIndex = 0;
  isHoDesign = true;
  constructor(private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dialogService: DialogService) {
    super();
    this.authService.userInfo$.pipe(
      map((user) => (user && user.role === Roles.HODESIGN)),
      take(1),
    ).subscribe(hoDesign => this.isHoDesign = hoDesign);
  }
  ngOnInit(): void {
    this.activatedRoute.fragment.subscribe(fragment => {
      const index = this.tabs.findIndex(t => t == fragment);

      if (index < 0) {
        this.router.navigate([`/app`]);
        return;
      }

      this.tabIndex = index;
    });
  }

 get hoDesignUser(){
    return this.isHoDesign;
  }

  onTabChanged(event: MatTabChangeEvent) {
    this.markActiveTab(this.tabs[event.index]);
  }

  markActiveTab(fragment: string) {
    this.router.navigate([`/app/settings/system-setting`], { fragment: fragment });
  }

  canDeactivate(): boolean {
    if (!this.editMasterSetting || !this.editIPTRate) {
      return true;
    }
    return this.editMasterSetting.systemSettingGroup.pristine && this.editIPTRate.form.pristine;
  }

  ngAfterViewInit(): void {
    // 1. Save the click handler for later
    const handleTabClick = this.tabGroups._handleClick;
    // 2. Replace the click handler with our custom function
    this.tabGroups._handleClick = (tab, header, index) => {
      // 3. Implement your conditional logic in canDeactivateTab()
      // (return the boolean here)
      if (this.canDeactivate()) {
        // 4. If the tab *should* be changed, call the 'old' click handler
        handleTabClick.apply(this.tabGroups, [tab, header, index]);
      }
      else {
        this.dialogService.openConfirmationDialog({ title: TitleMessages.confirmationTitle, message: 'Are you sure you want to continue. Your changes will be lost?' }).subscribe(result => {
          if (result) {
            this.editIPTRate.resetForm();
            this.editMasterSetting.reset();
            handleTabClick.apply(this.tabGroups, [tab, header, index]);
          }
        });
      }
    };
    let service = this.dialogService;
    let editMaster = this.editMasterSetting;
    let editIPTRate = this.editIPTRate;
    window.onhashchange = function () { //handle back browser
      if (!(editMaster.systemSettingGroup.pristine)) {
        editMaster.markAsPristine(); //prevent show popup 2 times
        service.openConfirmationDialog({ title: TitleMessages.confirmationTitle, message: 'Are you sure you want to continue. Your changes will be lost?' }).subscribe(result => {
          if (result) {
            editMaster.reset();
          }
          else {
            window.history.forward();
            setTimeout(() => {
              editMaster.markAsDirty();//prevent show popup 2 times
            }, 100);
          }
        });
      }

      if (!(editIPTRate.form.pristine)) {
        editIPTRate.markAsPristine(); //prevent show popup 2 times
        service.openConfirmationDialog({ title: TitleMessages.confirmationTitle, message: 'Are you sure you want to continue. Your changes will be lost?' }).subscribe(result => {
          if (result) {
            editIPTRate.resetForm();
          }
          else {
            window.history.forward();
            setTimeout(() => {
              editIPTRate.markAsDirty(); // prevent show popup 2 times
            }, 100);
          }
        });
      }
    }
  }
}
