export enum Roles {
    HOSYSADMIN='HO.SYSADMIN',
    HOADMIN='HO.ADMIN',
    HOSUPERVISOR='HO.SUPERVISOR',
    HOUSER='HO.USER',
    HODESIGN='HO.DESIGN',

    BROKERADMIN='BROKER.ADMIN',
    BROKERSUPERVISOR='BROKER.SUPERVISOR',
    BROKERUSER='BROKER.USER'
}