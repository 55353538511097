
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormBuilder, Validators, AbstractControl, FormGroup, FormControl, NgForm } from '@angular/forms';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { StationaryMaintenance } from 'src/app/models/stationary-maintenance';
import { HttpBaseService } from 'src/app/shared/services/http-base.service';
import { ApiEndPoints } from 'src/app/shared/config/api-end-points';
import { StationaryStatus } from 'src/app/models/stationary-status';
import { StatusCodeConstant, TitleMessages } from 'src/app/shared/constants/constants';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { noWhitespace } from 'src/app/validators/no-whitespace.validator';
import { noSpecialCharacterValidator } from 'src/app/validators/no-special-character.validator';


function lessThan(property: string) {
  return (control: FormControl) => {
    if (!control.parent) { return; }
    const compareControl = control.parent.controls[property]

    const valueMax = compareControl.value;
    const valueMin = control.value;

    if (valueMax != '' && valueMax !== null && parseInt(valueMin) >= parseInt(valueMax) && valueMin !== '' && valueMin !== null) {
      return { 'lessThanQuantity': true };
    }
    return null
  }
}

@Component({
  selector: 'app-stationary-maintenance-popup',
  templateUrl: './stationary-maintenance-popup.component.html',
  styleUrls: ['./stationary-maintenance-popup.component.scss']
})

export class StationaryMaintenancePopupComponent implements OnInit {
  updateStationary: StationaryMaintenance;
  updateMode = false;
  stationaryForm: FormGroup;
  @ViewChild('formDirective') formDirective: NgForm;

  statuses: StationaryStatus[] = [];
  errorMesageDuplicate: any;
  error: any = { isError: false, errorMessage: '' };
  saveSuccessMessage = '';
  updateSuccessMessage = '';
  requiredQuantity: boolean = true;

  get isRequiredQuantity() {
    return this.requiredQuantity;
  }
  get isUpdateMode() {
    return this.updateMode;
  }

  get description() {
    return this.stationaryForm.get('description') as AbstractControl;
  }

  get status() {
    return this.stationaryForm.get('status') as AbstractControl;
  }

  get statusNotes() {
    return this.stationaryForm.get('statusNotes') as AbstractControl;
  }

  get minimumOrderQuantity() {
    return this.stationaryForm.get('minimumOrderQuantity') as AbstractControl;
  }

  get maximumOrderQuantity() {
    return this.stationaryForm.get('maximumOrderQuantity') as AbstractControl;
  }

  get incrementQuantity() {
    return this.stationaryForm.get('incrementQuantity') as AbstractControl;
  }

  constructor(
    private readonly _httpService: HttpBaseService,
    private readonly _api: ApiEndPoints,
    public dialogService: DialogService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<StationaryMaintenancePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.createForm();
    this.updateStationary = data.stationary;
  }

  createForm() {
    this.stationaryForm = this.formBuilder.group({
      description: ['', [Validators.required, noWhitespace]],
      status: ['', [Validators.required]],
      statusNotes: [''],
      minimumOrderQuantity: ['', [Validators.required, Validators.min(1), lessThan('maximumOrderQuantity'), noSpecialCharacterValidator]],
      maximumOrderQuantity: ['', [Validators.required, Validators.min(1), noSpecialCharacterValidator]],
      incrementQuantity: ['', [Validators.required, Validators.min(1), noSpecialCharacterValidator]],
    });
  }

  async ngOnInit() {
    if (this.updateStationary) {
      this.updateMode = true;
      this.initDataForUpdate(this.updateStationary);
    }
    await this.loadStationaryStatuses();
    this.status.valueChanges.subscribe(value => {
      this.requiredQuantity = value === 3 ? false : true;
      this.setValidationRulesForQuantity();
    });
  }


  async loadStationaryStatuses() {
    this.statuses = await this._httpService.getDataAsync<StationaryStatus[]>(this._api.getStationaryStatuses());
  }


  initDataForUpdate(stationary: StationaryMaintenance) {
    if (stationary) {
      this.description.setValue(stationary.description);
      this.status.setValue(stationary.status);
      this.statusNotes.setValue(stationary.statusNotes);
      this.minimumOrderQuantity.setValue(stationary.minOrderQuantity);
      this.maximumOrderQuantity.setValue(stationary.maxOrderQuantity);
      this.incrementQuantity.setValue(stationary.incrementQuantity);
      if (stationary.status===3){
        this.requiredQuantity=false;
        this.setValidationRulesForQuantity();
      }
      this.stationaryForm.markAsPristine();
    }
  }

  async onSaveAndClose() {
    //DO SAVE DATA and CLOSE
    await this.save(true);
  }

  async save(closeDialog: boolean) {
    if (this.stationaryForm.valid) {
      let stationary: StationaryMaintenance = {
        id: this.updateMode ? this.updateStationary.id : undefined,
        rowVersion: this.updateMode ? this.updateStationary.rowVersion : undefined,
        description: this.description.value,
        status: this.status.value,
        statusNotes: this.statusNotes.value,
        minOrderQuantity: this.minimumOrderQuantity.value,
        maxOrderQuantity: this.maximumOrderQuantity.value,
        incrementQuantity: this.incrementQuantity.value
      };
      if (!this.updateMode) {
        try {
          await this._httpService.postDataAsync<string>(this._api.createStationary(), stationary);
            this.dialogService.openSuccessDialogConfirm({ title: TitleMessages.successTitle, message: 'The stationery has been saved successfully'}).subscribe(result => {
              if(result) {
                this.dialogRef.close(true);
              }
            });
        } catch (ex) {
          if (ex.status === StatusCodeConstant.conflict) {
            // Close "create popup". Another message is shown automatically with the message returned from server
            this.dialogRef.close(true);
          }
        }
      }
      
      else {
        try {
          await this._httpService.putDataAsync<StationaryMaintenance>(this._api.updateStationary(), stationary);
          this.dialogService.openSuccessDialogConfirm({
            title: TitleMessages.successTitle, message: 'The stationery has been updated successfully'}).subscribe(result => {
              if(result) {
                this.dialogRef.close(true);
              }
            });
        } catch (ex) {
          
        }
      }
      
    }
    else {
      return;
    }
   
  }

  onCloseDialog() {
    if (this.stationaryForm.dirty) {
      this.dialogService.openConfirmationDialog({ title: TitleMessages.confirmationTitle, message: 'Are you sure you want to continue. Your changes will be lost?' }).subscribe(result => {
        if (result) {
          this.dialogRef.close();
        }
      });
    }
    else {
      this.dialogRef.close();
    }
  }

  ngAfterContentInit() {
    this.maximumOrderQuantity.valueChanges.subscribe(() => {
      this.minimumOrderQuantity.updateValueAndValidity({ emitEvent: false });
    });
  }

  clearData() {
    this.formDirective.resetForm();
    this.stationaryForm.markAsUntouched();
  }

  setValidationRulesForQuantity() {
    if (this.isRequiredQuantity) {
      this.minimumOrderQuantity.enable();
      this.incrementQuantity.enable();
      this.maximumOrderQuantity.enable();
    }
    else {
      this.minimumOrderQuantity.setValue('');
      this.incrementQuantity.setValue('');
      this.maximumOrderQuantity.setValue('');
      this.minimumOrderQuantity.disable();
      this.incrementQuantity.disable();
      this.maximumOrderQuantity.disable();
    }
    this.stationaryForm.updateValueAndValidity();
  }

}

