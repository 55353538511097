import { Component } from '@angular/core';
import { NavService } from '../side-nav/nav.service';
import { AuthService } from 'src/app/services/auth.service';
import { DialogService } from '../../services/dialog.service';
import { UserDetailPopupComponent } from 'src/app/components/user-detail-popup/user-detail-popup.component';
import { MatDialogConfig } from '@angular/material/dialog';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent {
  loading: boolean = true;
  screenType: any;
  displaySearch: boolean = true;
  userProfile: object;

  public userName: string = 'Authenticating...';
  firstName: string = '';
  lastName: string = '';
  configUserDialog = new MatDialogConfig();

  constructor(
    private authService: AuthService,
    private navService: NavService,
    private readonly dialogService: DialogService) {

    this.configUserDialog.position = {
      top: 65 + 'px',
      right: '0px'
    };
    this.configUserDialog.width = '600px';

    this.authService.isAuthenticated$.subscribe(authenticated => {
      if (authenticated) {
        this.authService.userInfo$.subscribe(user => {
          this.userProfile = user;
          if (this.userProfile && this.userProfile['userName']) {
            this.loading = false;
            this.userName = 'Welcome ' + this.userProfile['userName'];
          }
        });
      }
    });
  }

  public onMenuButtonClick() {
    setTimeout(() => {
      this.navService.NavClick();
    }, 10);
  }

  openUserDetails() {
    this.dialogService.openComponentDialog(UserDetailPopupComponent, { user: this.userProfile }, this.configUserDialog).subscribe(async result => {
    });
  }
}
