export enum AuditEntity {
    Broker = 'Broker',
    ['Broker Branch'] = 'BrokerBranch',
    Product = 'Product',
    ['Sub Product'] = 'SubProduct',
    ['Level Of Cover'] = 'LevelOfCover',
    Policy = 'Policy',
    Quote = 'Quote',
    User= 'User'
}

export namespace AuditEntity {
    export function values() {
        return Object.keys(AuditEntity).filter((type) => isNaN(<any>type) && type !== 'values');
    }
}
