export enum AlertPriority {
    Normal = 'Normal',
    Medium = 'Medium',
    High = 'High',
}

export namespace AlertPriority {
    export function values() {
        return Object.keys(AlertPriority).filter((type) => isNaN(<any>type) && type !== 'values');
    }
}