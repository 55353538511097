import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-authenticate',
  templateUrl: './authenticate.component.html',
  styleUrls: ['./authenticate.component.scss']
})
export class AuthenticateComponent implements OnInit {

  isUnavaiable = false;

  constructor(private readonly router: Router) {
  }

  ngOnInit() {
    if (this.router.url.includes("app-unavailable")) {
      this.isUnavaiable = true;
    }
  }

}
