import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { Product } from 'src/app/models/product';
import { HttpBaseService } from 'src/app/shared/services/http-base.service';
import { ApiEndPoints } from 'src/app/shared/config/api-end-points';
import { combineLatest, Subscription, BehaviorSubject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { EMPTY_GUID, DEFAULT_KINDERTONS_LOGO, TitleMessages} from 'src/app/shared/constants/constants';
import { Roles } from 'src/app/models/enums/roles';
import { map, take } from 'rxjs/operators';
import { Status } from 'src/app/models/enums/status.enum';
import { noWhitespace } from 'src/app/validators/no-whitespace.validator';

@Component({
  selector: 'app-product-information',
  templateUrl: './product-information.component.html',
  styleUrls: ['./product-information.component.scss']
})
export class ProductInformationComponent implements OnInit, OnDestroy {

  private paramsSubscription!: Subscription;

  panelProductOpenState = true;
  panelSubProductOpenState = false;
  panelLevelOfCoverOpenState = false;
  customCollapsedHeight = '90px';

  updateMode = false;

  dataChanged = false;

  productInformationPlaceHolder = `<p>The product information</p>`;
  isFocused: boolean = false;
  countChanged: number = 0;

  currentProduct: Product;
  currentProduct$: BehaviorSubject<Product> = new BehaviorSubject<Product>(undefined);

  productId: string = EMPTY_GUID;
  productIconUrl: string = '';

  uploadIconFile: File;

  ckeConfig: any;
  mycontent: string;
  @ViewChild('productDescriptionCkEditor') ckeditor: any;

  updateProductDescription: string;

  Status = Status;

  productSourceTypes = [
    { value: 'Hub', name: 'Hub' },
    { value: 'API', name: 'API' },
    { value: 'Both', name: 'Both' },
  ];

  productForm = this._formBuilder.group({
    productName: ['', [Validators.required, noWhitespace]],
    productShortName: ['', [Validators.required]],
    productSourceType: ['', [Validators.required]],
    productType: ['', [Validators.required]],
    productStatus: [Status.Active, [Validators.required]],
    productIcon: ['', [Validators.required]],
    productColour: ['', [Validators.required]],
    productOrder: ['', [Validators.required]],
    premiumNominalCode: ['', [Validators.required]],
    iptNominalCode: ['', [Validators.required]],
    productDescription: ['']
  });


  loadingCkEditorInFlight$ = new BehaviorSubject<boolean>(false);
  isHoDesign = true;

  saved: boolean = false;

  get hoDesignUser() {
    return this.isHoDesign;
  }

  constructor(private readonly _formBuilder: FormBuilder,
    private readonly _httpService: HttpBaseService,
    private readonly _api: ApiEndPoints,
    private readonly _activeRouter: ActivatedRoute,
    private readonly _router: Router,
    private readonly _authService: AuthService,
    private readonly _dialogService: DialogService,
    private readonly router: Router) {
    this._authService.userInfo$.pipe(
      map((user) => (user && user.role === Roles.HODESIGN)),
      take(1),
    ).subscribe(
      hoDesign => {
        this.isHoDesign = hoDesign;
      }
    );

    this.loadingCkEditorInFlight$.next(true);
    // this.mycontent = this.productInformationPlaceHolder;

    this.ckeConfig = {
      uiColor: '#ffffff',
      allowedContent: false,
      extraPlugins: 'div,widget,lineutils',
      forcePasteAsPlainText: true,
      emoji_minChars: 0,
      fileTools_requestHeaders: {
        'Content-Disposition': 'mutipart/form-data',
        'Authorization': `Bearer ${this._authService.accessToken}`
      },
      filebrowserUploadMethod: 'xhr',
      filebrowserImageUploadUrl: this._api.ckEditorUploadFile(false, true),
      filebrowserUploadUrl: this._api.ckEditorUploadFile(false, true),
    };

    this.paramsSubscription = combineLatest(this._activeRouter.params,
      (params) => ({ params })).subscribe(async allParams => {

        let params = allParams.params;
        const productId = params.productId;

        if (productId !== undefined) {
          this.updateMode = true;
          this.productId = productId;
          this.initDataForUpdateProduct(this.productId);
        }
      });
  }

  public get productName() {
    return this.productForm.get('productName') as AbstractControl;
  }

  public get productShortName() {
    return this.productForm.get('productShortName') as AbstractControl;
  }

  public get productSourceType() {
    return this.productForm.get('productSourceType') as AbstractControl;
  }

  public get productType() {
    return this.productForm.get('productType') as AbstractControl;
  }

  public get productStatus() {
    return this.productForm.get('productStatus') as AbstractControl;
  }

  public get productIcon() {
    return this.productForm.get('productIcon') as AbstractControl;
  }

  public get productColour() {
    return this.productForm.get('productColour') as AbstractControl;
  }

  public get productOrder() {
    return this.productForm.get('productOrder') as AbstractControl;
  }

  public get premiumNominalCode() {
    return this.productForm.get('premiumNominalCode') as AbstractControl;
  }

  public get iptNominalCode() {
    return this.productForm.get('iptNominalCode') as AbstractControl;
  }

  public get productDescription() {
    return this.productForm.get('productDescription') as AbstractControl;
  }

  async ngOnInit() {
    this.productForm.valueChanges.subscribe(() => {
      if(this.productForm.dirty) {
        this.dataChanged = true;
      }
    });
  }

  canDeactivate(): boolean {
    //2 cases:
    //1 - changes without saved: dataChanged = true; saved = false; -> display popup message data lost (return false)
    //2 - changes and saved: datachanged = true; saved = true; -> don#t display popup message data lost (return true)

    if(!this.productForm.dirty) return true;

    return  this.dataChanged && this.saved;
  }

  async loadProductSourceTypes() {
    this.productSourceTypes = await this._httpService.getDataAsync<[]>(this._api.getProductSourceTypes());
  }

  ngOnDestroy(): void {
    this.paramsSubscription.unsubscribe();
  }

  onSelectFile(files: FileList) {
    if (files.length === 0) {
      return;
    }
    const fileToUpload = files.item(0);


    const fileReader: FileReader = new FileReader();
    fileReader.readAsDataURL(fileToUpload);

    fileReader.onload = (event: any) => {
      this.productIcon.setValue(fileToUpload.name);
      this.productForm.markAsDirty();
      this.uploadIconFile = fileToUpload;
    };
    this.dataChanged = true;
  }

  onEditorChange($event: any): void {
    let content = $event as string;
    this.productDescription.setValue(content);
    this.countChanged++;
    this.dataChanged = true;
  }

  onEditorFocus($event: any): void {
    this.isFocused = true;
    this.productForm.markAsDirty();
  }

  onEditorPaste($event: any): void {
    this.productDescription.setValue(this.mycontent);
  }

  onEditorReady($event: any): void {
    this.loadingCkEditorInFlight$.next(false);
    if (this.ckeditor) {
      this.mycontent = this.updateProductDescription;
      this.productDescription.setValue(this.mycontent);
    }
  }

  initDataForUpdateProduct(productId: string) {
    this._httpService.getData<Product>(this._api.getProduct(productId)).subscribe((product) => {

      this.currentProduct = product;
      this.mycontent = product.description;
      this.updateProductDescription = product.description;
      this.productDescription.setValue(product.description);

      this.productName.setValue(product.name);
      this.productShortName.setValue(product.shortName);
      this.productStatus.setValue(product.isActive ? Status.Active : Status.Inactive);
      this.productOrder.setValue(product.order);
      this.productColour.setValue(product.colour.includes('#') ? product.colour.substring(1, product.colour.length) : product.colour);
      this.productIcon.setValue(product.icon);
      this.premiumNominalCode.setValue(product.premiumNominalCode);
      this.iptNominalCode.setValue(product.iptNominalCode);
      this.productSourceType.setValue(product.productSourceType);
      this.productType.setValue(product.productType);
      this.productIconUrl = product.icon;

      this.currentProduct$.next(this.currentProduct);
    });
  }

  async onSave() {
    await this.save(false);
  }

  async onSaveAndClose() {
    await this.save(true);
  }

  disableSave() {
    if (this.productForm.invalid || this.productForm.pristine) {
      return true;
    }

    return false;
  }

  async uploadIcon(iconFile: File) {
    const isPrivate = false;
    const isOverriden = true;

    const url = await this._httpService.uploadAsync<string>(this._api.uploadFile(isPrivate, isOverriden), iconFile);
    return url;
  }

  async save(close: boolean) {
    if (this.productForm.valid) {

      if (this.uploadIconFile) {
        this.productIconUrl = await this.uploadIcon(this.uploadIconFile);
        this.productIcon.setValue(this.productIconUrl);
      }
      else {
        this.productIconUrl = this.updateMode ? this.productIcon.value : DEFAULT_KINDERTONS_LOGO;
      }

      let product: Product = {
        id: this.updateMode ? this.currentProduct.id : undefined,
        rowVersion: this.updateMode ? this.currentProduct.rowVersion : undefined,
        shortName: this.productShortName.value,
        name: this.productName.value,
        description: this.updateMode ? ((this.isFocused && this.countChanged > 0) ? this.productDescription.value : this.currentProduct.description) : this.productDescription.value,
        icon: this.productIconUrl,
        colour: `#${this.productColour.value}`,
        order: this.productOrder.value,
        isActive: this.productStatus.value,
        premiumNominalCode: this.premiumNominalCode.value,
        iptNominalCode: this.iptNominalCode.value,
        productSourceType: this.productSourceType.value,
        productType: this.productType.value,
        levelsOfCover: [],
        branchProduct: null,
        subProducts: []
      };

      this.currentProduct = product;

      if (!this.updateMode) {
        try {
          const newProductId = await this._httpService.postDataAsync<string>(this._api.createProduct(), product);
          this.saved = true;

          const saveSuccessMessage = 'Saving successful. It is ready for adding new one.';
          this._dialogService.openSuccessDialogConfirm({ title: TitleMessages.successTitle, message: saveSuccessMessage }).subscribe(async result => {
            if (result) {
              this.currentProduct.id = newProductId;
              this.openSubProductPanel(close);
              this.currentProduct$.next(this.currentProduct);
              if (!close) {
                this.updateMode = true;
                this.productId = newProductId;
                await this.initDataForUpdateProduct(this.productId);
              }
            }
          });
        }
        finally {
        }
      }
      else {
        try {
          await this._httpService.putDataAsync<Product>(this._api.updateProduct(), product);
          this.saved = true;

          const saveSuccessMessage = 'Product has been updated successfully';
          this._dialogService.openSuccessDialogConfirm({ title: TitleMessages.successTitle, message: saveSuccessMessage }).subscribe(result => {
            if (result) {
              this.openSubProductPanel(close);
            }
          });
        }
        finally {
        }
      }
    }
    else {
      return;
    }
  }

  openSubProductPanel(close: boolean) {
    if (!close) {
      this.panelProductOpenState = false;
      this.panelSubProductOpenState = true;
      this.panelLevelOfCoverOpenState = false;
    }
    else {
      this._router.navigate(['/app/products/configuration']);
    }
  }

  onClose() {
    if (this.productForm.dirty && !this.updateMode) {
      this._dialogService.openConfirmationDialog({ title: TitleMessages.confirmationTitle, message: 'Are you sure you want to continue. Your changes will be lost?' }).subscribe(result => {
        if (result) {
          this._router.navigate(['/app/products/configuration']);
        }
      });
    }
    else {
      this._router.navigate(['/app/products/configuration']);
    }
  }

  getFileName(productIconUrl) {
    if (productIconUrl) {
      let splits = productIconUrl.split('/');
      return splits[splits.length - 1];
    }
    return '';
  }

  checkOrderValue() {
    if (this.productOrder.value === 0) {
      this.productOrder.setValue('');
      this.productOrder.markAsTouched();
    }
  }
}
