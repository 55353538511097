import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { DialogService } from '../services/dialog.service';
import { ComponentCanDeactivate } from './component-can-deactivate';

@Injectable()
export class UnSavedChangesGuard  {

  constructor(private readonly _dialogService: DialogService, private activeRoute: ActivatedRoute) { }

  canDeactivate(
    component: ComponentCanDeactivate,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean> | boolean {
    return nextState?.url === '/error' ? true : component.canDeactivate() ?
      true :
      this._dialogService.openConfirmationDialog(
        {
          title: 'Confirmation Message',
          primaryButtons: 'Yes',
          secondaryButtons: 'No',
          message: "Are you sure you want to continue. Your changes will be lost?"
        }
      )
  }

}
