<div *ngIf="policyAudit" class="container">
  <span mat-header>{{policyAudit.changedBy}} changed at: </span>
  <span mat-header>{{policyAudit.auditAt | date: appconstants.dateConfig.dateAndTime}}</span>
  <div class="audit-report">
    <h4 class="text-elis"> {{Utility.removeTags(policyAudit.changedSummary)}}</h4>
  </div>
  <mat-divider [inset]="true"></mat-divider>
  <div *ngFor="let change of policyAudit.changes" class="audit-report">
    <p mat-line mat-header>
      <span>{{change.modelName}} - {{change.propertyName}}:</span>
      <span class="current break-line" [innerHTML]="change.currentValue | safeHtml"></span>
      <span class="original break-line" [innerHTML]="change.originalValue | safeHtml"></span>
    </p>
  </div>
</div>
