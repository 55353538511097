import { Component } from '@angular/core';
import { AuthService } from './services/auth.service';
import { PreviousRouteService } from './shared/services/previous-route.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  title = 'Admin | The Policy Hub';
  isAuthenticated: boolean;
  isAuthorized: boolean;
  get authenticated() {
    return this.isAuthenticated && this.isAuthorized;
  }
  constructor(private authService: AuthService,
    private _previousRouteService: PreviousRouteService) {
    this.authService.isAuthenticated$.subscribe(authenticated => {
      this.isAuthenticated = authenticated;
      if (!authenticated) {
        this.authService.runInitialLoginSequence();
      }
      else {
        this.authService.userInfo$.subscribe(
          (user) => {
            if (user) {
              this.isAuthorized = true;
            }
          });
      }
    });
  }
}
