<div class="stationary-popup ">
  <h3 class="title" *ngIf="!updateMode">Create new stationery</h3>
  <h3 class="title" *ngIf="updateMode">Edit stationery</h3>
  <mat-dialog-content class="mat-dialog-content">
    <span *ngIf="saveSuccessMessage" class="success-message">{{ saveSuccessMessage }}</span>
    <span *ngIf="updateSuccessMessage" class="success-message">{{ updateSuccessMessage }}</span>
    <br>
    <form [formGroup]="stationaryForm" #formDirective="ngForm">

      <mat-form-field class="description">
        <mat-label>Description</mat-label>
        <textarea matInput placeholder="Description" required data-test="description" formControlName="description"
          cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5"></textarea>
        <mat-error *ngIf="description.errors?.required || description.errors?.whitespace">Description is required</mat-error>
      </mat-form-field>

      <mat-form-field class="select-type">
        <mat-label>Select status from dropdown</mat-label>
        <mat-select data-test="status" required placeholder="Select status from dropdown" formControlName="status">
          <mat-option *ngFor="let status of statuses" [value]="status.value">
            {{status.name}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="status.invalid">Status is required</mat-error>
      </mat-form-field>

      <mat-form-field class="select-type">
        <mat-label>Status Notes</mat-label>
        <input [required]="(status.value === 2 || status.value === 3)? true : null" matInput data-test="statusNotes"
          placeholder="Status Notes" formControlName="statusNotes" maxlength="100">
        <mat-error *ngIf="statusNotes.invalid">StatusNotes is required</mat-error>
      </mat-form-field>

      <mat-form-field *ngIf="status.value !== 3" class="select-type">
        <mat-label>Minimum Order Quantity</mat-label>
        <input [required]="status.value !== 3"  matInput data-test="minimumOrderQuantity" placeholder="Minimum Order Quantity"
           formControlName="minimumOrderQuantity"  numberOnly>
           <mat-error *ngIf="minimumOrderQuantity.value && minimumOrderQuantity.errors?.specialCharacter">
            {{minimumOrderQuantity.errors?.specialCharacter?.message}}</mat-error>
        <mat-error *ngIf="minimumOrderQuantity.errors?.required">Minimum Order Quantity is required</mat-error>
        <mat-error *ngIf="minimumOrderQuantity.errors?.lessThanQuantity">Minimum Quantity must be less than Maximum Quantity</mat-error>
        <mat-error *ngIf="minimumOrderQuantity.errors?.min && !minimumOrderQuantity.errors?.lessThanQuantity">Please enter a value more than 0</mat-error>
      </mat-form-field>

      <mat-form-field *ngIf="status.value !== 3" class="select-type">
        <mat-label>Maximum Order Quantity</mat-label>
        <input [required]="status.value !== 3"   matInput data-test="maximumOrderQuantity" placeholder="Maximum Order Quantity"
          formControlName="maximumOrderQuantity" numberOnly>
          <mat-error *ngIf="maximumOrderQuantity.value && maximumOrderQuantity.errors?.specialCharacter">
            {{maximumOrderQuantity.errors?.specialCharacter?.message}}</mat-error>
        <mat-error *ngIf="maximumOrderQuantity.errors?.required">Maximum Order Quantity is required</mat-error>
        <mat-error *ngIf="maximumOrderQuantity.errors?.min">Please enter a value more than 0</mat-error>
      </mat-form-field>

      <mat-form-field *ngIf="status.value !== 3" class="select-type">
        <mat-label>Increment Quantity</mat-label>
        <input [required]="status.value !== 3"  matInput data-test="incrementQuantity" placeholder="Increment Quantity"
          formControlName="incrementQuantity" numberOnly>
        <mat-error *ngIf="incrementQuantity.errors?.required">Increment Quantity is required</mat-error>
        <mat-error *ngIf="incrementQuantity.errors?.min">Please enter a value more than 0</mat-error>
        <mat-error *ngIf="incrementQuantity.value && incrementQuantity.errors?.specialCharacter">
          {{incrementQuantity.errors?.specialCharacter?.message}}</mat-error>
      </mat-form-field>
    </form>

  </mat-dialog-content>

  <mat-dialog-actions class="justify-items-end button-row">
    <button mat-button mat-raised-button color="primary" [disabled]="!(stationaryForm.valid && stationaryForm.dirty)" (click)="onSaveAndClose()">{{ updateMode ? 'Update' : 'Save' }}</button>
    <button mat-button mat-raised-button color="secondary" (click)="onCloseDialog()">Cancel</button>
  </mat-dialog-actions>

</div>
