<div class="not-found" *ngIf="notFoundMessage">{{notFoundMessage}}</div>

<mat-card [style]="getTableStyle()">
  <mat-table [dataSource]="dataSource" matSort matSortActive="inceptionDate" matSortDirection="asc" matSortDisableClear>
    <ng-container matColumnDef="claimType">
      <mat-header-cell *matHeaderCellDef> Claim Type </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div class="ellipsis" matTooltip={{row.claimType}}> {{row.claimType}}</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="paymentType">
      <mat-header-cell *matHeaderCellDef> Payment Type </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div class="ellipsis" matTooltip={{row.paymentType}}> {{row.paymentType}}</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="paidValue">
      <mat-header-cell *matHeaderCellDef> Paid Value (Excluding VAT) </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div class="ellipsis"> {{row.paidValue | currency:'GBP':'symbol'}}</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="paidDate">
      <mat-header-cell *matHeaderCellDef> Paid Date </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div class="ellipsis">{{row.paidDate | date: appConstants.dateConfig.date}}</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="claimantType">
      <mat-header-cell *matHeaderCellDef> Claimant Type </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div class="ellipsis" matTooltip={{row.claimantType}}>{{row.claimantType}}</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="claimantName">
      <mat-header-cell *matHeaderCellDef> Claimant Name </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div class="ellipsis" matTooltip={{row.claimantName}}>{{row.claimantName}}</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="claimReference">
      <mat-header-cell *matHeaderCellDef> Claim Reference </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div class="ellipsis" matTooltip={{row.claimReference}}>{{row.claimReference}}</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="solicitorReference">
      <mat-header-cell *matHeaderCellDef> Solicitor Reference </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div class="ellipsis" matTooltip={{row.solicitorReference}}>{{row.solicitorReference}}</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="solicitor">
      <mat-header-cell *matHeaderCellDef> Solicitor </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div class="ellipsis" matTooltip={{row.solicitor}}>{{row.solicitor}}</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="claimOutcome">
      <mat-header-cell *matHeaderCellDef> Claim Outcome </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div class="ellipsis" matTooltip={{row.claimOutcome}}>{{row.claimOutcome}}</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="claimSettleTrack">
      <mat-header-cell *matHeaderCellDef> Claim Settled Track </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div class="ellipsis" matTooltip={{row.claimSettledTrack}}>{{row.claimSettledTrack}}</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="concludedDate">
      <mat-header-cell *matHeaderCellDef> Concluded Date </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div class="ellipsis">{{row.claimConcludedDate | date: appConstants.dateConfig.date}}</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="product">
      <mat-header-cell *matHeaderCellDef> Product </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div class="ellipsis" matTooltip={{row.product}}>{{row.product}}</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="subProduct">
      <mat-header-cell *matHeaderCellDef> Sub Product</mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div class="ellipsis" matTooltip={{row.subProduct}}>{{row.subProduct}}</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="brokerBranchName">
      <mat-header-cell *matHeaderCellDef> Broker Branch Name </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div class="ellipsis" matTooltip={{row.brokerBranchName}}>{{row.brokerBranchName}}</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="inceptionDate">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Policy Inception Date </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div class="ellipsis">{{row.inceptionDate |date: appConstants.dateConfig.date}}</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="certificateNumber">
      <mat-header-cell *matHeaderCellDef> Certificate Number </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div class="ellipsis" matTooltip={{row.certificateNumber}}>{{row.certificateNumber}}</div>
      </mat-cell>
    </ng-container>


    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row matRipple *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
  <div class="display-flex flex-direction-row footer">
    <div class="btn-group flex-50">
      <button class="btnExport" [disabled]="!(dataSource && dataSource.data && dataSource.data.length> 0)"
        matTooltip="Export to Excel" mat-raised-button color="primary" (click)="exportExcel()">
        Export to Excel
      </button>
      <button class="btnExport" [disabled]="!(dataSource && dataSource.data && dataSource.data.length> 0)"
        matTooltip="Export to CSV" mat-raised-button color="primary" (click)="exportCsv()">
        Export to CSV
      </button>
      <button class="btnExport" [disabled]="!(dataSource && dataSource.data && dataSource.data.length> 0)"
        matTooltip="Export to PDF" mat-raised-button color="primary" (click)="exportPdf()">
        Export to PDF
      </button>
    </div>

    <div class="paging flex-50">
      <mat-paginator [length]="totalRows" [pageIndex]="pagingConstant.pageIndex"
        [pageSize]="pagingConstant.pageSizeLarger" [pageSizeOptions]="pagingConstant.pageSizeOptionsLarger"
        showFirstLastButtons>
      </mat-paginator>
    </div>
  </div>
</mat-card>
