import { Component, OnInit, NgZone, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertPriority } from 'src/app/models/enums/alert-priority.enum';
import { HttpBaseService } from 'src/app/shared/services/http-base.service';
import { ApiEndPoints } from 'src/app/shared/config/api-end-points';
import { Alert } from 'src/app/models/alert.model';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { take } from 'rxjs/operators';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { TitleMessages } from 'src/app/shared/constants/constants';
import { MatDialogRef } from '@angular/material/dialog';
import { noWhitespace } from 'src/app/validators/no-whitespace.validator';


@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {

  alertFormGroup: FormGroup;
  alertFormUpdated: boolean = false; 
  Priorites = AlertPriority;
  alertSent : boolean = false;
  constructor(private readonly formBuilder: FormBuilder, private readonly router: Router,
              private readonly httpService: HttpBaseService, private readonly api: ApiEndPoints,
              private _ngZone: NgZone,
              public dialogService: DialogService) {
    this.alertFormGroup = this.formBuilder.group({
      title: ['', [Validators.required, Validators.maxLength(250), noWhitespace]],
      message: ['', [Validators.required, Validators.maxLength(3000), noWhitespace]],
      priority: [AlertPriority.Normal, Validators.required]
    });
  }

  @ViewChild('autosize', {read: false}) autosize: CdkTextareaAutosize;

  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable.pipe(take(1))
        .subscribe(() => this.autosize.resizeToFitContent(true));
  }
  get title() {
    return this.alertFormGroup.get('title') as AbstractControl;
  }

  get message() {
    return this.alertFormGroup.get('message') as AbstractControl;
  }

  get priority() {
    return this.alertFormGroup.get('priority') as AbstractControl;
  }

  ngOnInit() {
  }

  backToHome() {
    this.router.navigate(['app/notifications/alerthistory']);
  }

  canDeactivate(): boolean {
    if(this.alertFormGroup.pristine) return true;
    return !this.alertFormGroup.pristine && this.alertFormUpdated; 
  }

  async sendAlert() {
    const alert = new Alert(this.title.value, this.message.value, this.priority.value);
    const alertId = await this.httpService.createResource(this.api.sendAlert(), alert).toPromise();
    this.alertFormUpdated = true;
    this.alertSent = true;
      this.dialogService.openSuccessDialogConfirm({ title: TitleMessages.successTitle, message: 'Notification has been saved successfully' }).subscribe( result => {
        if(result) {
          if (alertId) {
            this.backToHome();
          }
        }
      })
  }
}
