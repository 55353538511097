import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { interval, Subscription } from 'rxjs';
import { ConfigurationService } from '../../services/configuration.service';

@Component({
  selector: 'app-unavailable',
  templateUrl: './unavailable.component.html',
  styleUrls: ['./unavailable.component.scss']
})
export class UnavailableComponent implements OnInit, OnDestroy {
  message1: string = 'Unfortunately the Policy Hub Admin is currently unavailable, please attempt to refresh your browser or clear your cache.';
  message2: string = 'If this does not resolve the issue please contact your Kindertons representative as the application may be under scheduled maintenance.';
  message3: string = 'Apologies for any inconvenience.';
  private timer: any;
  private countdownSubscription: Subscription;
  countdown: number = 10;

  constructor(private readonly _router: Router, private readonly _configurationService: ConfigurationService) {
  }

  ngOnInit(): void {
    this.countdownSubscription = interval(1000).subscribe(() => {
      this.countdown--;
      if (this.countdown <= 0) {
        this._configurationService.checkServices(true);
        this.countdown = 10;
      }
    });

    this.timer = setTimeout(() => {
      this.ngOnDestroy(); 
    }, 30*60*1000); // 30 minutes in milliseconds
  }

  ngOnDestroy(): void {   
    if (this.countdownSubscription) {
      this.countdownSubscription.unsubscribe();
    }

    if (this.timer) {
      clearTimeout(this.timer); // Clear the timer
    }
  }

  goBack() {
    this._router.navigate(['/app']);
  }
}