export interface DocumentModel {
    id: string,
    rowVersion: any,
    subProductId: string,
    url: string,
    fileName: string,
    fileType: string,
    documentType: string,
    createdOn: any,
    isActive: boolean,
    changedByUserName:string;
    effectiveDateFrom: any;
    effectiveDateTo:any;
}

export interface UploadDocument {
    id:number,
    documentType: string,
    fileName: string,
    effectiveDateFrom: any;
    effectiveDateTo:any;
    file:File;
    isActive: boolean;
}

export enum DocumentType {
    AtAGlance = 'AtAGlance',
    IPID = 'IPID',
    MasterCertificate = 'MasterCertificate',
    Schedule = 'Schedule',
    FVA = 'FVA',
    All = 'All'
}
