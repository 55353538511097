import { Component, OnInit, Output, EventEmitter, HostListener } from '@angular/core';
import { BranchInformation } from 'src/app/models/broker';
import { Subject, BehaviorSubject } from 'rxjs';
import { FormGroup, FormBuilder, AbstractControl } from '@angular/forms';
import { BrokerBranchService } from 'src/app/services/broker-branch.service';
import { PagingConstant } from 'src/app/shared/constants/constants';
import { map } from 'rxjs/operators';
import { KEY_CODE } from 'src/app/models/enums/key-codes.enum';
import { Utility } from 'src/app/utils/utility';

@Component({
  selector: 'app-search-broker-branch',
  templateUrl: './search-broker-branch.component.html',
  styleUrls: ['./search-broker-branch.component.scss']
})
export class SearchBrokerBranchComponent implements OnInit {

  results: BranchInformation[] = [];

  searchTerm$ = new Subject<string>();
  searchTerm: string = '';
  displayedColumns: string[] = ['originalBranchId', 'Name', 'Postcode', 'AddressLine1', 'Status'];

  showResult = false;
  index = -1;
  searchPageSize = 1000;

  selectedBranch: BranchInformation;
  @Output() selectedBranchOutput = new EventEmitter<BranchInformation>();

  private wasInside = false;

  viewResultInFlight$ = new BehaviorSubject<boolean>(false);

  form: FormGroup;

  constructor(private readonly brokerService: BrokerBranchService,
     private readonly formBuilder: FormBuilder ) {
    this.createForm();
  }

  get searchControl() {
    return this.form.get('searchControl') as AbstractControl;
  }

  get searchStringIsEmpty() {
    return Utility.isNullOrWhiteSpace(this.searchControl.value);
  }

  ngOnInit() {

  }

  createForm() {
    this.form = this.formBuilder.group({
      searchControl: [undefined]
    });
  }

  ngAfterViewInit(): void {
    if (this.searchControl) {
      this.searchControl.valueChanges.subscribe(x => {
        if (!x) {
          this.closeSearchResult();
        }
      });
    }
  }

  closeSearchResult() {
    this.index = -1;
    this.results = [];
    this.showResult = false;
  }

  search(query): void {
    this.viewResultInFlight$.next(true);
    this.closeSearchResult();
    this.brokerService.getBrokerBranchesList(query, true, 'Name', 'asc', PagingConstant.pageIndex, this.searchPageSize)
      .pipe(
        map(response => ({
          results: response.items.filter(t=>t.isActive),
        }))
      )
      .subscribe(results => {
        this.viewResultInFlight$.next(false);
        this.showResult = true;
        this.results = results.results;
      });
  }

  reset(){
    this.searchControl.reset();
  }

  onRowClick(brokerBranch: BranchInformation) {
    this.selectedBranch = brokerBranch;
    this.selectedBranchOutput.emit(this.selectedBranch);
    this.closeSearchResult();
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode === KEY_CODE.RIGHT_ARROW || event.keyCode === KEY_CODE.DOWN_ARROW) {
      this.increment();
    }

    if (event.keyCode === KEY_CODE.LEFT_ARROW || event.keyCode === KEY_CODE.UP_ARROW) {
      this.decrement();
    }

    if (event.keyCode === KEY_CODE.ESCAPE) {
      this.closeSearchResult();
    }

    if (event.keyCode === KEY_CODE.ENTER) {
      let index = this.index;
      if (this.showResult && index >= 0 && index < this.results.length) {

        this.selectedBranch = this.results[this.index];
        this.selectedBranchOutput.emit(this.selectedBranch);

        this.closeSearchResult();

      }
    }
  }

  @HostListener('click')
  clickInside() {
    this.wasInside = true;
  }

  @HostListener('document:click')
  clickout() {
    if (!this.wasInside) {
      this.closeSearchResult();
    }
    this.wasInside = false;
  }

  increment() {
    if (this.index < this.results.length - 1) {
      this.index++;
    }
    this.selectedBranch = this.results[this.index];
  }

  decrement() {
    if (this.index > 0) {
      this.index--;
    }

    this.selectedBranch = this.results[this.index];
  }

}
