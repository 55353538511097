import { Component, Input, OnChanges, SimpleChanges, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { Policy } from 'src/app/models/insurance-policy';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import moment from 'moment';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { PolicyUpdate, UpdatePricingForRenewal } from 'src/app/models/policy-update';
import { PolicyService } from 'src/app/services/policy.service';
import { SellableLevelOfCover } from 'src/app/models/sellable-level-of-cover';
import { FORMAT_ONLY_DATE, TitleMessages, MY_FORMATS } from 'src/app/shared/constants/constants';
import { Router } from '@angular/router';

@Component({
    selector: 'app-policy-details',
    templateUrl: './policy-details.component.html',
    styleUrls: ['./policy-details.component.scss']
})

export class PolicyDetailsComponent implements OnChanges, AfterViewInit {
    @Input() policyData: Policy;
    @Input() searched: boolean;
    @Input() isExpired: boolean;
    @Input() isDisableUpdate: boolean;
    @Input() errorMessage: string;

    @Output() save: EventEmitter<PolicyUpdate> = new EventEmitter<PolicyUpdate>();
    @Output() clearData: EventEmitter<any> = new EventEmitter<undefined>();

    minStartDate: Date;
    maxStartDate: Date;

    minDate: Date = new Date(1900, 0, 1);
    maxDate = moment(new Date()).startOf('day').add({ day: 0 });

    invalidGross: boolean = false;
    isGrossChange: boolean = false;

    public get inceptionDateControl() {
        return this.form.get('inceptionDate') as AbstractControl;
    }

    confirmMessage: string;

    // isExpired: boolean;

    get renewalDate() {
        let renewalDate = new Date(this.policyData.policyStartDate).setFullYear(new Date(this.policyData.policyStartDate).getFullYear() + 1);
        return moment(renewalDate).format(MY_FORMATS.display.dateInput);
    }
    get cancellationDate(){
      return moment(this.policyData.cancellationDate).format(MY_FORMATS.display.dateInput);
    }
    // isCancelled: boolean;
    public get isCancelled() {
        return !this.policyData || this.policyData.cancellationDate !== null;
    }

    get totalGross() {
        return `£${this.policyData.levelOfCovers.reduce<number>((accumulator: number, cover: SellableLevelOfCover) => accumulator + cover.customClientGrossPremium, 0).toFixed(2)}`;
    }

    public form: FormGroup;
    constructor(private builder: FormBuilder, private dialogService: DialogService, private policyService: PolicyService, private router: Router) {
        this.createForm();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.policyData) {
            this.resetForm();

            this.form.controls.company.setValue(this.policyData.company);
            this.form.controls.title.setValue(this.policyData.title);
            this.form.controls.firstName.setValue(this.policyData.firstName);
            this.form.controls.surname.setValue(this.policyData.surname);

            this.form.controls.certificateNumber.setValue(this.policyData.certificateNumber);
            this.form.controls.productName.setValue(this.policyData.productName);
            this.form.controls.policyId.setValue(this.policyData.policyId);
            this.form.controls.expiredDate.setValue(moment(this.policyData.policyExpireDate).format(MY_FORMATS.display.dateInput));
            this.form.controls.policyStartDate.setValue(moment(this.policyData.policyStartDate).format(MY_FORMATS.display.dateInput));
            if (this.isExpired && !this.isCancelled && this.policyData.levelOfCovers.filter(x => !x.isValidGrossPremium).length > 0) {
              this.invalidGross = true;
            }
        }
    }

    grossChange(value) {
      if (this.isExpired && !this.isCancelled && this.policyData.levelOfCovers.filter(x => !x.isValidGrossPremium).length > 0) {
        this.invalidGross = true;
      }
      else {
        this.invalidGross = false;
      }
      this.isGrossChange = value;
    }

    checkExpired() {
      let now = moment(new Date());
      let expiryDate = moment(this.policyData.policyExpireDate);
      return now.isAfter(expiryDate);
    }

    ngAfterViewInit() {
      this.maxStartDate = new Date(new Date().setDate(new Date().getDate() - 1));
      this.minStartDate = new Date(new Date().setFullYear(new Date().getFullYear() - 1));
    }

    createForm() {
        this.form = this.builder.group({
            company: [{value: '', disabled: true}],
            title: [{value: '', disabled: true}],
            firstName: [{value: '', disabled: true}],
            surname: [{value: '', disabled: true}],

            certificateNumber: [{value: '', disabled: true}, [Validators.required]],
            productName: [{value: '', disabled: true}, [Validators.required]],
            policyId: [{value: '', disabled: true}, [Validators.required]],
            inceptionDate: [undefined, [Validators.required]],
            policyStartDate: [{value: '', disabled: true}, [Validators.required]],
            expiredDate: [{value: '', disabled: true}, [Validators.required]],
        });
    }

    public saveClick() {
        if (this.isExpired) { // renewal message
            this.confirmMessage = `Are you sure you want to continue. The Policy  will be renewed with a Policy Inception Date ${this.renewalDate} and a Client Gross Premium of ${this.totalGross}?`;
        } else { // update policy message
            this.confirmMessage = `Are you sure you want to continue. The Policy  will be updated with a Policy Inception Date ${moment(this.inceptionDateControl.value, MY_FORMATS.display.dateInput).format(MY_FORMATS.display.dateInput)}?`;
        }

        this.dialogService.openConfirmationDialog({ title: TitleMessages.confirmationTitle, message: this.confirmMessage, disableClose: true, secondaryButtons: 'No' }).subscribe(result => {
            if (result) {
                 this.form.markAsPristine();
                let model = {
                    policyId: this.form.get('policyId').value,
                    inceptionDate:  this.isExpired ? new Date(moment(this.renewalDate, MY_FORMATS.display.dateInput).format(FORMAT_ONLY_DATE)) : new Date(moment(this.inceptionDateControl.value, MY_FORMATS.display.dateInput).format(FORMAT_ONLY_DATE)),
                    pricing: this.policyData.levelOfCovers.map(x => { return {
                        policyCoverDetailId: x.policyCoverDetailId,
                        brokerNet: x.customBrokerNet,
                        iptAmount: x.customIptAmount,
                        clientGrossPremium: x.customClientGrossPremium,
                        isNewLoc: x.isNewLoc,
                        LevelOfCoverId: x.id,
                        LevelOfCoverName: x.levelOfCoverName
                    } as UpdatePricingForRenewal; })
                } as PolicyUpdate;
                this.save.emit(model);
            }
        });
    }

    public cancelClick(){
        if (this.form.dirty || this.isGrossChange) {
            this.dialogService.openConfirmationDialog({ title: TitleMessages.confirmationTitle, message: 'Are you sure you want to continue. Your changes will be lost?', disableClose: true, secondaryButtons: 'No' }).subscribe(result => {
                if (result) {
                    this.resetForm();
                    this.clearData.emit();
                }
            });
        } else {
            this.clearData.emit();
        }
    }

    public resetForm() {
        this.form.reset();
        this.form.markAsPristine();
    }
}
