<mat-card>
  <mat-card-header>
    <mat-card-title><strong>Claims Made Reports</strong></mat-card-title>
    <mat-card-subtitle>View claims made reports here.</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content [formGroup]="formGroup">
    <mat-form-field class="blue-form-field title-form">
      <mat-label>Product</mat-label>
      <mat-select #matProduct data-test="productSelect" placeholder="Product" panelClass="custom-width-panel"
        formControlName="product" (closed)="onCloseProduct()">
        <mat-option *ngFor="let product of productsList" [value]="product.id">
          {{ product.name }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="product.errors?.required">Product is required</mat-error>
    </mat-form-field>

    <mat-form-field class="blue-form-field title-form">
      <mat-label>Selected Cover(s)</mat-label>
      <mat-select #matSelectCover data-test="coverSelect" placeholder="Selected Cover(s)"
        panelClass="custom-width-panel" formControlName="covers" multiple [matTooltip]="actualSelectedCover" (selectionChange)="toggleSelectionCover($event.value)">
        <mat-select-trigger>{{actualSelectedCover}}</mat-select-trigger>
        <mat-option [value]='allOptionTxt' #allSelectedCover >{{allOptionTxt}}</mat-option>
        <mat-option *ngFor="let cover of coversList" [value]="cover.id" >
          {{ cover.name }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="covers.errors?.required">Cover is required</mat-error>
    </mat-form-field>

    <mat-form-field class="blue-form-field title-form">
      <mat-label> Broker Group Name</mat-label>
      <input matInput [matAutocomplete]="autobbG" #autoCompleteInputBbg formControlName="brokerGroups" required
        placeholder="Enter Broker Group Name" matTooltip="Start typing to search">
      <button class="close-icon" *ngIf="!searchGroupIsEmpty" (click)="resetGroup()"><mat-icon>clear</mat-icon></button>
      <mat-icon class="search-icon" matSuffix svgIcon="magnify"></mat-icon>
      <mat-error *ngIf="brokerGroups.invalid">You must select a value from the list options</mat-error>
      <mat-autocomplete [autoActiveFirstOption]="true" #autobbG="matAutocomplete" md-menu-class="autocomplete">
        <mat-option *ngFor="let option of filteredBrokerGroupOptions | async" [value]="option.name"
          (onSelectionChange)="selectBrokerGroup($event,option)">
          <span>{{option.name}}</span>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field class="blue-form-field title-form">
      <mat-label> Broker Branch Name</mat-label>
      <input matInput [matAutocomplete]="autobb" formControlName="brokerBranchs" #autoCompleteInputBb
        placeholder="Enter Broker Branch Name" matTooltip="Start typing to search" class="brokerBranch"
        (blur)="onBlurBrokerBranch()">
      <button class="close-icon" *ngIf="!searchIsEmpty" (click)="reset()"><mat-icon>clear</mat-icon></button>
      <mat-icon class="search-icon" matSuffix svgIcon="magnify"></mat-icon>
      <mat-autocomplete [autoActiveFirstOption]="true" #autobb="matAutocomplete" md-menu-class="autocomplete">
        <mat-option *ngFor="let option of filteredBrokerBranchOptions | async" [value]="option.name"
          (onSelectionChange)="selectBrokerBranch($event,option)" [disabled]="option.isDisable">
          <span>{{ option.name}}</span>
        </mat-option>
      </mat-autocomplete>
      <mat-error *ngIf="brokerBranchs.hasError('invalid')">You must select a value from the list options</mat-error>
    </mat-form-field>

    <br>
    <mat-form-field class="date-input blue-form-field title-form">
      <mat-label class="">Date From (Incepted):</mat-label>
      <input data-test="dateFromDatePicker" [matDatepicker]="dateFromComponent" [required]="true" matNativeControl
        spellcheck="false" matInput placeholder="Date From:" formControlName="dateFrom" [min]="minDateFrom"
        [max]="maxDateFrom">
      <mat-datepicker-toggle matSuffix [for]="dateFromComponent"></mat-datepicker-toggle>
      <mat-datepicker #dateFromComponent></mat-datepicker>
      <mat-hint>dd/mm/yyyy</mat-hint>
      <mat-error *ngIf="dateFrom.errors?.matDatepickerMin">Date is before the minimum date.</mat-error>
      <mat-error *ngIf="dateFrom.errors?.matDatepickerMax">Date is over 60 days in the future.</mat-error>
      <mat-error *ngIf="dateFrom.errors?.matDatepickerParse || dateFrom.errors?.invalid">The date is
        invalid.</mat-error>
      <mat-error *ngIf="!dateFrom.errors?.matDatepickerParse && dateFrom.errors?.required">Date From is
        required.</mat-error>
    </mat-form-field>

    <mat-form-field class="date-input blue-form-field title-form">
      <mat-label class="">Date To (Incepted):</mat-label>
      <input data-test="dateToDatePicker" [matDatepicker]="dateToComponent" [required]="true" matNativeControl
        spellcheck="false" matInput placeholder="Date To:" formControlName="dateTo" [min]="minDateTo" [max]="maxDateTo">
      <mat-datepicker-toggle matSuffix [for]="dateToComponent"></mat-datepicker-toggle>
      <mat-datepicker #dateToComponent></mat-datepicker>
      <mat-hint>dd/mm/yyyy</mat-hint>
      <mat-error *ngIf="dateTo.errors?.matDatepickerMin">Date is before the minimum date.</mat-error>
      <mat-error *ngIf="dateTo.errors?.matDatepickerMax">Date is over 60 days in the future.</mat-error>
      <mat-error *ngIf="dateTo.errors?.matDatepickerParse || dateTo.errors?.invalid">The date is
        invalid.</mat-error>
      <mat-error *ngIf="!dateTo.errors?.matDatepickerParse && dateTo.errors?.required">Date To is
        required.</mat-error>
    </mat-form-field>
    <button [disabled]="!isValidSubmit" matTooltip="Submit" mat-raised-button color="primary" class="btn-submit"
      (click)="onSubmit()">
      Submit
    </button>
    <br>
    <section class="selected-branch">
      <h4 *ngIf="selectedBranchOptions.length > 0" class="broker-header"> Selected Broker Branch </h4>
      <span *ngIf="reachLimit" class="warnin-message">Reached limitation of 20 broker branches.</span>
      <mat-chip-listbox>
        <mat-chip *ngFor="let source of selectedBranchOptions" (removed)="remove(source)">
          {{source.name}}
          <button [singleClick]="false" matChipRemove>
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip>
      </mat-chip-listbox>
    </section>
  </mat-card-content>
</mat-card>
<app-mlei-table *ngIf="reportType == productType.MotorLegalExpenses" [data$]="data$"
  (loadData)="loadDataFromTable($event)" (export)="export($event)"></app-mlei-table>

<app-excess-protection-table *ngIf="reportType == productType.ExcessProtection" [data$]="data$"
  (loadData)="loadDataFromTable($event)" (export)="export($event)"></app-excess-protection-table>

<app-home-emergency-table *ngIf="reportType == productType.HomeEmergencySolutions" [data$]="data$"
  (loadData)="loadDataFromTable($event)" (export)="export($event)"></app-home-emergency-table>
