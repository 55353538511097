<mat-card appearance="outlined" >
  <mat-card-header>
    <mat-card-title><strong>Brokers</strong></mat-card-title>
    <mat-card-subtitle>Maintain brokers here.</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content >
    <div>
      <button mat-button color="primary" class="btn-table-row btn-add" (click)="addBroker()" matTooltip="Create new Broker">
        <mat-icon>add</mat-icon>
      </button>

    </div>
    <div [formGroup]="searchFormBroker" class="search-group">
      <div class="section">
        <app-shared-search (onSearch)="search()" (onValueChange)="onValueChange($event)" [placeholderText]="'Search by Id, Name, Post code or Email address'">
        </app-shared-search>
      </div>
      <div class="include-inactive">
        <label id="include-inactive-items" class="label-include"><span><strong>Include Inactive
              Items:</strong></span>
        </label>
        <mat-radio-group formControlName="isIncludedInactive">
          <mat-radio-button data-test="noRadio" value="false" [checked]="isIncludedInactive.value === false">No
          </mat-radio-button>
          <mat-radio-button data-test="yesRadio" value="true" [checked]="isIncludedInactive.value === true">Yes
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

    <table mat-table [dataSource]="dataSource" matSort matSortActive="name" matSortDirection="asc" matSortDisableClear>

      <ng-container matColumnDef="originalBrokerId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Broker ID</th>
        <td mat-cell *matCellDef="let row">{{row.originalBrokerId}}</td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Broker Name</th>
        <td mat-cell *matCellDef="let row">{{row.name}}</td>
      </ng-container>

      <ng-container matColumnDef="addressline1">
        <th mat-header-cell *matHeaderCellDef>Address Line 1</th>
        <td mat-cell *matCellDef="let row">{{row.address1}}</td>
      </ng-container>

      <ng-container matColumnDef="addressline2">
        <th mat-header-cell *matHeaderCellDef>Address Line 2</th>
        <td mat-cell *matCellDef="let row">{{row.address2}}</td>
      </ng-container>

      <ng-container matColumnDef="addressline3">
        <th mat-header-cell *matHeaderCellDef>Address Line 3</th>
        <td mat-cell *matCellDef="let row">{{row.address3}}</td>
      </ng-container>

      <ng-container matColumnDef="postcode">
        <th mat-header-cell *matHeaderCellDef>Postcode</th>
        <td mat-cell *matCellDef="let row">{{row.postCode }}</td>
      </ng-container>

      <ng-container matColumnDef="phonenumber">
        <th mat-header-cell *matHeaderCellDef>Telephone Number</th>
        <td mat-cell *matCellDef="let row">{{formatPhoneNumber(row.telephoneNumber)}}</td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>Email Address</th>
        <td mat-cell *matCellDef="let row">{{row.emailAddress}}</td>
      </ng-container>

      <ng-container matColumnDef="active">
        <th mat-header-cell *matHeaderCellDef>Active</th>
        <td mat-cell class="isactive-cell" *matCellDef="let row">
          <mat-icon *ngIf="row.isActive" class="colour-success" svgIcon="check"></mat-icon>
          <mat-icon *ngIf="!row.isActive" class="colour-danger" svgIcon="close"></mat-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Edit</th>
        <td mat-cell *matCellDef="let row">
          <button mat-button class="btn-action" (click)="editBroker(row)" matTooltip="Edit">
            <mat-icon svgIcon="pencil"></mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <div class="flex-row-between">
      <span class="not-found-text">{{notFoundMessage}}</span>
      <mat-paginator [length]="dataSource.totalRecords" [pageIndex]="pagingConstant.pageIndex" [pageSize]="pagingConstant.pageSizeLarger"
        [pageSizeOptions]="pagingConstant.pageSizeOptionsLarger" showFirstLastButtons>
      </mat-paginator>
    </div>

  </mat-card-content>
</mat-card>
