import { DataSource } from '@angular/cdk/table';
import { PricingInformation, BranchPricingInformation } from './pricing-item';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { PricingService } from '../services/pricing.service';
import { CollectionViewer } from '@angular/cdk/collections';
import { catchError, finalize } from 'rxjs/operators';
import { PagingConstant } from 'src/app/shared/constants/constants';
import * as _ from 'lodash';
import { PaginationResponse } from './pagination-response';

export class PricingDataSource implements DataSource<PricingInformation> {

    private pricingsSubject = new BehaviorSubject<PricingInformation[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);
    public totalRecords: number;

    public loading$ = this.loadingSubject.asObservable();
    public pricings$ = this.pricingsSubject.asObservable();

    constructor(private pricingService: PricingService) { }

    connect(collectionViewer: CollectionViewer): Observable<PricingInformation[]> {
        return this.pricingsSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.pricingsSubject.complete();
        this.loadingSubject.complete();
    }

    getPricingPagingByFilters(productId: string, subProductId: string, levelOfCoverId: string, query: string, isIncludedInactive: boolean, sortField: string, sortDirection: string, pageIndex = PagingConstant.pageIndex, pageSize = PagingConstant.pageSize) {
        this.loadingSubject.next(true);

        this.pricingService.getPricingsPagingByFilters(productId, subProductId, levelOfCoverId, query, isIncludedInactive, sortField, sortDirection, pageIndex, pageSize).pipe(
            catchError(() => of([])),
            finalize(() => this.loadingSubject.next(false))
        )
            .subscribe(pricings => {
              var rs = pricings as PaginationResponse<PricingInformation>;
              this.totalRecords = rs.total;
              this.pricingsSubject.next((rs.items) as any);
            });
    }
}


export class BranchPricingDataSource implements DataSource<BranchPricingInformation> {

    private pricingsSubject = new BehaviorSubject<BranchPricingInformation[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);
    public totalRecords: number;

    public loading$ = this.loadingSubject.asObservable();
    public pricings$ = this.pricingsSubject.asObservable();

    constructor(private pricingService: PricingService) { }

    connect(collectionViewer: CollectionViewer): Observable<BranchPricingInformation[]> {
        return this.pricingsSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.pricingsSubject.complete();
        this.loadingSubject.complete();
    }

    getPricingPagingByFilters(branchId: string, productId: string, subProductId: string, levelOfCoverId: string, query: string, isIncludedInactive: boolean, sortField: string, sortDirection: string, pageIndex = PagingConstant.pageIndex, pageSize = PagingConstant.pageSize) {
        this.loadingSubject.next(true);

        this.pricingService.getPricingsPagingByFiltersForBranch(branchId, productId, subProductId, levelOfCoverId, query, isIncludedInactive, sortField, sortDirection, pageIndex, pageSize).pipe(
            catchError(() => of([])),
            finalize(() => this.loadingSubject.next(false))
        )
            .subscribe(pricings => {
                var rs = pricings as PaginationResponse<PricingInformation>;
                this.totalRecords = rs.total;
                this.pricingsSubject.next((rs.items) as any);
            });
    }
}

