<main>
  <mat-card appearance="outlined" >
    <mat-card-header >
      <mat-card-title>Sub Products</mat-card-title>
      <mat-card-subtitle>Create, edit your Sub products here.</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content class="sub-product-container">
      <button mat-button class="btn-table-row btn-create" (click)="createSubProduct()"
        [disabled]="invalidAddSubProduct()" matTooltip="Create new Sub product">
        <mat-icon>add</mat-icon>
      </button>
      <table mat-table [dataSource]="subProducts" *ngIf="subProducts.length>0" matSort
        matSortActive="Name" matSortDirection="asc" matSortDisableClear>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Sub Product Name</th>
          <td mat-cell *matCellDef="let row"><span class="open-document-info"
              (click)="openSubProductView(row)">{{row.name}}</span></td>
        </ng-container>

        <ng-container matColumnDef="active">
          <th mat-header-cell *matHeaderCellDef>Active</th>
          <td mat-cell class="isactive-cell" *matCellDef="let row">
            <mat-icon *ngIf="row.isActive" class="colour-success" svgIcon="check"></mat-icon>
            <mat-icon *ngIf="!row.isActive" class="colour-danger" svgIcon="close"></mat-icon>
          </td>
        </ng-container>

        <ng-container matColumnDef="edit">
          <th mat-header-cell *matHeaderCellDef><span class="edit-header">Edit</span></th>
          <td mat-cell *matCellDef="let row">
            <button mat-button (click)="editSubProduct(row)" matTooltip="Edit">
              <mat-icon svgIcon="pencil"></mat-icon>
            </button>
          </td>
        </ng-container>

        <ng-container matColumnDef="document-control">
          <th mat-header-cell *matHeaderCellDef>Document Control</th>
          <td mat-cell *matCellDef="let row">
            <button mat-button (click)="controlDocument(row)" matTooltip="Document control">
              <mat-icon svgIcon="dots-horizontal-circle" colour="primary"></mat-icon>
            </button>
          </td>
        </ng-container>

        <ng-container matColumnDef="at_a_glance">
          <th mat-header-cell *matHeaderCellDef>At a Glance</th>
          <td mat-cell *matCellDef="let row">
            <mat-icon *ngIf="isAtAGlanceDocument(row)" class="colour-success" svgIcon="check"></mat-icon>
          </td>
        </ng-container>

        <ng-container matColumnDef="IPID">
          <th mat-header-cell *matHeaderCellDef>IPID</th>
          <td mat-cell *matCellDef="let row">
            <mat-icon *ngIf="isIPID(row)" class="colour-success" svgIcon="check"></mat-icon>
          </td>
        </ng-container>

        <ng-container matColumnDef="master_certificate">
          <th mat-header-cell *matHeaderCellDef>Master Certificate</th>
          <td mat-cell *matCellDef="let row">
            <mat-icon *ngIf="isMasterCertificate(row)" class="colour-success" svgIcon="check"></mat-icon>
          </td>
        </ng-container>

        <ng-container matColumnDef="FVA">
          <th mat-header-cell *matHeaderCellDef>Fair Value Assessment</th>
          <td mat-cell *matCellDef="let row">
            <mat-icon *ngIf="isFVA(row)" class="colour-success" svgIcon="check"></mat-icon>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </mat-card-content>
  </mat-card>

</main>
