<div>
  <h3 class="title">Email Template</h3>
  <mat-dialog-content class="email-template-content">
    <form [formGroup]="formGroup">
      <section>
        <mat-form-field class="name">
          <mat-label>Name</mat-label>
          <input matInput data-test="name" placeholder="Name" formControlName="name" required>
          <mat-error *ngIf="name.errors?.required || name.errors?.whitespace">Name is required</mat-error>
          <mat-error *ngIf="name.errors?.maxlength">Name cannot exceed 30 characters</mat-error>
        </mat-form-field>
      </section>

      <section>
        <mat-form-field class="description">
          <mat-label>Description</mat-label>
          <textarea matInput data-test="description" placeholder="Description" formControlName="description"
            cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
            cdkAutosizeMaxRows="5"></textarea>
          <mat-error *ngIf="description.errors?.maxlength">Description cannot exceed 100 characters</mat-error>
        </mat-form-field>
      </section>

      <section>
        <mat-form-field class="typeName">
          <mat-label>Type</mat-label>
          <input matInput data-test="typeName" placeholder="Type" formControlName="typeName" readonly="true">
        </mat-form-field>
      </section>

      <section>
        <mat-form-field class="subject">
          <mat-label>Subject</mat-label>
          <input matInput data-test="subject" placeholder="Subject" formControlName="subject" required>
          <mat-error *ngIf="subject.errors?.required || subject.errors?.whitespace">Subject is required</mat-error>
          <mat-error *ngIf="subject.errors?.maxlength">Subject cannot exceed 60 characters</mat-error>
        </mat-form-field>
      </section>

      <section>
        <mat-form-field class="body">
          <mat-label>Body</mat-label>
          <textarea matInput data-test="body" placeholder="Body" formControlName="body" required cdkTextareaAutosize
            #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5"></textarea>
          <mat-error *ngIf="body.errors?.required || body.errors?.whitespace">Body is required</mat-error>
        </mat-form-field>
      </section>

      <section>
        <mat-form-field class="footer">
          <mat-label>Footer</mat-label>
          <textarea matInput data-test="footer" placeholder="Footer" formControlName="footer" cdkTextareaAutosize
            #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5"></textarea>
        </mat-form-field>
      </section>
    </form>

  </mat-dialog-content>

  <mat-dialog-actions class="buttons">
    <button mat-button mat-raised-button color="primary" [disabled]="formGroup.invalid || formGroup.pristine"
      (click)="save(false)">Update</button>
    <button mat-button mat-raised-button color="primary" [disabled]="formGroup.invalid || formGroup.pristine"
      (click)="save(true)">Update &
      Close</button>
    <button mat-button mat-raised-button color="secondary" (click)="onCloseDialog(true)">Close</button>
  </mat-dialog-actions>
</div>