<div [formGroup]="contactDetailForm">
  <mat-expansion-panel class="contact-detail-form display-flex flex-direction-row" [expanded]="isExpand" *ngIf="keyContact">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h4>{{keyContact}}</h4>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="display-flex flex-direction-row">
      <section class="flex-50">
        <mat-form-field>
          <mat-label>Contact Name</mat-label>
          <input matInput data-test="contactNameInput" placeholder="Contact Name" formControlName="contactName" TitleCase>
          <mat-error *ngIf="contactName.errors?.required || contactName.errors?.whitespace">Contact Name is
            required</mat-error>
          <mat-error *ngIf="contactName.errors?.incorrect">Invalid contact name, please re-enter</mat-error>
          <mat-error *ngIf="contactName.errors?.maxlength ">{{'' | maxLengthMessage : 'Contact Name' :
            maxLength.name}}</mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Contact Phone Number</mat-label>
          <input spellcheck="false" data-test="contactPhoneNumberInput" matInput placeholder="Contact Phone Number"
            formControlName="contactPhoneNumber" (blur)="formatPhoneNumber()">
          <mat-error *ngIf="contactPhoneNumber.errors && contactPhoneNumber.errors.incorrect">Invalid phone number, please
            re-enter</mat-error>
        </mat-form-field>
      </section>
      <section class="flex-50">
        <mat-form-field>
          <mat-label>Job Title</mat-label>
          <input matInput data-test="jobTitleInput" placeholder="Job Title" formControlName="jobTitle" TitleCase>
          <mat-error *ngIf="jobTitle.errors?.maxlength ">{{'' | maxLengthMessage : 'Job Title' :
            maxLength.jobTitle}}</mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Email Address</mat-label>
          <input type="email" matInput data-test="emailAddressInput" placeholder="Email Address"
            formControlName="emailAddress">
          <mat-error *ngIf="emailAddress.hasError('invalidEmail')">Email Address is invalid, please re-enter
          </mat-error>
          <mat-error *ngIf="emailAddress.errors?.maxlength ">{{'' | maxLengthMessage : 'Email Address' :
            maxLength.email}}</mat-error>
        </mat-form-field>
      </section>
    </div>
  </mat-expansion-panel>

  <mat-card appearance="outlined" class="contact-detail-form display-flex flex-direction-row" *ngIf="!keyContact">
    <section class="flex-50">
      <mat-form-field>
        <mat-label>Contact Name</mat-label>
        <input matInput data-test="contactNameInput" placeholder="Contact Name" formControlName="contactName" TitleCase>
        <mat-error *ngIf="contactName.errors?.required || contactName.errors?.whitespace">Contact Name is
          required</mat-error>
        <mat-error *ngIf="contactName.errors?.incorrect">Invalid contact name, please re-enter</mat-error>
        <mat-error *ngIf="contactName.errors?.maxlength ">{{'' | maxLengthMessage : 'Contact Name' :
          maxLength.name}}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Contact Phone Number</mat-label>
        <input spellcheck="false" data-test="contactPhoneNumberInput" matInput placeholder="Contact Phone Number"
          formControlName="contactPhoneNumber" (blur)="formatPhoneNumber()">
        <mat-error *ngIf="contactPhoneNumber.errors && contactPhoneNumber.errors.incorrect">Invalid phone number, please
          re-enter</mat-error>
      </mat-form-field>
    </section>
    <section class="flex-50">
      <mat-form-field>
        <mat-label>Job Title</mat-label>
        <input matInput data-test="jobTitleInput" placeholder="Job Title" formControlName="jobTitle" TitleCase>
        <mat-error *ngIf="jobTitle.errors?.maxlength ">{{'' | maxLengthMessage : 'Job Title' :
          maxLength.jobTitle}}</mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Email Address</mat-label>
        <input type="email" matInput data-test="emailAddressInput" placeholder="Email Address"
          formControlName="emailAddress">
        <mat-error *ngIf="emailAddress.hasError('invalidEmail')">Email Address is invalid, please re-enter
        </mat-error>
        <mat-error *ngIf="emailAddress.errors?.maxlength ">{{'' | maxLengthMessage : 'Email Address' :
          maxLength.email}}</mat-error>
      </mat-form-field>
    </section>
  </mat-card>
</div>
