import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { CollectionViewer } from '@angular/cdk/collections';
import { catchError, finalize, map } from 'rxjs/operators';
import { PagingConstant } from 'src/app/shared/constants/constants';
import { NotificationItem } from './notification-item';
import { ApiEndPoints } from '../shared/config/api-end-points';
import { HttpClient } from '@angular/common/http';
import { PaginationResponse } from './pagination-response';

export class NotificationDataSource implements DataSource<NotificationItem> {
  private notificationItemSubject = new BehaviorSubject<NotificationItem[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public totalRecords: number;

  public loading$ = this.loadingSubject.asObservable();
  public notifications$ = this.notificationItemSubject.asObservable();

  constructor(private httpService: HttpClient, private api: ApiEndPoints) {}

  connect(collectionViewer: CollectionViewer): Observable<NotificationItem[]> {
    return this.notificationItemSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.notificationItemSubject.complete();
    this.loadingSubject.complete();
  }

  getNotificationItems(
    searchString: string,
    priority: string,
    includeInactive: boolean,
    sortField = "",
    sortDirection = "asc",
    pageIndex = PagingConstant.pageIndex,
    pageSize = PagingConstant.pageSize
  ) {
    this.loadingSubject.next(true);

    this.getNotifications(
      searchString,
      priority,
      includeInactive,
      sortField,
      sortDirection,
      pageIndex,
      pageSize
    )
      .pipe(
        catchError(() => of([])),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe((notifications) => {
        var rs = notifications as PaginationResponse<NotificationItem>;
        this.totalRecords = rs.total;
        this.notificationItemSubject.next(rs.items as any);
      });
  }

  getNotifications(
    searchString: string,
    priority: string,
    includeInactive: boolean,
    sortField = "",
    sortDirection = "asc",
    pageIndex = PagingConstant.pageIndex,
    pageSize = PagingConstant.pageSize
  ): Observable<PaginationResponse<NotificationItem>> {
    return this.httpService
      .get<PaginationResponse<NotificationItem>>(
        this.api.getNotifications(
          searchString,
          priority,
          includeInactive,
          sortField,
          sortDirection,
          pageIndex,
          pageSize
        )
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }
}
