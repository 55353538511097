import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { ApiEndPoints } from 'src/app/shared/config/api-end-points';
import { HttpBaseService } from 'src/app/shared/services/http-base.service';
import { NotificationItem } from 'src/app/models/notification-item';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { PagingConstant, NO_RESULTS_FOUND, TitleMessages } from 'src/app/shared/constants/constants';
import { NotificationDataSource } from 'src/app/models/notification-datasource';
import { Router } from '@angular/router';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, AbstractControl, Validators } from '@angular/forms';
import { AlertPriority } from 'src/app/models/enums/alert-priority.enum';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Utility } from 'src/app/utils/utility';
import { SharedSearchComponent } from 'src/app/shared/components/shared-search/shared-search.component';

@Component({
  selector: 'app-alert-history',
  templateUrl: './alert-history.component.html',
  styleUrls: ['./alert-history.component.scss']
})
export class AlertHistoryComponent implements OnInit, AfterViewInit {

  viewResultInFlight$ = new BehaviorSubject<boolean>(false);
  displayedColumns: string[] = ['Title', 'Message', 'CreatedOn', 'Status',  'InitialType', 'NumberOfResend', 'Type', 'Resend', 'Archive'];

  dataSource: NotificationDataSource;
  totalRecords: number;
  pagingConstant = PagingConstant;
  pageSizeOptions: number[] = PagingConstant.pageSizeOptionsLarger;

  Priorites = AlertPriority;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(SharedSearchComponent) sharedSearch: SharedSearchComponent;

  searchString: string;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly api: ApiEndPoints,
    private readonly http: HttpClient,
    private readonly httpService: HttpBaseService,
    private readonly dialogService: DialogService,
    private router: Router) {
    this.dataSource = new NotificationDataSource(this.http, this.api);
  }

  searchForm = this.formBuilder.group({
    isIncludedInactive: [false, [Validators.required]],
    status: ['']
  });

  notfound = ' ';
  get notFoundMessage() {
    return this.notfound;
  }

  ngOnInit() {
    this.loadData();
    this.dataSource.notifications$.subscribe(n => {
      this.checkNotFoundResults();
    });
  }

  get status() {
    return this.searchForm.get('status') as AbstractControl;
  }

  get includeInactive() {
    return this.searchForm.get('isIncludedInactive') as AbstractControl;
  }

  async loadData(sortField: string = 'CreatedOn', sortDirection: string = 'desc') {
    let index = this.paginator? this.paginator.pageIndex: 0;
    let pageSize = this.paginator? this.paginator.pageSize: PagingConstant.pageSizeLarger;
    this.dataSource.getNotificationItems(this.searchString, this.status.value, this.includeInactive.value, sortField, sortDirection, index, pageSize);
    if (this.sharedSearch) this.sharedSearch.setSearchString(this.searchString);

  }

  ngAfterViewInit() {
    // reset the paginator after sorting
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    this.paginator.page
      .pipe(
        tap(() => this.getNotificationsPage())
      )
      .subscribe();

    this.sort.sortChange
      .pipe(
        tap(() => this.getNotificationsPage())
      )
      .subscribe();

    this.status.valueChanges.subscribe(async v => {
      this.paginator.pageIndex = 0;
      this.paginator.pageSize = PagingConstant.pageSizeOptionsLarger[0];
      await this.loadData();
    });

    this.includeInactive.valueChanges.subscribe(async v => {
      this.paginator.pageIndex = 0;
      this.paginator.pageSize = PagingConstant.pageSizeOptionsLarger[0];
      await this.loadData();
    });
  }

  async getNotificationsPage() {
    let fieldSort = this.sort.active.charAt(0).toUpperCase() + this.sort.active.slice(1);
    let direction = this.sort.direction;
    this.loadData(fieldSort, direction);
  }

  async resend(notification: NotificationItem) {
    this.viewResultInFlight$.next(true);
    await this.httpService.postDataAsync<NotificationItem>(this.api.resendNotifications(), notification);

    // reload data for the grid
    let data = {
      type: 1,
      title: TitleMessages.successTitle,
      message: 'The message has been successfully sent.'
    };
    this.dialogService.openSuccessDialogConfirm(data).subscribe(async x => {
      await this.getNotificationsPage();
    });
    this.viewResultInFlight$.next(false);
  }

  async archive(notification: NotificationItem) {
    let confirmMessage = 'Are you sure you wish to archive this notification?';
    const primaryButtons = 'Yes';
    const secondaryButtons = 'No';
    this.dialogService.openConfirmationDialog({ title: TitleMessages.confirmationTitle, message: confirmMessage, primaryButtons, secondaryButtons }).subscribe(
      async (choice) => {
        if (choice) {
          let result = await this.httpService.putDataAsync<boolean>(this.api.archiveNotification(), notification);
          if (result === true) {
            await this.loadData();
          }
        }
      });
  }

  onValueChange(value: string) {
    this.searchString = value;
  }

  async search() {
    this.paginator.pageIndex = 0;
    await this.loadData();
  }

  checkNotFoundResults() {
    if (this.dataSource.totalRecords === 0) {
      this.notfound = NO_RESULTS_FOUND;
    } else {
      this.notfound = '';
    }
  }

  createNotificationItem() {
    this.router.navigate([`app/notifications/alert`]);
  }
}



