import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpBaseService } from 'src/app/shared/services/http-base.service';
import { ApiEndPoints } from 'src/app/shared/config/api-end-points';
import { SystemSetting } from 'src/app/models/system-setting';
import * as _ from "lodash";
import { DialogService } from 'src/app/shared/services/dialog.service';
import { EMPTY_GUID, TitleMessages } from 'src/app/shared/constants/constants';
import { Utility } from 'src/app/utils/utility';
import { isValidEmail } from 'src/app/validators/valid-email.validator';

@Component({
  selector: 'app-master-setting',
  templateUrl: './master-setting.component.html',
  styleUrls: ['./master-setting.component.scss']
})
export class MasterSettingComponent implements OnInit {

  savedChanges: boolean = false;
  defaultGuid: string = EMPTY_GUID;
  systemSettingId: string = '';
  rowVersion: string = '';
  systemSettingGroup: FormGroup;

  constructor(private readonly formBuilder: FormBuilder,
    private readonly router: Router,
    private readonly httpService: HttpBaseService,
    private readonly api: ApiEndPoints,
    private readonly dialogService: DialogService) {
    this.systemSettingGroup = this.formBuilder.group({
      stationarySupportEmail: ['', [Validators.required, Validators.email, isValidEmail]],
      applicationSupportEmail: ['', [Validators.required, Validators.email, isValidEmail]],
      healthCheckEmail: ['', [Validators.required, Validators.email, isValidEmail]],
      hotLineContactNumber: ['', [Validators.required]]
    });
  }

  markAsPristine() {
    this.systemSettingGroup.markAsPristine();
  }

  markAsDirty() {
    this.systemSettingGroup.markAsDirty();
  }

  get stationarySupportEmail() {
    return this.systemSettingGroup.get('stationarySupportEmail') as AbstractControl;
  }

  get applicationSupportEmail() {
    return this.systemSettingGroup.get('applicationSupportEmail') as AbstractControl;
  }

  get healthCheckEmail() {
    return this.systemSettingGroup.get('healthCheckEmail') as AbstractControl;
  }

  get hotLineContactNumber() {
    return this.systemSettingGroup.get('hotLineContactNumber') as AbstractControl;
  }

  ngOnInit() {
   this.loadSettingData();
  }

  currentDataStationarySupportEmai :string;
  currentDataSpplicationSupportEmail :string;
  currentDataHealthCheckEmail :string;
  currentDataHotLinePhoneNumber :string;

  loadSettingData(){
    this.httpService.getData<SystemSetting>(this.api.getSystemSetting()).subscribe(
      systemSetting => {
        this.systemSettingId = systemSetting.id;
        this.rowVersion = systemSetting.rowVersion;
        this.stationarySupportEmail.setValue(systemSetting.stationarySupportEmail);
        this.applicationSupportEmail.setValue(systemSetting.applicationSupportEmail);
        this.healthCheckEmail.setValue(systemSetting.healthCheckEmail);
        this.hotLineContactNumber.setValue(systemSetting.hotLinePhoneNumber);
        this.currentDataStationarySupportEmai = systemSetting.stationarySupportEmail;
        this.currentDataSpplicationSupportEmail =systemSetting.applicationSupportEmail;
        this.currentDataHealthCheckEmail =systemSetting.healthCheckEmail;
        this.currentDataHotLinePhoneNumber =systemSetting.hotLinePhoneNumber;
      }
    );
  }

  reset(){
    this.stationarySupportEmail.setValue(this.currentDataStationarySupportEmai);
    this.applicationSupportEmail.setValue(this.currentDataSpplicationSupportEmail);
    this.healthCheckEmail.setValue(this.currentDataHealthCheckEmail);
    this.hotLineContactNumber.setValue(this.currentDataHotLinePhoneNumber);
    this.systemSettingGroup.markAsPristine();
  }

  async saveChanges() {
    if (this.systemSettingGroup.valid) {
      let setting: SystemSetting = {
        id: this.systemSettingId,
        rowVersion: this.rowVersion,
        stationarySupportEmail: this.stationarySupportEmail.value,
        applicationSupportEmail: this.applicationSupportEmail.value,
        healthCheckEmail: this.healthCheckEmail.value,
        hotLinePhoneNumber: this.hotLineContactNumber.value
      };
      let response = '';
      let message = '';
      if (this.defaultGuid === setting.id || setting.id.length === 0) {
        setting.id = this.defaultGuid;
        response =  await this.httpService.postDataAsync<string>(this.api.SaveSystemSetting(), setting);
        message = 'The master settings has been saved successfully';
      }
      else {
        response = await this.httpService.putDataAsync<any>(this.api.SaveSystemSetting(), setting);
        message = 'The master settings has been updated successfully';
      }
      if (response && response !== this.defaultGuid) {
        this.dialogService.openSuccessDialogConfirm({ title: TitleMessages.successTitle, message: message}).subscribe(result => {
          if(result) {
            this.router.getCurrentNavigation();
            this.loadSettingData();
          }
        });
        this.savedChanges = true;
        setTimeout(() => {
          this.savedChanges = false;
        }, 5000);
      }
      this.currentDataStationarySupportEmai = this.stationarySupportEmail.value;
      this.currentDataSpplicationSupportEmail = this.applicationSupportEmail.value;
      this.currentDataHealthCheckEmail = this.healthCheckEmail.value;
      this.currentDataHotLinePhoneNumber = this.hotLineContactNumber.value;
      this.systemSettingGroup.markAsPristine();
    }
  }

  backToHome() {
    this.router.navigate(['app/']);
  }

  formatPhoneNumber() {
    if (this.hotLineContactNumber.value) {
      let original = this.hotLineContactNumber.value as string;
      original = original.split(' ').join("");
      const formatted = Utility.formatPhoneNumber(original);
      if (original === formatted) {
        this.hotLineContactNumber.setErrors({ 'incorrect': true });
      }
      else {
        this.hotLineContactNumber.setValue(formatted);
        this.hotLineContactNumber.setErrors(null);
      }
    }
  }
}

