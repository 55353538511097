<mat-card>
  <mat-card-header>
    <mat-card-title><strong>Declaration Reports</strong></mat-card-title>
    <mat-card-subtitle>View declaration reports here.</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content [formGroup]="formGroup">
    <div *ngIf="!isDialog">
      <mat-form-field class="blue-form-field title-form">
        <mat-label>Provider</mat-label>
        <mat-select #matProvider data-test="matProvider" placeholder="Provider" panelClass="custom-width-panel"
          formControlName="provider" [matTooltip]="matProvider.triggerValue">
          <mat-option *ngFor="let provider of providerList" [value]="provider.id">
            {{ provider.name }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="provider.errors?.required">Provider is required</mat-error>
      </mat-form-field>

      <mat-form-field class="blue-form-field title-form">
        <mat-label>Insurer Name</mat-label>
        <mat-select #matInsurer data-test="directionSelect" placeholder="Insurer Name" panelClass="custom-width-panel"
          formControlName="insurerName" multiple [matTooltip]="actualInsurer" (closed)="onCloseInsurer()" (selectionChange)="toggleSelectionInsurer($event.value)">
          <mat-select-trigger>{{actualInsurer}}</mat-select-trigger>
          <mat-option [value]='allInsurerOption' #allInsurer>{{allOptionTxt}}</mat-option>
          <mat-option *ngFor="let insurerName of insurerNames" [value]="insurerName">
            {{ insurerName.name }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="insurerName.errors?.required">Insurer name is required</mat-error>
      </mat-form-field>

      <mat-form-field class="blue-form-field title-form">
        <mat-label>Product</mat-label>
        <mat-select #matProduct data-test="productSelect" placeholder="Product" panelClass="custom-width-panel"
          formControlName="product" multiple [matTooltip]="actualProduct" (closed)="onCloseProduct()" (selectionChange)="toggleSelectionProduct($event.value)">
          <mat-select-trigger>{{actualProduct}}</mat-select-trigger>
          <mat-option [value]='allOptionTxt' #allProduct>{{allOptionTxt}}</mat-option>
          <mat-option *ngFor="let product of productsList" [value]="product.id">
            {{ product.name }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="product.errors?.required">Product is required</mat-error>
      </mat-form-field>

      <mat-form-field class="blue-form-field title-form">
        <mat-label>Selected Cover(s)</mat-label>
        <mat-select #matSelectCover data-test="coverSelect" placeholder="Selected Cover(s)"
          panelClass="custom-width-panel" formControlName="cover" multiple (selectionChange)="toggleSelectionCover($event.value)" [matTooltip]="actualSelectedCover">
          <mat-select-trigger>{{actualSelectedCover}}</mat-select-trigger>
          <mat-option [value]='allOptionTxt' #allSelectedCover >{{allOptionTxt}}</mat-option>
          <mat-option *ngFor="let cover of coversList" [value]="cover.id">
            {{ cover.name }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="cover.errors?.required">Cover is required</mat-error>
      </mat-form-field>
      <br><br>

      <mat-form-field class="date-input blue-form-field title-form">
        <mat-label class="">Date From (Incepted):</mat-label>
        <input data-test="dateFromDatePicker" [matDatepicker]="dateFromComponent" [required]="true" matNativeControl
          spellcheck="false" matInput placeholder="Date From:" formControlName="dateFrom" [min]="minDateFrom"
          [max]="maxDateFrom">
        <mat-datepicker-toggle matSuffix [for]="dateFromComponent"></mat-datepicker-toggle>
        <mat-datepicker #dateFromComponent></mat-datepicker>
        <mat-hint>dd/mm/yyyy</mat-hint>
        <mat-error *ngIf="dateFrom.errors?.matDatepickerMin">Date is before the effective date from of
          selected insurer.</mat-error>
        <mat-error *ngIf="dateFrom.errors?.matDatepickerMax">Date is over the effective date to of selected
          insurer.</mat-error>
        <mat-error *ngIf="dateFrom.errors?.matDatepickerParse || dateFrom.errors?.invalid">The date is
          invalid.</mat-error>
        <mat-error *ngIf="!dateFrom.errors?.matDatepickerParse && dateFrom.errors?.required">Date From is
          required.</mat-error>
      </mat-form-field>
      <mat-form-field class="date-input blue-form-field title-form">
        <mat-label class="">Date To (Incepted):</mat-label>
        <input data-test="dateToDatePicker" [matDatepicker]="dateToComponent" [required]="true" matNativeControl
          spellcheck="false" matInput placeholder="Date To:" formControlName="dateTo" [min]="minDateTo"
          [max]="maxDateTo">
        <mat-datepicker-toggle matSuffix [for]="dateToComponent"></mat-datepicker-toggle>
        <mat-datepicker #dateToComponent></mat-datepicker>
        <mat-hint>dd/mm/yyyy</mat-hint>
        <mat-error *ngIf="dateTo.errors?.matDatepickerMin">Date is before the effective date from of
          selected insurer.</mat-error>
        <mat-error *ngIf="dateTo.errors?.matDatepickerMax">{{getMaxDateToError()}}</mat-error>
        <mat-error *ngIf="dateTo.errors?.matDatepickerParse || dateTo.errors?.invalid">The date is
          invalid.</mat-error>
        <mat-error *ngIf="!dateTo.errors?.matDatepickerParse && dateTo.errors?.required">Date To is
          required.</mat-error>
      </mat-form-field>

      <button [disabled]="!isValidSubmit" matTooltip="Submit" mat-raised-button color="primary" class="btn-submit"
        (click)="onSubmit()">
        Submit
      </button>
    </div>
    <div *ngIf="isDialog">
      <mat-form-field class="blue-form-field">
        <mat-label>Provider</mat-label>
        <input matInput [value]="this.sourceDialog.providers" disabled>
      </mat-form-field>

      <mat-form-field class="blue-form-field">
        <mat-label>Insurer Name</mat-label>
        <input matInput [value]="this.sourceDialog.insurers" disabled>
      </mat-form-field>

      <mat-form-field class="blue-form-field">
        <mat-label>Product</mat-label>
        <input matInput [value]="this.sourceDialog.products" disabled>
      </mat-form-field>

      <mat-form-field class="blue-form-field">
        <mat-label>Selected Cover(s)</mat-label>
        <input matInput [value]="this.sourceDialog.covers" disabled>
      </mat-form-field>
      <br>
      <mat-form-field class="blue-form-field">
        <mat-label>Date From</mat-label>
        <input matInput [value]="this.sourceDialog.dateFrom | date:
        appConstants.dateConfig.date" disabled>
      </mat-form-field>

      <mat-form-field class="blue-form-field">
        <mat-label>Date To</mat-label>
        <input matInput [value]="this.sourceDialog.dateTo | date:
        appConstants.dateConfig.date" disabled>
      </mat-form-field>
      <div>
        <button class="right" mat-raised-button color="primary" *ngIf="isDialog"
          (click)="onCloseDialog()">Cancel</button>
      </div>
    </div>
  </mat-card-content>
</mat-card>

<div *ngIf="this.isLPGDriversClub; else declartionTable">
  <app-lpg-declarations-report [covers]="cover.value" [insurers]="insurerName.value"
    [provider]="provider.value" [products]="product.value" [dateFrom]="dateFrom.value" [dateTo]="dateTo.value"
    [productNames]="productNames" [sourceDialog]="sourceDialog"
    [historyItemId]="historyItemId"></app-lpg-declarations-report>
</div>
<ng-template #declartionTable>
  <app-arag-declarations-report [covers]="cover.value" [insurers]="insurerName.value" [provider]="provider.value"
    [products]="product.value" [dateFrom]="dateFrom.value" [dateTo]="dateTo.value" [sourceDialog]="sourceDialog"
    [historyItemId]="historyItemId"></app-arag-declarations-report>
</ng-template>
