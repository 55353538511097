<div class="pricing-popup">
  <h3 class="title">{{branchPricingMode?'Branch Pricing':'Level of Cover Pricing'}}</h3>
  <mat-dialog-content class="mat-dialog-content">
    <form [formGroup]="form" #formDirective="ngForm">
      <section class="display-flex flex-direction-row">
        <mat-form-field class="product-name">
          <mat-label>Product</mat-label>
          <input matInput data-test="product" placeholder="Product" formControlName="productControl" required
            readonly="true" matTooltip="{{productControl.value}}" [autofocus]="false">
        </mat-form-field>
        <mat-form-field class="subproduct-name">
          <mat-label>Sub Product</mat-label>
          <input matInput data-test="subProduct" placeholder="Sub Product" formControlName="subProductControl" required
            readonly="true">
        </mat-form-field>
        <mat-form-field class="levelofcover-name">
          <mat-label>Level of Cover</mat-label>
          <input matInput data-test="levelOfCover" placeholder="Level of Cover" formControlName="levelOfCoverControl"
            required readonly="true">
        </mat-form-field>
      </section>
      <br>
      <section>
        <mat-form-field>
          <mat-label>Select type from dropdown</mat-label>
          <mat-select data-test="priceType" placeholder="Select type from dropdown" formControlName="priceType"
            [disabled]="true" required>
            <mat-option *ngFor="let type of PriceType.values()" [value]="PriceType[type]">
              {{type}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="priceType.invalid">Price Type is required</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Broker Rate excl IPT</mat-label>
          <span matPrefix class="decimal-prefix">£ </span>
          <input matInput data-test="brokerRate" placeholder="Broker Rate excl IPT" formControlName="brokerRate"
            matInputCommified pattern="\d+(\.\d{1,2})?" appTwoDigitDecimalNumber
            [prevent-keys]="[13 , 16 , 18 , 20, '-']" required>
          <mat-error *ngIf="brokerRate.errors?.required">Broker Rate is required</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Broker Inc IPT</mat-label>
          <span matPrefix class="decimal-prefix">£ </span>
          <input matInput data-test="brokerIncIPT" placeholder="Broker Inc IPT" formControlName="brokerIncIPT" required
            readonly appTwoDigitDecimalNumber [prevent-keys]="[13 , 16 , 18 , 20, '-']" matInputCommified readonly>
          <mat-error *ngIf="brokerIncIPT.invalid">Broker Inc IPT is required</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Minimum Sale Rate</mat-label>
          <span matPrefix class="decimal-prefix">£ </span>
          <input matInput data-test="minimumSaleRate" placeholder="Minimum Sale Rate" formControlName="minimumSaleRate"
            pattern="\d+(\.\d{1,2})?" appTwoDigitDecimalNumber [prevent-keys]="[13 , 16 , 18 , 20, '-']"
            matInputCommified required>
          <mat-error *ngIf="minimumSaleRate.invalid">Minimum Sale Rate is required</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Net Maximum Retail Rate</mat-label>
          <span matPrefix class="decimal-prefix">£ </span>
          <input matInput data-test="maximumRetailRate" placeholder="Net Maximum Retail Rate"
            formControlName="maximumRetailRate" pattern="\d+(\.\d{1,2})?" appTwoDigitDecimalNumber
            [prevent-keys]="[13 , 16 , 18 , 20, '-']" matInputCommified>
          <mat-error *ngIf="maximumRetailRate.invalid">Net Maximum Retail Rate is required</mat-error>
        </mat-form-field>
      </section>
      <section>
        <mat-form-field>
          <mat-label>Underwriter Rate</mat-label>
          <span matPrefix class="decimal-prefix">£ </span>
          <input matInput data-test="underwriterRate" placeholder="Underwriter Rate" formControlName="underwriterRate"
            pattern="\d+(\.\d{1,2})?" appTwoDigitDecimalNumber [prevent-keys]="[13 , 16 , 18 , 20, '-']"
            matInputCommified required>
          <mat-error *ngIf="underwriterRate.invalid">Underwriter Rate is required</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Intermediary Commission</mat-label>
          <span matPrefix class="decimal-prefix">£ </span>
          <input matInput data-test="intermediaryCommission" placeholder="Intermediary Commission"
            appTwoDigitDecimalNumber [prevent-keys]="[13 , 16 , 18 , 20, '-']" matInputCommified
            formControlName="intermediaryCommission" required readonly>
          <mat-error *ngIf="intermediaryCommission.invalid">Intermediary Commission is required</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Select status from dropdown</mat-label>
          <mat-select data-test="status" placeholder="Select status from dropdown" formControlName="status" required>
            <mat-option *ngFor="let status of Status.values()" [value]="Status[status]">
              {{status}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="status.invalid">Status is required</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Effective Date From</mat-label>
          <input data-test="effectiveDateFrom" [min]="minEffectiveFromDate" [matDatepicker]="effectiveDateFromComponent"
            [required]="true" matNativeControl spellcheck="false" matInput placeholder="Effective Date From"
            formControlName="effectiveDateFrom" [readonly]="isReadonly || isEffectiveFromDisabled">
          <mat-datepicker-toggle matSuffix [for]="effectiveDateFromComponent"
            [disabled]="isReadonly || isEffectiveFromDisabled">
          </mat-datepicker-toggle>
          <mat-datepicker #effectiveDateFromComponent></mat-datepicker>
          <mat-hint *ngIf="isReadonly || updateMode">dd/mm/yyyy</mat-hint>
          <mat-hint *ngIf="!isReadonly && !updateMode">dd/mm/yyyy, the effective from date can not be prior to today's
            date</mat-hint>
          <mat-error
            *ngIf="effectiveDateFrom.invalid && isValidDate && !effectiveDateFrom.hasError('matDatepickerMin')">Effective
            date from is required</mat-error>
          <mat-error
            *ngIf="effectiveDateFrom.invalid && isValidDate && effectiveDateFrom.hasError('matDatepickerMin')">Effective
            date from is before the minimum date</mat-error>
          <mat-error *ngIf="!isValidDate">Please enter valid date</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Effective Date To</mat-label>
          <input data-test="effectiveDateTo" [matDatepicker]="effectiveDateToComponent" matNativeControl
            spellcheck="false" matInput placeholder="Effective Date To" formControlName="effectiveDateTo"
            [readonly]="isReadonly" [min]="minEffectiveToDate">
          <mat-datepicker-toggle matSuffix [for]="effectiveDateToComponent" [disabled]="isReadonly">
          </mat-datepicker-toggle>
          <mat-datepicker #effectiveDateToComponent></mat-datepicker>
          <mat-hint>dd/mm/yyyy</mat-hint>
          <mat-error *ngIf="!isValidToDate && !effectiveDateTo.hasError('matDatepickerMin')">Please enter valid
            date</mat-error>
          <mat-error *ngIf="effectiveDateFrom.invalid && effectiveDateTo.hasError('matDatepickerMin')">Effective date to
            is before the minimum date</mat-error>
        </mat-form-field>
        <button mat-button mat-raised-button color="primary" (click)="reset()" class="reset-button"
          matTooltip="{{createNewButtonTooltipText}}">{{createNewButtonText}}</button>
      </section>
    </form>

    <mat-dialog-actions class="justify-items-end button-row">
      <button mat-button mat-raised-button color="primary" [disabled]="form.invalid || form.pristine"
        (click)="save(false)">{{updateMode?'Update':'Save'}}</button>
      <button mat-button mat-raised-button color="primary" [disabled]="form.invalid || form.pristine"
        (click)="save(true)">{{updateMode?'Update & Close':'Save & Close'}}</button>
      <button mat-button mat-raised-button color="secondary" (click)="onCloseDialog(true)">Cancel</button>
    </mat-dialog-actions>
    <app-pricing-history [levelOfCover]="levelOfCover" [branchPricingMode]="branchPricingMode"
      [brokerBranchId]="brokerBranchId" (selectedPricing)="selectPricing($event)" [reloadHistory$]="reloadHistory$"
      [selectedPricingId]="selectedPricingId" [isIncludedInactiveFromParent]="this.data.isIncludedInactive">
    </app-pricing-history>
  </mat-dialog-content>
</div>
