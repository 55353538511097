import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild } from '@angular/core';
import { Policy } from 'src/app/models/insurance-policy';
import { PolicyService } from 'src/app/services/policy.service';
import {
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControl
} from '@angular/forms';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { UpdatePricingForRenewal } from 'src/app/models/policy-update';
import moment from 'moment';
import { LevelOfCoverRechoiceComponent } from './details/loc-rechoice-popup/loc-rechoice.component';
import { SellableLevelOfCover } from 'src/app/models/sellable-level-of-cover';
import { LevelOfCoverService } from 'src/app/services/level-of-cover.service';
import { FORMAT_ONLY_DATE, ProductConstant, TitleMessages } from 'src/app/shared/constants/constants';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Product } from 'src/app/models/product';
import { HttpBaseService } from 'src/app/shared/services/http-base.service';
import { ApiEndPoints } from 'src/app/shared/config/api-end-points';
import * as _ from 'lodash';
import { MatDialogConfig } from '@angular/material/dialog';
import { Utility } from 'src/app/utils/utility';
import { noWhitespace } from 'src/app/validators/no-whitespace.validator';
import { noSpecialCharacterValidator } from 'src/app/validators/no-special-character.validator';
import { PolicyDetailsComponent } from './details/policy-details.component';

@Component({
  selector: 'app-update-policy',
  templateUrl: './update-policy.component.html',
  styleUrls: ['./update-policy.component.scss']
})

export class UpdatePolicyComponent implements OnInit, OnDestroy, AfterViewInit {
  dataChanged = false;
  public searchForm: FormGroup;
  public isSearched: boolean;

  policyUpdate: Policy = null;
  isExpired: boolean;
  successMessage: string;
  isDisableUpdate = false;
  errorMessage: string = '';
  private destroy$ = new Subject();

  configLoCDialog = new MatDialogConfig();
  products: Product[] = [];

  @ViewChild('policyDetails') policyDetail: PolicyDetailsComponent;

  public get searchStringControl() {
    return this.searchForm.get('searchString') as AbstractControl;
  }

  constructor(private formBuilder: FormBuilder,
    private policyService: PolicyService,
    private readonly levelOfCoverService: LevelOfCoverService,
    public dialogService: DialogService,
    private readonly httpService: HttpBaseService,
    private readonly api: ApiEndPoints) {
    this.createSearchForm();
    this.configLoCDialog.disableClose = true;
  }

  get searchStringIsEmpty() {
    return Utility.isNullOrWhiteSpace(this.searchStringControl.value);
  }

  async ngOnInit() {
    await this.loadProductData();
  }

  canDeactivate(): boolean {
      if(!this.policyDetail || !this.policyDetail.form) return true;
      return !this.policyDetail.form.dirty;
  }

  ngAfterViewInit(): void {
    this.policyService.policy$.pipe(takeUntil(this.destroy$)).subscribe(x => {
      this.policyUpdate = x;
      this.isExpired = this.checkExpired(x);

      if (this.isExpired) {
        this.successMessage = 'The policy has been successfully renewed';
        this.checkValidLoC();
      } else {
        this.successMessage = 'Policy Inception Date has been successfully updated';
      }
    });
  }


  async loadProductData() {
    let products = await this.httpService.getDataAsync<Product[]>(this.api.getAllProducts());
    this.products = _.filter(products, (x) => { return x.isActive });
  }

  ngOnDestroy(): void {
    this.policyService.policy$.next(null);
    this.destroy$.next(null);  // trigger the unsubscribe
    this.destroy$.complete();
  }

  createSearchForm() {
    this.searchForm = this.formBuilder.group({
      searchString: [undefined, [Validators.required, noWhitespace, noSpecialCharacterValidator]],
    });
  }

  async search() {
    if (this.searchForm.valid) {
      this.policyUpdate = null;
      this.isSearched = false;
      this.dataChanged = false;
      this.isDisableUpdate = false;
      await this.policyService.getLatestPolicyByNumber(this.searchStringControl.value.trim());
      this.isSearched = true;
    }
  }

  checkExpired(policy: Policy) {
    if (policy !== null) {
      let now = moment(new Date());
      let expiryDate = moment(policy.policyExpireDate);
      return now.isAfter(expiryDate);
    }
  }

  async update(policy: UpdatePricingForRenewal) {
    const result = await this.policyService.updatePolicyInceptionDate(policy);

    if (result !== null) {
      this.dialogService.openSuccessDialogConfirm({ title: TitleMessages.successTitle, message: this.successMessage, disableClose: true }).subscribe(() => {
        this.reset();
      });
    }
    else {
      this.isDisableUpdate = true;
    }
  }

  resetSearchForm() {
    this.searchForm.reset();
    this.searchForm.markAsPristine();
    this.searchForm.updateValueAndValidity();
  }

  reset() {
    this.resetSearchForm();
    this.policyUpdate = null;
    this.isSearched = false;
    this.dataChanged = false;
  }

  checkValidLoC() {
    let checkNeedRenewLoCProduct = _.find(this.products, (x => { return x.id === this.policyUpdate.productId && (x.shortName === ProductConstant.breakdownCover || x.shortName === ProductConstant.taxiClub) }));
    if (checkNeedRenewLoCProduct) {
      this.policyUpdate.originalLevelOfCovers.forEach(loC => {
        let vehicle = loC.vehicle;
        let vehicleType = checkNeedRenewLoCProduct.shortName === ProductConstant.breakdownCover ? vehicle.vehicleType : '';
        let extraFilter = checkNeedRenewLoCProduct.shortName === ProductConstant.breakdownCover ? { AgeOfVehicle: this.getAgeOfVehicle(vehicle.year), Tonnage: vehicle.tonnage || '' } : { AgeOfVehicle: this.getAgeOfVehicle(vehicle.year) };

        this.levelOfCoverService.getValidLevelOfCovers(
          this.policyUpdate.productId,
          vehicleType,
          moment(this.policyUpdate.policyStartDate).add(1, 'year').format(FORMAT_ONLY_DATE),
          extraFilter
        ).subscribe(res => {

          let sellableLoCs = res.map(l => new SellableLevelOfCover(l));
          let isValid = sellableLoCs.findIndex(sLoC => sLoC.id === loC.id) > -1;

          if (!isValid) {
            this.dialogService.openComponentDialog(LevelOfCoverRechoiceComponent, { policy: this.policyUpdate, newLevelOfCovers: sellableLoCs, currentLoC: loC }, this.configLoCDialog).subscribe(async result => {
              if (!result) {
                this.isDisableUpdate = true;
              }
            });
          }
        }
        );
      })
    }
    else {
      this.errorMessage = '';
      this.policyUpdate.levelOfCovers.filter(x => x.invalidPricing).forEach(x => {
        this.isDisableUpdate = true;
        this.errorMessage += "<br>" + x.errorMessage;
      });
    }
  }

  private getAgeOfVehicle(year: any): number {
    let age: number = -1;

    if (Math.floor(+year) === +year) {
      age = new Date().getFullYear() - parseInt(year, 10);
      if (age === 0) { age += 1; }
    }
    return age;
  }
}
