import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { BehaviorSubject, tap } from 'rxjs';
import { ExportFileType } from 'src/app/models/enums/report-type.enum';
import { PolicyImportDetail } from 'src/app/models/policy-import-report-response';
import { ReportService } from 'src/app/services/report.service';
import { ApiEndPoints } from 'src/app/shared/config/api-end-points';
import { AppConstants, PagingConstant } from 'src/app/shared/constants/constants';
import { HttpBaseService } from 'src/app/shared/services/http-base.service';

@Component({
  selector: 'app-policy-import-report-detail',
  templateUrl: './policy-import-report-detail.component.html',
  styleUrls: ['./policy-import-report-detail.component.scss']
})
export class PolicyImportReportDetailComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() brokerNameExpanded: BehaviorSubject<string>;

  @Input() brokerName: string;
  @Input() dateFrom: string;
  @Input() dateTo: string;
  displayedColumnsub: string[] = ['dateImport', 'fileName', 'productName', 'policyNumber', 'policyHolder', 'transactionType', 'inceptionDate', 'errorDescription'];
  data: PolicyImportDetail[] = [];
  totalRows = 0;
  isShow = false;
  pagingConstant = PagingConstant;
  appConstants = AppConstants;
  notFound = "";

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  filterForm = this.formBuilder.group({
    isOnlyError: [true]
  });

  get isOnlyError() {
    return this.filterForm.get('isOnlyError') as AbstractControl;
  }

  constructor(
    private readonly _reportService: ReportService,
    private readonly formBuilder: FormBuilder, private readonly httpService: HttpBaseService,
    private readonly _api: ApiEndPoints,
  ) { }

  async ngOnInit(): Promise<void> {
    this.brokerNameExpanded?.subscribe(x => {
      if (x == this.brokerName && this.totalRows == 0) this.loadData();
    })

  }

  loadData() {
    let pageIndex = this.paginator?.pageIndex ? this.paginator?.pageIndex : this.pagingConstant.pageIndex;
    let pageSize = this.paginator?.pageSize ? this.paginator?.pageSize : this.pagingConstant.pageSizeLarger;
    this._reportService.getPolicyImportReportWithPaging(this.brokerName, this.dateFrom, this.dateTo, this.isOnlyError.value, pageIndex, pageSize)
      .subscribe(x => {
        this.data = x.policyReportLines;
        this.totalRows = x.total;
        this.isShow = true;
        if (this.data.length > 0) {
          this.notFound = "";
        }
        else {
          this.notFound = "No error records found";
        }
      })
  }
  getStyle() {
    let display = this.totalRows == 0 ? 'none' : 'block';
    return {
      'display': display
    };
  }

  ngOnDestroy(): void {
    this.brokerNameExpanded.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.isOnlyError?.valueChanges.subscribe(() => {
      this.paginator.pageSize = 10;
      this.paginator.pageIndex = 0;
      this.loadData();
    });
    this.paginator?.page
      .pipe(
        tap(() => this.loadData())
      )
      .subscribe();
  }
  exportExcel() {
    this.export(ExportFileType.Excel);
  }

  exportCSV() {
    this.export(ExportFileType.Csv);
  }

  export(type: ExportFileType) {
    let url = this._api.getPolicyImportReport(this.brokerName, this.dateFrom, this.dateTo, this.isOnlyError.value, type);
    let fileName = `${(this.data[0].brokerNameDescription).replace(/\s/g, '')}PolicyImportReport`;

    this.httpService.downloadFile(url).subscribe(
      (data: BlobPart) => {
        const blob = new Blob([data], { type: this.getContentType(type) });
        let fileURL = window.URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.style.display = "none";
        link.href = fileURL;
        link.download = `${fileName} ${new Date().toLocaleDateString("en-UK")}`;
        link.target = '_blank';
        link.click();

        setTimeout(() => {
          link.remove();
          window.URL.revokeObjectURL(fileURL);
        }, 100);
      });
  }

  getContentType(fileType: ExportFileType) {
    switch (fileType) {
      case ExportFileType.Pdf:
        return "application/pdf";

      case ExportFileType.Csv:
        return "text/csv";

      case ExportFileType.Excel:
        return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

      default:
        return '';
    }
  }
}


