
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { Directive, forwardRef, Input, Renderer2, ElementRef } from '@angular/core';
import { Utility } from 'src/app/utils/utility';

@Directive({
  selector: 'input[TitleCase]',
  // When the user updates the input
  host: { '(input)': 'handleInput($event.target.value)', '(blur)': 'onTouched()' },
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => TitleCaseDirective), multi: true },
  ]

})
export class TitleCaseDirective implements ControlValueAccessor {
  lastValue = "";
  onTouched = () => { }
  onChange = (_: any) => { }
  @Input('transformer') transformer = (v: string) => Utility.toTitleCase(v);

  constructor(private renderer: Renderer2, private element: ElementRef) {}

  handleInput(value: any) {
    let newVal = this.transformer(value);
    if (newVal != value || this.lastValue != newVal) {
      this.lastValue = newVal;
      let currentCursorPosition = this.element.nativeElement.selectionStart;
      this.renderer.setProperty(this.element.nativeElement, 'value', newVal);
      this.element.nativeElement.selectionEnd = currentCursorPosition;
      this.onChange(newVal);
    }
  }

  writeValue(value: any) {
    let normalizedValue = this.lastValue = value == null ? '' : value;
    normalizedValue = this.transformer(normalizedValue);
    this.lastValue = normalizedValue;
    this.renderer.setProperty(this.element.nativeElement, 'value', normalizedValue);
  }

  registerOnChange(fn: (value: any) => any): void { this.onChange = fn }

  registerOnTouched(fn: () => any): void { this.onTouched = fn }
}