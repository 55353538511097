import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class UserProfileService {
  identityClaimsReady: Subject<any> = new Subject();

  onIdentityClaimsReadyChanged(claims): void {
    this.identityClaimsReady.next(claims);
  }
}
