export interface AddressServerResponse {
    buildingNumber: string;
    addressLine1: string;
    addressLine2: string;
    town: string;
    county: string;
    postCode: string;
}

export class AddressByPostCode {
    buildingNumber: string;
    addressLine1: string;
    addressLine2: string;
    town: string;
    county: string;    
    addressLine3: string; //town
    addressLine4: string; //County    
    addressLine5: string = "United Kingdom"; //"United Kingdom" by default
    postCode: string;

    constructor (addressServerResponse: AddressServerResponse){
        this.buildingNumber = addressServerResponse.buildingNumber;
        this.addressLine1 = addressServerResponse.addressLine1;
        this.addressLine2= addressServerResponse.addressLine2;
        this.addressLine3 = addressServerResponse.town;
        this.addressLine4 = addressServerResponse.county;
        this.addressLine5 = "United Kingdom";
        this.town = addressServerResponse.town;
        this.county = addressServerResponse.county;
        this.postCode = addressServerResponse.postCode;
    }
}
