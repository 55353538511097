<div class="not-found" *ngIf="notFoundMessage">{{notFoundMessage}}</div>

<mat-card [class]='sourceDialog ? "diaglog-table" : "lpg-report"' [style]="getTableStyle()">
  <div class="head-report">
    <div class="desc-flex">
      <div class="desc-flex-start">
        <table class="bordereau">
          <tr>
            <td>Bordereau Period:</td>
            <td class="invi"></td>
          </tr>
          <tr>
            <td>Month:</td>
            <td>{{this.result?.month}}</td>
          </tr>
          <tr>
            <td>Year:</td>
            <td>{{this.result?.year}}</td>
          </tr>
        </table>
      </div>
      <div class="desc-flex-end">
        <table class="business">
          <tr>
            <td class="invi"> </td>
            <td class="invi"> </td>
            <td class="invi"> </td>
            <td colspan="2"> Business Partner Name:</td>
            <td colspan="2">{{this.result?.businessPartnerName}}</td>
          </tr>
          <tr>
            <td class="invi"> </td>
            <td class="invi"> </td>
            <td class="invi"> </td>
            <td colspan="2">LPG Agency Number:</td>
            <td colspan="2">{{this.result?.lpgAgencyNumber}}</td>
          </tr>
          <tr>
            <td class="invi"> </td>
            <td class="invi"> </td>
            <td class="invi"> </td>
            <td colspan="2">Product Type:</td>
            <td colspan="2">{{this.productNames}}</td>
          </tr>
          <tr>
            <td class="invi"> </td>
            <td class="invi"> </td>
            <td class="invi"> </td>
            <td colspan="2">LPG Scheme Reference Number:</td>
            <td colspan="2">{{this.result?.lpgSchemeReferenceNumber}}</td>
          </tr>
          <tr class="align-right">
            <td class="invi"> </td>
            <td> Gross Premium </td>
            <td> Net Premium</td>
            <td> Commission</td>
            <td> Fee</td>
            <td> IPT</td>
            <td> Premium to LPG (Inc IPT)</td>
          </tr>
          <tr class="align-right">
            <td class="align-left"> TOTAL </td>
            <td>{{this.result?.totalGross | currency:'GBP':'symbol'}}</td>
            <td>{{this.result?.totalNet | currency:'GBP':'symbol'}}</td>
            <td>{{this.result?.totalCommission | currency:'GBP':'symbol'}}</td>
            <td>{{this.result?.totalFee | currency:'GBP':'symbol'}}</td>
            <td>{{this.result?.totalIPT | currency:'GBP':'symbol'}}</td>
            <td>{{this.result?.totalPremiumToLPG | currency:'GBP':'symbol'}}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <hr>

  <mat-table class="report" [dataSource]="dataSource" matSort matSortActive="inceptionDate" matSortDirection="asc"
    matSortDisableClear>
    <ng-container matColumnDef="policyNumber">
      <mat-header-cell *matHeaderCellDef> Policy Number </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div class="ellipsis"> {{row.policyNumber}}</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="inceptionDate">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Inception Date </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div class="ellipsis"> {{row.inceptionDate | date: appConstants.dateConfig.date}}</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="renewalDate">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Renewal Date </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div class="ellipsis">{{row.renewalDate | date: appConstants.dateConfig.date}}</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="transactionType">
      <mat-header-cell *matHeaderCellDef> Transaction Type </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div class="ellipsis">{{row.transactionType}}</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="policyHolder">
      <mat-header-cell *matHeaderCellDef> Insured </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div class="ellipsis" matTooltip={{row.policyHolder}}>{{row.policyHolder}}</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="address">
      <mat-header-cell *matHeaderCellDef> Address </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div class="ellipsis" matTooltip={{row.address}}>{{row.address}}</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="postcode">
      <mat-header-cell *matHeaderCellDef> Postcode </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div class="ellipsis">{{row.postcode}}</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="country">
      <mat-header-cell *matHeaderCellDef> Country </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div class="ellipsis">{{row.country}}</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="vehicleMake">
      <mat-header-cell *matHeaderCellDef> Vehicle Make </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div class="ellipsis">{{row.vehicleMake}}</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="vehicleModel">
      <mat-header-cell *matHeaderCellDef> Vehicle Model </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div class="ellipsis">{{row.vehicleModel}}</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="vehicleYear">
      <mat-header-cell *matHeaderCellDef> Vehicle Year </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div class="ellipsis">{{row.vehicleYear}}</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="vehicleRegistration">
      <mat-header-cell *matHeaderCellDef> Vehicle Registration</mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div class="ellipsis">{{row.vehicleRegistration}}</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="grossPremium">
      <mat-header-cell *matHeaderCellDef> Gross Premium </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div class="ellipsis">{{row.grossPremium |currency:'GBP':'symbol'}}</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="netPremium">
      <mat-header-cell *matHeaderCellDef> Net Premium </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div class="ellipsis">{{row.netPremium |currency:'GBP':'symbol'}}</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="commission">
      <mat-header-cell *matHeaderCellDef> Commission </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div class="ellipsis">{{row.commission |currency:'GBP':'symbol'}}</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="fee">
      <mat-header-cell *matHeaderCellDef> Fee </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div class="ellipsis">{{row.fee |currency:'GBP':'symbol'}}</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="ipt">
      <mat-header-cell *matHeaderCellDef> IPT </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div class="ellipsis">{{row.ipt |currency:'GBP':'symbol'}}</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="premiumToLPG">
      <mat-header-cell *matHeaderCellDef> Premium to LPG (Inc IPT) </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div class="ellipsis">{{row.premiumToLPG |currency:'GBP':'symbol'}}</div>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row matRipple *matRowDef="let row; columns: displayedColumns;"
      [ngClass]="row.isHaveNegativeLine ? row.transactionType == transactionType.CANX ? 'canx-row': 'new-row':''"></mat-row>
  </mat-table>
  <div class="not-found">{{notFoundMessage}}</div>
  <div class="display-flex flex-direction-row footer">
    <div class="btn-group flex-50">
      <button class="btnExport" [disabled]="!(dataSource && dataSource.data && dataSource.data.length> 0)"
        matTooltip="Export to Excel" mat-raised-button color="primary" (click)="exportExcel()">
        Export to Excel
      </button>
      <button class="btnExport" [disabled]="!(dataSource && dataSource.data && dataSource.data.length> 0)"
        matTooltip="Export to CSV" mat-raised-button color="primary" (click)="exportCsv()">
        Export to CSV
      </button>
      <button class="btnExport" [disabled]="!(dataSource && dataSource.data && dataSource.data.length> 0)"
        matTooltip="Export to PDF" mat-raised-button color="primary" (click)="exportPdf()">
        Export to PDF
      </button>
    </div>

    <div class="paging flex-50">
      <mat-paginator [length]="totalRows" [pageIndex]="pagingConstant.pageIndex"
        [pageSize]="pagingConstant.pageSizeLarger" [pageSizeOptions]="pagingConstant.pageSizeOptionsLarger"
        showFirstLastButtons>
      </mat-paginator>
    </div>
  </div>
</mat-card>