<mat-dialog-content >
  <div >
    <h2>Confirmation Message</h2>
  </div>

  <div>The Gross Premium is lower than the Broker Premium due to Kindertons Ltd.</div>
  <br/>
  <div>Please tick the box to confirm the amount payable as below:</div>

  <br/><br/>
  <div [formGroup]="iptConfirm" class="flex-row-between">
    <div >
      <label for="confirm">I accept the Premium/IPT amount payable ({{moneytary(pricing.originalBrokerRate)}})</label>
      <mat-checkbox formControlName="confirm">
      </mat-checkbox>
    </div>

    <button  [disabled]="!iptConfirm.valid" mat-raised-button  color="primary" (click)="closeDialog()">
      Confirm
    </button>
  </div>
</mat-dialog-content>
