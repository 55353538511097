import { AbstractControl } from '@angular/forms';

export function urlValidator(control: AbstractControl) {
  const urlPattern = /^(http:\/\/|https:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;
  if (control.value && (!urlPattern.test(control.value) || ([...control.value.matchAll(/https:\/\//g)].length + [...control.value.matchAll(/http:\/\//g)].length) > 1)) {
    return { 'urlFormat': true };
  }

  return null;
};
export function requireHttps(control: AbstractControl) {
    const urlPattern = /^(https:\/\/)/;
    if (control.value && !urlPattern.test(control.value)) {
        return { 'requireHttps': true };
    }
    return null;
};
