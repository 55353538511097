import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpBaseService } from 'src/app/shared/services/http-base.service';
import { ApiEndPoints } from 'src/app/shared/config/api-end-points';
import { Role, User, UserDetails, UpdatedUser } from 'src/app/models/user.model';
import { Utility } from 'src/app/utils/utility';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { Status } from 'src/app/models/enums/status.enum';
import { BranchInformation } from 'src/app/models/broker';
import { TitleMessages } from 'src/app/shared/constants/constants';
import { result } from 'lodash';
import { noWhitespace } from 'src/app/validators/no-whitespace.validator';
import { isValidEmail } from 'src/app/validators/valid-email.validator';
import { ComponentCanDeactivate } from 'src/app/shared/can-deactivate/component-can-deactivate';
import { noLeadingWhitespaceValidator } from 'src/app/validators/no-leading-white-space.validator';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent extends ComponentCanDeactivate implements OnInit {
  @ViewChild('focus') userNameFocus: ElementRef;
  userFormGroup: FormGroup;
  dirtyUserForm: boolean = false;

  roles: Role[] = [];
  Status = Status;
  updatedUserId = '';
  updatedUser: UserDetails;
  loading: boolean = false;
  accessDeniedTriggerd: boolean = false;

  updateMode = false;

  constructor(private readonly formBuilder: FormBuilder, private readonly router: Router,
    private readonly activeRouter: ActivatedRoute,
    private readonly httpService: HttpBaseService, private readonly api: ApiEndPoints,
    private readonly dialogService: DialogService) {
    super();
    this.userFormGroup = this.formBuilder.group({
      userName: ['', [Validators.required, Validators.maxLength(250), noWhitespace, noLeadingWhitespaceValidator]],
      emailAddress: ['', [Validators.required, Validators.email, isValidEmail, Validators.maxLength(250)]],
      phoneNumber: ['', Validators.required],
      roleId: ['', Validators.required],
      brokerBranchId: ['', Validators.required],
      brokerBranchName: [''],
      status: [Status.Active],
    });
  }

  get userName() {
    return this.userFormGroup.get('userName') as AbstractControl;
  }

  get emailAddress() {
    return this.userFormGroup.get('emailAddress') as AbstractControl;
  }

  get phoneNumber() {
    return this.userFormGroup.get('phoneNumber') as AbstractControl;
  }

  get roleId() {
    return this.userFormGroup.get('roleId') as AbstractControl;
  }

  get brokerBranchName() {
    return this.userFormGroup.get('brokerBranchName') as AbstractControl;
  }

  get brokerBranchId() {
    return this.userFormGroup.get('brokerBranchId') as AbstractControl;
  }

  get status() {
    return this.userFormGroup.get('status') as AbstractControl;
  }

  get canUpdate() {
    if (!this.updatedUser) { return true; }

    return this.updatedUser && this.updatedUser.canUpdate;
  }

  get disableClearButton() {
    return this.brokerBranchName && this.brokerBranchName.value;
  }

  ngOnInit() {
    this.httpService.getData<Role[]>(this.api.getSystemRoles()).subscribe(data => {
      if (data) {
        this.roles = data;
      }
    });
    this.activeRouter.params.subscribe(param => {
      this.updatedUserId = param.userId;
      if (this.updatedUserId) {
        this.updateMode = true;
        this.loading = true;
        this.httpService.getData<UserDetails>(this.api.getUserById(this.updatedUserId)).subscribe(data => {
          if (data) {
            this.updatedUser = data;
            this.bindData(this.updatedUser);
            this.loading = false;
          }
        });
      }
      else {
        setTimeout(() => {
          this.userNameFocus.nativeElement.focus();
        }, 100);
      }
    });
  }

  async save() {
    this.loading = true;
    if (!this.updatedUserId) {
      const user = new User(
        this.userName.value,
        this.emailAddress.value,
        this.phoneNumber.value,
        this.roleId.value,
        this.brokerBranchId.value,
        this.status.value);

      try {
        const userId = await this.httpService.createResource(this.api.createUser(), user).toPromise();

        this.dialogService.openSuccessDialogConfirm({
          title: TitleMessages.successTitle, message: 'The user has been saved successfully'}).subscribe(result => {
            if (result) {
              this.dirtyUserForm = true;
              this.router.navigate(['app/users']);
            }
          });

      } catch (error) {
        this.loading = false;
      }
    }
    else {
      if (!this.canUpdate) {
        this.dialogService.openSuccessDialogConfirm({ title: 'Access Denied', message: 'You do not have the authority to amend your own permission levels.' })
          .subscribe(result => {
            this.accessDeniedTriggerd = true;
          });
        return;
      }
      const updatedUser = new UpdatedUser(
        this.userName.value,
        this.emailAddress.value,
        this.phoneNumber.value,
        this.roleId.value,
        this.brokerBranchId.value,
        this.status.value);
      updatedUser.id = this.updatedUserId;
      updatedUser.rowVersion = this.updatedUser.rowVersion;
      try {
        await this.httpService.putDataAsync<string>(this.api.createUser(), updatedUser);
        this.dialogService.openSuccessDialogConfirm({
          title: TitleMessages.successTitle, message: 'The user has been updated successfully'}).subscribe(result => {
            if (result) {
              this.dirtyUserForm = true;
              this.router.navigate(['app/users']);
            }
          });

      } catch (error) {
        this.loading = false;
      }
    }
  }

  cancel() {
      this.router.navigate([`/app/users`]);
  }

  formatPhoneNumber() {
    if (this.phoneNumber.value) {
      let original = this.phoneNumber.value as string;
      original = original.split(' ').join('');
      const formatted = Utility.formatPhoneNumber(original);
      if (original === formatted) {
        this.phoneNumber.setErrors({ incorrect: true });
      }
      else {
        this.phoneNumber.setValue(formatted);
        this.phoneNumber.setErrors(null);
      }
    }
  }

  setSelectedBrokerBranch(brokerBranch: BranchInformation) {
    if (brokerBranch) {
      this.brokerBranchName.setValue(brokerBranch.name);
      this.brokerBranchId.setValue(brokerBranch.id);
      this.userFormGroup.markAsDirty();
    }
  }

  bindData(user: UserDetails) {
    const role: Role = user.role;
    this.roleId.setValue(role.id, { emitEvent: false });
    this.status.setValue(user.isActive ? 1 : 0);
    this.emailAddress.setValue(user.emailAddress);
    this.phoneNumber.setValue(user.phoneNumber);
    this.userName.setValue(user.userName);
    if (user.brokerBranch) {
      this.brokerBranchName.setValue(user.brokerBranch.name);
      this.brokerBranchId.setValue(user.brokerBranch.id);
    }
    setTimeout(() => {
      this.userNameFocus.nativeElement.focus();
    }, 500);
  }

  canDeactivate(): boolean {
    if(!this.dirtyUserForm && !this.accessDeniedTriggerd){
      return this.userFormGroup.pristine;
    }
    return true;
  }
}


