import { Component, OnInit, OnDestroy } from '@angular/core';
import { Product, AssignProduct } from 'src/app/models/product';
import { ApiEndPoints } from 'src/app/shared/config/api-end-points';
import { HttpBaseService } from 'src/app/shared/services/http-base.service';
import * as _ from 'lodash';
import { Subscription, combineLatest, BehaviorSubject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { BranchProduct } from 'src/app/models/branch-product';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { BrokerBranch } from 'src/app/models/broker';
import { TitleMessages } from 'src/app/shared/constants/constants';
import { ComponentCanDeactivate } from 'src/app/shared/can-deactivate/component-can-deactivate';
import { PreviousRouteService } from 'src/app/shared/services/previous-route.service';

@Component({
  selector: 'app-branch-product-detail',
  templateUrl: './branch-product-detail.component.html',
  styleUrls: ['./branch-product-detail.component.scss']
})
export class BranchProductDetailComponent extends ComponentCanDeactivate implements OnInit, OnDestroy {

  canDeactivate(): boolean {
    return !this.selectedChange;
  }

  private paramsSubscription!: Subscription;
  brokerBranchId = '';

  allProducts: AssignProduct[] = [];
  brokerBranchProducts: Product[] = [];

  assignedProducts$: BehaviorSubject<Product[]> = new BehaviorSubject<Product[]>([]);
  assignedProducts: Product[] = [];
  brokerBranch: BrokerBranch;
  isSelectedAll: boolean = false;
  doneLoadingProducts: boolean = false;
  selectedChange: boolean = false;

  constructor(
    private readonly _api: ApiEndPoints,
    private readonly _httpService: HttpBaseService,
    private readonly activeRouter: ActivatedRoute,
    private readonly _router: Router,
    private readonly _previousRouteService: PreviousRouteService,
    public dialogService: DialogService) {
    super();
  }

  ngOnInit() {
    this.paramsSubscription = combineLatest([this.activeRouter.params],
      (params) => ({ params })).subscribe(async allParams => {

        let params = allParams.params;
        const branchId = params.branchId;

        if (branchId) {
          this.brokerBranchId = branchId;
          await this.loadData();
        }
      });
  }

  async loadData() {
    await this.loadBrokerBranchInfo();
    await this.loadProductData();
    await this.loadBrokerBranchProducts();

    this.doneLoadingProducts = true;

    this.assignedProducts = [];

    this.allProducts.forEach(p => {
      this.brokerBranchProducts.forEach(bP => {
        if (p.id === bP.id) {
          p.isAssigned = true;
          this.assignedProducts.push(p);
        }
      });
    });

    this.isSelectedAll = this.assignedProducts.length == this.allProducts.length;

    this.assignedProducts$.next(this.assignedProducts);
  }

  ngOnDestroy(): void {
    this.paramsSubscription.unsubscribe();
  }

  async changeProduct(product: AssignProduct) {
    this.selectedChange = true;

    if (!product.isAssigned) {
      if (this.isSelectedAll) {
        this.isSelectedAll = false;
      }
    }
    else {
      const filter = _.filter(this.allProducts, (x) => !x.isAssigned);
      this.isSelectedAll = filter.length == 0;
    }
  }

  async loadBrokerBranchInfo() {
    this.brokerBranch = await this._httpService.getDataAsync<BrokerBranch>(this._api.getBrokerBranch(this.brokerBranchId));
  }

  async loadProductData() {
    let products = await this._httpService.getDataAsync<AssignProduct[]>(this._api.getAllProducts());
    this.allProducts = _.filter(products, (x) => x.isActive);
    this.allProducts = _.each(this.allProducts, (x) => { x.isAssigned = false; });
  }

  async loadBrokerBranchProducts() {
    let brokerBranchProducts = await this._httpService.getDataAsync<Product[]>(this._api.getProductsByBrokerBranchId(this.brokerBranchId));
    this.brokerBranchProducts = _.filter(brokerBranchProducts, (x) => x.isActive);
  }

  async assign(branchId: string, branchProducts: BranchProduct[]) {
    await this._httpService.postDataAsync<any>(this._api.assignBranchProducts(branchId), branchProducts);
  }

  async onSave() {
    await this.save(false);
  }

  async onSaveAndClose() {
    await this.save(true);
  }

  async save(isClose: boolean) {
    let asignBranchProducts: BranchProduct[] = [];
    let notifyAssignProducts: Product[] = [];
    this.allProducts.forEach(p => {
      if (p.isAssigned) {
        notifyAssignProducts.push(p);
        const branchProduct: BranchProduct = {
          id: undefined,
          branchId: this.brokerBranchId,
          productId: p.id
        };
        asignBranchProducts.push(branchProduct);
      }
    });

    let difference = _.differenceBy(this.brokerBranchProducts, notifyAssignProducts, 'id');
    if (difference.length > 0) {
      let confirmMessage = 'Are you sure you wish to remove these records? If you continue all pricing records will be removed for the selected product';
      const primaryButtons = 'Yes';
      const secondaryButtons = 'No';
      this.dialogService.openConfirmationDialog({ title: TitleMessages.confirmationTitle, message: confirmMessage, primaryButtons: primaryButtons, secondaryButtons: secondaryButtons }).subscribe(
        async (result) => {
          if (result) {
            await this.assign(this.brokerBranchId, asignBranchProducts);
            this.confirmationChanged(isClose, notifyAssignProducts);
          }
        });
    }
    else {
      await this.assign(this.brokerBranchId, asignBranchProducts);
      this.confirmationChanged(isClose, notifyAssignProducts);
    }
  }

  confirmationChanged(isClose: boolean, assignedProducts: Product[]) {
    this.dialogService.openSuccessDialogConfirm({ title: TitleMessages.successTitle, message: 'Your changes have been successfully saved' }).subscribe(async result => {
      if (result) {

        this.selectedChange = false;

        if (isClose) {
          this._router.navigate([this.previousRoute()]);
        } else {

          await this.reloadBrokerBranchProducts();
        }
      }
    });
  }

  async reloadBrokerBranchProducts() {
    await this.loadBrokerBranchProducts();
    this.assignedProducts = [];
    this.allProducts.forEach(p => {
      this.brokerBranchProducts.forEach(bP => {
        if (p.id === bP.id) {
          p.isAssigned = true;
          this.assignedProducts.push(p);
        }
      });
    });
    this.assignedProducts$.next(this.assignedProducts);
  }


  onCancel() {
    this._router.navigate([this.previousRoute()]);
  }

  previousRoute() {
    let previousUrl = this._previousRouteService.getPreviousUrl();
    if (previousUrl.includes('branches')) return '/app/brokers/branches';
    return `/app/brokers/broker/update/${this.brokerBranch.brokerId}`;
  }

  selectAll() {
    this.selectedChange = true;
    this.allProducts.forEach(p => {
      p.isAssigned = this.isSelectedAll;
    });
  }
}
