import { Component, OnInit, Inject, AfterViewInit, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { FormBuilder, Validators, AbstractControl, FormGroup } from '@angular/forms';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { HttpBaseService } from 'src/app/shared/services/http-base.service';
import { ApiEndPoints } from 'src/app/shared/config/api-end-points';
import { SubProduct } from 'src/app/models/sub-product';
import { Product } from 'src/app/models/product';
import * as _ from 'lodash';
import { LevelOfCover, LevelOfCoverAdditional } from 'src/app/models/level-of-cover';
import { Status } from 'src/app/models/enums/status.enum';
import { ProductConstant, TitleMessages } from 'src/app/shared/constants/constants';
import { HirePeriodUnits } from 'src/app/models/enums/hire-period-unit.enum';
import { LevelOfCoverAdditionalFields } from 'src/app/models/enums/level-of-cover-additional-fields.enum';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { noWhitespace } from 'src/app/validators/no-whitespace.validator';

@Component({
  selector: 'app-level-of-cover-popup',
  templateUrl: './level-of-cover-popup.component.html',
  styleUrls: ['./level-of-cover-popup.component.scss']
})

export class LevelOfCoverPopupComponent implements OnInit, AfterViewInit, AfterViewChecked {

  product: Product;
  subProduct: SubProduct;
  form: FormGroup;
  products: Product[] = [];
  subProducts: SubProduct[] = [];

  updateLevelOfCover: LevelOfCover;
  updateMode = false;
  Status = Status;
  levelOfCoverSourceTypes = [
    { value: 'Hub', name: 'Hub' },
    { value: 'API', name: 'API' },
    { value: 'Both', name: 'Both' },
  ];

  HirePeriodUnits = HirePeriodUnits;

  public productConst = ProductConstant;

  constructor(
    private readonly _httpService: HttpBaseService,
    private readonly _api: ApiEndPoints,
    public dialogService: DialogService,
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
    public dialogRef: MatDialogRef<LevelOfCoverPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    this.createForm();
  }

  async ngOnInit() {
    await this.loadProductData();

    this.updateLevelOfCover = this.data.levelOfCover;
    if (this.updateLevelOfCover) {
      this.updateMode = true;
      this._httpService.getData<LevelOfCover>(this._api.getLevelOfCover(this.updateLevelOfCover.id)).subscribe(l => {
        this.updateLevelOfCover = l;
        this.product = l.product;
        this.subProducts = this.product.subProducts;
        this.subProduct = l.subProduct;
        this.bindingDataForUpdate();
      });
    }

    let selectedProduct = this.data.selectedProduct;
    if (selectedProduct) {
      this.product = selectedProduct;
      await this.bindingSubProductsByProduct(this.product.id);
      this.productId.setValue(this.product.id);
      this.productId.disable();
    }
  }

  async loadLevelOfCoverSourceTypes() {
    this.levelOfCoverSourceTypes = await this._httpService.getDataAsync<[]>(this._api.getLevelOfCoverSourceTypes());
  }

  bindingDataForUpdate() {
    this.productId.setValue(this.product.id);
    this.productId.disable();
    this.subProductId.setValue(this.subProduct.id);
    this.subProductId.disable();
    this.name.setValue(this.updateLevelOfCover.name);
    this.order.setValue(this.updateLevelOfCover.order);
    this.status.setValue(this.updateLevelOfCover.isActive ? Status.Active : Status.Inactive);
    this.levelOfCoverSourceType.setValue(this.updateLevelOfCover.levelOfCoverSourceType);

    if (this.product.shortName === this.productConst.guaranteedReplacementVehicle || this.product.shortName === this.productConst.landlordsLegalExpenses) {
      this.updateLevelOfCover.levelOfCoverAdditionals.forEach(element => {
        if (element.fieldName === LevelOfCoverAdditionalFields.HirePeriod) {
          this.hirePeriod.setValue(element.fieldValue);
        }

        if (element.fieldName === LevelOfCoverAdditionalFields.HirePeriodUnit) {
          this.hirePeriodUnit.setValue(HirePeriodUnits[element.fieldValue]);
        }

        if (element.fieldName === LevelOfCoverAdditionalFields.RentalGuarantee) {
          this.rentalGuarantee.setValue(element.fieldValue);
        }
      });
    }
  }

  async bindingSubProductsByProduct(productId: string) {
    const product = await this._httpService.getDataAsync<Product>(this._api.getProduct(productId));
    this.subProducts = product.subProducts;
    this.product = product;
  }

  ngAfterViewInit() {
    this.productId.valueChanges.subscribe(async v => {
      this.subProductId.updateValueAndValidity({ emitEvent: false });
      this.subProductId.setValue('');
      const selectedProduct = _.find(this.products, (x) => x.id === v);
      await this.bindingSubProductsByProduct(selectedProduct.id);

      if (selectedProduct.shortName === this.productConst.guaranteedReplacementVehicle) {
        this.hirePeriod.setValidators(Validators.required);
        this.hirePeriodUnit.setValidators(Validators.required);
      } else {
        this.hirePeriod.clearValidators();
        this.hirePeriod.updateValueAndValidity();
        this.hirePeriodUnit.clearValidators();
        this.hirePeriodUnit.updateValueAndValidity();
      }

      if (selectedProduct.shortName === this.productConst.landlordsLegalExpenses) {
        this.rentalGuarantee.setValidators(Validators.required);
      }
      else {
        this.rentalGuarantee.clearValidators();
        this.rentalGuarantee.updateValueAndValidity();
      }
    });
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  async loadProductData() {
    this.products = await this._httpService.getDataAsync<Product[]>(this._api.getAllProducts());
  }

  createForm() {
    this.form = this.formBuilder.group({
      productId: ['', [Validators.required]],
      subProductId: ['', [Validators.required]],
      name: ['', [Validators.required, noWhitespace]],
      levelOfCoverSourceType: ['', [Validators.required]],
      order: ['', [Validators.required]],
      status: [Status.Active, [Validators.required]],
      hirePeriod: [''],
      hirePeriodUnit: [''],
      rentalGuarantee: ['']
    });
  }

  get productId() {
    return this.form.get('productId') as AbstractControl;
  }

  get subProductId() {
    return this.form.get('subProductId') as AbstractControl;
  }

  get name() {
    return this.form.get('name') as AbstractControl;
  }

  get levelOfCoverSourceType() {
    return this.form.get('levelOfCoverSourceType') as AbstractControl;
  }

  get order() {
    return this.form.get('order') as AbstractControl;
  }

  get status() {
    return this.form.get('status') as AbstractControl;
  }

  get hirePeriod() {
    return this.form.get('hirePeriod') as AbstractControl;
  }

  get hirePeriodUnit() {
    return this.form.get('hirePeriodUnit') as AbstractControl;
  }

  get rentalGuarantee() {
    return this.form.get('rentalGuarantee') as AbstractControl;
  }

  async onSave() {
    await this.save(true);
  }

  async save(closeDialog: boolean) {
    if (this.form.valid) {

      let levelOfCover: LevelOfCover = {
        id: this.updateMode ? this.updateLevelOfCover.id : undefined,
        rowVersion: this.updateMode ? this.updateLevelOfCover.rowVersion : undefined,
        subProductId: this.subProductId.value,
        productId: this.productId.value,
        name: this.name.value,
        order: this.order.value,
        isActive: this.status.value,
        description: this.name.value,
        levelOfCoverSourceType: this.levelOfCoverSourceType.value,
        product: this.updateMode ? this.product : undefined,
        subProduct: this.updateMode ? this.subProduct : undefined,
        levelOfCoverAdditionals: this.getLevelOfCoverAdditionalProperties(),
        iptRate: undefined,
        maximumRetailRate: undefined,
        netPriceToBroker: undefined
      };

      try {
        if (!this.updateMode) {
          await this._httpService.postDataAsync<string>(this._api.createLevelOfCover(), levelOfCover);
        }
        else {
          await this._httpService.putDataAsync<LevelOfCover>(this._api.updateLevelOfCover(), levelOfCover);
        }

        let message = `The Level of Cover has been ${this.updateMode ? 'updated' : 'saved'} successfully`;

        this.dialogService.openSuccessDialogConfirm({ title: TitleMessages.successTitle, message }).subscribe(result => {
          if (result) {
            if (closeDialog) {
              this.dialogRef.close(true);
            }
          }
        });
        this.form.markAsPristine();
      }
      finally {
      }
    }
    else {
      return;
    }
  }

  getLevelOfCoverAdditionalProperties() {
    if (this.updateMode && this.updateLevelOfCover) {
      let additionalProperties = [];
      if (this.updateLevelOfCover.levelOfCoverAdditionals && this.updateLevelOfCover.levelOfCoverAdditionals.length > 0) {
        this.updateLevelOfCover.levelOfCoverAdditionals.forEach(x => {
          if (this.product && this.product.shortName === this.productConst.guaranteedReplacementVehicle && x.fieldName === LevelOfCoverAdditionalFields.HirePeriod) {
            x.fieldValue = this.hirePeriod.value;
          }
          if (this.product && this.product.shortName === this.productConst.guaranteedReplacementVehicle && x.fieldName === LevelOfCoverAdditionalFields.HirePeriodUnit) {
            x.fieldValue = HirePeriodUnits[this.hirePeriodUnit.value];
          }
          if (this.product && this.product.shortName === this.productConst.landlordsLegalExpenses) {
            x.fieldValue = this.rentalGuarantee.value;
          }
        });

        additionalProperties = this.updateLevelOfCover.levelOfCoverAdditionals;
        return additionalProperties;
      }
      else {
        // This is code handle for update cases on existed GHV and Lanlord LoCs before
        return this.getAdditionalPropertiesForCreating();
      }
    }
    else {
      return this.getAdditionalPropertiesForCreating();
    }
  }

  getAdditionalPropertiesForCreating() {
    let additionalProperties = [];
    if (this.product && this.product.shortName === this.productConst.guaranteedReplacementVehicle) {
      const hirePeriodAddional: LevelOfCoverAdditional = {
        id: undefined,
        rowVersion: undefined,
        levelOfCoverId: undefined,
        fieldName: LevelOfCoverAdditionalFields.HirePeriod,
        fieldValue: this.hirePeriod.value
      };
      additionalProperties.push(hirePeriodAddional);
      const hirePeriodUnitAddional: LevelOfCoverAdditional = {
        id: undefined,
        rowVersion: undefined,
        levelOfCoverId: undefined,
        fieldName: LevelOfCoverAdditionalFields.HirePeriodUnit,
        fieldValue: HirePeriodUnits[this.hirePeriodUnit.value]
      };
      additionalProperties.push(hirePeriodUnitAddional);
    }
    else if (this.product && this.product.shortName === this.productConst.landlordsLegalExpenses) {
      const rentalGuaranteeAddional: LevelOfCoverAdditional = {
        id: undefined,
        rowVersion: undefined,
        levelOfCoverId: undefined,
        fieldName: LevelOfCoverAdditionalFields.RentalGuarantee,
        fieldValue: this.rentalGuarantee.value
      };
      additionalProperties.push(rentalGuaranteeAddional);
    }
    return additionalProperties;
  }

  onCloseDialog(isReload: boolean) {
    if (this.form.dirty) {
      this.dialogService.openConfirmationDialog({ title: TitleMessages.confirmationTitle, message: 'Are you sure you want to continue. Your changes will be lost?' }).subscribe(result => {
        if (result) {
          this.dialogRef.close(isReload);
        }
      });
    }
    else {
      this.dialogRef.close(isReload);
    }
  }

  disableSaveButton(){
    if(this.updateMode){
      if(this.form && this.form.valid){
        const dirty = _.find(this.form.controls, (x) => x.dirty)
        return dirty ? false : true;
      }
      else{
        return true;
      }
    }else{
      return this.form.invalid;
    }
  }

  checkOrderValue() {
    if (this.order.value === 0) {
      this.order.setValue('');
      this.order.markAsTouched();
    }
  }
}
