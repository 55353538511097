import { Component, OnInit, Inject, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Broker } from 'src/app/models/broker';
import { HttpBaseService } from 'src/app/shared/services/http-base.service';
import { ApiEndPoints } from 'src/app/shared/config/api-end-points';
import { DialogService } from 'src/app/shared/services/dialog.service';
import * as _ from 'lodash';
import { CreateBrokerGroup, BrokerGroup } from 'src/app/models/broker-group';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TitleMessages } from 'src/app/shared/constants/constants';
import { SearchBrokerGroupComponent } from '../search-broker-group/search-broker-group.component';
import { SearchBrokerComponent } from '../search-broker/search-broker.component';
import { noWhitespace } from 'src/app/validators/no-whitespace.validator';

@Component({
  selector: 'app-broker-group-popup',
  templateUrl: './broker-group-popup.component.html',
  styleUrls: ['./broker-group-popup.component.scss']
})
export class BrokerGroupPopupComponent implements OnInit, AfterViewInit {

  @ViewChild('searchbrokergroup')
  searchbrokergroup: SearchBrokerGroupComponent;

  @ViewChild('searchbroker')
  searchbroker: SearchBrokerComponent;

  displayedColumns: string[] = ['originalId', 'name', 'address', 'remove'];

  dataSource: MatTableDataSource<Broker>;

  brokers: Broker[] = [];

  newBrokerGroupId: string;


  groupForm: FormGroup;

  selectedBrokerGroup: BrokerGroup;

  constructor(
    private readonly _httpService: HttpBaseService,
    private readonly _api: ApiEndPoints,
    public dialogService: DialogService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<BrokerGroupPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.createGroupForm();
    this.dataSource = new MatTableDataSource<Broker>(this.brokers);
  }

  async setSelectedBroker($event) {
    const selectedBroker = $event as Broker;
    const duplicated = _.find(this.brokers, (x) => x.id === selectedBroker.id)
    if (duplicated) {
      this.dialogService.openErrorDialog({ title: 'Message', message: 'The broker already selected before' });
    } else {
      if (selectedBroker.brokerGroupId) {
        const brokerGroup = await this._httpService.getDataAsync<BrokerGroup>(this._api.getBrokerGroup(selectedBroker.brokerGroupId));
        if (brokerGroup != null) {
          const message = `Broker ${selectedBroker.name} is already assigned to Broker Group ${brokerGroup.brokerGroupName}. Do you wish to continue?`;
          this.dialogService.openConfirmationDialog({ title: TitleMessages.confirmationTitle, message }).subscribe(v => {
            if (v) {
              this.brokers.push(selectedBroker);
              this.dataSource.data = this.brokers;
            }
          });

        }
        else {
          this.brokers.push(selectedBroker);
          this.dataSource.data = this.brokers;
        }

      }
      else {
        this.brokers.push(selectedBroker);
        this.dataSource.data = this.brokers;
      }
    }
  }

  setSelectedBrokerGroup($event) {

    this.selectedBrokerGroup = $event;
    this.existingGroup.setValue(this.selectedBrokerGroup.brokerGroupName);
    this.brokers = _.cloneDeep(this.selectedBrokerGroup.brokers);
    this.dataSource.data = this.brokers;
  }

  async ngOnInit() {

  }

  getCombileAddress(row: Broker) {
    return `${row.address1}-${row.address2}-${row.address3}-${row.postCode}`;
  }

  createGroupForm() {
    this.groupForm = this.formBuilder.group({
      newGroup: ['', [Validators.required, noWhitespace]],
      existingGroup: ['']
    });
  }

  get newGroup() {
    return this.groupForm.get('newGroup') as AbstractControl;
  }

  get existingGroup() {
    return this.groupForm.get('existingGroup') as AbstractControl;
  }

  ngAfterViewInit() {

  }

  disableSave() {
    if (this.selectedBrokerGroup) {
      return this.dataSource.data.length === 0;
    }

    return this.newBrokerGroupId === undefined || this.dataSource.data.length === 0;
  }

  disableAdd(): boolean {
    return (this.existingGroup.value) ? true : false;
  }

  async onSave() {
    await this.save();
  }

  async save() {
    let brokerGroup: BrokerGroup;
    if (this.selectedBrokerGroup) {
      brokerGroup = this.selectedBrokerGroup;
      this.selectedBrokerGroup.brokers = this.brokers;
    }
    else {
      brokerGroup = await this._httpService.getDataAsync<BrokerGroup>(this._api.getBrokerGroup(this.newBrokerGroupId));

      if (brokerGroup) {
        this.dataSource.data.forEach(b => {
          brokerGroup.brokers.push(b);
        });
      }
    }

    await this._httpService.putDataAsync<any>(this._api.updateBrokerGroup(), brokerGroup);

    this.dialogService.openSuccessDialogConfirm({ title: TitleMessages.successTitle, message: 'The broker group has been saved successfully' }).subscribe(result => {
      if (result) {
        this.dialogRef.close(true);
      }
    });
  }

  onCloseDialog(isReload: boolean) {
    if (this.groupForm.dirty || this.searchbroker.form.dirty || this.searchbrokergroup.form.dirty) {
      let confirmMessage = 'Are you sure you want to continue. Your changes will be lost?';
      const primaryButtons = 'Yes';
      const secondaryButtons = 'No';
      this.dialogService.openConfirmationDialog({ title: TitleMessages.confirmationTitle, message: confirmMessage, primaryButtons: primaryButtons, secondaryButtons: secondaryButtons }).subscribe(
        (result) => {
          if (result) {
            this.dialogRef.close(isReload);
          }
        });
    }
    else {
      this.dialogRef.close(isReload);
    }
  }

  async createBrokerGroup() {
    if (this.groupForm.valid) {
      let brokerGroup: CreateBrokerGroup = {
        id: undefined,
        rowVersion: undefined,
        brokerGroupName: this.newGroup.value
      };

      try {
        this.newBrokerGroupId = await this._httpService.postDataAsync<string>(this._api.createBrokerGroup(), brokerGroup);

        // reset brokers
        this.brokers = [];
        this.dataSource.data = [];
        this.selectedBrokerGroup = undefined;
      }
      finally {
      }

      this.dialogService.openSuccessDialogConfirm({ title: TitleMessages.successTitle, message: 'The broker group has been saved successfully' }).subscribe(result => {
        if (result) {
          this.existingGroup.setValue(brokerGroup.brokerGroupName);
        }
      });
    }
  }

  removeBroker(broker: Broker) {
    this.dialogService.openConfirmationDialog({ title: TitleMessages.confirmationTitle, message: 'Do you want to remove this broker out of this group?' }).subscribe(async x => {
      if (x) {
        await this.doRemoveBroker(broker);
        this.dialogService.openSuccessDialogConfirm({ title: TitleMessages.successTitle, message: 'The broker has been removed from group' }).subscribe(async y => {
          _.remove(this.brokers, (x) => x.id === broker.id);
          this.dataSource.data = this.brokers;
        });
      }
    });
  }
  async doRemoveBroker(broker: Broker) {
    if (this.selectedBrokerGroup && this.selectedBrokerGroup.brokers.length > 0) {
      const deleteBroker = _.find(this.selectedBrokerGroup.brokers, (x) => x.id === broker.id);
      if (deleteBroker) {
        await this._httpService.deleteDataAsync<boolean>(this._api.removeBrokerFromABrokerGroup(deleteBroker.id, this.selectedBrokerGroup.id));

      } else {
        _.remove(this.brokers, (x) => x.id === broker.id);
        this.dataSource.data = this.brokers;
      }
    }
    else {
      _.remove(this.brokers, (x) => x.id === broker.id);
      this.dataSource.data = this.brokers;
    }

  }

  reset() {
    this.newGroup.setValue('');
    this.existingGroup.setValue('');
    this.brokers = [];
    this.dataSource.data = [];
    this.selectedBrokerGroup = undefined;
  }
}



