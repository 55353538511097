import { Component, OnInit } from '@angular/core';

import * as _ from 'lodash';

import * as Messages from 'src/app/shared/config/messages';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { EmailTemplateComponent } from '../email-template/email-template.component';
import { EmailTemplateService } from 'src/app/services/email-template.service';
import { EmailTemplateResponse } from 'src/app/models/email-template-response';
import { MatDialogConfig } from '@angular/material/dialog';

@Component({
  selector: 'app-email-templates',
  templateUrl: './email-templates.component.html',
  styleUrls: ['./email-templates.component.scss']
})

export class EmailTemplatesComponent implements OnInit {

  displayedColumns = [
    'name',
    'description',
    'subject',
    'emailTemplateTypeName',
    'actions'
  ];

  public displayNoDataText = false;
  public emailTemplates!: EmailTemplateResponse[];

  emailTemplateDialogConfig = new MatDialogConfig();

  constructor(
    private readonly dialogService: DialogService,
    private readonly emailTemplateService: EmailTemplateService) {
      this.emailTemplateDialogConfig.width = '1000px';
      this.emailTemplateDialogConfig.height = '720px';
  }

  ngOnInit() {
    this.loadGridData();
  }

  editTemplate(emailTemplateResponse: EmailTemplateResponse) {
    this.dialogService.openComponentDialog(EmailTemplateComponent, { emailTemplateResponse },
      this.emailTemplateDialogConfig).subscribe(async result => {
        if (result) {
          this.loadGridData();
        }
      });
  }

  loadGridData() {
    this.emailTemplateService.getEmailTemplates().subscribe(emailTemplateResponses => {

      if (emailTemplateResponses.length === 0) {
        this.displayNoDataText = true;
      } else {
        this.displayNoDataText = false;

        const sortedEmailTemplateResponses = _.sortBy(
          emailTemplateResponses,
          'name',
          'emailTemplateTypeName');

        this.emailTemplates = sortedEmailTemplateResponses;
      }

    }, httpErrorResponse => {
      this.dialogService.openErrorDialog({ title: 'Error', message: Messages.generalErrorMessage });
    });
  }
}
