import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Observable, lastValueFrom, map, startWith, tap } from 'rxjs';
import { FilterResponseCustomReport } from 'src/app/models/custom-pricing-report-response';
import { ExportFileType } from 'src/app/models/enums/report-type.enum';
import { ReportService } from 'src/app/services/report.service';
import { AppConstants, NO_RESULTS_FOUND, PagingConstant } from 'src/app/shared/constants/constants';
import { HttpBaseService } from 'src/app/shared/services/http-base.service';
import { Product } from 'src/app/models/product';
import { ApiEndPoints } from 'src/app/shared/config/api-end-points';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { SubProduct } from 'src/app/models/sub-product';
import * as _ from 'lodash';
import { Utility } from 'src/app/utils/utility';
import { LevelOfCover } from 'src/app/models/level-of-cover';
import { MatOption } from '@angular/material/core';
import { CustomPricingReportLine } from 'src/app/models/custom-pricing-report-response';
import { FilterResponse } from 'src/app/models/user-access-report-response';

@Component({
  selector: 'custom-pricing-report-form-detail',
  templateUrl: './custom-pricing-report-form-detail.component.html',
  styleUrls: ['./custom-pricing-report-form-detail.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ]
})
export class CustomPricingFormDetailComponent implements OnInit, AfterViewInit {

  @Input() productId: string;

  allOptionTxt: string = 'All';
  noGroupOptionTxt: string = 'No Broker Group';
  actualSelectedSubProduct! : string;
  actualSelectedLoc! : string;
  filteredBrokerBranchOptions: Observable<FilterResponseCustomReport[]>;
  filteredBrokerGroupOptions: Observable<FilterResponse[]>;
  brokerBranchOptions: FilterResponseCustomReport[] = [];
  selectedBranchOptions: FilterResponseCustomReport[] = [];
  brokerGroupOptions: FilterResponse[] = [];
  selectedBrokerGroup: string;
  selectedBrokerGroupName: string;
  brokerBranchValid: boolean = true;
  @ViewChild('autoCompleteInputBb', { read: MatAutocompleteTrigger }) triggerbb: MatAutocompleteTrigger;
  @ViewChild('autoCompleteInputBbg', { read: MatAutocompleteTrigger }) triggerbbg: MatAutocompleteTrigger;
  @ViewChild('allSelectedLoC') private allSelected: MatOption;
  @ViewChild('allSelectedSub') private allSelectedSub: MatOption;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('matSelect') matSelect;
  @ViewChild('matSelectLoc') matSelectLoc;
  @ViewChild('autobb') autobb: MatAutocomplete;
  @ViewChild('autobbG') autobbG: MatAutocomplete;

  currentLocValues:string[] = [];
  currentSubValues:string[] = [];

  expandedElement: any;
  total: number = 0;
  displayedColumnsub: string[] = [
    'brokerGroupName',
    'brokerBranchName',
    'subProductName',
    'levelOfCoverName',
    'standardBrokerRate',
    'standardUnderwriterRate',
    'standardMaximumRetailRate',
    'standardEffectiveDateFrom',
    'customBrokerRate',
    'customUnderwriterRate',
    'customMaximumRetailRate',
    'customEffectiveDateFrom'
  ];

  dataSource: CustomPricingReportLine[] = [];

  public appConstants = AppConstants;
  pagingConstant = PagingConstant;
  pageSizeOptions: number[] = PagingConstant.pageSizeOptions;


  filterForm = this.formBuilder.group({
    brokerBranch: [null],
    brokerGroup: [null, [Validators.required]],
    subProduct: [null, [Validators.required]],
    levelOfCover: [null, [Validators.required]],
    custompricing: ['']
  });

  subProducts: SubProduct[] = [];
  levelOfCovers: LevelOfCover[] = [];
  notfound = '';
  get notFoundMessage() {
    return this.notfound;
  }

  constructor(private readonly formBuilder: FormBuilder,
    private readonly _reportService: ReportService,
    private readonly _api: ApiEndPoints,
    private readonly _httpService: HttpBaseService) {

  }

  get brokerGroup() {
    return this.filterForm.get('brokerGroup') as AbstractControl;
  }

  get brokerBranch() {
    return this.filterForm.get('brokerBranch') as AbstractControl;
  }

  get levelOfCover() {
    return this.filterForm.get('levelOfCover') as AbstractControl;
  }

  get subProduct() {
    return this.filterForm.get('subProduct') as AbstractControl;
  }

  get customPricing() {
    return this.filterForm.get('custompricing') as AbstractControl;
  }

  get searchIsEmpty() {
    return Utility.isNullOrWhiteSpace(this.brokerBranch.value);
  }

  get searchGroupIsEmpty() {
    return Utility.isNullOrWhiteSpace(this.brokerGroup.value);
  }

  get isValidSubmit() {
    return !this.filterForm.invalid && this.selectedBranchOptions.length > 0;
  }

  get reachLimit() {
    return (this.selectedBranchOptions.length ?? 0) >= 20;
  }

  async ngOnInit() {
    this._reportService.customPricing$.subscribe(async (x) => {
      if (x == this.productId) {
        if (this._reportService.brokerGroupOptions[this.productId]) {
          this.brokerGroupOptions = this._reportService.brokerGroupOptions[this.productId].map(a => { return { ...a } });
          this.brokerGroup.setValue("", { ermitEvent: false });
        }
        else {
          this.brokerGroupOptions = await lastValueFrom(this._reportService.getUserAccessReportFilterBrokerGroups());
          this.brokerGroupOptions.unshift({ id: '', name: this.noGroupOptionTxt });
          this.brokerGroupOptions.unshift({ id: this.allOptionTxt, name: this.allOptionTxt });
          this._reportService.brokerGroupOptions[this.productId] = this.brokerGroupOptions.map(a => { return { ...a } });
          this.brokerGroup.setValue("", { ermitEvent: false });
        }

        if (this._reportService.subProductOptions[this.productId]) {
          let subProducts = this._reportService.subProductOptions[this.productId]
          this.subProducts = _.orderBy(subProducts, 'name');
        }
        else {
          this.subProducts = await lastValueFrom(this._reportService.getSubProductsCustomPricingReport(this.productId));
          this.subProducts = _.orderBy(this.subProducts, 'name');
          this._reportService.subProductOptions[this.productId] = this.subProducts;
        }
        this.brokerBranch.setValue('');
      }
    });


    this.filteredBrokerGroupOptions = this.brokerGroup.valueChanges.pipe(
      startWith(''),
      map(value => this._filterBrokerGroup(value || '', this.brokerGroupOptions)),
    );

    this.filteredBrokerBranchOptions = this.brokerBranch.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '', this.brokerBranchOptions)),
    );

    this.subProduct.valueChanges.subscribe((ids) => {
      let locs: LevelOfCover[] = [];
      if (ids.findIndex(x => x == this.allOptionTxt) != -1) {
        this.levelOfCovers = [];
        this.subProducts.forEach(sub => {
          this.levelOfCovers.push(...sub.levelOfCovers);
        });
      }
      else {
        ids.forEach((id: string) => {
          const selectedSubProduct = _.find(this.subProducts, (x) => x.id === id);
          locs.push(...(_.orderBy(selectedSubProduct.levelOfCovers, 'name')));
        });
        this.levelOfCovers = locs;
      }
      this.resetTable();
      this.levelOfCover.setValue([]);
      this.currentLocValues = this.levelOfCover?.value;
    });

  }

  toggleSelectionLoC(values: string[]) {
    let selected = (values.filter(x => !this.currentLocValues.includes(x))).concat(this.currentLocValues.filter(x => !values.includes(x)));

    if (selected.includes(this.allOptionTxt)) {
      if (this.allSelected.selected) {
        let ids = this.levelOfCovers.map(({ id }) => id)
        this.levelOfCover.setValue([this.allOptionTxt, ...ids]);
      } else {
        this.levelOfCover.setValue([]);
      }
    }
    else {
      let ids: string[] = this.levelOfCover.value;
      const index = ids.indexOf(this.allOptionTxt);
      if (index > -1) {
        ids.splice(index, 1);
        this.levelOfCover.setValue([...ids]);
      }
      if (ids.length == this.levelOfCovers.length) {
        this.levelOfCover.setValue([this.allOptionTxt, ...ids]);
        this.actualSelectedLoc = this.allOptionTxt;
      }
    }
    this.actualSelectedLoc = this.matSelectLoc.triggerValue.includes(this.allOptionTxt) ? this.allOptionTxt : this.matSelectLoc.triggerValue;
    this.currentLocValues = this.levelOfCover.value;
  }

  toggleSelectionSub(values) {
    let selected = (values.filter(x => !this.currentSubValues.includes(x))).concat(this.currentSubValues.filter(x => !values.includes(x)));
    if (selected.includes(this.allOptionTxt)) {
      if (this.allSelectedSub.selected) {
        let ids = this.subProducts.map(({ id }) => id)
        this.subProduct.setValue([this.allOptionTxt, ...ids]);
      } else {
        this.subProduct.setValue([]);
      }
    }
    else {
      let ids: string[] = this.subProduct.value;
      const index = ids.indexOf(this.allOptionTxt);
      if (index > -1) {
        ids.splice(index, 1);
        this.subProduct.setValue([...ids]);
      }
      if (ids.length == this.subProducts.length) {
        this.subProduct.setValue([this.allOptionTxt, ...ids]);
        this.actualSelectedSubProduct = this.allOptionTxt;
      }
    }

    this.actualSelectedSubProduct = this.matSelect.triggerValue.includes(this.allOptionTxt) ? this.allOptionTxt : this.matSelect.triggerValue;
    this.currentSubValues = this.subProduct.value;
  }

  async getSelectedBrokerGroup(event: any, value: FilterResponse) {
    if (event.isUserInput) {
      this.brokerBranchOptions = [];
      this.selectedBranchOptions = [];
      this.brokerBranch.setValue(null);
      this.selectedBrokerGroup = value.id;
      this.selectedBrokerGroupName = value.name;
      if (value.name == this.allOptionTxt) {
        this.brokerBranchOptions = await lastValueFrom(this._reportService.getBrokerBranchFilterByProductId(this.productId, this.allOptionTxt));
      }
      else {
        this.brokerBranchOptions = await lastValueFrom(this._reportService.getBrokerBranchFilterByProductId(this.productId, value.id));
      }
      if (this.brokerBranchOptions.length > 0) {
        this.brokerBranchOptions.unshift({ id: this.allOptionTxt, name: this.allOptionTxt, isDisable: false })
      }
      else {
        this.brokerBranchOptions.unshift({ id: "", name: "No associated branches for this broker group", isDisable: true })
      }
      this.brokerBranch.setValue("", { ermitEvent: false });
      this.brokerBranch.setErrors({ 'invalid': true });
      this.resetTable();
    }

  }


  selectBrokerBranch(event: any, selectedOption: FilterResponseCustomReport) {
    if (event.isUserInput) {
      this.resetTable();
      if (this.brokerGroup.invalid) {
        this.brokerGroup.setValue(this.allOptionTxt);
        this.selectedBrokerGroup = this.allOptionTxt;
        this.selectedBrokerGroupName = this.allOptionTxt;
      }
      if(this.selectedBranchOptions.length > 19 && selectedOption.id != this.allOptionTxt) {
        return;
      }

      let index = this.selectedBranchOptions.indexOf(selectedOption);
      if (index == -1) {
        this.selectedBranchOptions.push(selectedOption);
        this.brokerBranchOptions.forEach(function (obj) {
          if (obj.id === selectedOption.id) {
            obj.isDisable = true;
          }
        })
      }

      if (selectedOption.id == this.allOptionTxt) {
        this.selectedBranchOptions = [];
        this.selectedBranchOptions.push(selectedOption);
        this.brokerBranchOptions.forEach(function (obj) {
          obj.isDisable = false;
        })
      }
      else {
        let index = this.selectedBranchOptions.findIndex(x => x.id == this.allOptionTxt);
        if (index == 0) {
          this.selectedBranchOptions.splice(index, 1);
        }
      }
      this.selectedBranchOptions.length <= 0 ? this.brokerBranch.setErrors({ 'invalid': true }) : this.brokerBranch.setErrors(null)
    }
  }

  private _filterBrokerGroup(value: string, source: FilterResponse[]): FilterResponse[] {
    const filterValue = value.toLowerCase();
    if (value == this.allOptionTxt) {
      return source;
    }
    let data = source?.filter(option => option.name.toLowerCase().includes(filterValue)
      || option.name == this.allOptionTxt
      || option.name == this.noGroupOptionTxt);
    return data;
  }

  private _filter(value: string, source: FilterResponseCustomReport[]): FilterResponseCustomReport[] {
    const filterValue = value.toLowerCase();
    if (value == this.allOptionTxt) {
      return source;
    }
    let data = source?.filter(option => option.name.toLowerCase().includes(filterValue)
      || option.name == this.allOptionTxt);
    return data;
  }

  remove(source) {
    const index = this.selectedBranchOptions.indexOf(source);
    if (index > -1) {
      this.selectedBranchOptions.splice(index, 1);
      this.brokerBranchOptions.forEach(function (obj) {
        if (obj.id === source.id) {
          obj.isDisable = false;
        }
      })
    }
    if (this.selectedBranchOptions.length <= 0) {
      this.brokerBranch.setValue('');
      this.brokerBranch.setErrors({ 'invalid': true })
    }
    else {
      this.brokerBranch.setErrors(null);
    }
    this.resetAutoInput(this.autobb);
    this.resetTable();
  }

  reset() {
    this.resetAutoInput(this.autobb);
    this.brokerBranch.setValue('');
    this.selectedBranchOptions.length <= 0 ? this.brokerBranch.setErrors({ 'invalid': true }) : this.brokerBranch.setErrors(null)
  }

  resetGroup() {
    this.brokerGroup.setValue('');
    this.selectedBranchOptions = [];
    this.brokerBranchOptions = [];
    this.brokerBranch.setValue('');
    this.brokerBranch.setErrors({ 'invalid': true });
    this.resetAutoInput(this.autobbG);
    this.resetAutoInput(this.autobb);
    this.resetTable();
  }

  onBlurBrokerBranch(){
    this.selectedBranchOptions.length <= 0 ? this.brokerBranch.setErrors({ 'invalid': true }) : this.brokerBranch.setErrors(null)
  }

  getTableStyle() {
    let display = this.total == 0 ? 'none' : '';
    return {
      'display': display
    };
  }

  onSubmit() {
    this.resetTable();
    this.loadData();
  }

  resetTable() {
    this.dataSource = [];
    this.paginator.pageIndex = 0;
    this.paginator.pageSize = PagingConstant.pageSizeOptions[1];
    this.total = 0;
  }

  loadData() {
    let subProductIds = this.subProduct.value.findIndex(x => x == this.allOptionTxt) != -1 ? this.allOptionTxt : this.subProduct.value;
    let levelOfCoverIds = this.levelOfCover.value.findIndex(x => x == this.allOptionTxt) != -1 ? this.allOptionTxt : this.levelOfCover.value;
    this._reportService.getCustomPricingReport(this.productId,
      this.selectedBrokerGroup,
      this.selectedBranchOptions.map(a => a.id),
      subProductIds, levelOfCoverIds,
      this.customPricing.value, this.sort.active
      , this.sort.direction
      , this.paginator.pageIndex
      , this.paginator.pageSize).subscribe(x => {
        this.dataSource = x.customPricingLines;
        this.total = x.total;
        this.checkNotFoundResults(this.total);
      });
  }

  checkNotFoundResults(totalRecords) {
    this.notfound = totalRecords === 0 ? NO_RESULTS_FOUND : '';
  }

  ngAfterViewInit() {
    this.triggerbb.panelClosingActions.subscribe((e) => {
      if (!(e && e.source)) {
        let errors = this.brokerBranch.errors;
        if (!this.brokerBranchOptions.find(x => x.name == this.brokerBranch.value)) {
          this.brokerBranch.setValue('');
          this.resetAutoInput(this.autobb);
        }
        this.brokerBranch.setErrors(errors);
      }
    });

    this.triggerbbg.panelClosingActions.subscribe((e) => {
      if (!(e && e.source)) {
        if (this.brokerGroup.value !== this.selectedBrokerGroupName) {
          this.brokerGroup.setValue('');
          this.resetAutoInput(this.autobbG);
        }
      }
    });

    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    this.sort.sortChange
      .pipe(
        tap(() => this.loadData())
      )
      .subscribe();

    this.paginator.page
      .pipe(
        tap(() => this.loadData())
      )
      .subscribe();
  }

  resetAutoInput(auto: MatAutocomplete) {
    auto.options.forEach((item) => {
      item.deselect();
    });
  }

  exportPdf() {
    this.export(ExportFileType.Pdf);
  }

  exportCsv() {
    this.export(ExportFileType.Csv);
  }

  exportExcel() {
    this.export(ExportFileType.Excel);
  }

  export(type: ExportFileType) {
    let subProductIds = this.subProduct.value.findIndex(x => x == this.allOptionTxt) != -1 ? this.allOptionTxt : this.subProduct.value;
    let levelOfCoverIds = this.levelOfCover.value.findIndex(x => x == this.allOptionTxt) != -1 ? this.allOptionTxt : this.levelOfCover.value;
    let url = this._reportService.exportCustonPricingReport(this.productId,
      this.selectedBrokerGroup,
      this.selectedBranchOptions.map(a => a.id),
      subProductIds, levelOfCoverIds,
      this.customPricing.value, this.sort.active
      , this.sort.direction, type);
    this._httpService.downloadFile(url).subscribe(
      (data: BlobPart) => {
        const blob = new Blob([data], { type: this.getContentType(type) });
        let fileURL = window.URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.style.display = "none";
        link.href = fileURL;
        link.download = "CustomPricingReport " + new Date().toLocaleDateString("en-UK");
        link.target = '_blank';
        link.click();

        setTimeout(() => {
          link.remove();
          window.URL.revokeObjectURL(fileURL);
        }, 100);

      }
    );
  }

  getContentType(fileType: ExportFileType) {
    switch (fileType) {
      case ExportFileType.Pdf:
        return "application/pdf";

      case ExportFileType.Csv:
        return "text/csv";

      case ExportFileType.Excel:
        return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

      default:
        return '';
    }
  }

}
