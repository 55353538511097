import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import * as _ from "lodash";
import { ApiEndPoints } from 'src/app/shared/config/api-end-points';
import { HttpBaseService } from 'src/app/shared/services/http-base.service';
import { PagingConstant, NO_RESULTS_FOUND, EMPTY_GUID } from 'src/app/shared/constants/constants';
import { LevelOfCover } from 'src/app/models/level-of-cover';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { tap } from 'rxjs/operators';
import { Product } from 'src/app/models/product';
import { LevelOfCoverValidationDataSource } from 'src/app/models/level-of-cover-datasource';
import { LevelOfCoverService } from 'src/app/services/level-of-cover.service';
import { LevelOfCoverValidationGroup } from 'src/app/models/level-of-cover-validation-item';
import { FormBuilder, AbstractControl } from '@angular/forms';
import { LevelOfCoverValidationRuleComponent } from './level-of-cover-validation-rule/level-of-cover-validation-rule.component';
import { SubProduct } from 'src/app/models/sub-product';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialogConfig } from '@angular/material/dialog';
import { Utility } from 'src/app/utils/utility';
import { SharedSearchComponent } from 'src/app/shared/components/shared-search/shared-search.component';

@Component({
  selector: 'app-level-of-cover-validation',
  templateUrl: './level-of-cover-validation.component.html',
  styleUrls: ['./level-of-cover-validation.component.scss']
})
export class LevelOfCoverValidationComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = ['productName', 'subProductName', 'levelOfCoverName', 'validationType', 'isActive', 'actions'];

  products: Product[] = [];
  subProducts: SubProduct[] = [];
  levelOfCovers: LevelOfCover[] = [];

  dataSource: LevelOfCoverValidationDataSource;
  totalRecords: number;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  pagingConstant = PagingConstant;
  pageSizeOptions: number[] = PagingConstant.pageSizeOptionsLarger;
  @ViewChild(SharedSearchComponent) sharedSearch: SharedSearchComponent;

  searchString: string;


  searchForm = this.formBuilder.group({
    isIncludedInactive: [false]
  });

  filterForm = this.formBuilder.group({
    productId: [''],
    subProductId: [''],
    levelOfCoverId: ['']
  });

  configDialog = new MatDialogConfig();

  notfound: string = '';
  get notFoundMessage() {
    return this.notfound;
  }

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly _levelOfCoverService: LevelOfCoverService,
    private readonly _api: ApiEndPoints,
    private readonly _httpService: HttpBaseService,
    private readonly _dialogService: DialogService) {
    this.configDialog.disableClose = true;
    this.configDialog.width = '1280px';
    this.configDialog.height = '800px';
  }


  get isIncludedInactive() {
    return this.searchForm.get('isIncludedInactive') as AbstractControl;
  }

  get productId() {
    return this.filterForm.get('productId') as AbstractControl;
  }

  get subProductId() {
    return this.filterForm.get('subProductId') as AbstractControl;
  }

  get levelOfCoverId() {
    return this.filterForm.get('levelOfCoverId') as AbstractControl;
  }

  async ngOnInit() {
    this.dataSource = new LevelOfCoverValidationDataSource(this._levelOfCoverService);
    await this.loadProductData();
    await this.loadAllData();
    this.dataSource.levelOfCoverValidations$.subscribe(l => {
      this.checkNotFoundResults();
    })
  }

  async loadProductData() {
    this.products = await this._httpService.getDataAsync<Product[]>(this._api.getAllProducts());
    this.products = _.orderBy(this.products, 'name')
  }

  async loadData(productId: string, subProductId: string, loCId: string, sortField: string = 'ProductName', sortDirection: string = 'asc') {
    let index = this.paginator? this.paginator.pageIndex: 0;
    let pageSize = this.paginator? this.paginator.pageSize: PagingConstant.pageSizeLarger;
    this.dataSource.getLevelOfCoverValidationsByFilters(productId, subProductId, loCId, this.searchString, this.isIncludedInactive.value, sortField, sortDirection, index, pageSize);
    if (this.sharedSearch) this.sharedSearch.setSearchString(this.searchString);
  }

  async loadAllData() {
    this.paginator.pageIndex = 0;
    this.paginator.pageSize = PagingConstant.pageSizeOptionsLarger[0];
    this.loadData(EMPTY_GUID, EMPTY_GUID, EMPTY_GUID);
  }

  async loadByProduct(productId: string) {
    this.paginator.pageIndex = 0;
    this.paginator.pageSize = PagingConstant.pageSizeOptionsLarger[0];
    this.loadData(productId, EMPTY_GUID, EMPTY_GUID);
  }

  async loadByProductAndSubProduct(productId: string, subProductId: string) {
    this.paginator.pageIndex = 0;
    this.paginator.pageSize = PagingConstant.pageSizeOptionsLarger[0];
    this.loadData(productId, subProductId, EMPTY_GUID);
  }

  async loadByProductAndSubProductAndLevelOfCover(productId: string, subProductId: string, levelOfCoverId: string) {
    this.paginator.pageIndex = 0;
    this.paginator.pageSize = PagingConstant.pageSizeOptionsLarger[0];
    this.loadData(productId, subProductId, levelOfCoverId);
  }

  checkNotFoundResults() {
    if (this.dataSource.totalRecords === 0) {
      this.notfound = NO_RESULTS_FOUND;
    } else {
      this.notfound = '';
    }
  }

  ngAfterViewInit() {
    // reset the paginator after sorting
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    this.paginator.page
      .pipe(
        tap(() => this.getLevelOfCoverValidationsPage())
      )
      .subscribe();

    this.sort.sortChange
      .pipe(
        tap(() => this.getLevelOfCoverValidationsPage())
      )
      .subscribe();

    this.isIncludedInactive.valueChanges.subscribe(value => {
      this.paginator.pageIndex = 0;
      this.paginator.pageSize = PagingConstant.pageSizeOptionsLarger[0];
      this.processLoadData(this.productId.value, this.subProductId.value, this.levelOfCoverId.value);
    })

    this.productId.valueChanges.subscribe(async (id: string) => {
      let product = await this._httpService.getDataAsync<Product>(this._api.getProduct(id));
      this.subProducts = _.orderBy(product.subProducts, 'name');
      this.levelOfCovers = [];
      this.subProductId.setValue('', { emitEvent: false });
      this.levelOfCoverId.setValue('', { emitEvent: false });
      if (id) {
        this.loadByProduct(id);
      } else {
        this.loadAllData();
      }
    });

    this.subProductId.valueChanges.subscribe((id: string) => {
      const selectedSubProduct = _.find(this.subProducts, (x) => { return x.id === id });
      this.levelOfCovers = _.orderBy(selectedSubProduct.levelOfCovers, 'name');
      this.levelOfCoverId.setValue('', { emitEvent: false });
      if (id) {
        this.loadByProductAndSubProduct(this.productId.value, id);
      }
    });

    this.levelOfCoverId.valueChanges.subscribe((id: string) => {
      if (id) {
        this.loadByProductAndSubProductAndLevelOfCover(this.productId.value, this.subProductId.value, id);
      }
    });
  }

  async getLevelOfCoverValidationsPage() {
    let fieldSort = this.sort.active.charAt(0).toUpperCase() + this.sort.active.slice(1);
    const direction = this.sort.direction;

    if (this.productId.value && this.subProductId.value && this.levelOfCoverId.value) {
      this.loadData(this.productId.value, this.subProductId.value, this.levelOfCoverId.value, fieldSort, direction);
    }
    else if (this.productId.value && this.subProductId.value) {
      this.loadData(this.productId.value, this.subProductId.value, EMPTY_GUID,  fieldSort, direction);
    }
    else if (this.productId.value) {
      this.loadData(this.productId.value, EMPTY_GUID, EMPTY_GUID, fieldSort, direction);
    }
    else {
      this.loadData(EMPTY_GUID, EMPTY_GUID, EMPTY_GUID, fieldSort, direction);
    }

  }

  editLevelOfCoverValidation(levelOfCoverValidation: LevelOfCoverValidationGroup) {
    this._dialogService.openComponentDialog(LevelOfCoverValidationRuleComponent, { "editMode": true, "levelOfCoverValidation": levelOfCoverValidation }, this.configDialog).subscribe(async result => {
      if (result) {
        await this.processLoadData(this.productId.value, this.subProductId.value, this.levelOfCoverId.value);
      }
    });
  }

  async processLoadData(productId: string, subProductId: string, levelOfCoverId: string) {
    if (productId && subProductId && levelOfCoverId) {
      await this.loadByProductAndSubProductAndLevelOfCover(productId, subProductId, levelOfCoverId);
    }
    else if (productId && subProductId) {
      await this.loadByProductAndSubProduct(productId, subProductId);
    }
    else if (productId) {
      await this.loadByProduct(productId);
    }
    else {
      await this.loadAllData();
    }
  }

  createLevelOfCoverValidation() {
    this._dialogService.openComponentDialog(LevelOfCoverValidationRuleComponent, { "editMode": false }, this.configDialog).subscribe(async result => {
      if (result) {
        await this.processLoadData(this.productId.value, this.subProductId.value, this.levelOfCoverId.value);
      }
    });
  }

  onValueChange(value: string) {
    this.searchString = value;
  }

  async search() {
    this.paginator.pageIndex = 0;
    await this.processLoadData(this.productId.value, this.subProductId.value, this.levelOfCoverId.value);
  }

}
