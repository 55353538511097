import { Component, Input, Optional, Host, OnInit } from '@angular/core';
import { SatPopover } from '@ncstate/sat-popover';
import { filter } from 'rxjs/operators';
import { FormControl, Validators } from '@angular/forms';
import { Utility } from 'src/app/utils/utility';
import { noSpecialCharacterValidatorButNotDot } from 'src/app/validators/no-special-character.validator';



@Component({
  selector: 'inline-edit',
  templateUrl: './inline-edit.component.html',
  styleUrls: ['./inline-edit.component.css']
})
export class InlineEditComponent implements OnInit {

  /** Overrides the comment and provides a reset value when changes are cancelled. */
  @Input()
  title: string = 'Edit';

  @Input()
  maximumRetailRate: number = 0;

  @Input()
  get value(): any { return this._value; }
  set value(x: any) {
    this.newValue = this._value = x;
  }
  private _value: any;

  /** Form model for the input. */
  newValue: any;

  inputControl = new FormControl(0, []);

  get maxErrorMessage() {
    return `The value you have entered exceeds the maximum retail rate ${Utility.moneytary(this.maximumRetailRate)}. Please enter a new premium and click Save.`;
  }

  constructor(@Optional() @Host() public popover: SatPopover) {}

  ngOnInit() {
    if (this.maximumRetailRate === 0) {
      this.maximumRetailRate = 1000; // one billion that means unlimitted;
    }
    this.inputControl.setValidators([
      Validators.required,
      Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$'),
      Validators.max(this.maximumRetailRate),
      noSpecialCharacterValidatorButNotDot
    ]
    );
    // subscribe to cancellations and reset form value
    if (this.popover) {
      this.popover.closed.pipe(filter(val => val == null))
        .subscribe(() => this.newValue = this.value);
    }
  }

  onSubmit() {
    if (this.inputControl.valid) {
      if (this.popover) {
        this.popover.close(this.newValue);
      }
    }
  }

  onCancel() {
    if (this.popover) {
      this.popover.close();
    }
  }
}
