<mat-card>
  <mat-card-header>
    <mat-card-title><strong>Custom Pricing Reports</strong></mat-card-title>
    <mat-card-subtitle>View custom pricing reports here.</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>

    <table mat-table [dataSource]="dataSource" multiTemplateDataRows>

      <ng-container matColumnDef="expandbutton">
        <th mat-header-cell *matHeaderCellDef>
        </th>
        <td mat-cell *matCellDef="let product">
          <button mat-button class="btn-table-row">
            <mat-icon svgIcon="unfold-more-horizontal"></mat-icon>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let product">{{product.productName}}</td>
      </ng-container>

      <ng-container matColumnDef="isActive">
        <th mat-header-cell *matHeaderCellDef>Active</th>
        <td mat-cell class="isactive-cell" *matCellDef="let product">
          <mat-icon *ngIf="product.isActive" class="colour-success" svgIcon="check"></mat-icon>
          <mat-icon *ngIf="!product.isActive" class="colour-danger" svgIcon="close"></mat-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="total" stickyEnd>
        <th mat-header-cell *matHeaderCellDef>Total Assigned Brokers</th>
        <td mat-cell class="isactive-cell" *matCellDef="let product">
          <span>{{product.totalAssignedBrokerBranches}}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let product" [attr.colspan]="displayedColumns.length">
          <div class="example-element-detail" [@detailExpand]="isExpanded(product)">
            <div>
              <custom-pricing-report-form-detail
                [productId]="product ? product.productId : null"></custom-pricing-report-form-detail>
            </div>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="example-element-row"
        (click)="toggleRow(row) "></tr>

      <tr mat-row *matRowDef="let row; columns: ['expandedDetail'];" class="example-detail-row"></tr>

    </table>
  </mat-card-content>
</mat-card>
