import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import * as _ from 'lodash';
import { ApiEndPoints } from 'src/app/shared/config/api-end-points';
import { HttpBaseService } from 'src/app/shared/services/http-base.service';
import { PagingConstant, NO_RESULTS_FOUND, TitleMessages } from 'src/app/shared/constants/constants';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { BrokerGroupPopupComponent } from './broker-group-popup/broker-group-popup.component';
import { FormBuilder, AbstractControl } from '@angular/forms';
import { BrokerGroupDataSource } from 'src/app/models/broker-group-datasource';
import { BrokerGroupItem } from 'src/app/models/broker-group';
import { Utility } from 'src/app/utils/utility';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialogConfig } from '@angular/material/dialog';
import { SharedSearchComponent } from 'src/app/shared/components/shared-search/shared-search.component';


@Component({
  selector: 'app-broker-group',
  templateUrl: './broker-group.component.html',
  styleUrls: ['./broker-group.component.scss']
})

export class BrokerGroupComponent implements OnInit, AfterViewInit {

  displayedColumns: string[] = ['brokerGroupName', 'originalBrokerId', 'brokerName', 'address1', 'postCode', 'telephoneNumber', 'emailAddress', 'isBrokerActive', 'remove'];

  dataSource: BrokerGroupDataSource;
  totalRecords: number;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(SharedSearchComponent) sharedSearch: SharedSearchComponent;

  pagingConstant = PagingConstant;
  pageSizeOptions: number[] = PagingConstant.pageSizeOptionsLarger;

  configBrokerGroupDialog = new MatDialogConfig();

  searchString: string;

  searchForm = this.formBuilder.group({
    isIncludedInactive: [false]
  });

  notfound: string = '';
  get notFoundMessage() {
    return this.notfound;
  }

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly _http: HttpClient,
    private readonly _api: ApiEndPoints,
    private readonly _httpService: HttpBaseService,
    private readonly _dialogService: DialogService) {
    this.configBrokerGroupDialog.disableClose = true;
    this.configBrokerGroupDialog.width = '1400px';
  }

  get isIncludedInactive() {
    return this.searchForm.get('isIncludedInactive') as AbstractControl;
  }

  async ngOnInit() {
    this.dataSource = new BrokerGroupDataSource(this._http, this._api);
    await this.loadData();
    this.dataSource.groupCompnaiesSubject$.subscribe(g => {
      this.checkNotFoundResults();
    });
  }

  checkNotFoundResults() {
    if (this.dataSource.totalRecords === 0) {
      this.notfound = NO_RESULTS_FOUND;
    } else {
      this.notfound = '';
    }
  }

  async loadData(sortField: string = 'BrokerGroupName', sortDirection: string = 'asc') {
    let index = this.paginator? this.paginator.pageIndex: 0;
    let pageSize = this.paginator? this.paginator.pageSize: PagingConstant.pageSizeLarger;
    this.dataSource.getGroupCompanies(this.searchString, this.isIncludedInactive.value, sortField, sortDirection , index, pageSize);
    if (this.sharedSearch) this.sharedSearch.setSearchString(this.searchString);

  }

  ngAfterViewInit() {
    // reset the paginator after sorting
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    this.paginator.page
      .pipe(
        tap(() => this.getBrokerGroupPage())
      )
      .subscribe();

    this.sort.sortChange
      .pipe(
        tap(() => this.getBrokerGroupPage())
      )
      .subscribe();

    this.isIncludedInactive.valueChanges.subscribe(value => {
      this.paginator.pageIndex = 0;
      this.paginator.pageSize = PagingConstant.pageSizeOptionsLarger[0];
      this.loadData();
    });
  }

  async getBrokerGroupPage() {
    const fieldSort = this.sort.active.charAt(0).toUpperCase() + this.sort.active.slice(1);
    const direction = this.sort.direction;
    this.loadData(fieldSort, direction);
  }

  createBrokerGroup() {
    this._dialogService.openComponentDialog(BrokerGroupPopupComponent, {
    }, this.configBrokerGroupDialog).subscribe(async result => {
      if (result) {
        await this.loadData();
      }
    });
  }

  removeBroker(BrokerGroup: BrokerGroupItem) {
    this._dialogService.openConfirmationDialog({ title: TitleMessages.confirmationTitle, message: 'Do you want to remove this broker out of this group?' }).subscribe(async x => {
      if (x) {
        await this._httpService.deleteDataAsync<boolean>(this._api.removeBrokerFromABrokerGroup(BrokerGroup.brokerId, BrokerGroup.id));
        this._dialogService.openSuccessDialogConfirm({ title: TitleMessages.successTitle, message: 'The broker has been removed from group' }).subscribe(async y => {
          if (y) {
            await this.loadData();
          }
        });
      }
    });
  }

  onValueChange(value: string) {
    this.searchString = value;
  }

  async search() {
    this.paginator.pageIndex = 0;
    this.paginator.pageSize = PagingConstant.pageSizeOptionsLarger[0];
    this.loadData();
  }

  formatPhoneNumber(phonenumber) {
    phonenumber = phonenumber.split(' ').join("");
    const formatted = Utility.formatPhoneNumber(phonenumber);
    return formatted;
  }
}

