import { BrokerBranch } from './broker';

export class User {
    constructor(
        public userName: string,
        public emailAddress: string,
        public phoneNumber: string,
        public roleId: string,
        public brokerBranchId: string,
        public isActive: boolean) { }
}

export class UpdatedUser extends User {
    id: string;
    rowVersion: string;
}

export interface UserDetails {
    id: string,
    rowVersion: string
    userName: string,
    emailAddress: string,
    phoneNumber: string,
    roleId: string,
    brokerBranchId: string,
    role: Role,
    brokerBranch: BrokerBranch,
    isActive: boolean,
    canUpdate: boolean
}

export interface Role {
    id: string,
    name: string,
    description: string
}

export interface UserDetail {
    id: string;
    rowVersion: any;
    userName: string;
    emailAddress: string;
    phoneNumber: string;
    role: Role;
    brokerBranch: BrokerBranch;
    isActive: boolean;
    administratorEmail: string;
}

export interface UserInfo {
    [key: string]: any;
    userName: string;
    emailAddress: string;
    phoneNumber: string;
    role: string;
    brokerBranchName: string | undefined;
}