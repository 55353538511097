import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { CollectionViewer } from '@angular/cdk/collections';
import { catchError, finalize, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ApiEndPoints } from '../shared/config/api-end-points';
import { PagingConstant } from '../shared/constants/constants';
import { BrokerGroupItem } from './broker-group';
import { PaginationResponse } from './pagination-response';


export class BrokerGroupDataSource implements DataSource<BrokerGroupItem> {
  private groupCompnaiesSubject = new BehaviorSubject<BrokerGroupItem[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);

  public loading$ = this.loadingSubject.asObservable();
  public groupCompnaiesSubject$ = this.groupCompnaiesSubject.asObservable();
  public totalRecords: number;

  constructor(private http: HttpClient, private api: ApiEndPoints) {}

  connect(collectionViewer: CollectionViewer): Observable<BrokerGroupItem[]> {
    return this.groupCompnaiesSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.groupCompnaiesSubject.complete();
    this.loadingSubject.complete();
  }

  getGroupCompanies(
    query = "",
    isIncludedInactive = true,
    sortField = "",
    sortDirection = "asc",
    pageIndex = PagingConstant.pageIndex,
    pageSize = PagingConstant.pageSize
  ) {
    this.loadingSubject.next(true);

    this.getGroupCompaniesPaging(
      query,
      isIncludedInactive,
      sortField,
      sortDirection,
      pageIndex,
      pageSize
    )
      .pipe(
        catchError(() => of([])),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe((st) => {
        var rs = st as PaginationResponse<BrokerGroupItem>;
        this.totalRecords = rs.total;
        this.loadingSubject.next(false);
        this.groupCompnaiesSubject.next(rs.items as BrokerGroupItem[]);
      });
  }

  getGroupCompaniesPaging(
    query: string,
    isIncludedInactive: boolean,
    sortField: string,
    sortDirection: string = "asc",
    pageIndex = PagingConstant.pageIndex,
    pageSize = PagingConstant.pageSize
  ): Observable<PaginationResponse<BrokerGroupItem>> {
    return this.http
      .get<PaginationResponse<BrokerGroupItem>>(
        this.api.getAllBrokerGroupPaging(
          query,
          isIncludedInactive,
          sortField,
          sortDirection,
          pageIndex,
          pageSize
        )
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }
}
