import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { PricingInformation } from '../models/pricing-item';
import { Observable } from 'rxjs';
import { ApiEndPoints } from '../shared/config/api-end-points';
import { map } from 'rxjs/operators';
import { PagingConstant } from 'src/app/shared/constants/constants';
import { PaginationResponse } from '../models/pagination-response';

@Injectable({
  providedIn: "root",
})
export class PricingService {
  constructor(private http: HttpClient, private api: ApiEndPoints) {}

  getPricingsPagingByFilters(
    productId: string,
    subProductId: string,
    levelOfCoverId: string,
    query: string,
    isIncludedInactive: boolean,
    sortField: string,
    sortDirection: string,
    pageIndex = PagingConstant.pageIndex,
    pageSize = PagingConstant.pageSize
  ): Observable<PaginationResponse<PricingInformation>> {
    return this.http
      .get<PaginationResponse<PricingInformation>>(
        this.api.getPricingsPagingByFilters(
          productId,
          subProductId,
          levelOfCoverId,
          query,
          isIncludedInactive,
          sortField,
          sortDirection,
          pageIndex,
          pageSize
        )
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  getPricingsPagingByFiltersForBranch(
    branchId: string,
    productId: string,
    subProductId: string,
    levelOfCoverId: string,
    query: string,
    isIncludedInactive: boolean,
    sortField: string,
    sortDirection: string,
    pageIndex = PagingConstant.pageIndex,
    pageSize = PagingConstant.pageSize
  ): Observable<PaginationResponse<PricingInformation>> {
    return this.http
      .get<PaginationResponse<PricingInformation>>(
        this.api.getPricingsPagingByFiltersForBranch(
          branchId,
          productId,
          subProductId,
          levelOfCoverId,
          query,
          isIncludedInactive,
          sortField,
          sortDirection,
          pageIndex,
          pageSize
        )
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }
}
