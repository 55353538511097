import { Component, OnInit, Inject, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, Validators, AbstractControl, FormGroup, NgForm } from '@angular/forms';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { HttpBaseService } from 'src/app/shared/services/http-base.service';
import { ApiEndPoints } from 'src/app/shared/config/api-end-points';
import { SubProduct } from 'src/app/models/sub-product';
import { Product } from 'src/app/models/product';
import moment from 'moment';
import * as _ from 'lodash';
import { DocumentModel, DocumentType, UploadDocument } from 'src/app/models/document';
import { DEFAULT_EFFECTIVE_TO_DATE, FORMAT_ONLY_DATE, LeiConfig, TitleMessages } from 'src/app/shared/constants/constants';
import { Status } from 'src/app/models/enums/status.enum';
import { tap, distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { ProductService } from 'src/app/services/product.service';
import { MatSort } from '@angular/material/sort';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-sub-product-document-control',
  templateUrl: './sub-product-document-control.component.html',
  styleUrls: ['./sub-product-document-control.component.scss']
})

export class SubProductDocumentControlComponent implements OnInit, AfterViewInit {

  product: Product;
  subProduct: SubProduct;
  form: FormGroup;
  @ViewChild('formDirective') private formDirective: NgForm;
  documentTypes = [];
  documentType = DocumentType;

  Status = Status;
  updateDocument: DocumentModel;
  updateMode: boolean = false;

  displayedColumns: string[] = ['name', 'fileName', 'effectiveDateFrom', 'effectiveDateTo', 'actions'];
  displayedHistoryColumns: string[] = ['documentType', 'fileName', 'changedByUserName', 'createdOn', 'effectiveDateFrom', 'effectiveDateTo', 'isActive'];

  currentSelectedDocuments: UploadDocument[] = [];

  historyDocuments: DocumentModel[] = [];
  liveDocuments: DocumentModel[] = [];

  enterdEffectiveDateValid: boolean = true;
  defaultEffectiveTo = moment(new Date(DEFAULT_EFFECTIVE_TO_DATE)).endOf('day');

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('file') fileControl: ElementRef | undefined;
  @ViewChild('filePath') filePathControl: ElementRef | undefined;

  constructor(
    private readonly _httpService: HttpBaseService,
    private readonly _api: ApiEndPoints,
    private readonly productService: ProductService,
    public dialogService: DialogService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<SubProductDocumentControlComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.subProduct = data.subProduct;
    this.product = data.product;
    this.createForm();
    if (this.subProduct) {
      this.historyDocuments = _.orderBy(this.subProduct.documents, ['effectiveDateFrom'], ['desc']);
      this.liveDocuments = this.productService.getActiveDocuments(this.historyDocuments);
    }
  }

  async ngOnInit() {
    await this.loadDocumentTypes();
  }

  async loadDocumentTypes() {
    this.documentTypes = await this._httpService.getDataAsync(this._api.getDocumentTypes());
    this.documentTypes = _.filter(this.documentTypes, (x) => x.key !== DocumentType.All);
  }

  onSelectFile(files: FileList) {
    if (files.length === 0) {
      return;
    }

    if (this.type.invalid) {
      this.dialogService.openErrorDialog({ title: TitleMessages.warningTitle, message: 'Please select the document type!' });
      this.type.markAsTouched();
      this.fileControl.nativeElement.value = '';
      return;
    }

    if (this.effectiveDateFrom.invalid) {
      this.dialogService.openErrorDialog({ title: TitleMessages.warningTitle, message: 'Please select the effective date from!' });
      this.effectiveDateFrom.markAsTouched();
      this.fileControl.nativeElement.value = '';
      return;
    }

    const fileToUpload = files.item(0);

    const fileReader: FileReader = new FileReader();
    fileReader.readAsDataURL(fileToUpload);

    fileReader.onload = (event: any) => {
      // this.filePath.setValue(fileToUpload.name);
      this.addToCurrentSelectedDocuments(fileToUpload);
      this.reset();

      this.filePathControl.nativeElement.blur();
      this.filePath.markAsPristine();
      this.filePath.markAsUntouched();
    };
  }

  addToCurrentSelectedDocuments(uploadFile: File) {
    const uploadDoc: UploadDocument = {
      id: _.maxBy(this.currentSelectedDocuments, 'id') ? _.maxBy(this.currentSelectedDocuments, 'id').id + 1 : 1,
      documentType: this.type.value,
      fileName: uploadFile.name,
      effectiveDateFrom: this.effectiveDateFrom.value,
      effectiveDateTo: this.effectiveDateTo.value ? (moment(this.effectiveDateTo.value).isValid() ? this.effectiveDateTo.value : this.defaultEffectiveTo) : this.defaultEffectiveTo,
      file: uploadFile,
      isActive: this.status.value
    };

    let docbyType = _.filter(this.currentSelectedDocuments, (x) => x.documentType === uploadDoc.documentType);
    let orderByEffectiveDateFromDoc = _.orderBy(docbyType, ['effectiveDateFrom'], ['desc']);
    let latestEffectiveDateFromDoc = orderByEffectiveDateFromDoc[0];

    if (latestEffectiveDateFromDoc) {
      if (latestEffectiveDateFromDoc.effectiveDateFrom >= uploadDoc.effectiveDateFrom) {
        this.dialogService.openErrorDialog({ title: TitleMessages.warningTitle, message: 'Please select future effective date from!' });
        this.effectiveDateFrom.markAsTouched();
        return;
      }

      this.currentSelectedDocuments.forEach(x => {
        if (x.id === latestEffectiveDateFromDoc.id) {
          // Update effective date to -1 days base on new effective date from of uploadDoc
          x.effectiveDateTo = moment(uploadDoc.effectiveDateFrom).subtract(1, 'day').endOf('day');
        }
      });
    }

    this.currentSelectedDocuments.push(uploadDoc);

    this.currentSelectedDocuments = _.clone(this.currentSelectedDocuments);
  }

  deleteDocument(document: UploadDocument) {
    let docbyType = _.filter(this.currentSelectedDocuments, (x) => x.documentType === document.documentType);
    let orderByEffectiveDateFromDoc = _.orderBy(docbyType, ['effectiveDateFrom'], ['desc']);

    // update effective date from and effective date to for previous record
    if (document.id !== orderByEffectiveDateFromDoc[0].id && document.id !== orderByEffectiveDateFromDoc[docbyType.length - 1].id) {
      let deleteIndex = this.currentSelectedDocuments.findIndex(x => x.id === document.id);
      this.currentSelectedDocuments[deleteIndex - 1].effectiveDateTo = moment(this.currentSelectedDocuments[deleteIndex + 1].effectiveDateFrom).subtract(1, 'day');
    }
    _.remove(this.currentSelectedDocuments, (x) => x.id === document.id);
    this.currentSelectedDocuments = _.clone(this.currentSelectedDocuments);
  }

  createForm() {
    this.form = this.formBuilder.group({
      productName: [this.product ? this.product.name : ''],
      subProductName: [this.subProduct ? this.subProduct.name : ''],
      type: ['', [Validators.required]],
      status: [Status.Active, [Validators.required]],
      filePath: ['', [Validators.required]],
      effectiveDateFrom: ['', [Validators.required]],
      effectiveDateTo: ['']
    });
  }

  get productName() {
    return this.form.get('productName') as AbstractControl;
  }

  get subProductName() {
    return this.form.get('subProductName') as AbstractControl;
  }

  get type() {
    return this.form.get('type') as AbstractControl;
  }

  get filePath() {
    return this.form.get('filePath') as AbstractControl;
  }

  get effectiveDateFrom() {
    return this.form.get('effectiveDateFrom') as AbstractControl;
  }

  get effectiveDateTo() {
    return this.form.get('effectiveDateTo') as AbstractControl;
  }

  public get status() {
    return this.form.get('status') as AbstractControl;
  }

  ngAfterViewInit() {
    this.effectiveDateFrom.valueChanges
      .pipe(
        debounceTime(LeiConfig.delayBeforeSearchingWithAutoSearchFields),
        distinctUntilChanged()
      )
      .subscribe(v => {
        if (!this.updateMode && v) {
          this.validateEffectiveDateFrom(v);
        }
      });

    this.effectiveDateTo.valueChanges
      .pipe(
        debounceTime(LeiConfig.delayBeforeSearchingWithAutoSearchFields),
        distinctUntilChanged()
      )
      .subscribe(v => {
        if (!this.updateMode && v) {
          this.effectiveDateTo.setValue(moment(v).endOf('day'), { emitEvent: false });
          this.validateEffectiveDateTo(v);
        }
      });
    this.sort.sortChange
      .pipe(
        tap(() => this.sortHistoryDocuments())
      )
      .subscribe();

    this.type.valueChanges.subscribe(v => {
      if (v) {
        this.status.setValue(Status.Active);
      }
    });
  }

  sortHistoryDocuments() {
    this.historyDocuments = _.orderBy(this.historyDocuments, this.sort.active, [this.sort.direction === 'asc' ? 'asc' : 'desc']);
  }

  async validateEffectiveDateFrom(enterdDate: any) {

    if (this.effectiveDateTo.value) {
      const fromDate = moment(enterdDate).format('YYYY-MM-DD');
      const toDate = moment(this.effectiveDateTo.value).format('YYYY-MM-DD');
      if (fromDate >= toDate) {
        this.dialogService.openErrorDialog({ title: TitleMessages.warningTitle, message: `The effective to date entered is ${fromDate == toDate ? 'same as' : 'less than'} the effective from date entered. Please check and re-enter the date!` });
        this.effectiveDateFrom.setValue('', { emitEvent: false });
        return;
      }
    }

    let existedDocument = _.find(this.historyDocuments, (x) => x.subProductId === this.subProduct.id && x.documentType === this.type.value && x.isActive);

    if (existedDocument) {
      const enteredDate = moment(enterdDate);
      const existedDate = moment(existedDocument.effectiveDateFrom);
      if (enteredDate <= existedDate) {
        this.dialogService.openErrorDialog({ title: TitleMessages.warningTitle, message: 'The effective from date entered conflicts with another record in the system. Please check and re-enter the date!' });
        this.effectiveDateFrom.setValue('', { emitEvent: false });
        return;
      }
      else {
        this.enterdEffectiveDateValid = true;
      }
    }
    return;
  }

  async validateEffectiveDateTo(enterdDate: any) {
    let errorMessage = '';
    if (this.effectiveDateFrom.value) {
      const fromDate = moment(this.effectiveDateFrom.value).format('YYYY-MM-DD');
      const toDate = moment(enterdDate).format('YYYY-MM-DD');
      if (fromDate >= toDate) {
        errorMessage = `The effective to date entered is ${fromDate == toDate ? 'same as' : 'less than'} the effective from date entered. Please check and re-enter the date!`;
        this.enterdEffectiveDateValid = false;
      } else {
        this.enterdEffectiveDateValid = true;
      }
    }

    if (!this.enterdEffectiveDateValid) {
      this.dialogService.openErrorDialog({ title: TitleMessages.warningTitle, message: errorMessage });
      this.effectiveDateTo.setValue('', { emitEvent: false });
      return;
    }
  }

  async uploadDocument(documentFile: File) {
    let url: string;

    try {
      const isPrivate = true;
      const isOverriden = false;
      url = await this._httpService.uploadAsync<string>(this._api.uploadFile(isPrivate, isOverriden), documentFile);
    }
    finally {
    }

    return url;
  }

  async onSave(isClose: boolean) {
    if (this.updateMode) {
      await this.doUpdateDocument(isClose);
    }
    else {
      await this.doCreateDocument(isClose);
    }
  }

  async doUpdateDocument(isClose: boolean) {
    let document: DocumentModel = {
      id: this.updateDocument.id,
      rowVersion: this.updateDocument.rowVersion,
      subProductId: this.subProduct.id,
      url: this.updateDocument.url,
      fileName: this.updateDocument.fileName,
      fileType: 'pdf',
      documentType: this.updateDocument.documentType,
      createdOn: this.updateDocument.createdOn,
      effectiveDateFrom: moment(this.updateDocument.effectiveDateFrom).format(FORMAT_ONLY_DATE),
      effectiveDateTo: moment(this.updateDocument.effectiveDateTo).format(`${FORMAT_ONLY_DATE} 23:59:59`),
      isActive: this.status.value,
      changedByUserName: this.updateDocument.changedByUserName
    };
    try {
      await this._httpService.putDataAsync<SubProduct>(this._api.updateDocument(), document);
      this.dialogService.openSuccessDialogConfirm({ title: TitleMessages.successTitle, message: 'Document has been updated successfully' }).subscribe(async result => {
        if (result) {
          if (isClose) {
            this.dialogRef.close(true);
          } else {
            await this.reloadData();

            this.reset();
            this.updateDocument = undefined;
            this.filePathControl.nativeElement.blur();
            this.filePath.markAsPristine();
            this.filePath.markAsUntouched();
          }
        }
      });
    }
    finally {
    }
  }

  async doCreateDocument(isClose: boolean) {
    let createDocuments: DocumentModel[] = [];
    for (var i = 0; i < this.currentSelectedDocuments.length; i++) {
      let d = this.currentSelectedDocuments[i];
      let url = await this.uploadDocument(d.file);

      if (url) {
        let document: DocumentModel = {
          id: undefined,
          rowVersion: undefined,
          subProductId: this.subProduct.id,
          url,
          fileName: d.fileName,
          fileType: 'pdf',
          documentType: d.documentType,
          createdOn: moment(new Date()),
          effectiveDateFrom: moment(d.effectiveDateFrom).format(FORMAT_ONLY_DATE),
          effectiveDateTo: moment(d.effectiveDateTo).format(`${FORMAT_ONLY_DATE} 23:59:59`),
          isActive: d.isActive,
          changedByUserName: undefined
        };
        createDocuments.push(document);
      }
    }

    try {
      await this._httpService.postDataAsync<any>(this._api.createDocuments(), createDocuments);

      this.dialogService.openSuccessDialogConfirm({ title: TitleMessages.successTitle, message: 'Documents has been saved successfully' }).subscribe(async result => {
        if (result) {
          if (isClose) {
            this.dialogRef.close(true);
          } else {
            await this.reloadData();
          }
        }
      });
    }
    finally {
    }
  }

  async reloadData() {
    this.currentSelectedDocuments = [];
    this.subProduct = await this._httpService.getDataAsync<SubProduct>(this._api.getSubProduct(this.subProduct.id));
    if (this.subProduct) {
      this.historyDocuments = _.orderBy(this.subProduct.documents, ['effectiveDateFrom'], ['desc']);
      this.liveDocuments = this.productService.getActiveDocuments(this.historyDocuments);
    }
  }

  onRowClick(row: DocumentModel) {
    this.updateDocument = row;
    this.updateMode = true;
    this.bindingDataForUpdate(this.updateDocument);
  }

  reset() {
    setTimeout(() => {
      this.updateMode = false;
      this.formDirective.resetForm();
      this.productName.setValue(this.product ? this.product.name : '');
      this.subProductName.setValue(this.subProduct ? this.subProduct.name : '');

      this.type.enable({ emitEvent: false });
      this.effectiveDateFrom.enable({ emitEvent: false });
      this.effectiveDateTo.enable({ emitEvent: false });
      this.fileControl.nativeElement.value = '';

      this.form.markAsUntouched();
    }, 0);

  }

  bindingDataForUpdate(document: DocumentModel) {
    this.type.setValue(document.documentType, { emitEvent: false });
    this.type.disable();
    this.status.setValue(document.isActive ? Status.Active : Status.Inactive);
    this.effectiveDateFrom.setValue(document.effectiveDateFrom, { emitEvent: false });
    this.effectiveDateFrom.disable();
    this.effectiveDateTo.setValue(document.effectiveDateTo, { emitEvent: false });
    this.effectiveDateTo.disable();
    this.filePath.setValue(document.url, { emitEvent: false });
    this.filePath.disable();
  }


  onCloseDialog(isReload: boolean) {
    if (this.form.dirty || this.currentSelectedDocuments.length > 0) {
      this.dialogService.openConfirmationDialog({ title: TitleMessages.confirmationTitle, message: 'Are you sure you want to continue. Your changes will be lost?' }).subscribe(result => {
        if (result) {
          this.dialogRef.close(isReload);
        }
      });
    }
    else {
      this.dialogRef.close(isReload);
    }
  }

  getDocumentTypeName(key: string) {
    const type = _.find(this.documentTypes, (x) => (x.key == key));
    return type ? type.name : '';
  }

  openDocument(document: DocumentModel) {
    if (document.documentType !== DocumentType.Schedule) {
      this._httpService.openPdf(this._api.downloadFile(document.url), document.url, () => {
      }, () => {
        this.dialogService.openErrorDialog({ title: 'Broker Admin', message: 'File not found!' });
      });
    }
  }
}

