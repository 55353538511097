<div class="level-of-cover-popup">
  <h3 class="title">{{updateMode?'Update':'Create'}} Level of Cover</h3>
  <mat-dialog-content class="mat-dialog-content">
    <form [formGroup]="form">
      <section>
        <mat-form-field class="select-dropdown">
          <mat-label>Product</mat-label>
          <mat-select data-test="product" required placeholder="Product" formControlName="productId">
            <mat-option *ngFor="let product of products" [value]="product.id">
              {{product.name}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="productId.invalid">Product is required</mat-error>
        </mat-form-field>

        <mat-form-field class="select-dropdown">
          <mat-label>Sub Product</mat-label>
          <mat-select data-test="subProduct" required placeholder="Sub Product" formControlName="subProductId">
            <mat-option *ngFor="let subProduct of subProducts" [value]="subProduct.id">
              {{subProduct.name}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="subProductId.invalid">Sub product is required</mat-error>
        </mat-form-field>

          <mat-form-field class="select-dropdown">
            <mat-label>Select Source Type from dropdown list</mat-label>
            <mat-select data-test="levelOfCoverSourceType" required placeholder="Select Source Type from dropdown list"
              formControlName="levelOfCoverSourceType">
              <mat-option *ngFor="let type of levelOfCoverSourceTypes" [value]="type.value">
                {{type.name}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="levelOfCoverSourceType.invalid">Level Of Cover Source Type is required</mat-error>
          </mat-form-field>
        </section>

        <section>
        <mat-form-field>
          <mat-label>Level of Cover</mat-label>
          <input matInput data-test="name" placeholder="Level of Cover" formControlName="name" TitleCase required>
          <mat-error *ngIf="name.errors?.required || name.errors?.whitespace">Level of Cover is required</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Order</mat-label>
          <input required matInput type="number" data-test="order" spellcheck="false" min="1" step="1" maxlength="2"
          onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode > 47 && event.charCode <= 57"
          placeholder="Order" formControlName="order" (blur)="checkOrderValue()">

          <mat-error *ngIf="order.invalid">Order is required</mat-error>
        </mat-form-field>

        <mat-form-field class="select-dropdown">
          <mat-label>Select status from dropdown</mat-label>
          <mat-select data-test="status" placeholder="Select status from dropdown" formControlName="status" required>
            <mat-option *ngFor="let status of Status.values()" [value]="Status[status]">
              {{status}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="status.invalid">Status is required</mat-error>
        </mat-form-field>
      </section>
      <section *ngIf="product && product.shortName===productConst.guaranteedReplacementVehicle">
        <mat-form-field class="select-dropdown">
          <mat-label>Hire Period</mat-label>
            <input required matInput type="number" data-test="hirePeriod" spellcheck="false" min="0" step="1"
            onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57"
            placeholder="Hire Period" formControlName="hirePeriod">

          <mat-error *ngIf="hirePeriod.invalid">Hire Period is required</mat-error>
        </mat-form-field>
        <mat-form-field class="select-dropdown">
          <mat-label>Hire Period Unit</mat-label>
          <mat-select data-test="status" placeholder="Hire Period Unit" formControlName="hirePeriodUnit"
            required>
            <mat-option *ngFor="let unit of HirePeriodUnits.values()" [value]="HirePeriodUnits[unit]">
              {{unit}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="hirePeriodUnit.invalid">Hire Period Unit is required</mat-error>
        </mat-form-field>
      </section>
      <section *ngIf="product && product.shortName===productConst.landlordsLegalExpenses">
        <mat-form-field class="select-dropdown">
          <mat-label>Rental Guarantee</mat-label>
            <input required matInput data-test="rentalGuarantee" spellcheck="false"
            onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.key=='£' || event.charCode == 60 || (event.charCode >= 48 && event.charCode <= 57)"
            placeholder="Rental Guarantee" formControlName="rentalGuarantee">
            <!-- <span matPrefix class="mat-prefix">&#60; £ </span> -->
          <mat-error *ngIf="rentalGuarantee.invalid">Rental Guarantee is required</mat-error>
        </mat-form-field>
      </section>
    </form>

    <mat-dialog-actions class="justify-items-end button-row">
      <button mat-button mat-raised-button color="primary" [disabled]="disableSaveButton()" (click)="onSave()">{{updateMode?'Update':'Save'}}</button>
      <button mat-button mat-raised-button color="secondary" (click)="onCloseDialog(false)">Cancel</button>
    </mat-dialog-actions>
  </mat-dialog-content>
</div>
