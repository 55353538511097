import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import * as _ from 'lodash';
import { Product } from 'src/app/models/product';
import { ApiEndPoints } from 'src/app/shared/config/api-end-points';
import { HttpBaseService } from 'src/app/shared/services/http-base.service';
import { ProductDataSource } from 'src/app/models/product-datasource';
import { ProductService } from 'src/app/services/product.service';
import { Router, ActivatedRoute } from '@angular/router';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { tap } from 'rxjs/operators';
import { PagingConstant, NO_RESULTS_FOUND } from 'src/app/shared/constants/constants';
import { FormBuilder, AbstractControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-product-configuration',
  templateUrl: './product-configuration.component.html',
  styleUrls: ['./product-configuration.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ]
})

export class ProductConfigurationComponent implements OnInit, AfterViewInit {

  expandedElement: any;

  displayedColumns: string[] = ['expandbutton', 'name', 'isActive', 'buttons'];
  displayedColumnsSub: string[] = ['name', 'isActive'];

  dataSource: ProductDataSource;
  totalRecords: number;
  pagingConstant = PagingConstant;

  pageSizeOptions: number[] = PagingConstant.pageSizeOptionsLarger;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  searchForm = this.formBuilder.group({
    isIncludedInactive: [false]
  });

  notfound: string = '';
  get notFoundMessage() {
    return this.notfound;
  }

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly _productService: ProductService,
    private readonly _api: ApiEndPoints,
    private readonly _httpService: HttpBaseService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  get isIncludedInactive() {
    return this.searchForm.get('isIncludedInactive') as AbstractControl;
  }

  async ngOnInit() {
    this.dataSource = new ProductDataSource(this._productService);
    await this.loadData(this.isIncludedInactive.value);
    this.dataSource.products$.subscribe(p => {
      this.checkNotFoundResults();
    });
  }

  ngAfterViewInit() {
    // reset the paginator after sorting
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    this.paginator.page
      .pipe(
        tap(() => this.getProductsPage())
      )
      .subscribe();

    this.sort.sortChange
      .pipe(
        tap(() => this.getProductsPage())
      )
      .subscribe();

    this.isIncludedInactive.valueChanges
      .subscribe(value => {
        this.paginator.pageIndex = 0;
        this.paginator.pageSize = PagingConstant.pageSizeOptionsLarger[0];
        this.loadData(this.isIncludedInactive.value);
      });
  }


  checkNotFoundResults() {
    if (this.dataSource.totalRecords === 0) {
      this.notfound = NO_RESULTS_FOUND;
    } else {
      this.notfound = '';
    }
  }

  async loadData(isIncludedInactive: boolean) {
    let index = this.paginator? this.paginator.pageIndex: 0;
    let pageSize = this.paginator? this.paginator.pageSize: PagingConstant.pageSizeLarger;
    this.dataSource.getProducts(isIncludedInactive, 'Name', 'asc', index, pageSize);
  }

  async getProductsPage() {
    const fieldSort = this.sort.active.charAt(0).toUpperCase() + this.sort.active.slice(1);
    const direction = this.sort.direction;

    this.dataSource.getProducts(this.isIncludedInactive.value, fieldSort, direction, this.paginator.pageIndex, this.paginator.pageSize);
  }

  editProduct(product: Product) {
    this.router.navigate([`/app/products/product-information/update/${product.id}`], { relativeTo: this.activatedRoute });
  }

  createProduct() {
    this.router.navigate([`/app/products/product-information/create`], { relativeTo: this.activatedRoute });
  }
}
