<main>
  <mat-card appearance="outlined" >
    <mat-card-header>
      <mat-card-title><strong>Products</strong></mat-card-title>
      <mat-card-subtitle>Maintain products here.</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <div [formGroup]="searchForm" class="search-group">
        <div class="include-inactive">
          <label id="include-inactive-items" class="label-include"><span><strong>Include Inactive
                Items:</strong></span>
          </label>
          <mat-radio-group formControlName="isIncludedInactive">
            <mat-radio-button data-test="noRadio" value="false" [checked]="isIncludedInactive.value === false">No
            </mat-radio-button>
            <mat-radio-button data-test="yesRadio" value="true" [checked]="isIncludedInactive.value === true">Yes
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort matSortActive="name"
        matSortDirection="asc" matSortDisableClear>

        <ng-container matColumnDef="expandbutton">
          <th mat-header-cell *matHeaderCellDef>
          </th>
          <td mat-cell *matCellDef="let product">
            <button mat-button class="btn-table-row"
              (click)="expandedElement = expandedElement === product ? product : null">
              <mat-icon svgIcon="unfold-more-horizontal"></mat-icon>
            </button>
          </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
          <td mat-cell *matCellDef="let product">{{product.name}}</td>
        </ng-container>

        <ng-container matColumnDef="isActive">
          <th mat-header-cell *matHeaderCellDef>Active</th>
          <td mat-cell class="isactive-cell" *matCellDef="let product">
            <mat-icon *ngIf="product.isActive" class="colour-success" svgIcon="check"></mat-icon>
            <mat-icon *ngIf="!product.isActive" class="colour-danger" svgIcon="close"></mat-icon>
          </td>
        </ng-container>

        <ng-container matColumnDef="buttons" stickyEnd>
          <th mat-header-cell *matHeaderCellDef>Edit</th>
          <td mat-cell *matCellDef="let product">
            <button mat-button class="btn-table-row btn-action" (click)="editProduct(product)">
              <mat-icon svgIcon="pencil"></mat-icon>
            </button>
          </td>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let product" [attr.colspan]="displayedColumns.length">
            <div class="example-element-detail" [@detailExpand]="product == expandedElement ? 'expanded' : 'collapsed'">
              <div>
                <mat-card appearance="outlined">
                  <mat-card-header >
                    <mat-card-title>Sub product for {{ product.name }}</mat-card-title>
                    <mat-card-subtitle></mat-card-subtitle>
                  </mat-card-header>
                  <mat-card-content >
                    <table mat-table [dataSource]="product.subProducts" multiTemplateDataRows>

                      <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef>Name</th>
                        <td mat-cell *matCellDef="let sub">{{sub.name}}</td>
                      </ng-container>

                      <ng-container matColumnDef="isActive">
                        <th mat-header-cell *matHeaderCellDef>Active</th>
                        <td mat-cell class="isactive-cell" *matCellDef="let sub">
                          <mat-icon *ngIf="sub.isActive" class="colour-success" svgIcon="check"></mat-icon>
                          <mat-icon *ngIf="!sub.isActive" class="colour-danger" svgIcon="close"></mat-icon>
                        </td>
                      </ng-container>

                      <tr mat-header-row *matHeaderRowDef="displayedColumnsSub"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumnsSub;"></tr>
                    </table>
                  </mat-card-content>
                </mat-card>
              </div>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="example-element-row"
          (click)="expandedElement = expandedElement === row ? null : row"></tr>

        <tr mat-row *matRowDef="let row; columns: ['expandedDetail'];" class="example-detail-row"></tr>

      </table>

      <div class="flex-row-between">
        <span class="not-found-text">{{notFoundMessage}}</span>
        <mat-paginator [length]="dataSource.totalRecords" [pageIndex]="pagingConstant.pageIndex"
          [pageSize]="pagingConstant.pageSizeLarger" [pageSizeOptions]="pageSizeOptions" showFirstLastButtons></mat-paginator>
      </div>
    </mat-card-content>

  </mat-card>
</main>
