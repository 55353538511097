import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import * as _ from 'lodash';
import { ApiEndPoints } from 'src/app/shared/config/api-end-points';
import { HttpBaseService } from 'src/app/shared/services/http-base.service';
import { PagingConstant, NO_RESULTS_FOUND } from 'src/app/shared/constants/constants';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { tap } from 'rxjs/operators';
import { StationaryMaintenanceDataSource } from 'src/app/models/stationary-maintenance-datasource';
import { StationaryMaintenanceService } from 'src/app/services/stationary-maintenance.service';
import { StationaryMaintenancePopupComponent } from './stationary-maintenance-popup/stationary-maintenance-popup.component';
import { StationaryMaintenance } from 'src/app/models/stationary-maintenance';
import { StationaryStatus } from 'src/app/models/stationary-status';
import { FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialogConfig } from '@angular/material/dialog';

@Component({
  selector: 'app-stationary-maintenance',
  templateUrl: './stationary-maintenance.component.html',
  styleUrls: ['./stationary-maintenance.component.scss']
})

export class StationaryMaintenanceComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = ['description', 'status', 'statusNotes', 'minOrderQuantity', 'maxOrderQuantity', 'incrementQuantity', 'actions'];

  dataSource: StationaryMaintenanceDataSource;
  totalRecords: number;
  stationaryStatuses: StationaryStatus[] = [];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  pagingConstant = PagingConstant;
  pageSizeOptions: number[] = PagingConstant.pageSizeOptionsLarger;

  configDialog = new MatDialogConfig();

  filterForm = this.formBuilder.group({
    isIncludedInactive: [false, [Validators.required]]
  });

  get isIncludedInactive() {
    return this.filterForm.get('isIncludedInactive') as AbstractControl;
  }

  notfound = '';

  get notFoundMessage() {
    return this.notfound;
  }

  constructor(
    private readonly _stationaryMaintenanceService: StationaryMaintenanceService,
    private readonly _api: ApiEndPoints,
    private formBuilder: FormBuilder,
    private readonly _httpService: HttpBaseService,
    private readonly _dialogService: DialogService) {
      this.configDialog.width = '700px';
      this.configDialog.disableClose = true;
      this.loadStationaryStatuses();
  }

  ngOnInit() {
    this.dataSource = new StationaryMaintenanceDataSource(this._stationaryMaintenanceService);
    this.loadData(this.isIncludedInactive.value);
    this.dataSource.stationarysSubject$.subscribe(s => {
      this.checkNotFoundResults();
    });
  }

  checkNotFoundResults() {
    if (this.dataSource.totalRecords === 0) {
      this.notfound = NO_RESULTS_FOUND;
    } else {
      this.notfound = '';
    }
  }

  async loadData(isIncludedInactive: boolean) {
    let index = this.paginator? this.paginator.pageIndex: 0;
    let pageSize = this.paginator? this.paginator.pageSize: PagingConstant.pageSizeLarger;
    this.dataSource.getStationaryMaintenances(isIncludedInactive, 'Description', 'asc', index, pageSize);
  }

  async loadStationaryStatuses(){
    this.stationaryStatuses = await this._httpService.getDataAsync<StationaryStatus[]>(this._api.getStationaryStatuses());
  }

  ngAfterViewInit() {
    // reset the paginator after sorting
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    this.paginator.page
      .pipe(
        tap(() => this.getStationaryMaintenancePage())
      )
      .subscribe();

    this.sort.sortChange
      .pipe(
        tap(() => this.getStationaryMaintenancePage())
      )
      .subscribe();

    this.isIncludedInactive.valueChanges.subscribe(value=>{
      this.paginator.pageIndex = 0;
      this.paginator.pageSize = PagingConstant.pageSizeOptionsLarger[0];
      this.loadData(value);
    });
  }

  getStationaryMaintenancePage() {
    const fieldSort = this.sort.active.charAt(0).toUpperCase() + this.sort.active.slice(1);
    const direction = this.sort.direction;
    this.dataSource.getStationaryMaintenances(this.isIncludedInactive.value, fieldSort, direction, this.paginator.pageIndex, this.paginator.pageSize);
  }

  getStatus(status: number){
    const statusObj = _.find(this.stationaryStatuses, (x) => (x.value === status));
    return statusObj ? statusObj.name : '';
  }

  editStationary(st: StationaryMaintenance) {
    this._dialogService.openComponentDialog(StationaryMaintenancePopupComponent, { stationary: st}, this.configDialog).subscribe(async result => {
      if (result){
        await this.loadData(this.isIncludedInactive.value);
      }
    });
  }

  createStationary() {
    this._dialogService.openComponentDialog(StationaryMaintenancePopupComponent, {
    }, this.configDialog).subscribe(async result => {
      if (result) {
        await this.loadData(this.isIncludedInactive.value);
      }
    });
  }
}

