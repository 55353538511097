import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { Broker } from 'src/app/models/broker';
import { PagingConstant, NO_RESULTS_FOUND } from 'src/app/shared/constants/constants';
import { BrokerDataSource } from 'src/app/models/broker-datasource';
import { BrokerService } from 'src/app/services/broker.service';
import { tap } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { Utility } from 'src/app/utils/utility';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { PreviousRouteService } from 'src/app/shared/services/previous-route.service';
import { SharedSearchComponent } from 'src/app/shared/components/shared-search/shared-search.component';

@Component({
  selector: 'app-broker',
  templateUrl: './broker.component.html',
  styleUrls: ['./broker.component.scss']
})
export class BrokerComponent implements OnInit, AfterViewInit {

  broker: Broker;
  brokerList: Broker[] = [];
  isLoading = false;

  displayedColumns: string[] = ['originalBrokerId', 'name', 'addressline1', 'addressline2', 'addressline3', 'postcode', 'phonenumber', 'email', 'active', 'actions'];


  totalRecords: number;

  dataSource: BrokerDataSource;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(SharedSearchComponent) sharedSearch: SharedSearchComponent;

  pagingConstant = PagingConstant;
  searchString: string;

  searchFormBroker = this.formBuilder.group({
    isIncludedInactive: [false, [Validators.required]]
  });

  get isIncludedInactive() {
    return this.searchFormBroker.get('isIncludedInactive') as AbstractControl;
  }

  notfound = '';
  get notFoundMessage() {
    return this.notfound;
  }

  constructor(private readonly formBuilder: FormBuilder,
    private readonly _brokerService: BrokerService,
    private readonly router: Router,
    private activatedRoute: ActivatedRoute,
    private _previousRouteService: PreviousRouteService) {
    }

  async ngOnInit() {
    if (this._previousRouteService.getPreviousUrl().includes("broker/update")) {
      this.searchString = this._brokerService.getSearchStringBrokerList();
    }
    this.dataSource = new BrokerDataSource(this._brokerService);
    await this.loadData();
    this.sharedSearch.setSearchString(this.searchString);
    this.dataSource.brokerSubject$.subscribe(b => {
      this.checkNotFoundResults();
    });
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    this.paginator.page
      .pipe(
        tap(() => this.getBrokerDetailsPage())
      )
      .subscribe();

    this.sort.sortChange
      .pipe(
        tap(() => this.getBrokerDetailsPage())
      )
      .subscribe();
    this.isIncludedInactive.valueChanges.subscribe(value => {
      this.paginator.pageIndex = 0;
      this.paginator.pageSize = PagingConstant.pageSizeOptionsLarger[0];
      this.loadData();
    });
  }

  async getBrokerDetailsPage() {
    const fieldSort = this.sort.active.charAt(0).toUpperCase() + this.sort.active.slice(1);
    const direction = this.sort.direction;
    this.loadData(fieldSort, direction);
  }

  async loadData(sortField: string = 'Name', sortDirection: string = 'asc') {
    let index = this.paginator ? this.paginator.pageIndex : 0;
    let pageSize = this.paginator ? this.paginator.pageSize : PagingConstant.pageSizeLarger;
    this.dataSource.getBrokerList(this.searchString, this.isIncludedInactive.value, sortField, sortDirection, index, pageSize);
    this._brokerService.setSearchStringBrokerList(this.searchString);
    if (this.sharedSearch) this.sharedSearch.setSearchString(this.searchString);
  }

  checkNotFoundResults() {
    if (this.dataSource.totalRecords === 0) {
      this.notfound = NO_RESULTS_FOUND;
    } else {
      this.notfound = '';
    }
  }

  onValueChange(value: string) {
    this.searchString = value;
  }

  async search() {
    this.paginator.pageIndex = 0;
    await this.loadData();
  }

  addBroker() {
    this.router.navigate([`/app/brokers/broker/create`], { relativeTo: this.activatedRoute });
  }

  editBroker(broker: Broker) {
    this.router.navigate([`/app/brokers/broker/update/${broker.id}`], { relativeTo: this.activatedRoute });
  }

  formatPhoneNumber(phonenumber) {
    phonenumber = phonenumber.split(' ').join("");
    const formatted = Utility.formatPhoneNumber(phonenumber);
    return formatted;
  }
}
