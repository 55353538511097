import { Component } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent {
  public loading = true;
  constructor(private authService: AuthService, private readonly _dialogService: DialogService) {
    combineLatest(this.authService.isAuthenticated$, 
                  this.authService.loadedUserInfo$)
    .pipe(map((isAuthenticated,loadedUserInfo)=>
                                            {
                                                return isAuthenticated && loadedUserInfo;
                                            })).subscribe((isDone)=>{
      if (isDone) {
        this.authService.userInfo$.subscribe(user => {
          if (user && user['userName']) {
            this.loading = false;
          }
          // else {
          //   console.log('home unauthorised');
            
          //   this._dialogService.openAccessDeniedDialog();
          // }
        });
      }
    });
   

  }
}
