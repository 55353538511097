<mat-card appearance="outlined" class="mat-elevation-z2">
  <mat-card-content class="email-templates-content">
    <div *ngIf="displayNoDataText" class="report">No data</div>

    <mat-table #table *ngIf="emailTemplates?.length > 0" [dataSource]="emailTemplates">
      <ng-container matColumnDef="emailTemplateId">
        <mat-header-cell *matHeaderCellDef> Email Template Id </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.emailTemplateId}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.name}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="description">
        <mat-header-cell *matHeaderCellDef> Description </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.description}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="subject">
        <mat-header-cell *matHeaderCellDef> Subject </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.subject}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="emailTemplateTypeName">
        <mat-header-cell *matHeaderCellDef> Type </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.emailTemplateTypeName}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef class="edit-column">
         <div class="edit">Edit</div> 
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <button mat-button class="btn-action-edit" (click)="editTemplate(row)" matTooltip="Edit">
            <mat-icon svgIcon="pencil"></mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
  </mat-card-content>
</mat-card>
