import { AbstractControl } from "@angular/forms";
import { Utility } from "../utils/utility";

export function isValidPhoneNumber(control: AbstractControl) {
  let original = control.value as string;
  if (original) {
    original = original.split(" ").join("");
    const formatted = Utility.formatPhoneNumber(original);
    if (original === formatted) {
      return { invalidPhoneNumber: true };
    } else {
      return null;
    }
  }
  return null;
}
