<mat-card appearance="outlined" class="mat-elevation-z2">
  <mat-card-header>
    <mat-card-title><strong>Master Settings</strong></mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div [formGroup]="systemSettingGroup" class="ipt-rate-setting">
      <section>
        <mat-form-field>
          <mat-label>Stationery Support Email</mat-label>
          <input [required]="true" data-test="systemSetting-stationarySupportEmail-input" matInput
            placeholder="Stationery Support Email" formControlName="stationarySupportEmail">
          <mat-error *ngIf="stationarySupportEmail.errors?.required">Stationery Support Email is required
          </mat-error>
          <mat-error *ngIf="(stationarySupportEmail.invalid || stationarySupportEmail.hasError('invalidEmail'))  && !stationarySupportEmail.errors?.required">This doesn't seem to be a valid email address
          </mat-error>
        </mat-form-field>
      </section>
      <section>
        <mat-form-field>
          <mat-label>Application Support Email</mat-label>
          <input [required]="true" data-test="systemSetting-applicationSupportEmail-input" matInput
            placeholder="Application Support Email" formControlName="applicationSupportEmail">
          <mat-error *ngIf="applicationSupportEmail.errors?.required">Application Support Email is required
          </mat-error>
          <mat-error *ngIf="(applicationSupportEmail.invalid || applicationSupportEmail.hasError('invalidEmail'))  && !applicationSupportEmail.errors?.required">This doesn't seem to be a valid email address
          </mat-error>
        </mat-form-field>
      </section>
      <section>
        <mat-form-field>
          <mat-label>Health Check Email</mat-label>
          <input [required]="true" data-test="systemSetting-healthCheckEmail-input" matInput
            placeholder="Health Check Email" formControlName="healthCheckEmail">
          <mat-error *ngIf="healthCheckEmail.errors?.required">Health Check Email is required
          </mat-error>
          <mat-error *ngIf="(healthCheckEmail.invalid || healthCheckEmail.hasError('invalidEmail'))  && !healthCheckEmail.errors?.required">This doesn't seem to be a valid email address
          </mat-error>
        </mat-form-field>
      </section>
      <section>
        <mat-form-field>
          <mat-label>Hotline Contact Number</mat-label>
          <input [required]="true" spellcheck="false" data-test="systemSetting-hotlineContactNumber-input"
          matInput placeholder="Hotline Contact Number" formControlName="hotLineContactNumber" (blur)="formatPhoneNumber()" >
          <mat-error *ngIf="hotLineContactNumber.errors && hotLineContactNumber.errors.required">Hotline contact number is required</mat-error>
          <mat-error *ngIf="hotLineContactNumber.errors && hotLineContactNumber.errors.incorrect">Invalid phone number, please re-enter</mat-error>
        </mat-form-field>
      </section>
    </div>
  </mat-card-content>
  <mat-card-actions>
    <section>
      <div>
        <button mat-raised-button color="primary" (click)="saveChanges()" [disabled]="systemSettingGroup.invalid || systemSettingGroup.pristine">Save</button>
        <button mat-raised-button (click)="backToHome()">Cancel</button>
      </div>
    </section>
  </mat-card-actions>
</mat-card>
