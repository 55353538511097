import { Directive, ElementRef, AfterViewInit } from '@angular/core';

@Directive({
    // tslint:disable-next-line: directive-selector
    selector: 'input'
})
export class DisabledAutocompleteInputDirective implements AfterViewInit {

    constructor(private el: ElementRef) {
    }

    ngAfterViewInit(): void {
        this.el.nativeElement.setAttribute('autocomplete', 'off');
    }
}
