import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { CollectionViewer } from '@angular/cdk/collections';
import { catchError, finalize } from 'rxjs/operators';
import { LevelOfCoverService } from '../services/level-of-cover.service';
import { PagingConstant } from 'src/app/shared/constants/constants';
import { LevelOfCoverInformation } from './level-of-cover';
import { LevelOfCoverValidationGroup } from './level-of-cover-validation-item';
import { PaginationResponse } from './pagination-response';

export class LevelOfCoverValidationDataSource implements DataSource<LevelOfCoverValidationGroup> {

    private levelOfCoverValidationsSubject = new BehaviorSubject<LevelOfCoverValidationGroup[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);
    public totalRecords:number;

    public loading$ = this.loadingSubject.asObservable();
    public levelOfCoverValidations$ = this.levelOfCoverValidationsSubject.asObservable();

    constructor(private levelOfCoverService: LevelOfCoverService) { }

    connect(collectionViewer: CollectionViewer): Observable<LevelOfCoverValidationGroup[]> {
        return this.levelOfCoverValidationsSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.levelOfCoverValidationsSubject.complete();
        this.loadingSubject.complete();
    }

    getLevelOfCoverValidationsByFilters(productId: string, subProductId: string, loCId: string, searchString = '', isIncludedInactive = true, sortField = '', sortDirection = 'asc', pageIndex = PagingConstant.pageIndex, pageSize = PagingConstant.pageSize) {

        this.loadingSubject.next(true);

        this.levelOfCoverService.getLevelOfCoverValidationsByFilters(productId, subProductId, loCId, searchString, isIncludedInactive, sortField, sortDirection, pageIndex, pageSize).pipe(
            catchError(() => of([])),
            finalize(() => this.loadingSubject.next(false))
        )
            .subscribe(loCs => {
              var rs = loCs as PaginationResponse<LevelOfCoverValidationGroup>;
              this.totalRecords = rs.total;
              this.levelOfCoverValidationsSubject.next(rs.items as any);
            });
    }
}

export class LevelOfCoverDataSource implements DataSource<LevelOfCoverInformation> {

    private levelOfCoverSubject = new BehaviorSubject<LevelOfCoverInformation[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);
    public totalRecords:number;

    public loading$ = this.loadingSubject.asObservable();
    public levelOfCovers$ = this.levelOfCoverSubject.asObservable();

    constructor(private levelOfCoverService: LevelOfCoverService) { }

    connect(collectionViewer: CollectionViewer): Observable<LevelOfCoverInformation[]> {
        return this.levelOfCoverSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.levelOfCoverSubject.complete();
        this.loadingSubject.complete();
    }

    getLevelOfCoversByFilters(productId: string, subProductId: string, loCId: string, searchString = '', isIncludedInactive = true, sortField = '', sortDirection = 'asc', pageIndex = PagingConstant.pageIndex, pageSize = PagingConstant.pageSize) {

        this.loadingSubject.next(true);

        this.levelOfCoverService
          .getLevelOfCoversByFilters(
            productId,
            subProductId,
            loCId,
            searchString,
            isIncludedInactive,
            sortField,
            sortDirection,
            pageIndex,
            pageSize
          )
          .pipe(
            catchError(() => of([])),
            finalize(() => this.loadingSubject.next(false))
          )
          .subscribe((loCs) => {
            var rs = loCs as PaginationResponse<LevelOfCoverInformation>;
            this.totalRecords = rs.total;
            this.levelOfCoverSubject.next(rs.items as any);
          });
    }
}
