import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiEndPoints } from '../shared/config/api-end-points';
import { ReportsHistoryResponse } from '../models/reports-history-response';
import { map } from 'rxjs';
import { ExportDeclarationReportResponse, FilterResponse } from '../models/declartion-report-responses/declaration-report-response';
import { ExportLPGDeclarationReportResponse } from '../models/declartion-report-responses/lpg-drivers-club-declaration-report-response';
import { HttpBaseService } from '../shared/services/http-base.service';
import { ExportFileType } from '../models/enums/report-type.enum';

@Injectable({
  providedIn: 'root'
})
export class ReportHistoryService {
  providerList: FilterResponse[] = [];

  constructor(private readonly api: ApiEndPoints, private readonly _httpService: HttpBaseService) { }

  public async getProvider(): Promise<FilterResponse[]> {
    if (this.providerList.length > 0) {
      return this.providerList;
    }
    else {
      this.providerList = await this._httpService.getDataAsync<FilterResponse[]>(this.api.getProviderDeclarations());
    }
    return this.providerList;
  }

  getReportHistories(pageIndex: number, pageSize: number) {
    return this._httpService.getData<ReportsHistoryResponse>(this.api.getReportHistories(pageIndex, pageSize))
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  getARAGDeclarationFromHistory(historyItemId: string) {
    return this._httpService.getData<ExportDeclarationReportResponse>(this.api.getARAGDeclarationFromHistory(historyItemId))
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  getLPGDeclarationFromHistory(historyItemId: string) {
    return this._httpService.getData<ExportLPGDeclarationReportResponse>(this.api.getLPGDeclarationFromHistory(historyItemId))
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  async deleteHistoryItem(historyItemId: string) {
    return await this._httpService.deleteDataAsync<boolean>(this.api.deleteHistoryItem(historyItemId))

  }

  exportDeclarationReport(historyItemId: string, exportFileType: ExportFileType) {
    return this.api.exportARAGDeclarationFromHistory(historyItemId, exportFileType)
  }

  exportLPGDeclarationReport(historyItemId: string, exportFileType: ExportFileType) {
    return this.api.exportLPGDeclarationFromHistory(historyItemId, exportFileType)
  }
}
