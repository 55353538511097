const contactDetailForm = {
    email: 100,
    name: 100,
    jobTitle: 100,
    phone: 20,
}



export const MaxLength = {
    contactDetailForm
}
