import { AbstractControl } from '@angular/forms';

export function noLeadingWhitespaceValidator(control: AbstractControl) {
    const inputValue = control.value as string;
    
    if (control.value) {
        if (inputValue && (inputValue.startsWith(' ')) || inputValue.trim() === '') {            
          return { noLeadingWhitespace: true};
        }
    }
    return null;
};