<main >
  <mat-card appearance="outlined" >
    <mat-card-header >
      <mat-card-title><strong>Broker Group</strong></mat-card-title>
      <mat-card-subtitle>Maintain existing Brokers together under a Broker Group here.
      </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <div>
        <button mat-button class="btn-table-row btn-create" (click)="createBrokerGroup()" matTooltip="Create new Broker Group">
          <mat-icon>add</mat-icon>
        </button>
      </div>
      <div [formGroup]="searchForm" class="search-group">
        <div >
          <div class="section">
            <app-shared-search (onSearch)="search()" (onValueChange)="onValueChange($event)" [placeholderText]="'Search by Broker Group or Broker'">
            </app-shared-search>
          </div>
        </div>
        <div class="include-inactive">
          <label id="include-inactive-items" class="label-include"><span><strong>Include Inactive
                Items:</strong></span>
          </label>
          <mat-radio-group formControlName="isIncludedInactive">
            <mat-radio-button data-test="noRadio" value="false" [checked]="isIncludedInactive.value === false">No
            </mat-radio-button>
            <mat-radio-button data-test="yesRadio" value="true" [checked]="isIncludedInactive.value === true">Yes
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <table mat-table [dataSource]="dataSource" matSort matSortActive="brokerGroupName" matSortDirection="asc"
        matSortDisableClear>

        <ng-container matColumnDef="brokerGroupName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Broker Group</th>
          <td mat-cell *matCellDef="let row">{{row.brokerGroupName}}</td>
        </ng-container>

        <ng-container matColumnDef="originalBrokerId">
          <th mat-header-cell *matHeaderCellDef>Broker ID</th>
          <td mat-cell *matCellDef="let row">{{row.originalBrokerId}}</td>
        </ng-container>

        <ng-container matColumnDef="brokerName">
          <th mat-header-cell *matHeaderCellDef>Broker Name</th>
          <td mat-cell *matCellDef="let row">{{row.brokerName}}</td>
        </ng-container>

        <ng-container matColumnDef="address1">
          <th mat-header-cell *matHeaderCellDef>Address 1</th>
          <td mat-cell *matCellDef="let row">{{row.address1}}</td>
        </ng-container>

        <ng-container matColumnDef="postCode">
          <th mat-header-cell *matHeaderCellDef>Post Code</th>
          <td mat-cell *matCellDef="let row">{{row.postCode}}</td>
        </ng-container>

        <ng-container matColumnDef="telephoneNumber">
          <th mat-header-cell *matHeaderCellDef>Telephone Number</th>
          <td mat-cell *matCellDef="let row">{{formatPhoneNumber(row.telephoneNumber)}}</td>
        </ng-container>

        <ng-container matColumnDef="emailAddress">
          <th mat-header-cell *matHeaderCellDef>Email Address</th>
          <td mat-cell *matCellDef="let row">{{row.emailAddress}}</td>
        </ng-container>

        <ng-container matColumnDef="isBrokerActive">
          <th mat-header-cell *matHeaderCellDef>Active</th>
          <td mat-cell *matCellDef="let row">
            <mat-icon *ngIf="row.isBrokerActive" class="colour-success" svgIcon="check"></mat-icon>
            <mat-icon *ngIf="!row.isBrokerActive" class="colour-danger" svgIcon="close"></mat-icon>
          </td>
        </ng-container>

        <ng-container matColumnDef="remove">
          <th mat-header-cell *matHeaderCellDef>Remove</th>
          <td mat-cell *matCellDef="let row">
            <button mat-button class="btn-action" (click)="removeBroker(row)" matTooltip="Remove Broker from a Group">
              <mat-icon svgIcon="delete"></mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

      <div class="flex-row-between">
        <span class="not-found-text">{{notFoundMessage}}</span>
        <mat-paginator [length]="dataSource.totalRecords" [pageIndex]="pagingConstant.pageIndex"
          [pageSize]="pagingConstant.pageSizeLarger" [pageSizeOptions]="pageSizeOptions" showFirstLastButtons>
        </mat-paginator>
      </div>
    </mat-card-content>
  </mat-card>

</main>
