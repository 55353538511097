import { Directive, HostListener } from "@angular/core";
import { NgControl } from "@angular/forms";
import { Utility } from "src/app/utils/utility";

@Directive({
  selector: "input[type=tel]",
})
export class PhoneDirecive {
  constructor(private readonly ngControl: NgControl) {}

  @HostListener("window:keyup", ["$event"])
  keyup() {
    const formControl = this.ngControl.control;
    let original = this.ngControl.value as string;
    if (original) {
      original = original.split(" ").join("");
      const formatted = Utility.formatPhoneNumber(original);

      formControl.setValue(formatted);
    }
  }
}
