import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators, AbstractControl, FormGroup } from '@angular/forms';

import * as Messages from 'src/app/shared/config/messages';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { EmailTemplate } from 'src/app/models/email-template';
import { EmailTemplateResponse } from 'src/app/models/email-template-response';
import { EmailTemplateService } from 'src/app/services/email-template.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TitleMessages } from 'src/app/shared/constants/constants';
import { noWhitespace } from 'src/app/validators/no-whitespace.validator';

@Component({
  selector: 'app-email-template',
  templateUrl: './email-template.component.html',
  styleUrls: ['./email-template.component.scss']
})
export class EmailTemplateComponent {

  formGroup: FormGroup;

  emailTemplateResponse: EmailTemplateResponse;
  updateMode = false;

  constructor(
    public readonly dialogService: DialogService,
    private readonly emailTemplateService: EmailTemplateService,
    private readonly formBuilder: FormBuilder,
    public readonly dialogRef: MatDialogRef<EmailTemplateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    this.createForm();

    this.emailTemplateResponse = data.emailTemplateResponse;

    this.bindData();
  }

  public get body() {
    return this.formGroup.get('body') as AbstractControl;
  }

  public get description() {
    return this.formGroup.get('description') as AbstractControl;
  }

  public get footer() {
    return this.formGroup.get('footer') as AbstractControl;
  }

  public get name() {
    return this.formGroup.get('name') as AbstractControl;
  }

  public get subject() {
    return this.formGroup.get('subject') as AbstractControl;
  }

  public get typeName() {
    return this.formGroup.get('typeName') as AbstractControl;
  }

  markEditableFieldsAsPristine() {
      this.body.markAsPristine();
      this.description.markAsPristine();
      this.footer.markAsPristine();
      this.name.markAsPristine();
      this.subject.markAsPristine();
  }

  async save(isClose: boolean) {
    if (!this.formGroup.dirty) {
      this.dialogService.openSuccessDialog(
        {
          title: 'Information',
          message: 'There are no changes to save.',
          secondaryButtons: null
        });

      return;
    }

    const emailTemplate: EmailTemplate = {
      body: this.body.value,
      emailTemplateId: this.emailTemplateResponse.emailTemplateId,
      emailTemplateTypeId: this.emailTemplateResponse.emailTemplateTypeId,
      name: this.name.value,
      subject: this.subject.value
    };

    if (!!this.description.value && this.description.value.length > 0) {
      emailTemplate.description = this.description.value;
    }

    if (!!this.footer.value && this.footer.value.length > 0) {
      emailTemplate.footer = this.footer.value;
    }

    const confirmMessage = 'The email template has been updated successfully';

    this.emailTemplateService.update(emailTemplate).subscribe(emailTemplateResponse => {
      this.markEditableFieldsAsPristine();

      this.dialogService.openSuccessDialogConfirm(
        { title: TitleMessages.successTitle, message: confirmMessage }
        ).subscribe(result => {
          if (result) {
            if (isClose) {
              this.dialogRef.close(true);
            }
          }
        });

    }, httpErrorResponse => {
      this.dialogService.openErrorDialog({ title: 'Error', message: Messages.generalErrorMessage });
    });
  }

  onCloseDialog(isReload: boolean) {
    if (this.formGroup.dirty) {
      this.dialogService.openConfirmationDialog(
        {
          title: TitleMessages.confirmationTitle,
          message: 'Are you sure you want to continue. Your changes will be lost?'
        }).subscribe(result => {
          if (result) {
            this.dialogRef.close(isReload);
          }
        });
    } else {
      this.dialogRef.close(isReload);
    }
  }

  bindData() {
    this.body.setValue(this.emailTemplateResponse.body);
    this.description.setValue(this.emailTemplateResponse.description);
    this.footer.setValue(this.emailTemplateResponse.footer);
    this.name.setValue(this.emailTemplateResponse.name);
    this.subject.setValue(this.emailTemplateResponse.subject);
    this.typeName.setValue(this.emailTemplateResponse.emailTemplateTypeName);
  }

  createForm() {
    this.formGroup = this.formBuilder.group({
      body: ['', [Validators.required, noWhitespace]],
      description:['', [Validators.maxLength(100)]],
      footer: [],
      name: ['', [Validators.required, noWhitespace, Validators.maxLength(30)]],
      subject: ['', [Validators.required, noWhitespace, Validators.maxLength(60)]],
      typeName: [],
    });
  }
}
