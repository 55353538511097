import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { FormGroup, AbstractControl, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Status } from 'src/app/models/enums/status.enum';
import { BehaviorSubject, Subscription, combineLatest } from 'rxjs';
import { AddressService } from 'src/app/services/address.service';
import { tap, distinctUntilChanged } from 'rxjs/operators';
import { Broker } from 'src/app/models/broker';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpBaseService } from 'src/app/shared/services/http-base.service';
import { ApiEndPoints } from 'src/app/shared/config/api-end-points';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { ContactDetailComponent } from '../../contact-detail/contact-detail.component';
import { MessageForBroker, TitleMessages } from 'src/app/shared/constants/constants';
import * as _ from 'lodash';
import { noSpecialCharacterValidator, noSpecialCharacterValidatorForExternalReference } from 'src/app/validators/no-special-character.validator';
import { MatSelect } from '@angular/material/select';
import { ComponentCanDeactivate } from 'src/app/shared/can-deactivate/component-can-deactivate';
import { noWhitespace } from 'src/app/validators/no-whitespace.validator';
import { isValidPhoneNumber } from 'src/app/validators/phone-number.validator';
import { AddressInfoFormControlModel } from 'src/app/models/address-info-form-control-model';
import { isValidEmail } from 'src/app/validators/valid-email.validator';
import { urlValidator } from 'src/app/validators/url.validator';
import { ExternalBrokerType } from 'src/app/models/enums/external-broker-branch-type.enum';

@Component({
  selector: 'app-broker-details',
  templateUrl: './broker-details.component.html',
  styleUrls: ['./broker-details.component.scss']
})
export class BrokerDetailsComponent extends ComponentCanDeactivate  implements OnInit, OnDestroy {

  dataChanged = false;
  updateMode = false;
  brokerForm: FormGroup;
  broker: Broker;

  currentBroker: Broker;
  newBrokerId: any;

  updateBrokerId: string = '';

  ExternalBrokerType = ExternalBrokerType;
  emptyExternalType = 0;


  Status = Status;

  private paramsSubscription: Subscription;

  brokerLogoUrl: string = '';
  uploadLogoFile: File;

  reloadBrokerBranch$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);
  reloadBroker$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);

  @ViewChild('contactDetailFormComponent1', {static: true})
  contactDetailFormComponent1: ContactDetailComponent;

  @ViewChild('contactDetailFormComponent2', {static: true})
  contactDetailFormComponent2: ContactDetailComponent;

  get brokerId() {
    return this.brokerForm.get('brokerId') as AbstractControl;
  }

  get brokerName() {
    return this.brokerForm.get('brokerName') as AbstractControl;
  }

  get brokerAddress(){
    return this.brokerForm.get('address') as AddressInfoFormControlModel;
  }

  get phonenumber() {
    return this.brokerForm.get('phonenumber') as AbstractControl;
  }

  get email() {
    return this.brokerForm.get('email') as AbstractControl;
  }

  get logo() {
    return this.brokerForm.get('logo') as AbstractControl;
  }

  get website() {
    return this.brokerForm.get('website') as AbstractControl;
  }

  get status() {
    return this.brokerForm.get('status') as AbstractControl;
  }

  get isBranchDetail() {
    return this.brokerForm.get('isBranchDetail') as AbstractControl;
  }

  get externalType() {
    return this.brokerForm?.get('externalType') as AbstractControl;
  }

  get externalReference() {
    return this.brokerForm.get('externalReference') as AbstractControl;
  }

  get externalReferenceMaxLenghtMessage() {
    let maxLenght = this.externalType.value == ExternalBrokerType.Acturis ? 1000 : 20;
    return `${ExternalBrokerType[this.externalType.value]} Reference can be max ${maxLenght} characters long`;
  }

  constructor(private formBuilder: FormBuilder,
    private readonly _api: ApiEndPoints,
    private readonly addressService: AddressService,
    private readonly router: Router,
    private readonly _dialogService: DialogService,
    private readonly _httpService: HttpBaseService,
    private readonly activeRouter: ActivatedRoute) {
    super();
    this.createForm();
    this.paramsSubscription = combineLatest(this.activeRouter.params,
      (params) => ({ params })).subscribe(async allParams => {

        let params = allParams.params;
        const id = params['brokerId'];
        if (id !== undefined) {
          this.updateMode = true;
          this.updateBrokerId = id;
          this.reloadBroker$.next(true);
        }
      });
  }

  async ngOnInit() {

    this.isInvaliditySave();
    this.isInvaliditySaveAndClose();
    this.brokerForm.valueChanges.subscribe(() => {
      if(this.brokerForm.dirty) {
        this.dataChanged = true;
      }
    });

    this.reloadBroker$.subscribe(async s => {
      if (s) {
        await this.initDataForUpdateBroker(this.updateBrokerId ? this.updateBrokerId : this.newBrokerId);
      }
    });

    this.externalType.valueChanges.subscribe(val => {
      if (!val || val == this.emptyExternalType) {
        this.externalReference.setValue(null);
        this.externalReference.disable();
      }
      else {
        this.externalReference.enable();
      }

      if (val == ExternalBrokerType.Acturis) {
        this.externalReference.setValidators([Validators.maxLength(1000), noSpecialCharacterValidatorForExternalReference]);
      }
      else {
        this.externalReference.setValidators([Validators.maxLength(20), noSpecialCharacterValidatorForExternalReference]);
      }
      this.externalReference.updateValueAndValidity();
    });

  }

  ngOnDestroy(): void {
    this.paramsSubscription.unsubscribe();
  }

  async reloadBrokerOutput($event) {
    const broker = $event as Broker;
    await this.initDataForUpdateBroker(broker.id);
  }

  async initDataForUpdateBroker(id: string) {
    this.broker = await this._httpService.getDataAsync<Broker>(this._api.getBrokerByBrokerId(id));


    if (this.broker) {
      this.currentBroker = this.broker;
      this.brokerName.setValue(this.broker.name);
      this.brokerId.setValue(this.broker.originalBrokerId);
      this.brokerAddress.postcode.setValue(this.broker.postCode, { emitEvent: false });
      this.brokerAddress.address1.setValue(this.broker.address1);
      this.brokerAddress.address2.setValue(this.broker.address2);
      this.brokerAddress.address3.setValue(this.broker.address3);
      this.brokerAddress.address4.setValue(this.broker.address4);
      this.brokerAddress.address5.setValue(this.broker.address5);
      this.website.setValue(this.broker.website)
      this.status.setValue(this.broker.isActive ? 1 : 0);
      this.phonenumber.setValue(this.broker.telephoneNumber);
      let data = _.find(this.broker.branches, (x) => (x.brokerId === this.broker.id && x.originalBranchId === this.broker.originalBrokerId));
      if (data) {
        this.broker.isCreatingDefaultBranch = true;
        this.isBranchDetail.setValue(true);
        this.isBranchDetail.disable();
      }
      else {
        this.broker.isCreatingDefaultBranch = false;
        this.isBranchDetail.setValue(false);
      }

      this.email.setValue(this.broker.emailAddress);
      this.logo.setValue(this.broker.logo);
      this.brokerLogoUrl = this.broker.logo;

      if (this.broker.sspBrokerId) {
        this.externalType.setValue(ExternalBrokerType.Ssp);
        this.externalReference.setValue(this.broker.sspBrokerId);
      }
      else if (this.broker.openGiBrokerId) {
        this.externalType.setValue(ExternalBrokerType.OpenGi);
        this.externalReference.setValue(this.broker.openGiBrokerId);
      }
      else if (this.broker.acturisBrokerId) {
        this.externalType.setValue(ExternalBrokerType.Acturis);
        this.externalReference.setValue(this.broker.acturisBrokerId);
      }
      else if (this.broker.howdenBrokerId) {
        this.externalType.setValue(ExternalBrokerType.Howden);
        this.externalReference.setValue(this.broker.howdenBrokerId);
      }
      else {
        this.externalType.setValue(null);
        this.externalReference.setValue(null);
      }

      //Reset contact form
      this.contactDetailFormComponent1.resetForm();
      this.contactDetailFormComponent2.resetForm();

      if (this.broker.contacts && this.broker.contacts.length > 0) {
        this.contactDetailFormComponent1.contactName.patchValue(this.broker.contacts[0].name ? this.broker.contacts[0].name : '');
        this.contactDetailFormComponent1.jobTitle.patchValue(this.broker.contacts[0].jobTitle ? this.broker.contacts[0].jobTitle : '');
        this.contactDetailFormComponent1.contactPhoneNumber.patchValue(this.broker.contacts[0].phoneNumber ? this.broker.contacts[0].phoneNumber : '');
        this.contactDetailFormComponent1.emailAddress.patchValue(this.broker.contacts[0].email ? this.broker.contacts[0].email : '');
      }

      if (this.broker.contacts && this.broker.contacts.length > 1) {
        this.contactDetailFormComponent2.contactName.patchValue(this.broker.contacts[1].name ? this.broker.contacts[1].name : '');
        this.contactDetailFormComponent2.jobTitle.patchValue(this.broker.contacts[1].jobTitle ? this.broker.contacts[1].jobTitle : '');
        this.contactDetailFormComponent2.contactPhoneNumber.patchValue(this.broker.contacts[1].phoneNumber ? this.broker.contacts[1].phoneNumber : '');
        this.contactDetailFormComponent2.emailAddress.patchValue(this.broker.contacts[1].email ? this.broker.contacts[1].email : '');
      }
    }
    this.brokerForm.markAllAsTouched();
  }

  createForm() {
    this.brokerForm = this.formBuilder.group({
      brokerName: ['', [Validators.required, noWhitespace]],
      brokerId: ['', [Validators.required, noSpecialCharacterValidator]],
      possibleAddresses: [''],
      phonenumber: ['', [Validators.required, isValidPhoneNumber]],
      email: ['', [Validators.required, Validators.email, isValidEmail]],
      logo: ['', []],
      website: ['', [urlValidator]],
      status: [Status.Active],
      isBranchDetail: [false, []],
      isAddressFound: [true],
      address: new AddressInfoFormControlModel(null, this.addressService),
      externalType: [''],
      externalReference: [{value: null, disabled: true}, [Validators.maxLength(20) ,noSpecialCharacterValidatorForExternalReference]]
    });
  }


  onSelectFile(files: FileList) {

    if (files.length === 0) {
      return;
    }

    const fileToUpload = files.item(0);


    const fileReader: FileReader = new FileReader();
    fileReader.readAsDataURL(fileToUpload);

    fileReader.onload = (event: any) => {
      this.logo.setValue(fileToUpload.name);
      this.uploadLogoFile = fileToUpload;
    };

    this.brokerForm.markAsDirty();
  }

  async onSave() {
    await this.save(false);
  }

  async onSaveAndClose() {
    await this.save(true);
  }

  async uploadIcon(iconFile: File) {
    const isPrivate = false;
    const isOverriden = true;

    const url = await this._httpService.uploadAsync<string>(this._api.uploadFile(isPrivate, isOverriden), iconFile);
    return url;
  }

  async save(isClose: boolean) {
    if (this.brokerForm.valid) {
      let arrayContact = [];
      arrayContact = this.getContacts(this.updateMode);

      if (this.uploadLogoFile) {
        this.brokerLogoUrl = await this.uploadIcon(this.uploadLogoFile);
        this.logo.setValue(this.brokerLogoUrl);
      }
      else {
        this.brokerLogoUrl = this.updateMode ? this.broker.logo : undefined;
      }

      let broker: Broker = {
        id: this.updateMode ? this.broker.id : undefined,
        rowVersion: this.updateMode ? this.broker.rowVersion : undefined,
        name: this.brokerName.value,
        originalBrokerId: this.brokerId.value,
        postCode: this.brokerAddress.postcode.value,
        address1: this.brokerAddress.address1.value,
        address2: this.brokerAddress.address2.value,
        address3: this.brokerAddress.address3.value,
        address4: this.brokerAddress.address4.value,
        address5: this.brokerAddress.address5.value,
        telephoneNumber: this.phonenumber.value,
        emailAddress: this.email.value,
        branches: this.updateMode ? undefined : [],
        website: this.website.value,
        logo: this.brokerLogoUrl,
        isActive: this.status.value === 1 ? true : false,
        isCreatingDefaultBranch: this.isBranchDetail.value,
        brokerGroupId: this.updateMode ? this.broker.brokerGroupId : undefined,
        contacts: arrayContact,
        openGiBrokerId: this.externalType.value == ExternalBrokerType.OpenGi ? this.getExternalReferenceValue(this.externalReference.value) : null,
        sspBrokerId: this.externalType.value == ExternalBrokerType.Ssp ? this.getExternalReferenceValue(this.externalReference.value) : null,
        acturisBrokerId: this.externalType.value == ExternalBrokerType.Acturis ? this.getExternalReferenceValue(this.externalReference.value) : null,
        howdenBrokerId: this.externalType.value == ExternalBrokerType.Howden ? this.getExternalReferenceValue(this.externalReference.value) : null
      };

      if (!this.updateMode) {
        if(this.isBranchDetail.value == true){
          try {
            this.newBrokerId = await this._httpService.postDataAsync<any>(this._api.createBroker(), broker);
            this.currentBroker = broker;
            this.currentBroker.id = this.newBrokerId;
            this.showConfirmMessageWithBroker(isClose);

          }
          catch (err) { // process for buseness if Conflict
            if (err.status === 409) {
              this.brokerId.setValue('');
              this.brokerId.markAsTouched();
              this.brokerForm.markAsPristine();
            }
          }
        }

        if(this.isBranchDetail.value == false){
          try {
            this.newBrokerId = await this._httpService.postDataAsync<any>(this._api.createBroker(), broker);
            this.currentBroker = broker;
            this.currentBroker.id = this.newBrokerId;
            this.showConfirmMessage(isClose);

          }
          catch (err) { // process for buseness if Conflict
            if (err.status === 409) {
              this.brokerId.setValue('');
              this.brokerId.markAsTouched();
              this.brokerForm.markAsPristine();
            }
          }
        }


      } else {
        const updatedBroker = await this._httpService.putDataAsync<Broker>(this._api.updateBroker(), broker);
        this.newBrokerId = updatedBroker.id;
        this.currentBroker = updatedBroker;

        if (this.broker.contacts.length > broker.contacts.length) { // Delete contact
          let differences = _.differenceBy(this.broker.contacts, broker.contacts, 'id');
          for (var i = 0; i < differences.length; i++) {
            await this._httpService.deleteDataAsync<boolean>(this._api.deleteContact(differences[i].id));
          }
        }
        this.isBranchDetail.value == true ? this.showConfirmMessageWithBroker(isClose) : this.showConfirmMessage(isClose);
      }
      this.brokerForm.markAsPristine();
      this.contactDetailFormComponent1.contactDetailForm.markAsPristine();
      this.contactDetailFormComponent2.contactDetailForm.markAsPristine();
    }
    else {
      return;
    }
  }

  showConfirmMessage(isClose: boolean) {
    this._dialogService.openSuccessDialogConfirm({ title: TitleMessages.successTitle, message: this.updateMode ? MessageForBroker.messageUpdate : MessageForBroker.messageCreateBroker }).subscribe(result => {
      if (result) {
        if (!isClose) {
          this.updateMode = true;
          this.reloadBrokerBranch$.next(true);
          this.reloadBroker$.next(true);
        } else {
          this.router.navigate([`/app/brokers/broker`]);
        }
      }
    });
  }

  showConfirmMessageWithBroker(isClose: boolean){
    this._dialogService.openSuccessDialogConfirm({ title: TitleMessages.successTitle, message: this.updateMode ? MessageForBroker.messageUpdateWithBranch : MessageForBroker.messageCreate }).subscribe(result => {
      if (result) {
        if (!isClose) {
          this.updateMode = true;
          this.uploadLogoFile = null;
          this.reloadBrokerBranch$.next(true);
          this.reloadBroker$.next(true);
        } else {
          this.router.navigate([`/app/brokers/broker`]);
        }
      }
    });
  }

  onCancel() {
      this.router.navigate([`/app/brokers/broker`]);
  }

  canDeactivate(): boolean {
    return this.brokerForm.pristine && this.contactDetailFormComponent1.contactDetailForm.pristine &&
    this.contactDetailFormComponent2.contactDetailForm.pristine;
  }

  isInvaliditySave(): boolean {
    if (this.brokerForm.pristine && this.contactDetailFormComponent1.contactDetailForm.pristine && this.contactDetailFormComponent2.contactDetailForm.pristine) {
      return true;
    }

    if (this.contactDetailFormComponent1.contactDetailForm.dirty && this.contactDetailFormComponent2.contactDetailForm.dirty) {
      return this.brokerForm.invalid || this.contactDetailFormComponent1.contactDetailForm.invalid || this.contactDetailFormComponent2.contactDetailForm.invalid;
    }

    if (this.contactDetailFormComponent1.contactDetailForm.dirty) {
      return this.brokerForm.invalid || this.contactDetailFormComponent1.contactDetailForm.invalid;
    }

    if (this.contactDetailFormComponent2.contactDetailForm.dirty) {
      return this.brokerForm.invalid || this.contactDetailFormComponent2.contactDetailForm.invalid;
    }

    return this.brokerForm.invalid;
  }

  isInvaliditySaveAndClose(): boolean {
    return this.isBranchDetail.value ? this.isInvaliditySave() : true;
  }

  getContacts(updateMode: boolean) {
    let arrayContact = [];
    if (this.contactDetailFormComponent1.contactName.value && this.contactDetailFormComponent2.contactName.value) {
      arrayContact = [
        {
          id: updateMode && this.broker.contacts.length > 0 ? this.broker.contacts[0].id : undefined,
          rowVersion: updateMode && this.broker.contacts.length > 0 ? this.broker.contacts[0].rowVersion : undefined,
          name: this.contactDetailFormComponent1.contactName.value?.trim(),
          jobTitle: this.contactDetailFormComponent1.jobTitle.value?.trim(),
          phoneNumber: this.contactDetailFormComponent1.contactPhoneNumber.value?.trim(),
          email: this.contactDetailFormComponent1.emailAddress.value?.trim()
        },
        {
          id: updateMode && this.broker.contacts.length > 1 ? this.broker.contacts[1].id : undefined,
          rowVersion: updateMode && this.broker.contacts.length > 1 ? this.broker.contacts[1].rowVersion : undefined,
          name: this.contactDetailFormComponent2.contactName.value?.trim(),
          jobTitle: this.contactDetailFormComponent2.jobTitle.value?.trim(),
          phoneNumber: this.contactDetailFormComponent2.contactPhoneNumber.value?.trim(),
          email: this.contactDetailFormComponent2.emailAddress.value?.trim()
        }
      ];
    }
    else if (this.contactDetailFormComponent1.contactName.value) {
      arrayContact = [
        {
          id: updateMode && this.broker.contacts.length > 0 ? this.broker.contacts[0].id : undefined,
          rowVersion: updateMode && this.broker.contacts.length > 0 ? this.broker.contacts[0].rowVersion : undefined,
          name: this.contactDetailFormComponent1.contactName.value?.trim(),
          jobTitle: this.contactDetailFormComponent1.jobTitle.value?.trim(),
          phoneNumber: this.contactDetailFormComponent1.contactPhoneNumber.value?.trim(),
          email: this.contactDetailFormComponent1.emailAddress.value?.trim()
        }
      ];
    }
    else if (this.contactDetailFormComponent2.contactName.value) {
      arrayContact = [
        {
          id: updateMode && this.broker.contacts.length > 1 ? this.broker.contacts[1].id : undefined,
          rowVersion: updateMode && this.broker.contacts.length > 1 ? this.broker.contacts[1].rowVersion : undefined,
          name: this.contactDetailFormComponent2.contactName.value?.trim(),
          jobTitle: this.contactDetailFormComponent2.jobTitle.value?.trim(),
          phoneNumber: this.contactDetailFormComponent2.contactPhoneNumber.value?.trim(),
          email: this.contactDetailFormComponent2.emailAddress.value?.trim()
        }
      ];
    }

    return arrayContact;
  }

  getExternalReferenceValue(value: string) {
    return value ? value : null;
  }

  getFileName(url) {
    if (url) {
      let splits = url.split('/');
      return splits[splits.length - 1];
    }
    return '';
  }

}

