import { Component, OnInit, ViewChild, AfterViewInit, Input } from '@angular/core';
import * as _ from "lodash";
import { ApiEndPoints } from 'src/app/shared/config/api-end-points';
import { HttpBaseService } from 'src/app/shared/services/http-base.service';
import { PricingInformation } from 'src/app/models/pricing-item';
import { BROKER_ID, PagingConstant, NO_RESULTS_FOUND, EMPTY_GUID } from 'src/app/shared/constants/constants';
import { LevelOfCover } from 'src/app/models/level-of-cover';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { PricingDataSource } from 'src/app/models/pricing-datasource';
import { tap, merge } from 'rxjs/operators';
import { PricingService } from 'src/app/services/pricing.service';
import { PricingComponent } from './pricing/pricing.component';
import { FormBuilder, AbstractControl } from '@angular/forms';
import { Product } from 'src/app/models/product';
import { SubProduct } from 'src/app/models/sub-product';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialogConfig } from '@angular/material/dialog';
import { Utility } from 'src/app/utils/utility';
import { SharedSearchComponent } from 'src/app/shared/components/shared-search/shared-search.component';

@Component({
  selector: 'app-product-pricing',
  templateUrl: './product-pricing.component.html',
  styleUrls: ['./product-pricing.component.scss']
})
export class ProductPricingComponent implements OnInit, AfterViewInit {

  brokerId = BROKER_ID;
  displayedColumns: string[] = ['productName', 'subProductName', 'levelOfCoverName', 'priceType', 'brokerRate', 'brokerRateIncludeIPT', 'minimumSaleRate', 'maximumRetailRate' , 'underwriterRate', 'intermediaryCommission', 'effectiveDateFrom', 'effectiveDateTo', 'isActive', 'actions'];

  dataSource: PricingDataSource;
  totalRecords: number;
  pagingConstant = PagingConstant;


  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(SharedSearchComponent) sharedSearch: SharedSearchComponent;

  configPricingDialog = new MatDialogConfig();

  searchString: string;

  searchForm = this.formBuilder.group({
    isIncludedInactive: [false]
  });

  filterForm = this.formBuilder.group({
    productId: [''],
    subProductId: [''],
    levelOfCoverId: ['']
  });

  notfound: string = ''
  get notFoundMessage() {
    return this.notfound;
  }

  products: Product[] = [];
  subProducts: SubProduct[] = [];
  levelOfCovers: LevelOfCover[] = [];

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly _pricingService: PricingService,
    private readonly _api: ApiEndPoints,
    private readonly _httpService: HttpBaseService,
    private readonly _dialogService: DialogService) {

    this.configPricingDialog.disableClose = true;
    this.configPricingDialog.width = '1400px';
    this.configPricingDialog.height = '1000px';
  }

  get isIncludedInactive() {
    return this.searchForm.get('isIncludedInactive') as AbstractControl;
  }

  get productId() {
    return this.filterForm.get('productId') as AbstractControl;
  }

  get subProductId() {
    return this.filterForm.get('subProductId') as AbstractControl;
  }

  get levelOfCoverId() {
    return this.filterForm.get('levelOfCoverId') as AbstractControl;
  }

  async ngOnInit() {
    this.dataSource = new PricingDataSource(this._pricingService);
    await this.getProducts();
    await this.loadData(EMPTY_GUID, EMPTY_GUID, EMPTY_GUID);

    this.dataSource.pricings$.subscribe(pricings =>{
      this.checkNotFoundResults();
    });
  }


  checkNotFoundResults() {
    if (this.dataSource.totalRecords === 0) {
      this.notfound = NO_RESULTS_FOUND;
    } else {
      this.notfound = '';
    }
  }

  async loadData(productId: string, subProductId: string, levelOfCoverId: string, sortField: string = 'ProductName', sortDirection: string = 'asc') {
    let index = this.paginator? this.paginator.pageIndex: 0;
    let pageSize = this.paginator? this.paginator.pageSize: PagingConstant.pageSizeLarger;
    this.dataSource.getPricingPagingByFilters(productId, subProductId, levelOfCoverId, this.searchString, this.isIncludedInactive.value, sortField, sortDirection, index, pageSize);
    if (this.sharedSearch) this.sharedSearch.setSearchString(this.searchString);
  }

  async loadByProduct(productId: string) {
    this.paginator.pageIndex = 0;
    this.paginator.pageSize = PagingConstant.pageSizeLarger;
    this.loadData(productId, EMPTY_GUID, EMPTY_GUID);
  }

  async loadByProductAndSubProduct(productId: string, subProductId: string) {
    this.paginator.pageIndex = 0;
    this.paginator.pageSize = PagingConstant.pageSizeLarger;
    this.loadData(productId, subProductId, EMPTY_GUID);
  }

  async loadByProductAndSubProductAndLevelOfCover(productId: string, subProductId: string, levelOfCoverId: string) {
    this.paginator.pageIndex = 0;
    this.paginator.pageSize = PagingConstant.pageSizeLarger;
    this.loadData(productId, subProductId, levelOfCoverId);
  }

  ngAfterViewInit() {
    // reset the paginator after sorting
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    this.paginator.page
      .pipe(
        tap(() => this.getPricingsPage())
      )
      .subscribe();

    this.sort.sortChange
      .pipe(
        tap(() => this.getPricingsPage())
      )
      .subscribe();

    this.isIncludedInactive.valueChanges.subscribe(value => {
      this.paginator.pageIndex = 0;
      this.paginator.pageSize = PagingConstant.pageSizeOptionsLarger[0];
      this.processLoadData(this.productId.value, this.subProductId.value, this.levelOfCoverId.value);
    });

    this.productId.valueChanges.subscribe(async (id: string) => {
      let product = await this._httpService.getDataAsync<Product>(this._api.getProduct(id));
      this.subProducts = _.orderBy(product.subProducts,'name');
      this.levelOfCovers = [];
      this.subProductId.setValue('', {emitEvent: false});
      this.levelOfCoverId.setValue('', {emitEvent: false});
      if (id) {
        this.loadByProduct(id);
      } else {
        this.loadData(EMPTY_GUID, EMPTY_GUID, EMPTY_GUID);
      }
    });

    this.subProductId.valueChanges.subscribe((id: string) => {
      const selectedSubProduct = _.find(this.subProducts, (x) => x.id === id);
      this.levelOfCovers = _.orderBy(selectedSubProduct.levelOfCovers, 'name');
      this.levelOfCoverId.setValue('', { emitEvent: false });
      if (id) {
        this.loadByProductAndSubProduct(this.productId.value, id);
      }
    });

    this.levelOfCoverId.valueChanges.subscribe((id: string) => {
      if (id) {
        this.loadByProductAndSubProductAndLevelOfCover(this.productId.value, this.subProductId.value, id);
      }
    });
  }

  async getPricingsPage() {
    let fieldSort = this.sort.active.charAt(0).toUpperCase() + this.sort.active.slice(1);
    let direction = this.sort.direction;

    if (this.productId.value && this.subProductId.value && this.levelOfCoverId.value) {
      this.loadData(this.productId.value, this.subProductId.value, this.levelOfCoverId.value, fieldSort, direction);
    }
    else if (this.productId.value && this.subProductId.value) {
      this.loadData(this.productId.value, this.subProductId.value, EMPTY_GUID, fieldSort, direction);
    }
    else if (this.productId.value) {
      this.loadData(this.productId.value, EMPTY_GUID, EMPTY_GUID, fieldSort, direction);
    }
    else {
      this.loadData(EMPTY_GUID, EMPTY_GUID, EMPTY_GUID, fieldSort, direction);
    }
  }

  editPricing(pricing: PricingInformation) {
    this._dialogService.openComponentDialog(PricingComponent, { addPricing: false, pricingInformation: pricing, isIncludedInactive: this.isIncludedInactive.value }, this.configPricingDialog).subscribe(async result => {
      if (result) {
        await this.processLoadData(this.productId.value, this.subProductId.value, this.levelOfCoverId.value);
      }
    });
  }

  onValueChange(value: string) {
    this.searchString = value;
  }

  async search() {
    this.paginator.pageIndex = 0;
    await this.processLoadData(this.productId.value, this.subProductId.value, this.levelOfCoverId.value);
  }

  async processLoadData(productId: string, subProductId: string, levelOfCoverId: string) {
    if (productId && subProductId && levelOfCoverId) {
      await this.loadByProductAndSubProductAndLevelOfCover(productId, subProductId, levelOfCoverId);
    }
    else if (productId && subProductId) {
      await this.loadByProductAndSubProduct(productId, subProductId);
    }
    else if (productId) {
      await this.loadByProduct(productId);
    }
    else {
      await this.loadData(EMPTY_GUID, EMPTY_GUID, EMPTY_GUID);
    }
  }

  async getProducts() {
    this.products = await this._httpService.getDataAsync<Product[]>(this._api.getAllProducts());
    this.products = _.orderBy(this.products, 'name');
  }
}
