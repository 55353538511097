<div class="sub-product-popup">
  <h3 class="title">Sub Product Documents</h3>
  <span class="title-desciption">View documents here.</span>
  <mat-dialog-content class="mat-dialog-content">
    <div class="product-name"><span><strong>Product:</strong> {{product.name}}</span></div>
    <div class="product-name"><span><strong>Sub Product:</strong> {{subProduct.name}}</span></div>
    <table mat-table [dataSource]="documents">

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Document Type</th>
        <td mat-cell *matCellDef="let row">{{getDocumentTypeName(row.documentType)}}</td>
      </ng-container>

      <ng-container matColumnDef="fileName">
        <th mat-header-cell *matHeaderCellDef>File Name</th>
        <td mat-cell *matCellDef="let row">
          <a [class]="row.documentType !== documentType.Schedule? 'document-file' : ''" (click)="openDocument(row)">{{row.fileName}}</a>
        </td>
      </ng-container>

      <ng-container matColumnDef="dateUploaded">
        <th mat-header-cell *matHeaderCellDef>Date Uploaded</th>
        <td mat-cell *matCellDef="let row">{{row.createdOn | date: 'dd/MM/yyyy HH:mm'}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

  </mat-dialog-content>

  <mat-dialog-actions class="justify-items-end button-row ">
    <button mat-button class="btn-create mat-mdc-raised-button" (click)="close()" matTooltip="Close">
      Close
    </button>
  </mat-dialog-actions>
</div>
