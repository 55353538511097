<div>
  <form [formGroup]="form">
    <mat-form-field>
      <mat-label>{{isBrokerGroup?'Search existing broker group':'Search broker name'}}</mat-label>
      <input formControlName="searchControl" id="searchMenu" matInput
        placeholder="{{isBrokerGroup?'Search existing broker group':'Search broker name'}}"
        (keyup.enter)="search(searchControl.value)" [readonly]="isUpdateBrokerGroup">
      <button class="close-icon" *ngIf="!searchStringIsEmpty" (click)="reset()"
        type="button"><mat-icon>clear</mat-icon></button>
      <mat-icon class="mainsearch-icon" matSuffix svgIcon="magnify" (click)="search(searchControl.value)"></mat-icon>
    </mat-form-field>
  </form>
  <div class="search-result mat-elevation-z8" *ngIf="showResult">

    <table mat-table [dataSource]="results">
      <div class="btn-close-search">
        <mat-icon class="btn-close-search" svgIcon="close" (click)="closeSearchResult()"></mat-icon>
      </div>

      <ng-container matColumnDef="BrokerId">
        <mat-header-cell *matHeaderCellDef>Broker ID</mat-header-cell>
        <mat-cell *matCellDef="let element">{{element.originalBrokerId}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Name">
        <mat-header-cell *matHeaderCellDef>Broker Name</mat-header-cell>
        <mat-cell *matCellDef="let element">{{element.name}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="Postcode">
        <mat-header-cell *matHeaderCellDef>Postcode</mat-header-cell>
        <mat-cell *matCellDef="let element">{{element.postCode}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="AddressLine1">
        <mat-header-cell *matHeaderCellDef>Address Line 1</mat-header-cell>
        <mat-cell *matCellDef="let element">{{element.address1}} </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row matRipple *matRowDef="let row; columns: displayedColumns;"
        [ngClass]="{selected: (selectedBroker && selectedBroker.originalBrokerId === row.originalBrokerId)}"
        (click)="onRowClick(row)">
      </mat-row>
    </table>
  </div>
</div>