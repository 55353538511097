<mat-card >
  <h2 class="branch-info" *ngIf="brokerBranch">{{brokerBranch.name}} - {{brokerBranch.originalBranchId}}</h2>
  <mat-card-header class="card-header" *ngIf="brokerBranch">
    <mat-card-title>Branch Product Details</mat-card-title>
    <mat-card-subtitle>Assign and remove products and standard pricing to/from the branch.</mat-card-subtitle>
  </mat-card-header>
  <div *ngIf="doneLoadingProducts" class="select-all">
    <mat-checkbox [(ngModel)]="isSelectedAll" (click)="selectAll()">
      <strong>Select All Products</strong>
    </mat-checkbox>
  </div>
  <mat-card-content class="branch-product" >
    <div *ngFor="let product of allProducts" class="detail">
      <mat-checkbox [(ngModel)]="product.isAssigned" (click)="changeProduct(product)">
        {{ product.name}}
      </mat-checkbox>
    </div>
  </mat-card-content>
  <mat-card-actions *ngIf="doneLoadingProducts">
    <button mat-button mat-raised-button color="primary" (click)="onSave()" [disabled]="!selectedChange">Save</button>
    <button mat-button mat-raised-button color="primary" (click)="onSaveAndClose()" [disabled]="!selectedChange">Save &
      Close</button>
    <button mat-button mat-raised-button color="secondary" (click)="onCancel()">Cancel</button>
  </mat-card-actions>
  <br>
</mat-card>

<section *ngIf="doneLoadingProducts">
  <app-branch-pricing [brokerBranchId]="brokerBranchId" [products$]="assignedProducts$"></app-branch-pricing>
</section>
