import { Component, OnInit, ChangeDetectionStrategy, HostListener, Input, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { FormControl, FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { map } from 'rxjs/operators';
import { Broker } from 'src/app/models/broker';
import { BrokerService } from 'src/app/services/broker.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { KEY_CODE } from 'src/app/models/enums/key-codes.enum';
import { PagingConstant } from 'src/app/shared/constants/constants';
import { Utility } from 'src/app/utils/utility';


@Component({
  selector: 'app-search-broker',
  templateUrl: './search-broker.component.html',
  styleUrls: ['./search-broker.component.scss']
})
export class SearchBrokerComponent implements OnInit, AfterViewInit {
  results: Broker[] = [];

  searchTerm$ = new Subject<string>();
  searchTerm: string = '';
  displayedColumns: string[] = ['BrokerId', 'Name', 'Postcode', 'AddressLine1'];

  showResult = false;
  index = -1;
  searchPageSize = 1000;


  @Input() isBrokerGroup = false;
  @Input() isUpdateBrokerGroup = false;

  selectedBroker: Broker;
  @Output() selectedBrokerOutput = new EventEmitter<Broker>();

  private wasInside = false;

  viewResultInFlight$ = new BehaviorSubject<boolean>(false);

  form: FormGroup;

  constructor(private readonly brokerService: BrokerService, private readonly dialogService: DialogService, private readonly formBuilder: FormBuilder, ) {
    this.createForm();
  }

  get searchControl() {
    return this.form.get('searchControl') as AbstractControl;
  }

  get searchStringIsEmpty() {
    return Utility.isNullOrWhiteSpace(this.searchControl.value);
  }

  ngOnInit() {

  }

  createForm() {
    this.form = this.formBuilder.group({
      searchControl: [undefined]
    });
  }

  ngAfterViewInit(): void {
    if (this.searchControl) {
      this.searchControl.valueChanges.subscribe(x => {
        if (!x) {
          this.closeSearchResult();
        }
      });
    }
  }

  closeSearchResult() {
    this.index = -1;
    this.results = [];
    this.showResult = false;
  }

  search(query): void {
    this.viewResultInFlight$.next(true);
    this.closeSearchResult();
    this.brokerService.getBrokerList(query, true, 'Name', 'asc', PagingConstant.pageIndex, this.searchPageSize)
      .pipe(
        map(response => ({
          results: response,
        }))
      )
      .subscribe(results => {
        this.viewResultInFlight$.next(false);
        this.showResult = true;
        this.results = results.results.items;
      });
  }

  reset(){
    this.searchControl.setValue('');
  }

  onRowClick(broker: Broker) {
    this.selectedBroker = broker;
    this.selectedBrokerOutput.emit(this.selectedBroker);
    this.closeSearchResult();
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode === KEY_CODE.RIGHT_ARROW || event.keyCode === KEY_CODE.DOWN_ARROW) {
      this.increment();
    }

    if (event.keyCode === KEY_CODE.LEFT_ARROW || event.keyCode === KEY_CODE.UP_ARROW) {
      this.decrement();
    }

    if (event.keyCode === KEY_CODE.ESCAPE) {
      this.closeSearchResult();
    }

    if (event.keyCode === KEY_CODE.ENTER) {
      let index = this.index;
      if (this.showResult && index >= 0 && index < this.results.length) {

        this.selectedBroker = this.results[this.index];
        this.selectedBrokerOutput.emit(this.selectedBroker);

        this.closeSearchResult();

      }
    }
  }

  @HostListener('click')
  clickInside() {
    this.wasInside = true;
  }

  @HostListener('document:click')
  clickout() {
    if (!this.wasInside) {
      this.closeSearchResult();
    }
    this.wasInside = false;
  }

  increment() {
    if (this.index < this.results.length - 1) {
      this.index++;
    }
    this.selectedBroker = this.results[this.index];
  }

  decrement() {
    if (this.index > 0) {
      this.index--;
    }

    this.selectedBroker = this.results[this.index];
  }
}
