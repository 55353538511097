import { Injectable } from '@angular/core';
import { configuration } from 'src/environments/environment';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { Utility } from 'src/app/utils/utility';
import { ExportFileType } from 'src/app/models/enums/report-type.enum';

@Injectable({
    providedIn: 'root'
})
export class ApiEndPoints {

    constructor(private readonly configurationService: ConfigurationService) { }

    /*********** NOTIFICATION **************/
    sendAlert() {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/notifications`;
    }

    getNotifications(searchString: string, priority: string, includeInactive: boolean, sortField: string, sortDirection: string, pageIndex: number, pageSize: number) {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/notifications/${Utility.encodeSearchString(priority)}/${includeInactive}/${sortField}/${sortDirection}/${pageIndex}/${pageSize}?searchString=${Utility.encodeSearchString(searchString)}`;
    }

    resendNotifications() {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/notifications/remind`;
    }

    archiveNotification() {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/notifications`;
    }

    /************ REPORT *****************/
    getAmendmentsList(dateFrom: string, dateTo: string) {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/report/summary/amendments/${dateFrom}/${dateTo}`;
    }

    getDeclarationReportWithPaging(covers: string[], arrInsurerIds: string[], dateFrom: string, dateTo: string, sortField: string, sortDirection: string, pageIndex: number, pageSize: number) {

        return `${this.configurationService.apiBaseUrl}/api/v1/report/declaration?subProductIds=${covers}&insurerIds=${arrInsurerIds}&dateFrom=${dateFrom}&dateTo=${dateTo}&sortField=${sortField}&sortDirection=${sortDirection}&pageIndex=${pageIndex}&pageSize=${pageSize}`;
    }

    getLPGDriversClubDeclarationReportWithPaging(covers: string[], arrInsurerIds: string[], dateFrom: string, dateTo: string, isLoadTotal: boolean, sortField: string, sortDirection: string, pageIndex: number, pageSize: number) {

        return `${this.configurationService.apiBaseUrl}/api/v1/report/lpg-declaration?subProductIds=${covers}&insurerIds=${arrInsurerIds}&dateFrom=${dateFrom}&dateTo=${dateTo}&isLoadTotal=${isLoadTotal}&sortField=${sortField}&sortDirection=${sortDirection}&pageIndex=${pageIndex}&pageSize=${pageSize}`;
    }

    exportDeclarationReport(covers: string[], dateFrom: string, dateTo: string, providers: string[], insurerIds: string[], productIds: string[], exportFileType) {
        return `${this.configurationService.apiBaseUrl}/api/v1/report/declaration/export?subProductIds=${covers}&dateFrom=${dateFrom}&dateTo=${dateTo}&providers=${providers}&insurerIds=${insurerIds}&productIds=${productIds}&exportFileType=${exportFileType}`;
    }

    exportLPGDriversClubDeclarationReport(covers: string[], dateFrom: string, dateTo: string, providers: string[], insurerIds: string[], productIds: string[], exportFileType) {
        return `${this.configurationService.apiBaseUrl}/api/v1/report/lpg-declaration/export?subProductIds=${covers}&dateFrom=${dateFrom}&dateTo=${dateTo}&providers=${providers}&insurerIds=${insurerIds}&productIds=${productIds}&exportFileType=${exportFileType}`;
    }

    getUserAccessReportWithPaging(brokerGroupId: string, brokerId: string, brokerBranchId: string,
        noGroup: boolean,
        status: string,
        sortField: string,
        sortDirection: string, pageIndex: number, pageSize: number) {
        return `${this.configurationService.apiBaseUrl}/api/v1/report/user-access-reports?brokerGroupId=${brokerGroupId}&brokerID=${brokerId}&brokerBranchId=${brokerBranchId}&status=${status}&noGroup=${noGroup}&sortDirection=${sortDirection}&sortField=${sortField}&pageIndex=${pageIndex}&pageSize=${pageSize}`;
    }

    exportUserAccessReport(brokerGroupId: string, brokerId: string, brokerBranchId: string,
        noGroup: boolean, status: string, exportFileType) {
        return `${this.configurationService.apiBaseUrl}/api/v1/report/export/user-access-reports?brokerGroupId=${brokerGroupId}&brokerID=${brokerId}&brokerBranchId=${brokerBranchId}&status=${status}&noGroup=${noGroup}&exportFileType=${exportFileType}`;
    }


    getUserAccessReportFilterBrokerGroups() {
        return `${this.configurationService.apiBaseUrl}/api/v1/broker/filter-report/broker-groups`;
    }

    getUserAccessReportFilterBroker(brokerGroupId: string, isGroup: boolean) {
        return `${this.configurationService.apiBaseUrl}/api/v1/broker/filter-report/broker?brokerGroupId=${brokerGroupId}&isGroup=${isGroup}`;
    }

    getUserAccessReportFilterBrokerBranch(brokerId: string, brokerGroupId: string, isGroup: boolean) {
        return `${this.configurationService.apiBaseUrl}/api/v1/broker/filter-report/broker-branch?brokerId=${brokerId}&brokerGroupId=${brokerGroupId}&isGroup=${isGroup}`;
    }

    getBrokerBranchFilterByProductId(productId: string, brokerGroupId: string) {
        return `${this.configurationService.apiBaseUrl}/api/v1/product/${productId}/broker-branches?brokerGroupId=${brokerGroupId}`;
    }

    getProductCustomPricingReport() {
        return `${this.configurationService.apiBaseUrl}/api/v1/report/custom-pricings`;
    }

    getSubProductsCustomPricingReport(productId: string) {
        return `${this.configurationService.apiBaseUrl}/api/v1/sub-product/custom-pricings/${productId}`;
    }

    getBrokerGroupForClaimsMadeReport() {
        return `${this.configurationService.apiBaseUrl}/api/v1/broker/broker-groups/claims-made`;
    }

    getClaimsMadeReport(productId: string, brokerBranchIds: string[], brokerGroupId: string,
        subProductIds: string[],
        dateFrom: string,
        dateTo: string,
        sortField: string,
        sortDirection: string,
        pageIndex: number,
        pageSize: number) {
        return `${this.configurationService.apiBaseUrl}/api/v1/report/claims-made?productId=${productId}&subProductIds=${subProductIds}&brokerGroupId=${brokerGroupId}&brokerBranchIds=${brokerBranchIds}&dateFrom=${dateFrom}&dateTo=${dateTo}&sortField=${sortField}&sortDirection=${sortDirection}&pageIndex=${pageIndex}&pageSize=${pageSize}`;
    }

    exportClaimsMadeReport(productId: string, brokerBranchIds: string[], brokerGroupId: string,
        subProductIds: string[],
        dateFrom: string,
        dateTo: string,
        exportFileType: ExportFileType
    ) {
        return `${this.configurationService.apiBaseUrl}/api/v1/report/claims-made/export?productId=${productId}&subProductIds=${subProductIds}&brokerGroupId=${brokerGroupId}&brokerBranchIds=${brokerBranchIds}&dateFrom=${dateFrom}&dateTo=${dateTo}&exportFileType=${exportFileType}`;
    }

    exportExcessProtectionReportLine(productId: string, brokerBranchIds: string[], brokerGroupId: string,
        subProductIds: string[],
        dateFrom: string,
        dateTo: string,
        exportFileType: ExportFileType
    ) {
        return `${this.configurationService.apiBaseUrl}/api/v1/report/claims-made/excess-protection/export?productId=${productId}&subProductIds=${subProductIds}&brokerGroupId=${brokerGroupId}&brokerBranchIds=${brokerBranchIds}&dateFrom=${dateFrom}&dateTo=${dateTo}&exportFileType=${exportFileType}`;
    }

    exportHomeEmergencyReportLine(productId: string, brokerBranchIds: string[], brokerGroupId: string,
        subProductIds: string[],
        dateFrom: string,
        dateTo: string,
        exportFileType: ExportFileType
    ) {
        return `${this.configurationService.apiBaseUrl}/api/v1/report/claims-made/home-emergency/export?productId=${productId}&subProductIds=${subProductIds}&brokerGroupId=${brokerGroupId}&brokerBranchIds=${brokerBranchIds}&dateFrom=${dateFrom}&dateTo=${dateTo}&exportFileType=${exportFileType}`;
    }

    getProductClaimsMadeReport() {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/products/claims-made`;
    }


    getCustomPricingReport(productId: string, brokerGroupId, brokerBranchIds: string[],
        subProductIds: string[],
        levelOfCoverIds: string[],
        isCustom: boolean,
        sortField: string,
        sortDirection: string,
        pageIndex: number,
        pageSize: number) {
        let queryString = '';
        queryString = `brokerGroupId=${brokerGroupId}&brokerBranchIds=${brokerBranchIds}&subProductIds=${subProductIds}&levelOfCoverIds=${levelOfCoverIds}&isCustom=${isCustom}&sortDirection=${sortDirection}&sortField=${sortField}&pageIndex=${pageIndex}&pageSize=${pageSize}`
        return `${this.configurationService.apiBaseUrl}/api/v1/report/custom-pricings/${productId}?${queryString}`;
    }

    exportCustomPricingReport(productId: string,
        brokerGroupId: string, brokerBranchIds: string[],
        subProductIds: string[],
        levelOfCoverIds: string[],
        isCustom: boolean,
        sortField: string,
        sortDirection: string, exportFileType) {
        let queryString = '';
        queryString = `brokerGroupId=${brokerGroupId}&brokerBranchIds=${brokerBranchIds}&subProductIds=${subProductIds}&levelOfCoverIds=${levelOfCoverIds}&isCustom=${isCustom}&sortDirection=${sortDirection}&sortField=${sortField}&exportFileType=${exportFileType}`
        return `${this.configurationService.apiBaseUrl}/api/v1/report/custom-pricings/${productId}/export?${queryString}`;
    }

    getExternalBrokersForPolicyImportsReport() {
      return `${this.configurationService.apiBaseUrl}/api/v1/broker/external-brokers`;
    }

    getPolicyImportSummaryReport(brokers: string,
      dateFrom: string,
      dateTo: string) {
      return `${this.configurationService.apiBaseUrl}/api/v1/report/policies-imported?brokers=${brokers}&dateFrom=${dateFrom}&dateTo=${dateTo}`;
    }

    getPolicyImportReportWithPaging(brokers: string,
      dateFrom: string,
      dateTo: string, isOnlyError: boolean,
      pageIndex: number,
      pageSize: number ) {
      return `${this.configurationService.apiBaseUrl}/api/v1/report/policies-imported/broker?broker=${brokers}&dateFrom=${dateFrom}&dateTo=${dateTo}&isOnlyError=${isOnlyError}&pageIndex=${pageIndex}&pageSize=${pageSize}`;
    }

    getPolicyImportReport(brokers: string,
      dateFrom: string,
      dateTo: string, isOnlyError: boolean, type
    ) {
      return `${this.configurationService.apiBaseUrl}/api/v1/report/policies-imported/export?brokers=${brokers}&dateFrom=${dateFrom}&dateTo=${dateTo}&isOnlyError=${isOnlyError}&exportFileType=${type}`;
    }

    /*************PRICING****************/
    getCurrentPricingByLevelOfCover(levelOfCoverId: string, effectiveDate: string): string {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/pricing/level-of-cover/${levelOfCoverId}/effective-date/${effectiveDate}`;
    }

    getHistoryPricingsByLevelOfCoverId(levelOfCoverId: string, isIncludedInactive: boolean): string {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/pricing/level-of-cover/${levelOfCoverId}/${isIncludedInactive}`;
    }

    getLatestPricingByLevelOfCoverId(levelOfCoverId: string) {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/pricing/level-of-cover/${levelOfCoverId}`;
    }

    getPricingsPagingByFilters(productId: string, subProductId: string, levelOfCoverId: string, searchString: string, isIncludedInactive: boolean, sortField: string, sortDirection: string, pageIndex: number, pageSize: number) {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/pricing/product/${productId}/sub-product/${subProductId}/level-of-cover/${levelOfCoverId}/${isIncludedInactive}/${sortField}/${sortDirection}/${pageIndex}/${pageSize}?searchString=${Utility.encodeSearchString(searchString)}`;
    }

    getPricing(pricingId: string) {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/pricing/${pricingId}`;
    }

    createPricing() {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/pricing`;
    }

    updatePricing() {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/pricing`;
    }

    /*************  BROKER  **************/

    getBrokerList(searchString: string, isIncludedInactive: boolean, sortField: string, sortDirection: string, pageIndex: number, pageSize: number) {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/broker/${isIncludedInactive}/${sortField}/${sortDirection}/${pageIndex}/${pageSize}?searchString=${Utility.encodeSearchString(searchString)}`;
    }

    createBroker() {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/broker`;
    }

    updateBroker() {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/broker`;
    }

    getBrokerByBrokerId(brokerId: string) {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/broker/${brokerId}`;
    }

    deleteContact(contactId: string) {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/broker/contact/${contactId}`;
    }


    getBrokerBranchesByBrokerId(brokerId: string, searchString: string, isIncludedInactive: boolean, sortField: string, sortDirection: string, pageIndex: number, pageSize: number) {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/broker/${brokerId}/broker-branches/${isIncludedInactive}/${sortField}/${sortDirection}/${pageIndex}/${pageSize}?searchString=${Utility.encodeSearchString(searchString)}`;
    }


    getAllBrokerGroupPaging(searchString: string, isIncludedInactive: boolean, sortField: string, sortDirection: string, pageIndex: number, pageSize: number): string {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/broker/broker-group/${isIncludedInactive}/${sortField}/${sortDirection}/${pageIndex}/${pageSize}?searchString=${Utility.encodeSearchString(searchString)}`;
    }

    getBrokerGroup(brokerGroupId: string) {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/broker/broker-group/${brokerGroupId}`;
    }

    createBrokerGroup() {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/broker/broker-group/`;
    }

    updateBrokerGroup() {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/broker/broker-group/`;
    }

    removeBrokerFromABrokerGroup(brokerId: string, BrokerGroupId: string): string {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/broker/${brokerId}/broker-group/${BrokerGroupId}`;
    }

    searchBrokerGroup(query: any): string {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/broker/broker-groups?searchString=${Utility.encodeSearchString(query)}`
    }

    /***************BROKER BRANCH ***************/
    getHistoryBranchPricingsByLevelOfCoverId(brokerBranchId: string, levelOfCoverId: string, isIncludedInactive: boolean): string {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/broker-branch/${brokerBranchId}/pricings/level-of-cover/${levelOfCoverId}/${isIncludedInactive}`;
    }

    getPricingByLevelOfCoverIdForBranch(branchId: string, levelOfCoverId: string) {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/broker-branch/${branchId}/pricing/level-of-cover/${levelOfCoverId}`;
    }

    getPricingsPagingByFiltersForBranch(branchId: string, productId: string, subProductId: string, levelOfCoverId: string, searchString: string, isIncludedInactive: boolean, sortField: string, sortDirection: string, pageIndex: number, pageSize: number) {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/broker-branch/${branchId}/pricings/product/${productId}/sub-product/${subProductId}/level-of-cover/${levelOfCoverId}/${isIncludedInactive}/${sortField}/${sortDirection}/${pageIndex}/${pageSize}?searchString=${Utility.encodeSearchString(searchString)}`;
    }

    getCurrentPricingByLevelOfCoverForBranch(branchId: string, levelOfCoverId: string, effectiveDate: string): string {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/broker-branch/${branchId}/pricings/level-of-cover/${levelOfCoverId}/effective-date/${effectiveDate}`;
    }

    getPricingForBranch(branchId: string, pricingId: string) {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/broker-branch/${branchId}/pricing/${pricingId}`;
    }

    createBranchPricing(branchId: string) {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/broker-branch/${branchId}/pricing`;
    }

    updateBranchPricing(branchId: string) {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/broker-branch/${branchId}/pricing`;
    }

    getBrokerBranch(brokerBranchId: string) {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/broker-branch/${brokerBranchId}`;
    }

    createBrokerBranch() {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/broker-branch`;
    }

    updateBrokerBranch() {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/broker-branch`;
    }

    getBrokerBranchesList(searchString: string, isIncludedInactive: boolean, sortField: string, sortDirection: string, pageIndex: number, pageSize: number) {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/broker-branch/${isIncludedInactive}/${sortField}/${sortDirection}/${pageIndex}/${pageSize}?searchString=${Utility.encodeSearchString(searchString)}`;
    }

    /*********** PRODUCT ***********/
    getAllProducts() {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/products`;
    }

    getAllProductsPaging(isIncludedInactive: boolean, sortField: string, sortDirection: string, pageIndex: number, pageSize: number) {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/products/${isIncludedInactive}/${sortField}/${sortDirection}/${pageIndex}/${pageSize}`;
    }
    getProduct(productId: string) {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/products/${productId}`;
    }

    createProduct() {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/products`;
    }

    updateProduct() {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/products`;
    }

    deleteProduct(productId: string) {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/products/${productId}`;
    }

    getProductSourceTypes() {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/products/source-types`;
    }

    /******************* LEVEL OF COVER *******************/
    getLevelOfCoversPagingByFilters(productId: string, subProductId: string, loCId: string, searchString: string, isIncludedInactive: boolean, sortField: string, sortDirection: string, pageIndex: number, pageSize: number): string {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/product/${productId}/sub-product/${subProductId}/levels-of-cover/${loCId}/${isIncludedInactive}/${sortField}/${sortDirection}/${pageIndex}/${pageSize}?searchString=${Utility.encodeSearchString(searchString)}`;
    }

    getValidLevelOfCover(productId: string, validationType: string, startDate: string, conditions: any) {
        const jsonConditions = JSON.stringify(conditions);
        const encodedConditions = Utility.encodeBase64ParamValue(jsonConditions);
        const encodedType = Utility.encodeBase64ParamValue(validationType);
        const encodedStartDate = encodeURIComponent(startDate);
        return `${this.configurationService.apiBaseUrl}/api/v1/levels-of-cover/filteredProduct/${productId}?validationType=${encodedType}&startDate=${encodedStartDate}&conditions=${encodedConditions}`;
    }

    getLevelOfCover(levelOfCoverId: string) {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/levels-of-cover/${levelOfCoverId}`;
    }

    createLevelOfCover() {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/levels-of-cover/`;
    }

    updateLevelOfCover() {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/levels-of-cover/`;
    }

    getLevelOfCoverSourceTypes() {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/levels-of-cover/source-types`;
    }

    getLevelsOfCoverForProduct(productId: string) {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/product/${productId}/levels-of-cover`;
    }

    getLevelOfCoverValidationRulesForLevelOfCover(levelOfCoverId: string) {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/levels-of-cover/${levelOfCoverId}/validation-rules`;
    }

    getLevelOfCoverValidationRulesForProduct(productId: string) {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/product/${productId}/levels-of-cover/validation-rules`;
    }

    updateLevelOfCoverValidation() {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/levels-of-cover/validation-rules`;
    }

    createLevelOfCoverValidation() {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/levels-of-cover/validation-rules`;
    }

    getLevelOfCoverValidationsPagingByFilters(productId: string, subProductId: string, loCId: string, searchString: string, isIncludedInactive: boolean, sortField: string, sortDirection: string, pageIndex: number, pageSize: number): string {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/product/${productId}/sub-product/${subProductId}/levels-of-cover/${loCId}/validation-rules/${isIncludedInactive}/${sortField}/${sortDirection}/${pageIndex}/${pageSize}?searchString=${Utility.encodeSearchString(searchString)}`;
    }

    /************* BRANCH PRODUCT *************/
    getProductsByBrokerBranchId(brokerBranchId: string) {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/broker-branch/${brokerBranchId}/branch-products`;
    }

    assignBranchProducts(brokerBranchId: string) {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/broker-branch/${brokerBranchId}/branch-products/assign`;
    }

    /*************** STATIONARY MAINTENANCE ***************/
    getStationaryStatuses() {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/stationary/status`;
    }

    getStationariesRecords(isIncludeInactive: boolean, sortField: string, sortDirection: string, pageIndex: number, pageSize: number) {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/stationary/${isIncludeInactive}/${sortField}/${sortDirection}/${pageIndex}/${pageSize}`;
    }

    getStationary(stationaryId: string) {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/stationary/${stationaryId}`;
    }

    createStationary() {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/stationary`;
    }

    updateStationary() {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/stationary`;
    }

    /*************** SYSTEM SETTING ***************/
    getValidIptRate(effectiveDate: string): string {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/settings/ipt-rate/${effectiveDate}`;
    }

    getIptRates(isIncludedInactiveRecords: boolean): string {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/settings/ipt-rates/${isIncludedInactiveRecords}`;
    }

    createIptRateSetting(isUpdatePricing: boolean): string {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/settings/ipt-rates/${isUpdatePricing}`;
    }

    updateIptRateSetting(): string {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/settings/ipt-rates`;
    }

    getSystemSetting() {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/settings/system`;
    }

    SaveSystemSetting() {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/settings/system`;
    }

    getAuthorizedNavItems() {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/action-roles/authorized-nav-items`;
    }

    /*************  SUB-PRODUCT  **************/
    getSubProduct(id: string): string {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/sub-product/${id}`;
    }

    getAllSubProducts(): string {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/sub-product/getAll`;
    }

    createSubProduct() {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/sub-product`;
    }

    updateSubProduct() {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/sub-product`;
    }

    /*************  INSURER  **************/
    getProviderDeclarations() {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/insurers/providers-declaration`;
    }

    getInsurerDeclarations(provider: string[]) {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/insurers/declaration?providers=${provider}`;
    }

    getInsurerProductDeclaration(insurerIds: string[]) {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/products/declaration?insurerIds=${insurerIds}`;
    }

    getSubProductDeclaration(productIds: string[], insurerIds: string[]) {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/sub-product/declaration?productIds=${productIds}&insurerIds=${insurerIds}`;
    }

    getLatestInsurerBySubProduct(subProductId: string): string {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/sub-product/${subProductId}/insurer`;
    }

    getAllInsurerPaging(searchString: string, subProductId: string, isIncludedInactiveRecords: boolean, sortField: string, sortDirection: string, pageIndex: number, pageSize: number): string {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/insurers/sub-product/${subProductId}/${isIncludedInactiveRecords}/${sortField}/${sortDirection}/${pageIndex}/${pageSize}?searchString=${Utility.encodeSearchString(searchString)}`;
    }

    createInsurers() {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/insurers/`;
    }

    updateInsurers() {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/insurers/`;
    }

    getProvidersForInsurer(): string {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/insurers/providers`;
    }

    /*************  Audit  **************/
    searchAuditEntity(entity: string, searchTerm: string) {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/audit/${entity}?searchTerm=${Utility.encodeSearchString(searchTerm)}`;
    }

    getSpecificAuditHistory(id: string, description: string) {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/audit/details/${id}/${description}`;
    }

    getReportHistories(pageIndex: number, pageSize: number) {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/audit/report-history/${pageIndex}/${pageSize}`;
    }

    exportPaymentFile(historyItemId: string) {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/audit/report-history/export-payment/${historyItemId}`;
    }

    getARAGDeclarationFromHistory(historyItemId: string) {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/audit/report-history/arag-declaration/${historyItemId}`;
    }

    exportARAGDeclarationFromHistory(historyItemId: string, type: ExportFileType) {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/audit/report-history/export-arag-declaration/${historyItemId}?exportFileType=${type}`;
    }

    getLPGDeclarationFromHistory(historyItemId: string) {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/audit/report-history/lpg-declaration/${historyItemId}`;
    }

    exportLPGDeclarationFromHistory(historyItemId: string, type: ExportFileType) {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/audit/report-history/export-lpg-declaration/${historyItemId}?exportFileType=${type}`;
    }

    uploadDeclarationReport(historyId: string, covers: string[], provider: string[], insurerIds: string[], productIds: string[]) {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/audit/report-history/upload/${historyId}?subProductIds=${covers}&provider=${provider}&insurerIds=${insurerIds}&productIds=${productIds}`;
    }

    deleteHistoryItem(historyItemId: string) {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/audit/report-history/${historyItemId}`;
    }
    /****************** DOCUMENT *****************/
    getDocumentTypes() {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/document/document-types`;
    }

    uploadFile(isPrivate: boolean, isOverriden: boolean) {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/document/upload/${isPrivate}/${isOverriden}`;
    }

    downloadFile(fileName: string) {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/document/download?fileName=${Utility.encodeSearchString(fileName)}`;
    }

    ckEditorUploadFile(isPrivate: boolean, isOverriden: boolean) {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/document/ckeditor-upload/${isPrivate}/${isOverriden}`;
    }

    createDocuments() {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/document`;
    }

    updateDocument() {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/document`;
    }

    getDocumentBySubProductAndDocumentType(subProductId: string, documentType: string) {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/document/sub-product/${subProductId}/document-type/${documentType}`;
    }

    /*************** EMAIL TEAMPLATE ***********/
    updateEmailTemplate() {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/email-template`;
    }

    getEmailTemplates() {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/email-template`;
    }

    getEmailTemplateTypes() {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/email-template/types`;
    }

    /*************** USER *******************/

    getUsersPaging(query: string, isIncludedInactive: boolean, sortField: string, sortDirection: string, pageIndex: number, pageSize: number): string {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/users/${isIncludedInactive}/${sortField}/${sortDirection}/${pageIndex}/${pageSize}?searchString=${Utility.encodeSearchString(query)}`;
    }

    getUsersPagingByRoleId(roleId: string, query: string, isIncludedInactive: boolean, sortField: string, sortDirection: string, pageIndex: number, pageSize: number): string {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/users/role/${roleId}/${isIncludedInactive}/${sortField}/${sortDirection}/${pageIndex}/${pageSize}?searchString=${Utility.encodeSearchString(query)}`;
    }

    getSystemRoles() {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/users/system-roles`;
    }

    createUser() {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/users`;
    }

    getUserById(userId: string) {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/users/${userId}`;
    }

    getUserInfo() {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/users/info/${this.configurationService.clientId}`;
    }

    signOut() {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/users/sign-out`;
    }

    /************ POSTCODE *************/
    getAddressInformationByPostcode(postcode: string) {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/postcode/${postcode}`;
    }

    /************ POLICY *************/
    getPolicyByCertificate(certificateNumber: string): string {
        return `${this.configurationService.apiBaseUrl}/api/v1/policy/${certificateNumber}`;
    }

    updatePolicyInceptionDate(policyId: string) {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/policy/${policyId}`;
    }

}
