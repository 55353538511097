import { Component, Input, AfterViewInit, OnInit } from '@angular/core';
import { FormGroup, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { isValidEmail } from 'src/app/validators/valid-email.validator';
import { LeiConfig } from 'src/app/shared/constants/constants';
import { Utility } from 'src/app/utils/utility';
import { combineLatest } from 'rxjs';
import { noWhitespace } from 'src/app/validators/no-whitespace.validator';
import { MaxLength } from 'src/app/shared/constants/maxlength';

@Component({
  selector: 'app-contact-detail',
  templateUrl: './contact-detail.component.html',
  styleUrls: ['./contact-detail.component.scss']
})
export class ContactDetailComponent implements AfterViewInit {
  contactDetailForm: FormGroup;
  maxLength = MaxLength.contactDetailForm;
  @Input() keyContact:string
  @Input() isExpand:boolean


  get contactName() {
    return this.contactDetailForm.get('contactName') as AbstractControl;
  }

  get jobTitle() {
    return this.contactDetailForm.get('jobTitle') as AbstractControl;
  }

  get contactPhoneNumber() {
    return this.contactDetailForm.get('contactPhoneNumber') as AbstractControl;
  }

  get emailAddress() {
    return this.contactDetailForm.get('emailAddress') as AbstractControl;
  }

  constructor(private formBuilder: FormBuilder) {
    this.createForm();
  }

  createForm() {
    this.contactDetailForm = this.formBuilder.group({
      contactName: ['', [noWhitespace,Validators.maxLength(this.maxLength.name)]],
      jobTitle: ['', [Validators.maxLength(this.maxLength.jobTitle)]],
      contactPhoneNumber: ['',[Validators.maxLength(this.maxLength.phone)]],
      emailAddress: ['', [isValidEmail,Validators.maxLength(this.maxLength.email)]],
    });
  }

  resetForm() {
    this.contactName.setValue("");
    this.jobTitle.setValue("", { emitEvent: false });
    this.emailAddress.setValue("", { emitEvent: false });
    this.contactPhoneNumber.setValue("", { emitEvent: false });
  }

  ngAfterViewInit() {
    // check valueChanges of each control to work properly for multiple component active
    this.contactName.valueChanges
      .pipe(
        debounceTime(LeiConfig.delayBeforeSearchingWithAutoSearchFields),
        distinctUntilChanged()
      )
      .subscribe(() => { this.setValidatorForm();});

    this.jobTitle.valueChanges
      .pipe(
        debounceTime(LeiConfig.delayBeforeSearchingWithAutoSearchFields),
        distinctUntilChanged()
      )
      .subscribe(() => { this.setValidatorForm();});

    this.contactPhoneNumber.valueChanges
      .pipe(
        debounceTime(LeiConfig.delayBeforeSearchingWithAutoSearchFields),
        distinctUntilChanged()
      )
      .subscribe(() => { this.setValidatorForm();});

    this.emailAddress.valueChanges
      .pipe(
        debounceTime(LeiConfig.delayBeforeSearchingWithAutoSearchFields),
        distinctUntilChanged()
      )
      .subscribe(() => { this.setValidatorForm(); });
      this.contactName.setValue("");
      this.jobTitle.setValue("");
      this.emailAddress.setValue("");
      this.contactPhoneNumber.setValue("");
  }

  setValidatorForm()
  {
    if ((this.contactName.value != null && this.contactName.value !== "") ||
      (this.jobTitle.value != null && this.jobTitle.value !== "") ||
      (this.emailAddress.value != null && this.emailAddress.value !== "") ||
      (this.contactPhoneNumber.value != null && this.contactPhoneNumber.value !== "")) {
      this.contactName.setValidators([noWhitespace,Validators.maxLength(100),Validators.required]);
      this.contactName.setValue(this.contactName.value, { emitEvent: false});
    } else {
      this.contactName.setValidators([noWhitespace,Validators.maxLength(100),]);
      this.contactName.setValue(this.contactName.value, { emitEvent: false });
      if (
        (this.contactName.value == null || this.contactName.value === "") &&
        (this.jobTitle.value == null || this.jobTitle.value === "") &&
        (this.emailAddress.value == null ||this.emailAddress.value === "") &&
        (this.contactPhoneNumber.value == null ||this.contactPhoneNumber.value === "")
      ) {
        this.contactName.clearValidators();
        this.contactName.setErrors(null);
      }
    }

  }
  formatPhoneNumber() {
    if (this.contactPhoneNumber.value) {
      let original = this.contactPhoneNumber.value as string;
      original = original.split(' ').join('');
      const formatted = Utility.formatPhoneNumber(original);
      if (original === formatted) {
        this.contactPhoneNumber.setErrors({ incorrect: true });
      }
      else {
        this.contactPhoneNumber.setValue(formatted);
        this.contactPhoneNumber.setErrors(null);
      }
    }
  }
}
