<div class="sub-product-popup">
  <h3 class="title" *ngIf="!updateMode">Create New Sub Product</h3>
  <h3 class="title" *ngIf="updateMode">Edit Sub Product</h3>
  <mat-dialog-content class="mat-dialog-content">
    <span *ngIf="saveSuccessMessage" class="success-message">{{ saveSuccessMessage }}</span>
    <form [formGroup]="form">
      <section>
        <mat-form-field class="sub-product-name">
          <mat-label>Sub Product Name</mat-label>
          <input [required] matInput data-test="name" placeholder="Sub Product Name" formControlName="name" TitleCase
            maxlength="250">
          <mat-error *ngIf="name.errors?.required || name.errors?.whitespace">Sub Product Name is required</mat-error>
        </mat-form-field>

        <mat-form-field class="order">
          <mat-label>Order</mat-label>
          <input matInput type="number" data-test="subProductOrder" spellcheck="false" matInput min="1" step="1" maxlength="2"  
            onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode > 47 && event.charCode <= 57"
            placeholder="Order" formControlName="order" (blur)="checkOrderValue()">
          <mat-error *ngIf="order.invalid">Order is required</mat-error>
        </mat-form-field>

        <mat-form-field class="status">
          <mat-label>Select status from dropdown list</mat-label>
          <mat-select data-test="subProductStatus" required placeholder="Select status from dropdown list"
            formControlName="status">
            <mat-option *ngFor="let status of statuses" [value]="status.value">
              {{status.name}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="status.invalid">Status is required</mat-error>
        </mat-form-field>
      </section>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions class="justify-items-end button-row">
    <button mat-button mat-raised-button color="primary" [disabled]="!form.valid || form.pristine"
      (click)="onSave()">{{updateMode?'Update':'Save'}}</button>
    <button mat-button mat-raised-button color="secondary" (click)="onCloseDialog(false)">Cancel</button>
  </mat-dialog-actions>
</div>