import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { CollectionViewer } from '@angular/cdk/collections';
import { catchError, finalize } from 'rxjs/operators';
import { UserService } from '../services/user.service';
import { PagingConstant } from '../shared/constants/constants';
import { UserDetail } from './user.model';
import { PaginationResponse } from './pagination-response';

export class UserDataSource implements DataSource<UserDetail> {
  private usersSubject = new BehaviorSubject<UserDetail[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public totalRecords:number;

  public loading$ = this.loadingSubject.asObservable();
  public usersSubject$ = this.usersSubject.asObservable();

  constructor(private userService: UserService) {}

  connect(collectionViewer: CollectionViewer): Observable<UserDetail[]> {
    return this.usersSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.usersSubject.complete();
    this.loadingSubject.complete();
  }

  getUsers(
    query: string,
    isIncludedInactive: boolean,
    sortField: string,
    sortDirection: string,
    pageIndex = PagingConstant.pageIndex,
    pageSize = PagingConstant.pageSize
  ) {
    this.loadingSubject.next(true);

    this.userService
      .getUsers(
        query,
        isIncludedInactive,
        sortField,
        sortDirection,
        pageIndex,
        pageSize
      )
      .pipe(
        catchError(() => of([])),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe((users) => {
        var rs = users as PaginationResponse<UserDetail>;
        this.totalRecords = rs.total;
        this.usersSubject.next(rs.items as any);
      });
  }

  getUsersByRole(
    roleId: string,
    query: string,
    isIncludedInactive: boolean,
    sortField: string,
    sortDirection: string,
    pageIndex = PagingConstant.pageIndex,
    pageSize = PagingConstant.pageSize
  ) {
    this.loadingSubject.next(true);

    this.userService
      .getUsersByRole(
        roleId,
        query,
        isIncludedInactive,
        sortField,
        sortDirection,
        pageIndex,
        pageSize
      )
      .pipe(
        catchError(() => of([])),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe((users) => {
        var rs = users as PaginationResponse<UserDetail>;
        this.totalRecords = rs.total;
        this.usersSubject.next(rs.items as any);
      });
  }
}
