export enum ProductType {
  CommercialLegalExpenses = 1,
  LandlordsLegalExpenses = 2,
  HomeEmergencySolutions = 3,
  MotorLegalExpenses = 4,
  GuaranteedReplacementVehicle = 5,
  BreakdownCover = 6,
  ExcessProtection = 7,
  PremierFamilyLegalExpenses = 8,
  TaxiClub = 9,
  GuaranteedTaxiHireVehicle = 10,
  DriversClub = 11,
  LloydLatchfordDrivingSchoolMotorLegalExpenses = 12,
  LloydLatchfordCombinedMotorLegalExpenses= 13,
  HowdenMotorLegalExpenses = 14,
  FreewayCompleteCover = 15,
  MotorcycleLegalExpenses = 16,
  HowdenTaxiCompleteHire = 17,
  MLEIFastTrackLevel1 = 18,
  MLEIFastTrackLevel2 = 19
}
