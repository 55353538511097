import { Injectable } from '@angular/core';
import { ApiEndPoints } from '../shared/config/api-end-points';
import { PagingConstant } from '../shared/constants/constants';
import { Observable } from 'rxjs';
import { Broker } from '../models/broker';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { HttpBaseService } from '../shared/services/http-base.service';
import { PaginationResponse } from '../models/pagination-response';

@Injectable({
  providedIn: "root",
})
export class BrokerService {
  constructor(
    private http: HttpClient,
    private api: ApiEndPoints,
    private readonly _httpService: HttpBaseService
  ) {}

  private searchStringBrokerList: string;
  private searchStringBrokerBranchList: string;

  getBrokerList(
    searchString: string,
    isIncludeInactive: boolean,
    sortField: string,
    sortDirection = "asc",
    pageIndex = PagingConstant.pageIndex,
    pageSize = PagingConstant.pageSize
  ): Observable<PaginationResponse<Broker>> {
    return this.http
      .get<PaginationResponse<Broker>>(
        this.api.getBrokerList(
          searchString,
          isIncludeInactive,
          sortField,
          sortDirection,
          pageIndex,
          pageSize
        )
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  getBrokerByBrokerId(brokerId: string) {
    return this.http.get<Broker[]>(this.api.getBrokerByBrokerId(brokerId)).pipe(
      map((res) => {
        return res;
      })
    );
  }

  createBrokerDetail(isCreatingDefaultBranch: boolean, broker: any) {
    return this._httpService.postDataAsync<Broker>(this.api.createBroker(), {
      isCreatingDefaultBranch,
      broker,
    });
  }

  updateBrokerDetail(data: any) {
    return this._httpService.putDataAsync<Broker>(
      this.api.updateBroker(),
      data
    );
  }

  public setSearchStringBrokerList(searchString) {
    this.searchStringBrokerList = searchString;
  }

  public getSearchStringBrokerList() {
    return this.searchStringBrokerList;
  }

  public setSearchStringBrokerBranchList(searchString) {
    this.searchStringBrokerBranchList = searchString;
  }

  public getSearchStringBrokerBranchList() {
    return this.searchStringBrokerBranchList;
  }
}
