<div class="message">
	<h4>
		{{message1}}
	</h4>
	<br />
	<h4>
		{{message2}}
	</h4>
	<br />
	<h4>
		{{message3}}
	</h4>
	<p>Try to reload the page after {{ countdown }} seconds...</p>
</div>