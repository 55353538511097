import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import * as _ from 'lodash';
import { ApiEndPoints } from 'src/app/shared/config/api-end-points';
import { HttpBaseService } from 'src/app/shared/services/http-base.service';
import { PagingConstant, NO_RESULTS_FOUND, EMPTY_GUID } from 'src/app/shared/constants/constants';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { tap } from 'rxjs/operators';
import { InsurerDataSource } from 'src/app/models/insurer-datasource';
import { InsurerService } from 'src/app/services/insurer.service';
import { InsurerItem } from 'src/app/models/insurer';
import { InsurerPopupComponent } from './insurer-popup/insurer-popup.component';
import { Validators, FormBuilder, AbstractControl } from '@angular/forms';
import { MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SubProduct } from 'src/app/models/sub-product';
import { Subscription } from 'rxjs';
import { SharedSearchComponent } from 'src/app/shared/components/shared-search/shared-search.component';


@Component({
  selector: 'app-insurer',
  templateUrl: './insurer.component.html',
  styleUrls: ['./insurer.component.scss']
})

export class InsurerComponent implements OnInit, AfterViewInit, OnDestroy {

  displayedColumns: string[] = ['providerName', 'subProductName', 'value', 'masterCertificate', 'schemeCode', 'effectiveDateFrom', 'effectiveDateTo', 'updatedBy', 'isActive', 'actions'];

  dataSource: InsurerDataSource;
  totalRecords: number;

  subProducts: SubProduct[] = [];
  configDialog = new MatDialogConfig();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(SharedSearchComponent) sharedSearch: SharedSearchComponent;

  pagingConstant = PagingConstant;

  searchString: string;
  subProductChange: Subscription;

  searchInsurerForm = this.formBuilder.group({
    isIncludedInactive: [false, [Validators.required]]
  });

  subProductForm = this.formBuilder.group({
    subProduct: ['']
  });

  notfound: string = '';

  get notFoundMessage() {
    return this.notfound;
  }

  get isIncludedInactive() {
    return this.searchInsurerForm.get('isIncludedInactive') as AbstractControl;
  }

  get subProduct() {
    return this.subProductForm.get('subProduct') as AbstractControl;
  }

  constructor(
    private readonly _insurerService: InsurerService,
    private readonly _api: ApiEndPoints,
    private readonly _httpService: HttpBaseService,
    private readonly _dialogService: DialogService,
    private readonly formBuilder: FormBuilder) {
    this.configDialog.disableClose = true;
    this.configDialog.width = '1200px';
  }

  async ngOnInit() {
    this.dataSource = new InsurerDataSource(this._insurerService);
    await this.loadData();
    this.dataSource.InsurersSubject$.subscribe(i => {
      this.checkNotFoundResults();
    });

    await this.loadSubProductData();

    this.subProductChange = this.subProduct.valueChanges.subscribe( () => {
      this.paginator.pageIndex = 0;
      this.loadData();
    });
  }

  async loadSubProductData() {
    this.subProducts = await this._httpService.getDataAsync<SubProduct[]>(this._api.getAllSubProducts());
  }

  checkNotFoundResults() {
    if (this.dataSource.totalRecords === 0) {
      this.notfound = NO_RESULTS_FOUND;
    } else {
      this.notfound = '';
    }
  }

  async loadData(sortField: string = 'SubProductName', sortDirection: string = 'asc') {
    let index = this.paginator? this.paginator.pageIndex: 0;
    let pageSize = this.paginator? this.paginator.pageSize: PagingConstant.pageSizeLarger;
    let subProdValue = this.subProduct.value || EMPTY_GUID;
    this.dataSource.getInsurers(this.searchString, subProdValue, this.isIncludedInactive.value, sortField, sortDirection, index, pageSize);
    if (this.sharedSearch) this.sharedSearch.setSearchString(this.searchString);
  }

  ngAfterViewInit() {
    // reset the paginator after sorting
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    this.paginator.page
      .pipe(
        tap(() => this.getInsurerPage())
      )
      .subscribe();

    this.sort.sortChange
      .pipe(
        tap(() => this.getInsurerPage())
      )
      .subscribe();

    this.isIncludedInactive.valueChanges.subscribe(value => {
      this.paginator.pageIndex = 0;
      this.paginator.pageSize = PagingConstant.pageSizeOptionsLarger[0];
      this.search();
    })
  }

  getInsurerPage() {
    const fieldSort = this.sort.active.charAt(0).toUpperCase() + this.sort.active.slice(1);
    const direction = this.sort.direction;
    this.loadData(fieldSort, direction);
  }

  editInsurer(insurer: InsurerItem) {
    this._dialogService.openComponentDialog(InsurerPopupComponent, { Insurer: insurer }, this.configDialog).subscribe(async result => {
      if (result) {
        await this.loadData();
      }
    });
  }

  createInsurer() {
    this._dialogService.openComponentDialog(InsurerPopupComponent, {
    }, this.configDialog).subscribe(async result => {
      if (result) {
        await this.loadData();
      }
    });
  }

  onValueChange(value: string) {
    this.searchString = value?.trim();
  }

  async search() {
    this.paginator.pageIndex = 0;
    await this.loadData();
  }

  ngOnDestroy(){
    if(this.subProductChange){
      this.subProductChange.unsubscribe();
    }
  }
}

