<form (ngSubmit)="onSubmit()">
  <div class="mat-subtitle-1">{{title}}</div>

  <mat-form-field class="inline-edit-form-field">
    <span matPrefix class="mat-income-prefix">£ </span>
    <input matInput maxLength="10" name="newValue" [(ngModel)]="newValue" [formControl]="inputControl">
    <!-- <mat-hint align="end">{{newValue?.length || 0}}/10</mat-hint> -->
    <mat-error *ngIf="inputControl.hasError('required')">
      Input value is <strong>required</strong>!
    </mat-error>
    <mat-error *ngIf="inputControl.hasError('pattern') && !inputControl.errors?.specialCharacter && !inputControl.hasError('max')">
      Please enter a number with <strong>two decimal places</strong>!</mat-error>
    <mat-error *ngIf="inputControl.hasError('max')">
      {{maxErrorMessage}}
    </mat-error>
    <mat-error *ngIf="inputControl.hasError('specialCharacter') && !inputControl.hasError('max')">
      {{inputControl.errors?.specialCharacter?.message}}
    </mat-error>

  </mat-form-field>

  <div class="actions">
    <button mat-button type="button" color="primary" (click)="onCancel()">CANCEL</button>
    <button [disabled]="!inputControl.valid" mat-button type="submit" color="primary">SAVE</button>
  </div>
</form>
