import { Component, OnInit, ViewChild, AfterViewInit, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, Validators, AbstractControl, FormGroup } from '@angular/forms';
import { HttpBaseService } from 'src/app/shared/services/http-base.service';
import { ApiEndPoints } from 'src/app/shared/config/api-end-points';
import { Broker, BrokerBranch } from 'src/app/models/broker';
import { BrokerBranchDataSource } from 'src/app/models/broker-branch-datasource';
import { PagingConstant, NO_RESULTS_FOUND } from 'src/app/shared/constants/constants';
import { BrokerBranchService } from 'src/app/services/broker-branch.service';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { BranchPopupComponent } from './branch-popup/branch-popup.component';
import { Utility } from 'src/app/utils/utility';
import { MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { PreviousRouteService } from 'src/app/shared/services/previous-route.service';
import { BrokerService } from 'src/app/services/broker.service';
import { SharedSearchComponent } from 'src/app/shared/components/shared-search/shared-search.component';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-branches',
  templateUrl: './branches.component.html',
  styleUrls: ['./branches.component.scss']
})
export class BranchesComponent implements OnInit, AfterViewInit {

  brokerBranch: BrokerBranch;
  brokerBranchList: BrokerBranch[] = [];

  configBrokerBranchDialog = new MatDialogConfig();

  displayedColumns: string[] =
  ['originalBranchId', 'name', 'addressline1', 'addressline2', 'addressline3',
  'postcode', 'phonenumber', 'email', 'originalBrokerId', 'brokerName', 'active', 'actions', 'products'];

  totalRecords: number;

  dataSource: BrokerBranchDataSource;

  @Output() reloadBrokerOutput = new EventEmitter<Broker>();

  @Input() broker: Broker;
  @Input() reloadBrokerBranch$: BehaviorSubject<boolean>;


  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(SharedSearchComponent) sharedSearch: SharedSearchComponent;

  pagingConstant = PagingConstant;
  pageSizeOptions: number[] = PagingConstant.pageSizeOptionsLarger;

  searchString: string;

  searchFormBranch = this.formBuilder.group({
    isIncludedInactive: [false, [Validators.required]]
  });

  get isIncludedInactive() {
    return this.searchFormBranch.get('isIncludedInactive') as AbstractControl;
  }

  notfound: string = ''
  get notFoundMessage() {
    return this.notfound;
  }

  constructor(private readonly formBuilder: FormBuilder,
    private readonly _httpService: HttpBaseService,
    private readonly _api: ApiEndPoints,
    private router: Router,
    private readonly _brokerBranchService: BrokerBranchService,
    private readonly _dialogService: DialogService,
    private readonly _previousRouteService: PreviousRouteService,
    private readonly _brokerService: BrokerService) {
    this.configBrokerBranchDialog.width = '1200px';
    this.configBrokerBranchDialog.disableClose = true;
  }

  async ngOnInit() {

    if (this._previousRouteService.getPreviousUrl().includes("products/branch-product-detail")) {
      this.searchString = this._brokerService.getSearchStringBrokerBranchList();
    }
    if (this.broker) {
      this.displayedColumns =
        ['originalBranchId', 'name', 'addressline1', 'addressline2', 'addressline3',
          'postcode', 'phonenumber', 'email', 'active', 'actions', 'products'];
    }
    else {
      this.displayedColumns =
        ['originalBranchId', 'name', 'addressline1',
          'postcode', 'phonenumber', 'email', 'originalBrokerId', 'brokerName', 'active', 'actions', 'products'];
    }

    this.dataSource = new BrokerBranchDataSource(this._brokerBranchService);
    await this.loadData(this.broker);
    this.sharedSearch?.setSearchString(this.searchString);
    this.dataSource.brokerSubject$.subscribe(b => {
      this.checkNotFoundResults();
    });
    if (this.reloadBrokerBranch$) {
      this.reloadBrokerBranch$.subscribe(async x => {
        if (x) {
          await this.loadData(this.broker);
        }
      });
    }

  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    this.paginator.page
      .pipe(
        tap(() => this.getBrokerDetailsPage())
      )
      .subscribe();

    this.sort.sortChange
      .pipe(
        tap(() => this.getBrokerDetailsPage())
      )
      .subscribe();

    this.isIncludedInactive.valueChanges.subscribe(value => {
      this.paginator.pageIndex = 0;
      this.paginator.pageSize = PagingConstant.pageSizeOptionsLarger[0];
      this.loadData(this.broker);
    });
  }

  async getBrokerDetailsPage() {
    const fieldSort = this.sort.active.charAt(0).toUpperCase() + this.sort.active.slice(1);
    const direction = this.sort.direction;
    this.loadData(this.broker, fieldSort, direction);
  }

  async loadData(broker: Broker, fieldSort: string= 'Name', direction: string = 'asc') {
    let index = this.paginator ? this.paginator.pageIndex : 0;
    let pageSize = this.paginator ? this.paginator.pageSize : PagingConstant.pageSizeLarger;
    this._brokerService.setSearchStringBrokerBranchList(this.searchString);
    if (broker) {
      this.dataSource.getBrokerBrancesByBrokerId(broker.id, this.searchString, this.isIncludedInactive.value, fieldSort, direction, index, pageSize);
    }
    else {
      this.dataSource.getBrokerBranchList(this.searchString,  this.isIncludedInactive.value, fieldSort, direction, index, pageSize);

    }
    if (this.sharedSearch) this.sharedSearch.setSearchString(this.searchString);


  }


  checkNotFoundResults() {
    if (this.dataSource.totalRecords === 0) {
      this.notfound = NO_RESULTS_FOUND;
    } else {
      this.notfound = '';
    }
  }

  onValueChange(value: string) {
    this.searchString = value;
  }

  async search() {
    this.paginator.pageIndex = 0;
    this.paginator.pageSize = PagingConstant.pageSizeOptionsLarger[0];
    this.loadData(this.broker);
  }

  addBranch() {
    this._dialogService.openComponentDialog(BranchPopupComponent, { brokerBranch: this.brokerBranch, broker : this.broker }, this.configBrokerBranchDialog).subscribe(async result => {
      if (result) {
        this.reloadBroker();
        this.loadData(this.broker);
      }
    });
  }

  editBranch(branch: any) {
    this._dialogService.openComponentDialog(BranchPopupComponent, { branch: branch, broker : this.broker }, this.configBrokerBranchDialog).subscribe(async result => {
      if (result) {
        this.reloadBroker();
        this.loadData(this.broker);
      }
    });
  }

  onNavigateBranchProduct(brokerbranch: any) {
    this.router.navigate([`app/products/branch-product-detail/${brokerbranch.id}`]);
  }

  async reloadBroker() {
    if (this.broker) {
      this.broker = await this._httpService.getDataAsync<Broker>(this._api.getBrokerByBrokerId(this.broker.id));
      this.reloadBrokerOutput.emit(this.broker);
    }
  }

  formatPhoneNumber(phonenumber) {
    phonenumber = phonenumber.split(' ').join("");
    const formatted = Utility.formatPhoneNumber(phonenumber);
    return formatted;
  }
}
