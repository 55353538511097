<div [formGroup]="searchForm" class="search-group">
    <div class="section">
        <mat-form-field class="search-field white-form-field">
            <mat-label>{{placeholderText}}</mat-label>
            <input formControlName="searchString" matInput type="text" #searchStr placeholder="{{placeholderText}}"
                (keyup.enter)="search()">
            <button class="close-icon" *ngIf="!searchStringIsEmpty" (click)="reset()"><mat-icon>clear</mat-icon></button>
            <mat-icon class="search-icon" matSuffix svgIcon="magnify" (click)="search()"></mat-icon>
            <mat-error>{{MaxLengthSearch.errorMessage}}</mat-error>
        </mat-form-field>
    </div>
</div>
