import { EventEmitter, Injectable } from '@angular/core';
import { Policy } from '../models/insurance-policy';
import { ApiEndPoints } from '../shared/config/api-end-points';
import { HttpBaseService } from '../shared/services/http-base.service';
import { SellableLevelOfCover } from '../models/sellable-level-of-cover';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PolicyService {
  loading$: BehaviorSubject<boolean>;
  policy$: BehaviorSubject<Policy>;

  constructor(private _httpService: HttpBaseService, private api: ApiEndPoints) {
    this.policy$ = new BehaviorSubject<Policy>(null);
    this.loading$ = new BehaviorSubject<boolean>(false);
  }

  async getLatestPolicyByNumber(query: string) {
    this.loading$.next(true);
    const res = await this._httpService.getDataAsync<Policy>(this.api.getPolicyByCertificate(query)).catch(err => {
      return null;
    });
    if (res !== null) {
      let policy = {
        company: res.company,
        cancellationDate: res.cancellationDate,
        certificateNumber: res.certificateNumber,
        firstName: res.firstName,
        policyExpireDate: res.policyExpireDate,
        policyId: res.policyId,
        policyStartDate: res.policyStartDate,
        productId: res.productId,
        productName: res.productName,
        surname: res.surname,
        title: res.title,
        levelOfCovers: res.levelOfCovers !== null ? res.levelOfCovers.map(x => new SellableLevelOfCover(x)) : res.originalLevelOfCovers.map(x => new SellableLevelOfCover(x)),
        originalLevelOfCovers: res.originalLevelOfCovers.map(x => new SellableLevelOfCover(x))        
      } as Policy;

      this.policy$.next(policy);
    } else {
      this.policy$.next(null);
    }
    this.loading$.next(false);
  }

  async updatePolicyInceptionDate(model: any) {
    let result = await this._httpService.putDataAsync<Policy>(this.api.updatePolicyInceptionDate(model.policyId), model)
      .catch(err => {
        return null;
      });
    return result;
  }
}
