import { Component, OnInit, ViewChild, AfterViewInit, Input } from '@angular/core';
import * as _ from 'lodash';
import { ApiEndPoints } from 'src/app/shared/config/api-end-points';
import { HttpBaseService } from 'src/app/shared/services/http-base.service';
import { PagingConstant, EMPTY_GUID, NO_RESULTS_FOUND } from 'src/app/shared/constants/constants';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { tap } from 'rxjs/operators';
import { FormBuilder, AbstractControl } from '@angular/forms';
import { LevelOfCoverDataSource } from 'src/app/models/level-of-cover-datasource';
import { LevelOfCoverService } from 'src/app/services/level-of-cover.service';
import { LevelOfCoverPopupComponent } from './level-of-cover-popup/level-of-cover-popup.component';
import { LevelOfCover } from 'src/app/models/level-of-cover';
import { Product } from 'src/app/models/product';
import { BehaviorSubject } from 'rxjs';
import { PricingComponent } from '../product-pricing/pricing/pricing.component';
import { SubProduct } from 'src/app/models/sub-product';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialogConfig } from '@angular/material/dialog';
import { SharedSearchComponent } from 'src/app/shared/components/shared-search/shared-search.component';

@Component({
    selector: 'app-levelsofcover-configuration',
    templateUrl: './levelsofcover-configuration.component.html',
    styleUrls: ['./levelsofcover-configuration.component.scss']
})

export class LevelsOfCoverConfigurationComponent implements OnInit, AfterViewInit {

    displayedColumns: string[] = ['productName', 'subProductName', 'name', 'order', 'isActive', 'actions', 'pricing'];

    dataSource: LevelOfCoverDataSource;
    totalRecords: number;

    @Input() product$: BehaviorSubject<Product>;
    product: Product;

    @Input() productId: string;

    @Input() isFromProduct: false;

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(SharedSearchComponent) sharedSearch: SharedSearchComponent;

    pagingConstant = PagingConstant;
    pageSizeOptions: number[] = PagingConstant.pageSizeOptionsLarger;

    configLoCDialog = new MatDialogConfig();
    configPricingDialog = new MatDialogConfig();
    searchString: string;

    searchForm = this.formBuilder.group({
        isIncludedInactive: [false]
    });

    filterForm = this.formBuilder.group({
        filterProductId: [''],
        filterSubProductId: [''],
        filterLevelOfCoverId: ['']
    });

    notfound: string = ''
    get notFoundMessage() {
        return this.notfound;
    }

    products: Product[] = [];
    subProducts: SubProduct[] = [];
    levelOfCovers: LevelOfCover[] = [];

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly _api: ApiEndPoints,
        private readonly _httpService: HttpBaseService,
        private readonly _levelOfCoverService: LevelOfCoverService,
        private readonly _dialogService: DialogService) {
        this.configLoCDialog.disableClose = true;

        this.configPricingDialog.disableClose = true;
        this.configPricingDialog.width = '1400px';
        this.configPricingDialog.height = '1000px';
    }

    invalidAddLoC() {
        return this.isFromProduct && (this.productId === undefined || this.productId === EMPTY_GUID);
    }

    get isIncludedInactive() {
        return this.searchForm.get('isIncludedInactive') as AbstractControl;
    }


    get filterProductId() {
        return this.filterForm.get('filterProductId') as AbstractControl;
    }

    get filterSubProductId() {
        return this.filterForm.get('filterSubProductId') as AbstractControl;
    }

    get filterLevelOfCoverId() {
        return this.filterForm.get('filterLevelOfCoverId') as AbstractControl;
    }

    async ngOnInit() {
        this.dataSource = new LevelOfCoverDataSource(this._levelOfCoverService);
        await this.getProducts();

        if (!this.isFromProduct) {
            await this.loadData(EMPTY_GUID, EMPTY_GUID, EMPTY_GUID);
        }

        this.dataSource.levelOfCovers$.subscribe(() => {
            this.checkNotFoundResults();
        });
    }

    async getProducts() {
        this.products = await this._httpService.getDataAsync<Product[]>(this._api.getAllProducts());
        this.products = _.orderBy(this.products, 'name');
    }

    async loadData(productId: string, subProductId: string, loCId: string, sortField: string = 'ProductName', sortDirection: string = 'asc') {
        let index = this.paginator? this.paginator.pageIndex: 0;
        let pageSize = this.paginator? this.paginator.pageSize: PagingConstant.pageSizeLarger;
        this.dataSource.getLevelOfCoversByFilters(productId, subProductId, loCId, this.searchString, this.isIncludedInactive.value, sortField, sortDirection, index, pageSize);
        if (this.sharedSearch) this.sharedSearch.setSearchString(this.searchString);
    }

    async loadAllData() {
      this.paginator.pageIndex = 0;
      this.paginator.pageSize = PagingConstant.pageSizeOptionsLarger[0];
      this.loadData(EMPTY_GUID, EMPTY_GUID, EMPTY_GUID);
    }

    async loadByProduct(productId: string) {
        this.paginator.pageIndex = 0;
        this.paginator.pageSize = PagingConstant.pageSizeOptionsLarger[0];
        this.loadData(productId, EMPTY_GUID, EMPTY_GUID);
    }

    async loadByProductAndSubProduct(productId: string, subProductId: string) {
        this.paginator.pageIndex = 0;
        this.paginator.pageSize = PagingConstant.pageSizeOptionsLarger[0];
        this.loadData(productId, subProductId, EMPTY_GUID);
    }

    async loadByProductAndSubProductAndLevelOfCover(productId: string, subProductId: string, levelOfCoverId: string) {
        this.paginator.pageIndex = 0;
        this.paginator.pageSize = PagingConstant.pageSizeOptionsLarger[0];
        this.loadData(productId, subProductId, levelOfCoverId);
    }

    checkNotFoundResults() {
        if (this.dataSource.totalRecords === 0) {
            this.notfound = NO_RESULTS_FOUND;
        } else {
            this.notfound = '';
        }
    }

    ngAfterViewInit() {
        // reset the paginator after sorting
        this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

        this.paginator.page
            .pipe(
                tap(() => this.getLevelOfCoversPaging())
            )
            .subscribe();

        this.sort.sortChange
            .pipe(
                tap(() => this.getLevelOfCoversPaging())
            )
            .subscribe();

        this.isIncludedInactive.valueChanges.subscribe(value => {
          this.paginator.pageIndex = 0;
          this.paginator.pageSize = PagingConstant.pageSizeOptionsLarger[0];
          this.processLoadData(this.filterProductId.value, this.filterSubProductId.value, this.filterLevelOfCoverId.value);
        });

        this.product$ && this.product$.subscribe(async p => {
            this.product = p;
            this.productId = this.product ? this.product.id : this.productId;
            this.loadByProduct(this.productId);
        });

        this.filterProductId.valueChanges.subscribe(async (id: string) => {
            let product = await this._httpService.getDataAsync<Product>(this._api.getProduct(id));
            this.subProducts = _.orderBy(product.subProducts, 'name');
            this.levelOfCovers = [];
            this.filterSubProductId.setValue('', { emitEvent: false });
            this.filterLevelOfCoverId.setValue('', { emitEvent: false });
            if (id) {
                this.loadByProduct(id);
            } else {
                this.loadData(EMPTY_GUID, EMPTY_GUID, EMPTY_GUID);
            }
        });

        this.filterSubProductId.valueChanges.subscribe((id: string) => {
            const selectedSubProduct = _.find(this.subProducts, (x) => x.id === id);
            this.levelOfCovers = _.orderBy(selectedSubProduct.levelOfCovers, 'name');
            this.filterLevelOfCoverId.setValue('', { emitEvent: false });
            if (id) {
                this.loadByProductAndSubProduct(this.filterProductId.value, id);
            }
        });

        this.filterLevelOfCoverId.valueChanges.subscribe((id: string) => {
            if (id) {
                this.loadByProductAndSubProductAndLevelOfCover(this.filterProductId.value, this.filterSubProductId.value, id);
            }
        });
    }

    async getLevelOfCoversPaging() {
        const fieldSort = this.sort.active.charAt(0).toUpperCase() + this.sort.active.slice(1);
        const direction = this.sort.direction;

        if (this.isFromProduct) {
            this.loadData(this.productId, EMPTY_GUID, EMPTY_GUID, fieldSort, direction);
        }
        else {

            if (this.filterProductId.value && this.filterSubProductId.value && this.filterLevelOfCoverId.value) {
                this.loadData(this.filterProductId.value, this.filterSubProductId.value, this.filterLevelOfCoverId.value, fieldSort, direction);
            }
            else if (this.filterProductId.value && this.filterSubProductId.value) {
                this.loadData(this.filterProductId.value, this.filterSubProductId.value, EMPTY_GUID, fieldSort, direction);
            }
            else if (this.filterProductId.value) {
                this.loadData(this.filterProductId.value, EMPTY_GUID, EMPTY_GUID, fieldSort, direction);
            }
            else {
                this.loadData(EMPTY_GUID, EMPTY_GUID, EMPTY_GUID, fieldSort, direction);
            }
        }
    }

    editLevelOfCover(levelOfCover: any) {
        this._dialogService.openComponentDialog(LevelOfCoverPopupComponent, { levelOfCover }, this.configLoCDialog).subscribe(async result => {
            if (result) {
                await this.processLoadData(this.filterProductId.value, this.filterSubProductId.value, this.filterLevelOfCoverId.value);
            }
        });
    }

    createLevelOfCover() {
        this._dialogService.openComponentDialog(LevelOfCoverPopupComponent, { selectedProduct: this.product }, this.configLoCDialog).subscribe(async result => {
            if (result) {
                await this.processLoadData(this.filterProductId.value, this.filterSubProductId.value, this.filterLevelOfCoverId.value);
            }
        });
    }

    addPricing(levelOfCover: any) {
        this._dialogService.openComponentDialog(PricingComponent, { addPricing: true, levelOfCover }, this.configPricingDialog).subscribe(async result => {
            if (result) {
                await this.processLoadData(this.filterProductId.value, this.filterSubProductId.value, this.filterLevelOfCoverId.value);
            }
        });
    }

    async processLoadData(productId: string, subProductId: string, levelOfCoverId: string) {
        if (this.isFromProduct) {
            await this.loadByProduct(this.productId);
        }
        else {
            if (productId && subProductId && levelOfCoverId) {
                await this.loadByProductAndSubProductAndLevelOfCover(productId, subProductId, levelOfCoverId);
            }
            else if (productId && subProductId) {
                await this.loadByProductAndSubProduct(productId, subProductId);
            }
            else if (productId) {
                await this.loadByProduct(productId);
            }
            else {
                await this.loadAllData();
            }
        }
    }

    onValueChange(value: string) {
      this.searchString = value;
    }

    async search() {
        this.paginator.pageIndex = 0;
        await this.processLoadData(this.filterProductId.value, this.filterSubProductId.value, this.filterLevelOfCoverId.value);
    }

}

