export enum ExternalBrokerType {
    Ssp = 1,
    OpenGi = 2,
    Acturis = 3,
    Howden = 4
}

export namespace ExternalBrokerType {
  export function values() {
      return Object.keys(ExternalBrokerType).filter((type) => isNaN(<any>type) && type !== 'values');
  }
}

export enum ExternalBroker {
  Acturis = "Acturis",
  Advance = "Advance",
  CIAInsurance = "CIA Insurance",
  Freeway = "Freeway",
  Howden = "Howden",
  LloydLatchford = "Lloyd Latchford",
  OpenGI =  "OpenGI",
  OriginalInsurance ="Original Insurance",
  SSP = "SSP"
}

export namespace ExternalBroker {
  export function values() {
      return Object.keys(ExternalBroker).filter((type) => isNaN(<any>type) && type !== 'values');
  }
}
