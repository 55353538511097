<form (ngSubmit)="onSubmit()">
  <div class="mat-subtitle-1">{{title}}</div>

  <mat-form-field>
    <span matPrefix class="mat-income-prefix">£ </span>
    <input matInput maxLength="10" name="newValue" [(ngModel)]="newValue"
      [formControl]="inputControl" autocomplete="off">
    
    <mat-error *ngIf="inputControl.hasError('required')">
      Input value is <strong>required</strong>!
    </mat-error>
    <mat-error *ngIf="inputControl.hasError('pattern')">
      Input value <strong>must a number</strong>!
    </mat-error>
    <!-- <mat-error *ngIf="inputControl.hasError('max')">
     {{maxErrorMessage}}
    </mat-error> -->
  </mat-form-field>

  <div class="actions">
    <button mat-button type="button" color="primary" (click)="onCancel()">CANCEL</button>
    <button [disabled]="!inputControl.valid" mat-button type="submit" color="primary">SAVE</button>
  </div>
</form>