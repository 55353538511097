import { Component, OnInit, AfterViewInit, Inject } from '@angular/core';
import { FormBuilder, Validators, AbstractControl, FormGroup, FormArray } from '@angular/forms';
import { LevelOfCover } from 'src/app/models/level-of-cover';
import { ApiEndPoints } from 'src/app/shared/config/api-end-points';
import { HttpBaseService } from 'src/app/shared/services/http-base.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { BehaviorSubject } from 'rxjs';
import { Product } from 'src/app/models/product';
import { LevelOfCoverValidation, LevelOfCoverValidationGroup } from 'src/app/models/level-of-cover-validation-item';
import * as _ from 'lodash';
import { ProductConstant, TitleMessages } from 'src/app/shared/constants/constants';
import { Status } from 'src/app/models/enums/status.enum';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { positiveNumberValidator } from 'src/app/validators/only-numbers.validator';

@Component({
  selector: 'app-level-of-cover-validation-rule',
  templateUrl: './level-of-cover-validation-rule.component.html',
  styleUrls: ['./level-of-cover-validation-rule.component.scss']
})
export class LevelOfCoverValidationRuleComponent implements OnInit, AfterViewInit {

  levelOfCoverValidations: LevelOfCoverValidation[] = [];
  levelOfCovers: LevelOfCover[] = [];
  products: Product[] = [];

  validationTypes: string[] = [];

  selectLoCValidation$: BehaviorSubject<LevelOfCoverValidation[]> = new BehaviorSubject<LevelOfCoverValidation[]>([]);

  updateMode = false;
  Status = Status;

  updateLevelofCoverValidation: LevelOfCoverValidationGroup = undefined;

  ruleForms: FormArray;
  levelOfCoverValidationForm: FormGroup;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly _api: ApiEndPoints,
    private readonly _httpService: HttpBaseService,
    private readonly _dialogService: DialogService,
    public dialogRef: MatDialogRef<LevelOfCoverValidationRuleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    this.createForm();

    const editMode = data.editMode;
    if (editMode) {
      this.updateMode = true;
      this.updateLevelofCoverValidation = data.levelOfCoverValidation;
    }
  }

  createRule(ruleName: string, levelOfCoverValidation: LevelOfCoverValidation = undefined): FormGroup {
    return this.formBuilder.group({
      id: [levelOfCoverValidation ? levelOfCoverValidation.id : undefined],
      rowVersion: [levelOfCoverValidation ? levelOfCoverValidation.rowVersion : undefined],
      ruleName: [ruleName],
      lowerLimit: [levelOfCoverValidation ? levelOfCoverValidation.lowerLimit : undefined, [Validators.min(0)]],
      upperLimit: [levelOfCoverValidation ? levelOfCoverValidation.upperLimit : undefined, [Validators.min(0)]],
      hasBooleanFlag: [levelOfCoverValidation ? levelOfCoverValidation.booleanFlag != undefined : false],
      booleanFlag: [levelOfCoverValidation ? levelOfCoverValidation.booleanFlag : undefined],
      status: [levelOfCoverValidation ? (levelOfCoverValidation.isActive ? Status.Active : Status.Inactive) : Status.Active, [Validators.required]],
    });
  }

  createForm() {
    this.levelOfCoverValidationForm = this.formBuilder.group({
      product: ['', [Validators.required]],
      levelOfCover: ['', [Validators.required]],
      validationType: [''],
      validationField: [''],
      ruleForms: this.formBuilder.array([])
    });
  }

  public get product() {
    return this.levelOfCoverValidationForm.get('product') as AbstractControl;
  }

  public get levelOfCover() {
    return this.levelOfCoverValidationForm.get('levelOfCover') as AbstractControl;
  }

  public get validationType() {
    return this.levelOfCoverValidationForm.get('validationType') as AbstractControl;
  }

  public get validationField() {
    return this.levelOfCoverValidationForm.get('validationField') as AbstractControl;
  }

  public get lowerLimit() {
    return this.levelOfCoverValidationForm.get('lowerLimit') as AbstractControl;
  }

  public get upperLimit() {
    return this.levelOfCoverValidationForm.get('upperLimit') as AbstractControl;
  }

  public get hasBooleanFlag() {
    return this.levelOfCoverValidationForm.get('hasBooleanFlag') as AbstractControl;
  }

  public get booleanFlag() {
    return this.levelOfCoverValidationForm.get('booleanFlag') as AbstractControl;
  }

  public get validationRuleForms() {
    return this.levelOfCoverValidationForm.get('ruleForms') as FormArray;
  }

  public get status() {
    return this.levelOfCoverValidationForm.get('status') as AbstractControl;
  }

  async ngOnInit() {
    await this.loadProductData();
    if (this.updateMode) {
      await this.bindingDataForUpdate();
    }
  }

  async bindingDataForUpdate() {
    this.product.setValue(this.updateLevelofCoverValidation.productId);
  }

  clearForm(form: FormArray) {
    while (0 !== form.length) {
      form.removeAt(0);
    }
  }

  resetRuleForms() {
    this.ruleForms = this.levelOfCoverValidationForm.get('ruleForms') as FormArray;
    this.clearForm(this.ruleForms);
  }

  addRuleForm(loCValidation: LevelOfCoverValidation) {
    this.ruleForms = this.levelOfCoverValidationForm.get('ruleForms') as FormArray;
    if (this.updateMode) {
      this.ruleForms.push(this.createRule(loCValidation.validationField, loCValidation));
    }
    else {
      this.ruleForms.push(this.createRule(loCValidation.validationField));
    }
  }

  async ngAfterViewInit() {
    // Filter by product
    this.product.valueChanges.subscribe(async productId => {      
      let levelOfCovers = await this._httpService.getDataAsync<LevelOfCover[]>(this._api.getLevelsOfCoverForProduct(productId));
      levelOfCovers = _.filter(levelOfCovers, (x) => { return x.isActive }); //Only active level of cover #11364

      this.levelOfCoverValidations = await this._httpService.getDataAsync<LevelOfCoverValidation[]>(this._api.getLevelOfCoverValidationRulesForProduct(productId));

      const levelOfCoversNoValidationRules = _.filter(levelOfCovers, (x) => {
        return (_.find(this.levelOfCoverValidations, (y) => {
          return y.levelOfCoverId === x.id
        }) === undefined)
      });

      this.levelOfCovers = this.updateMode ? _.orderBy(levelOfCovers, 'name') : _.orderBy(levelOfCoversNoValidationRules, 'name');

      this.validationTypes = [];
      this.validationTypes = _.uniqBy(this.levelOfCoverValidations, 'validationType').map(u => u.validationType);

      this.levelOfCover.setValue('', { emitEvent: false });
      this.validationType.setValue('', { emitEvent: false });

      if (!this.updateMode) {
        let selectLoCValidations = [];
        if (this.validationTypes.length === 1) {
          selectLoCValidations = _.uniqBy(this.levelOfCoverValidations, 'validationField');
        }
        this.selectLoCValidation$.next(selectLoCValidations);
      }

      if (this.updateMode) {
        this.levelOfCover.setValue(this.updateLevelofCoverValidation.levelOfCoverId);
        this.validationType.setValue(this.updateLevelofCoverValidation.validationType);
      }
    });

    // Filter validationField (Rule) by validation type and product

    this.validationType.valueChanges.subscribe(validationType => {
      const productId = this.product.value;
      const levelOfCoverId = this.levelOfCover.value;
      let selectLoCValidations: LevelOfCoverValidation[] = [];

      selectLoCValidations = _.filter(this.levelOfCoverValidations, (x) => x.productId === productId && x.levelOfCoverId === levelOfCoverId && x.validationType === validationType);

      if (!this.updateMode && selectLoCValidations.length === 0) { // New rules for this 
        selectLoCValidations = _.filter(this.levelOfCoverValidations, (x) => x.productId === productId && x.validationType === validationType);
      }

      selectLoCValidations = _.uniqBy(selectLoCValidations, 'validationField');
      this.selectLoCValidation$.next(selectLoCValidations);
    });

    this.levelOfCover.valueChanges.subscribe(levelOfCoverId => {
      if (levelOfCoverId && this.validationType.value) {
        let selectLoCValidations: LevelOfCoverValidation[] = [];
        selectLoCValidations = _.filter(this.levelOfCoverValidations, (x) => x.productId === this.product.value && x.levelOfCoverId === levelOfCoverId && x.validationType === this.validationType.value);

        if (!this.updateMode && selectLoCValidations.length === 0) { // new rules for this
          selectLoCValidations = _.filter(this.levelOfCoverValidations, (x) => x.productId === this.product.value && x.validationType === this.validationType.value);
        }

        selectLoCValidations = _.uniqBy(selectLoCValidations, 'validationField');
        this.selectLoCValidation$.next(selectLoCValidations);
      }
    })

    this.selectLoCValidation$.subscribe(async rules => {
      this.resetRuleForms();
      for (let i = 0; i < rules.length; i++) {
        this.addRuleForm(rules[i]);
      }
    });
  }

  async loadProductData() {
    let products = await this._httpService.getDataAsync<Product[]>(this._api.getAllProducts());
    products = _.filter(products, (x) => { return x.isActive }); //Only active products #11364
    this.products = _.orderBy(products, 'name');
  }

  async save() {
    if (this.levelOfCoverValidationForm.valid) {
      this.ruleForms = this.levelOfCoverValidationForm.get('ruleForms') as FormArray;

      let levelOfCoverValidations: LevelOfCoverValidation[] = [];
      for (let i = 0; i < this.ruleForms.length; i++) {
        const ruleForm = this.ruleForms.controls[i];

        const lowerLimit = ruleForm.get('lowerLimit').value;
        const upperLimit = ruleForm.get('upperLimit').value;
        const hasBooleanFlag = ruleForm.get('hasBooleanFlag').value;
        const booleanFlag = ruleForm.get('booleanFlag').value;
        const validationField = ruleForm.get('ruleName').value;
        const status = ruleForm.get('status').value;

        let levelOfCoverValidation: LevelOfCoverValidation = {
          id: this.updateMode ? ruleForm.get('id').value : undefined,
          rowVersion: this.updateMode ? ruleForm.get('rowVersion').value : undefined,
          productId: this.product.value,
          levelOfCoverId: this.levelOfCover.value,
          validationType: this.validationType.value,
          validationField: validationField,
          lowerLimit: lowerLimit,
          upperLimit: upperLimit,
          booleanFlag: hasBooleanFlag ? (booleanFlag ? booleanFlag : false) : null,
          isActive: status
        };

        levelOfCoverValidations.push(levelOfCoverValidation);
      }

      try {
        if (this.updateMode) {
          await this._httpService.putDataAsync<LevelOfCoverValidation[]>(this._api.updateLevelOfCoverValidation(), levelOfCoverValidations);
        } else {
          await this._httpService.postDataAsync<string[]>(this._api.createLevelOfCoverValidation(), levelOfCoverValidations);
        }

        let confirmMessage = this.updateMode ? 'The level of cover validation has been updated successfully' : 'A new level of cover validation has been saved successfully';
        this._dialogService.openSuccessDialogConfirm({ title: TitleMessages.successTitle, message: confirmMessage }).subscribe(() => {
          this.dialogRef.close(true);
        });
      }
      finally {
      }
    }
  }

  onCloseDialog(isReload: boolean) {
    if (!this.disableSaveButton()) {
      this._dialogService.openConfirmationDialog({ title: TitleMessages.confirmationTitle, message: 'Are you sure you want to continue. Your changes will be lost?' }).subscribe(result => {
        if (result) {
          this.dialogRef.close(isReload);
        }
      });
    }
    else {
      this.dialogRef.close(isReload);
    }
  }

  disableSaveButton() {
    if (this.updateMode) {
      if (this.ruleForms && this.ruleForms.length > 0 && this.ruleForms.valid) {
        const dirty = _.find(this.ruleForms.controls, (x) => x.dirty);
        return dirty ? false : true;
      }
      else {
        return true;
      }
    }
    else {
      return this.levelOfCoverValidationForm.invalid || (this.ruleForms && this.ruleForms.length <= 0);
    }
  }

  isExcessProtectionProduct() {
    const selectedProduct = _.find(this.products, (x) => x.id === this.product.value);
    return selectedProduct && selectedProduct.shortName === ProductConstant.excessprotection;
  }
}
