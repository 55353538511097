<div class="user-popup">
  <div class="end">
    <div class="close-button"><mat-icon svgIcon="close" (click)="close()" matTooltip="Close"></mat-icon></div>
  </div>

  <mat-dialog-content >
    <div class="user-detail">
      <div class="flex-30"><label><strong>User name :</strong></label></div>
      <div class="flex-70" matTooltip="{{user.userName}}" matTooltipClass='custom-tooltip'><span>{{user.userName}}</span></div>
    </div>
    <div class="user-detail">
      <div class="flex-30"><label><strong>Email address :</strong></label></div>
      <div class="flex-70" matTooltip="{{user.emailAddress}}" matTooltipClass='custom-tooltip'><span>{{user.emailAddress}}</span></div>
    </div>
    <div class="user-detail">
      <div class="flex-30"><label><strong>Telephone :</strong></label></div>
      <div class="flex-70"><span>{{formatPhoneNumber(user.phoneNumber)}}</span></div>
    </div>
    <div class="user-detail">
      <div class="flex-30"><label><strong>Broker branch :</strong></label></div>
      <div class="flex-70" matTooltip="{{user.brokerBranchName}}" matTooltipClass='custom-tooltip'><span>{{user.brokerBranchName}}</span></div>
    </div>
  </mat-dialog-content>
  <hr>
  <mat-dialog-actions class="justify-items-end button-row">
    <button mat-button mat-raised-button color="primary" (click)="logout()">Logout</button>
  </mat-dialog-actions>
</div>
