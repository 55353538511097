import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder} from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { AppConstants, PagingConstant } from 'src/app/shared/constants/constants';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { HttpBaseService } from 'src/app/shared/services/http-base.service';
import { ProductCustomPricingReportResponse } from 'src/app/models/custom-pricing-report-response';
import { ApiEndPoints } from 'src/app/shared/config/api-end-points';
import * as _ from 'lodash';
import { ReportService } from 'src/app/services/report.service';

@Component({
  selector: 'app-custom-pricing-report',
  templateUrl: './custom-pricing-report.component.html',
  styleUrls: ['./custom-pricing-report.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ]
})
export class CustomPricingReportComponent implements OnInit, AfterViewInit {
  expandedElement: ProductCustomPricingReportResponse[] = [];

  dataSource: ProductCustomPricingReportResponse[];
  totalRecords: number;
  pagingConstant = PagingConstant;
  displayedColumns: string[] = ['expandbutton', 'name', 'isActive', 'total'];

  pageSizeOptions: number[] = PagingConstant.pageSizeOptionsLarger;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  searchForm = this.formBuilder.group({
    isIncludedInactive: [false]
  });

  notfound: string = '';
  get notFoundMessage() {
    return this.notfound;
  }


  public appConstants = AppConstants;

  constructor(private readonly formBuilder: FormBuilder,
    private readonly _reportService: ReportService,
    private readonly _httpService: HttpBaseService,
    private readonly _api: ApiEndPoints,
  ) {

  }

  get isIncludedInactive() {
    return this.searchForm.get('isIncludedInactive') as AbstractControl;
  }

  async ngOnInit() {
    let products = await this._httpService.getDataAsync<ProductCustomPricingReportResponse[]>(this._api.getProductCustomPricingReport());
    this.dataSource = _.orderBy(products, 'productName');
  }

  ngAfterViewInit() {
  }

  toggleRow(product) {
    if (this.expandedElement) {
      const index = this.expandedElement.findIndex(x => x.productId === product.productId);
      if (index === -1) {
        this._reportService.customPricing$.next(product.productId);
        this.expandedElement.push(product);
      } else {
        this.expandedElement.splice(index, 1);
      }
    }
  }

  isExpanded(product): string {
    if (!this.expandedElement) {
      return 'collapsed';
    }
    if (
      this.expandedElement.findIndex(x => x.productId === product.productId) !== -1
    ) {
      return 'expanded';
    }
    return 'collapsed';
  }
}
