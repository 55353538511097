import { Component, OnInit, Input } from '@angular/core';
import { PolicyAudit } from 'src/app/models/policy-audit';
import { AppConstants } from 'src/app/shared/constants/constants';
import { Utility } from 'src/app/utils/utility';

@Component({
  selector: 'app-audit-trail-detail',
  templateUrl: './audit-trail-detail.component.html',
  styleUrls: ['./audit-trail-detail.component.scss']
})
export class AuditTrailDetailComponent implements OnInit {

  @Input()
  policyAudit: PolicyAudit=null;
  appconstants = AppConstants;
  Utility = Utility;

  constructor() { }

  ngOnInit() {
  }

}
