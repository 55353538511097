import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { CollectionViewer } from '@angular/cdk/collections';
import { catchError, finalize } from 'rxjs/operators';
import { Insurer, InsurerItem } from './insurer';
import { InsurerService } from '../services/insurer.service';
import { PaginationResponse } from './pagination-response';


export class InsurerDataSource implements DataSource<InsurerItem> {

    private InsurersSubject = new BehaviorSubject<InsurerItem[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);
    public totalRecords: number;

    public loading$ = this.loadingSubject.asObservable();
    public InsurersSubject$ = this.InsurersSubject.asObservable();

    constructor(private InsurerService: InsurerService) { }

    connect(collectionViewer: CollectionViewer): Observable<InsurerItem[]> {
        return this.InsurersSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.InsurersSubject.complete();
        this.loadingSubject.complete();
    }

    getInsurers(searchString: string, subProductId: string, isIncludeInactiveRecords: boolean, sortField = '', sortDirection = 'asc', pageIndex = 1, pageSize = 10) {

        this.loadingSubject.next(true);
        if (!searchString) {
            searchString = ' ';
        }
        this.InsurerService.getInsurers(searchString, subProductId, isIncludeInactiveRecords, sortField, sortDirection, pageIndex, pageSize).pipe(
            catchError(() => of([])),
            finalize(() => this.loadingSubject.next(false))
        )
            .subscribe(st => {
              var rs = st as PaginationResponse<InsurerItem>;
              this.totalRecords = rs.total;
              this.InsurersSubject.next(rs.items as any);
            });
    }
}
