import { Component, OnInit, ViewChild, Inject, AfterViewInit } from '@angular/core';
import { BrokerBranch, Broker } from 'src/app/models/broker';
import { BehaviorSubject, Observable } from 'rxjs';
import { FormGroup, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { HttpBaseService } from 'src/app/shared/services/http-base.service';
import { ApiEndPoints } from 'src/app/shared/config/api-end-points';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { AddressService } from 'src/app/services/address.service';
import { map } from 'rxjs/operators';
import { Utility } from 'src/app/utils/utility';
import { BrokerDataSource } from 'src/app/models/broker-datasource';
import { BrokerService } from 'src/app/services/broker.service';
import { PagingConstant, TitleMessages } from 'src/app/shared/constants/constants';
import * as _ from 'lodash';
import { ContactDetailComponent } from '../../contact-detail/contact-detail.component';
import { Status } from 'src/app/models/enums/status.enum';
import { noSpecialCharacterValidator, noSpecialCharacterValidatorForExternalReference } from 'src/app/validators/no-special-character.validator';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { noWhitespace } from 'src/app/validators/no-whitespace.validator';
import { isValidPhoneNumber } from 'src/app/validators/phone-number.validator';
import { AddressInfoFormControlModel } from 'src/app/models/address-info-form-control-model';
import { isValidEmail } from 'src/app/validators/valid-email.validator';
import { KeyContact } from 'src/app/models/enums/key-contact.enum';
import { urlValidator } from 'src/app/validators/url.validator';
import { ExternalBrokerType } from 'src/app/models/enums/external-broker-branch-type.enum';

@Component({
  selector: 'app-branch-popup',
  templateUrl: './branch-popup.component.html',
  styleUrls: ['./branch-popup.component.scss']
})
export class BranchPopupComponent implements OnInit, AfterViewInit {
  brokerList: Broker[] = [];
  selectedBroker: Broker[] = new Array<Broker>();

  branch: any;

  updateBranch: BrokerBranch;
  updateMode = false;

  selectedRowIndex: any;

  showResult = false;

  public addressLookupInFlight$ = new BehaviorSubject<boolean>(false);

  branchForm: FormGroup;
  searchForm: FormGroup;

  Status = Status;
  ExternalBrokerType = ExternalBrokerType;

  saveSuccessMessage: string = '';

  @ViewChild('contactDetailFormComponent1', {static : true})
  contactDetailFormComponent1: ContactDetailComponent;

  @ViewChild('contactDetailFormComponent2', {static : true})
  contactDetailFormComponent2: ContactDetailComponent;

  @ViewChild('contactDetailFormComponent3', {static : true})
  contactDetailFormComponent3: ContactDetailComponent;

  @ViewChild('contactDetailFormComponent4', {static : true})
  contactDetailFormComponent4: ContactDetailComponent;

  @ViewChild('contactDetailFormComponent5', {static : true})
  contactDetailFormComponent5: ContactDetailComponent;

  id: string;

  @ViewChild('possibleAddresses')
  possibleAddressesSelectComponent: MatSelect;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  dataSource: BrokerDataSource;

  totalRecords = 0;

  emptyExternalType = 0;

  searchResulst = 1000;

  filteredOptions: Observable<Broker[]>;

  options: Broker[];

  broker: Broker;
  keyContact = KeyContact;

  get brokerId() {
    return this.branchForm.get('brokerId') as AbstractControl;
  }

  get brokerName() {
    return this.branchForm.get('brokerName') as AbstractControl;
  }

  get branchName() {
    return this.branchForm.get('branchName') as AbstractControl;
  }

  get branchId() {
    return this.branchForm.get('branchId') as AbstractControl;
  }

  get branchAddress(){
    return this.branchForm.get('address') as AddressInfoFormControlModel;
  }

  get phonenumber() {
    return this.branchForm.get('phonenumber') as AbstractControl;
  }

  get email() {
    return this.branchForm.get('email') as AbstractControl;
  }

  get website() {
    return this.branchForm.get('website') as AbstractControl;
  }

  get status() {
    return this.branchForm.get('status') as AbstractControl;
  }

  get possibleAddresses() {
    return this.branchForm.get('possibleAddresses') as AbstractControl;
  }

  get searchString() {
    return this.searchForm.get('searchString') as AbstractControl;
  }

  get isIncludedInactive() {
    return this.searchForm.get('isIncludedInactive') as AbstractControl;
  }

  get externalType() {
    return this.branchForm.get('externalType') as AbstractControl;
  }

  get externalReference() {
    return this.branchForm.get('externalReference') as AbstractControl;
  }

  get externalReferenceMaxLenghtMessage() {
    let maxLenght = this.externalType.value == ExternalBrokerType.Acturis ? 1000 : 20;
    return `${ExternalBrokerType[this.externalType.value]} Reference can be max ${maxLenght} characters long`;
  }

  constructor(
    private readonly _httpService: HttpBaseService,
    private readonly _api: ApiEndPoints,
    public dialogService: DialogService,
    private readonly addressService: AddressService,
    private formBuilder: FormBuilder,
    private readonly _brokerService: BrokerService,
    public dialogRef: MatDialogRef<BranchPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.createForm();
    this.createFormSearch();
    this.updateBranch = data.branch;
    this.branch = data.brokerBranch;
    this.broker = data.broker;

    if (this.updateBranch) {
      this.updateMode = true;
    }
  }

  ngAfterViewInit(): void {
    if (this.updateMode) {
      setTimeout(async () => {
        await this.initDataForUpdate(this.updateBranch);
      });
    }
  }

  ngOnInit() {
    if (!this.broker && !this.updateMode)
      this._brokerService.getBrokerList(' ', false, 'Name', 'asc', PagingConstant.pageIndex, this.searchResulst).subscribe((broker: any) => {
        this.options = broker.items;
        this.filteredOptions = this.searchString.valueChanges
          .pipe(
            map(value => typeof value === 'string' ? value : value.name),
            map(name => name ? this._filter(name) : this.options.slice())
          );
      });

    this.searchString.valueChanges.subscribe(val => {
      if (val == null || val === '') {
        this.brokerId.setValue('');
        this.brokerName.setValue('');
      }
    });

    this.externalType.valueChanges.subscribe(val => {
      if (!val || val == this.emptyExternalType) {
        this.externalReference.setValue(null);
        this.externalReference.disable();
      }
      else {
        this.externalReference.enable();
      }

      if (val == ExternalBrokerType.Acturis) {
        this.externalReference.setValidators([Validators.maxLength(1000), noSpecialCharacterValidatorForExternalReference]);
      }
      else {
        this.externalReference.setValidators([Validators.maxLength(20), noSpecialCharacterValidatorForExternalReference]);
      }
      this.externalReference.updateValueAndValidity();
    });

    if (this.broker) {
      this.brokerId.setValue(this.broker.originalBrokerId);
      this.brokerName.setValue(this.broker.name);
      this.id = this.broker.id;
    }
  }
  private _filter(searchValue: string) {
    const filterValue = searchValue.toLowerCase();

    return this.options.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0 || option.originalBrokerId.toString().indexOf(filterValue) === 0);
  }

  getSelectedBroker(event: any, broker: any) {
    if (event.isUserInput) {
      this.brokerId.setValue(broker.originalBrokerId);
      this.brokerName.setValue(broker.name);
      this.id = broker.id;
    }
  }

  createForm() {
    this.branchForm = this.formBuilder.group({
      brokerId: ['', [Validators.required, noSpecialCharacterValidator]],
      brokerName: ['', [Validators.required]],
      branchId: ['', [Validators.required, noSpecialCharacterValidator]],
      branchName: ['', [Validators.required, noWhitespace]],
      possibleAddresses: [''],
      phonenumber: ['', [Validators.required, isValidPhoneNumber]],
      email: ['', [Validators.required, Validators.email, isValidEmail]],
      website: ['', [urlValidator]],
      status: [Status.Active, [Validators.required]],
      isAddressFound: [true],
      address: new AddressInfoFormControlModel(null, this.addressService),
      externalType: [''],
      externalReference: [{value: null, disabled: true}, [Validators.maxLength(20) ,noSpecialCharacterValidatorForExternalReference]]
    });
  }

  createFormSearch() {
    this.searchForm = this.formBuilder.group({
      searchString: [null, [noSpecialCharacterValidator]],
      isIncludedInactive: [false, []]
    });
  }

  async initDataForUpdate(brokerBranch: BrokerBranch) {
    this.updateBranch = await this._httpService.getDataAsync<BrokerBranch>(this._api.getBrokerBranch(brokerBranch.id));

    if (brokerBranch) {
      this.branchId.setValue(brokerBranch.originalBranchId);
      this.brokerId.setValue(this.broker ? this.broker.originalBrokerId : brokerBranch.originalBrokerId);
      this.brokerName.setValue(this.broker ? this.broker.name : brokerBranch.brokerName);
      this.branchName.setValue(brokerBranch.name);
      this.status.setValue(brokerBranch.isActive ? Status.Active : Status.Inactive);
      this.branchAddress.postcode.setValue(brokerBranch.postcode, { emitEvent: false });
      this.branchAddress.address1.setValue(brokerBranch.address1);
      this.branchAddress.address2.setValue(brokerBranch.address2);
      this.branchAddress.address3.setValue(brokerBranch.address3);
      this.branchAddress.address4.setValue(brokerBranch.address4);
      this.branchAddress.address5.setValue(brokerBranch.address5);
      this.phonenumber.setValue(brokerBranch.telephoneNumber);
      this.email.setValue(brokerBranch.emailAddress);
      this.website.setValue(brokerBranch.website);

      if (this.updateBranch.sspBrokerId) {
        this.externalType.setValue(ExternalBrokerType.Ssp);
        this.externalReference.setValue(this.updateBranch.sspBrokerId)
      }
      else if (this.updateBranch.openGiBrokerId) {
        this.externalType.setValue(ExternalBrokerType.OpenGi);
        this.externalReference.setValue(this.updateBranch.openGiBrokerId)
      }
      else if (this.updateBranch.acturisBrokerId) {
        this.externalType.setValue(ExternalBrokerType.Acturis);
        this.externalReference.setValue(this.updateBranch.acturisBrokerId)
      }
      else if (this.updateBranch.howdenBrokerId) {
        this.externalType.setValue(ExternalBrokerType.Howden);
        this.externalReference.setValue(this.updateBranch.howdenBrokerId)
      }
      else {
        this.externalType.setValue(null);
        this.externalReference.setValue(null);
      }


      //Reset contact form
      this.contactDetailFormComponent1.resetForm();
      this.contactDetailFormComponent2.resetForm();
      this.contactDetailFormComponent3.resetForm();
      this.contactDetailFormComponent4.resetForm();
      this.contactDetailFormComponent5.resetForm();
      if (this.updateBranch.contacts) {
        let finance = this.updateBranch.contacts.find(x => x.keyContact == this.keyContact.Finance)
        if (finance) {
          this.contactDetailFormComponent1.contactName.patchValue(finance.name ? finance.name : '');
          this.contactDetailFormComponent1.jobTitle.patchValue(finance.jobTitle ? finance.jobTitle : '');
          this.contactDetailFormComponent1.contactPhoneNumber.patchValue(finance.phoneNumber ? finance.phoneNumber : '');
          this.contactDetailFormComponent1.emailAddress.patchValue(finance.email ? finance.email : '');
        }
        let compliance = this.updateBranch.contacts.find(x => x.keyContact == this.keyContact.Compliance)
        if (compliance) {
          this.contactDetailFormComponent2.contactName.patchValue(compliance.name ? compliance.name : '');
          this.contactDetailFormComponent2.jobTitle.patchValue(compliance.jobTitle ? compliance.jobTitle : '');
          this.contactDetailFormComponent2.contactPhoneNumber.patchValue(compliance.phoneNumber ? compliance.phoneNumber : '');
          this.contactDetailFormComponent2.emailAddress.patchValue(compliance.email ? compliance.email : '');
        }

        let pro = this.updateBranch.contacts.find(x => x.keyContact == this.keyContact.ProductUpdate)
        if (pro) {
          this.contactDetailFormComponent3.contactName.patchValue(pro.name ? pro.name : '');
          this.contactDetailFormComponent3.jobTitle.patchValue(pro.jobTitle ? pro.jobTitle : '');
          this.contactDetailFormComponent3.contactPhoneNumber.patchValue(pro.phoneNumber ? pro.phoneNumber : '');
          this.contactDetailFormComponent3.emailAddress.patchValue(pro.email ? pro.email : '');
        }

        let emptyKeyContact = this.updateBranch.contacts.filter(x => x.keyContact == null);
        if(emptyKeyContact && emptyKeyContact.length > 0)
        {
          this.contactDetailFormComponent4.contactName.patchValue(emptyKeyContact[0].name ? emptyKeyContact[0].name : '');
          this.contactDetailFormComponent4.jobTitle.patchValue(emptyKeyContact[0].jobTitle ? emptyKeyContact[0].jobTitle : '');
          this.contactDetailFormComponent4.contactPhoneNumber.patchValue(emptyKeyContact[0].phoneNumber ? emptyKeyContact[0].phoneNumber : '');
          this.contactDetailFormComponent4.emailAddress.patchValue(emptyKeyContact[0].email ? emptyKeyContact[0].email : '');
        }
        if(emptyKeyContact && emptyKeyContact.length > 1)
        {
          this.contactDetailFormComponent5.contactName.patchValue(emptyKeyContact[1].name ? emptyKeyContact[1].name : '');
          this.contactDetailFormComponent5.jobTitle.patchValue(emptyKeyContact[1].jobTitle ? emptyKeyContact[1].jobTitle : '');
          this.contactDetailFormComponent5.contactPhoneNumber.patchValue(emptyKeyContact[1].phoneNumber ? emptyKeyContact[1].phoneNumber : '');
          this.contactDetailFormComponent5.emailAddress.patchValue(emptyKeyContact[1].email ? emptyKeyContact[1].email : '');
        }
      }
    }
    this.branchForm.markAllAsTouched();
  }

  async onSave() {
    await this.save(true);
  }

  async save(closeDialog: boolean) {
    if (this.branchForm.valid) {
      let arrayContact = [];
      arrayContact = this.getContacts(this.updateMode);
      let brokerBranch: BrokerBranch = {
        brokerName: this.updateMode ? this.brokerName.value : undefined,
        id: this.updateMode ? this.updateBranch.id : undefined,
        rowVersion: this.updateMode ? this.updateBranch.rowVersion : undefined,
        brokerId: this.updateMode ? this.updateBranch.brokerId : this.id,
        originalBranchId: this.branchId.value,
        originalBrokerId: this.brokerId.value,
        name: this.branchName.value,
        address1: this.branchAddress.address1.value,
        address2: this.branchAddress.address2.value,
        address3: this.branchAddress.address3.value,
        address4: this.branchAddress.address4.value,
        address5: this.branchAddress.address5.value,
        postcode: this.branchAddress.postcode.value,
        telephoneNumber: this.phonenumber.value,
        emailAddress: this.email.value,
        website: this.website.value,
        isActive: this.status.value,
        contacts: arrayContact,
        openGiBrokerId: this.externalType.value == ExternalBrokerType.OpenGi ? this.getExternalReferenceValue(this.externalReference.value) : null,
        sspBrokerId: this.externalType.value == ExternalBrokerType.Ssp ? this.getExternalReferenceValue(this.externalReference.value) : null,
        acturisBrokerId: this.externalType.value == ExternalBrokerType.Acturis ? this.getExternalReferenceValue(this.externalReference.value) : null,
        howdenBrokerId: this.externalType.value == ExternalBrokerType.Howden ? this.getExternalReferenceValue(this.externalReference.value) : null
      };
      try {
        if (!this.updateMode) {
          await this._httpService.postDataAsync<any>(this._api.createBrokerBranch(), brokerBranch);
          this.dialogService.openSuccessDialogConfirm({ title: TitleMessages.successTitle, message: 'The broker branch has been saved successfully' }).subscribe(result => {
          if (result) {
            if (closeDialog) {
              this.dialogRef.close(true);
            }
          }
        });
        }
        else {
          await this._httpService.putDataAsync<BrokerBranch>(this._api.updateBrokerBranch(), brokerBranch);
          if (this.updateBranch.contacts.length > brokerBranch.contacts.length) { // Delete contact
            let differences = _.differenceBy(this.updateBranch.contacts, brokerBranch.contacts, 'id');
            for (var i = 0; i < differences.length; i++) {
              await this._httpService.deleteDataAsync<boolean>(this._api.deleteContact(differences[i].id));
            }
          }
          this.dialogService.openSuccessDialogConfirm({ title: TitleMessages.successTitle, message: 'The broker branch has been updated successfully' }).subscribe(result => {
            if (result) {
              if (closeDialog) {
                this.dialogRef.close(true);
              }
            }
          });
        }
      }
      finally {
      }
    }
    else {
      return;
    }
  }

  getValidity() {
    if (this.branchForm.pristine
      && this.contactDetailFormComponent1.contactDetailForm.pristine
      && this.contactDetailFormComponent2.contactDetailForm.pristine
      && this.contactDetailFormComponent3.contactDetailForm.pristine
      && this.contactDetailFormComponent4.contactDetailForm.pristine
      && this.contactDetailFormComponent5.contactDetailForm.pristine) {
      return true;
    }
    return this.branchForm.invalid || !this.contactDetailFormComponent1.contactDetailForm.valid
    || !this.contactDetailFormComponent2.contactDetailForm.valid
    || !this.contactDetailFormComponent3.contactDetailForm.valid
    || !this.contactDetailFormComponent4.contactDetailForm.valid
    || !this.contactDetailFormComponent5.contactDetailForm.valid;
  }

  onCloseDialog(isReload: boolean) {
    if (this.branchForm.dirty || this.contactDetailFormComponent1.contactDetailForm.dirty ||
      this.contactDetailFormComponent2.contactDetailForm.dirty || this.contactDetailFormComponent3.contactDetailForm.dirty  ||
      this.contactDetailFormComponent4.contactDetailForm.dirty || this.contactDetailFormComponent5.contactDetailForm.dirty  || this.searchForm.dirty) {
      this.dialogService.openConfirmationDialog({ title: TitleMessages.confirmationTitle, message: 'Are you sure you want to continue. Your changes will be lost?' }).subscribe(result => {
        if (result) {
          this.dialogRef.close(isReload);
        }
      });
    }
    else {
      this.dialogRef.close(isReload);
    }
  }

  uppercase(value: string) {
    return value.toUpperCase();
  }

  getContacts(updateMode: boolean) {
    let arrayContact = [];
    if (this.contactDetailFormComponent1.contactName.value) {
      let finance = updateMode ? this.updateBranch.contacts.find(x => x.keyContact == this.keyContact.Finance) : undefined;
      arrayContact.push({
        id: updateMode && finance ? finance.id : undefined,
        keyContact: this.keyContact.Finance,
        rowVersion: updateMode && finance ? finance.rowVersion : undefined,
        name: this.contactDetailFormComponent1.contactName.value?.trim(),
        jobTitle: this.contactDetailFormComponent1.jobTitle.value?.trim(),
        phoneNumber: this.contactDetailFormComponent1.contactPhoneNumber.value?.trim(),
        email: this.contactDetailFormComponent1.emailAddress.value?.trim()
      });
    }
    if (this.contactDetailFormComponent2.contactName.value) {
      let compliance = updateMode ? this.updateBranch.contacts.find(x => x.keyContact == this.keyContact.Compliance): undefined;
      arrayContact.push({
        id: updateMode && compliance ? compliance.id : undefined,
        keyContact: this.keyContact.Compliance,
        rowVersion: updateMode && compliance ? compliance.rowVersion : undefined,
        name: this.contactDetailFormComponent2.contactName.value?.trim(),
        jobTitle: this.contactDetailFormComponent2.jobTitle.value?.trim(),
        phoneNumber: this.contactDetailFormComponent2.contactPhoneNumber.value?.trim(),
        email: this.contactDetailFormComponent2.emailAddress.value?.trim()
      });
    }
    if (this.contactDetailFormComponent3.contactName.value) {
      let pro = updateMode ? this.updateBranch.contacts.find(x => x.keyContact == this.keyContact.ProductUpdate): undefined;
      arrayContact.push({
        id: updateMode && pro ? pro.id : undefined,
        keyContact: this.keyContact.ProductUpdate,
        rowVersion: updateMode && pro ? pro.rowVersion : undefined,
        name: this.contactDetailFormComponent3.contactName.value?.trim(),
        jobTitle: this.contactDetailFormComponent3.jobTitle.value?.trim(),
        phoneNumber: this.contactDetailFormComponent3.contactPhoneNumber.value?.trim(),
        email: this.contactDetailFormComponent3.emailAddress.value?.trim()
      });
    }

    let emptyKeyContact = updateMode ? this.updateBranch.contacts.filter(x => x.keyContact == null) : [];
    if (this.contactDetailFormComponent4.contactName.value) {
      arrayContact.push({
        id: updateMode && emptyKeyContact.length > 0 ? emptyKeyContact[0].id : undefined,
        rowVersion: updateMode && emptyKeyContact.length > 0 ? emptyKeyContact[0].rowVersion : undefined,
        name: this.contactDetailFormComponent4.contactName.value?.trim(),
        jobTitle: this.contactDetailFormComponent4.jobTitle.value?.trim(),
        phoneNumber: this.contactDetailFormComponent4.contactPhoneNumber.value?.trim(),
        email: this.contactDetailFormComponent4.emailAddress.value?.trim()
      });
    }
    if (this.contactDetailFormComponent5.contactName.value) {
      arrayContact.push({
        id: updateMode && emptyKeyContact.length > 1 ? emptyKeyContact[1].id : undefined,
        rowVersion: updateMode && emptyKeyContact.length > 1 ? emptyKeyContact[1].rowVersion : undefined,
        name: this.contactDetailFormComponent5.contactName.value?.trim(),
        jobTitle: this.contactDetailFormComponent5.jobTitle.value?.trim(),
        phoneNumber: this.contactDetailFormComponent5.contactPhoneNumber.value?.trim(),
        email: this.contactDetailFormComponent5.emailAddress.value?.trim()
      });
    }
    return arrayContact;
  }

  formatPhoneNumber() {
    if (this.phonenumber.value) {
      let original = this.phonenumber.value as string;
      original = original.split(' ').join("");
      const formatted = Utility.formatPhoneNumber(original);
      if (original === formatted) {
        this.phonenumber.setErrors({ 'incorrect': true });
      }
      else {
        this.phonenumber.setValue(formatted);
        this.phonenumber.setErrors(null);
      }
    }
  }

  getExternalReferenceValue(value: string) {
    return value ? value : null;
  }
}
