import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthModuleConfig } from 'angular-oauth2-oidc';
import { firstValueFrom, forkJoin } from 'rxjs';

@Injectable()
export class ConfigurationService {

  private configuration!: IServerConfiguration;
  private clientConfiguration!: IClientConfiguration;
  constructor(
    private readonly router: Router,
    private readonly moduleConfig: OAuthModuleConfig,
    private readonly httpClient: HttpClient) { }

  async loadRuntimeConfig() {
    const result = await firstValueFrom(this.httpClient.get<IClientConfiguration>(`/assets/config.json`));
    this.moduleConfig.resourceServer.allowedUrls!.push(result.apiUrl);
    this.clientConfiguration = result;
    return result;
  }

  checkServices(reload: boolean = false) {
    const apiHealthCheck = this.httpClient.get(`${this.clientConfiguration.apiUrl}/api/v1/healthprobe`);
    const kspHealthCheck = this.httpClient.get(`${this.clientConfiguration.identityUrl}`, { responseType: 'text' });

    forkJoin([apiHealthCheck, kspHealthCheck]).subscribe({
      next: ([apiResponse, kspResponse]) => {
        console.log('API alive');
        console.log('KSP alive');
        if (reload) {
          window.location.href = '/app';
          //this.router.navigate(['/app']);
        }
      },
      error: error => {
        console.error('One or more services are down', error);
        this.router.navigate(['/app-unavailable']);
      }
    });
  }

  async loadConfig(apiUrl: string) {
    await firstValueFrom(this.httpClient.get<IServerConfiguration>(`${apiUrl}/api/v1/Configuration/admin`))
      .then(result => {
        this.configuration = (result) as IServerConfiguration;
      },
        error => {
          console.error(error);
        });
  }

  get apiBaseUrl() {
    return (this.configuration && this.configuration.apiBaseUrl);
  }

  get identityServerAddress() {
    return this.configuration && this.configuration.identityServerAddress;
  }

  get redirectUrl() {
    return this.configuration && this.configuration.redirectUrl;
  }

  get clientId() {
    return this.configuration && this.configuration && this.configuration.clientId;
  }

  get scope() {
    return this.configuration && this.configuration.scope;
  }

  get silentRefreshUrl() {
    return this.configuration && this.configuration.silentRefreshUrl;
  }

  get disableNonceCheck() {
    return this.configuration && this.configuration.disableNonceCheck;
  }
}

export interface IServerConfiguration {
  apiBaseUrl: string;
  clientId: string;
  identityServerAddress: string;
  redirectUrl: string;
  scope: string;
  silentRefreshUrl: string;
  disableNonceCheck: boolean;
}

export interface IClientConfiguration {
  apiUrl: string;
  identityUrl: string;
}
