import { Component, OnInit, Inject, AfterViewInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators, AbstractControl, FormGroup, NgForm } from '@angular/forms';
import { LevelOfCoverInformation } from 'src/app/models/level-of-cover';
import { ApiEndPoints } from 'src/app/shared/config/api-end-points';
import { HttpBaseService } from 'src/app/shared/services/http-base.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { Status } from 'src/app/models/enums/status.enum';
import { PricingInformation, PricingItem, BranchPricingInformation, BranchPricingItem } from 'src/app/models/pricing-item';
import moment from 'moment';
import { IptRateSetting } from 'src/app/models/system-setting';
import { PriceType } from 'src/app/models/enums/price-type.enum';
import { DEFAULT_EFFECTIVE_TO_DATE, DEFAULT_IPT_RATE, FORMAT_ONLY_DATE, LeiConfig, TitleMessages } from 'src/app/shared/constants/constants';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit, AfterViewInit {

  form: FormGroup;
  parentForm: FormGroup;

  defaultEffectiveTo = moment(new Date(DEFAULT_EFFECTIVE_TO_DATE));
  defaultEffectiveFrom = moment(new Date());

  levelOfCover: LevelOfCoverInformation;
  updateMode = false;
  branchPricingMode = false;
  updatePricing: PricingItem;
  updateBranchPricing: BranchPricingItem;

  PriceType = PriceType;
  Status = Status;

  iptRate$: BehaviorSubject<number> = new BehaviorSubject<number>(DEFAULT_IPT_RATE);

  isReadonly: boolean = false;
  isEffectiveFromDisabled: boolean = false;
  isValidDate: boolean = true;
  isValidToDate: boolean = true;
  brokerBranchId: string = '';
  createNewButtonText: string = '';
  createNewButtonTooltipText: string = '';
  selectedPricingId: string;

  get minEffectiveFromDate() {
    return this.isReadonly || this.isEffectiveFromDisabled ? undefined : new Date();
  }

  get minEffectiveToDate() {
    const today = new Date();
    return today;
  }

  reloadHistory$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);

  @ViewChild('formDirective') private formDirective: NgForm;

  constructor(
    private readonly _httpService: HttpBaseService,
    private readonly _api: ApiEndPoints,
    public _dialogService: DialogService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<PricingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.createForm();
  }

  async ngOnInit() {
    this.iptRate.setValue(DEFAULT_IPT_RATE);
    const addPricing = this.data.addPricing;
    const brokerBranchId = this.data.brokerBranchId;

    if (addPricing) {
      this.updateMode = false;
      this.levelOfCover = this.data.levelOfCover;

      this.bindingProductSubProductLevelOfCover(this.levelOfCover.productName, this.levelOfCover.subProductName, this.levelOfCover.name);

      await this.bindingCurrentStandardPricing(this.levelOfCover.id, this.defaultEffectiveFrom.format(FORMAT_ONLY_DATE));

      this.createNewButtonText = 'Create New Standard Price';
      this.createNewButtonTooltipText = 'Click here to create a new standard price record';
    } else {
      this.updateMode = true;

      this.levelOfCover = {
        id: this.data.pricingInformation.levelOfCoverId,
        rowVersion: undefined,
        name: this.data.pricingInformation.levelOfCoverName,
        description: undefined,
        isActive: false,
        order: 0,
        productId: this.data.pricingInformation.productId,
        subProductId: this.data.pricingInformation.subProductId,
        productName: this.data.pricingInformation.productName,
        subProductName: this.data.pricingInformation.subProductName
      };
      this.selectedPricingId = this.data.pricingInformation.id;
      this.bindingProductSubProductLevelOfCover(this.data.pricingInformation.productName, this.data.pricingInformation.subProductName, this.data.pricingInformation.levelOfCoverName);

      if (brokerBranchId) {
        this.brokerBranchId = brokerBranchId;
        this.branchPricingMode = true;
        let branchPricingInfo = this.data.pricingInformation as BranchPricingInformation;
        this.getBranchPricingData(branchPricingInfo);
        this.createNewButtonText = 'Create New Custom Price';
        this.createNewButtonTooltipText = 'Click here to create a new custom price record';
      }
      else {
        this.branchPricingMode = false;
        let pricingInfo = this.data.pricingInformation as PricingInformation;
        this.getPricingData(pricingInfo);
        this.createNewButtonText = 'Create New Standard Price';
        this.createNewButtonTooltipText = 'Click here to create a new standard price record';
      }
    }
    this.disableControls(true);
    this.priceType.disable({ emitEvent: false });
    await this.getIptRate(this.defaultEffectiveFrom.format(FORMAT_ONLY_DATE));
  }

  selectPricing($event) {
    this.updateMode = true;
    this.disableControls(false);
    if (this.branchPricingMode) {
      let branchPricingInfo = $event as BranchPricingInformation;
      this.getBranchPricingData(branchPricingInfo);
    }
    else {
      let pricingInfo = $event as PricingInformation;
      this.getPricingData(pricingInfo);
    }
  }

  getBranchPricingData(branchPricingInfo: BranchPricingInformation) {
    this._httpService.getData<BranchPricingItem>(this._api.getPricingForBranch(this.brokerBranchId, branchPricingInfo.id)).subscribe(p => {
      this.updateBranchPricing = p;

      const today = new Date();
      let tomorrow = new Date(today);
      tomorrow.setDate(today.getDate() + 1);

      this.isReadonly = !p.isCustom || moment(today).isAfter(p.effectiveDateTo, 'day');
      this.isEffectiveFromDisabled = moment(tomorrow).isAfter(p.effectiveDateFrom, 'day');
      this.bindingDataForUpdate(this.updateBranchPricing);
      if (!p.isCustom) {
        this.disableControls(true);
      }
      else {
        this.disableControls(!this.updateBranchPricing.isActive);
      }
      if (this.isEffectiveFromDisabled) {
        this.disableFromEditPrice();
      }
    });
  }

  getPricingData(pricingInfo: PricingInformation) {
    this._httpService.getData<PricingItem>(this._api.getPricing(pricingInfo.id)).subscribe(p => {
      this.updatePricing = p;

      const today = new Date();
      let tomorrow = new Date(today);
      tomorrow.setDate(today.getDate() + 1);

      this.isReadonly = moment(today).isAfter(p.effectiveDateTo, 'day');
      this.isEffectiveFromDisabled = moment(tomorrow).isAfter(p.effectiveDateFrom, 'day');
      this.bindingDataForUpdate(this.updatePricing);

      this.disableControls(!this.updatePricing.isActive);

      if (this.isEffectiveFromDisabled) {
        this.disableFromEditPrice();
      }
    });
  }

  createForm() {
    this.form = this.formBuilder.group({
      productControl: [],
      subProductControl: [],
      levelOfCoverControl: [],
      priceType: [PriceType.Standard, [Validators.required]],
      brokerRate: ['', [Validators.required]],
      brokerIncIPT: ['', [Validators.required]],
      minimumSaleRate: ['', [Validators.required]],
      maximumRetailRate: [''],
      underwriterRate: ['', [Validators.required]],
      intermediaryCommission: ['', [Validators.required]],
      status: [Status.Active, [Validators.required]],
      effectiveDateFrom: ['', [Validators.required]],
      effectiveDateTo: [''],
      iptRate: ['', [Validators.required]],
    });
  }

  public get productControl() {
    return this.form.get('productControl') as AbstractControl;
  }

  public get subProductControl() {
    return this.form.get('subProductControl') as AbstractControl;
  }

  public get levelOfCoverControl() {
    return this.form.get('levelOfCoverControl') as AbstractControl;
  }

  public get priceType() {
    return this.form.get('priceType') as AbstractControl;
  }

  public get brokerRate() {
    return this.form.get('brokerRate') as AbstractControl;
  }

  public get brokerIncIPT() {
    return this.form.get('brokerIncIPT') as AbstractControl;
  }

  public get minimumSaleRate() {
    return this.form.get('minimumSaleRate') as AbstractControl;
  }

  public get maximumRetailRate() {
    return this.form.get('maximumRetailRate') as AbstractControl;
  }

  public get underwriterRate() {
    return this.form.get('underwriterRate') as AbstractControl;
  }

  public get intermediaryCommission() {
    return this.form.get('intermediaryCommission') as AbstractControl;
  }

  public get status() {
    return this.form.get('status') as AbstractControl;
  }

  get effectiveDateFrom() {
    return this.form.get('effectiveDateFrom') as AbstractControl;
  }

  get effectiveDateTo() {
    return this.form.get('effectiveDateTo') as AbstractControl;
  }

  get iptRate() {
    return this.form.get('iptRate') as AbstractControl;
  }

  async bindingCurrentStandardPricing(levelOfCoverId: string, dateEffective: string) {
    let pricing = await this._httpService.getDataAsync<PricingItem>(this._api.getCurrentPricingByLevelOfCover(levelOfCoverId, dateEffective));
    if (pricing) {
      this.updateMode = true;
      this.updatePricing = pricing;
      this.bindingDataForUpdate(pricing);
    }
  }

  ngAfterViewInit(): void {
    this.status.valueChanges.subscribe(statusValue => {
      if (statusValue === Status.Inactive) {
        this._dialogService.openConfirmationDialog({
          title: TitleMessages.warningTitle,
          message: 'Changing the status to Inactive will update the Effective Date To to the current day. Do you want to continue?'
        }).subscribe(result => {
          if (result) {
            this.effectiveDateTo.setValue(moment().format(FORMAT_ONLY_DATE), { emitEvent: false });
          } else {
            this.status.setValue(Status.Active, { emitEvent: false });
          }
        });
      }
    });

    this.isValidDate = true;
    this.isValidToDate = true;
    this.effectiveDateFrom.valueChanges
      .pipe(
        debounceTime(LeiConfig.delayBeforeSearchingWithAutoSearchFields),
        distinctUntilChanged()
      ).subscribe(async value => {
        if (value) {
          this.isValidDate = true;

          if (this.updatePricing && this.updatePricing.isActive || this.updateBranchPricing && this.updateBranchPricing.isActive) {
            this.validateEffectiveDateFrom(value);
          }

          await this.getIptRate(moment(value).format(FORMAT_ONLY_DATE));
        } else {
          this.isValidDate = false;
        }
      });

    this.effectiveDateTo.valueChanges
      .pipe(
        debounceTime(LeiConfig.delayBeforeSearchingWithAutoSearchFields),
        distinctUntilChanged()
      ).subscribe(value => {
        if (value) {
          this.isValidToDate = true;

          if (this.updatePricing && this.updatePricing.isActive || this.updateBranchPricing && this.updateBranchPricing.isActive) {
            this.validateEffectiveDateTo(value);
          }

        } else {
          this.isValidToDate = false;
        }
      });

    this.priceType.valueChanges.subscribe(v => {
      if (v === PriceType.Standard) {
        this.bindingCurrentStandardPricing(this.levelOfCover.id, this.defaultEffectiveFrom.format(FORMAT_ONLY_DATE));
      }
    });

    combineLatest(
      this.brokerRate.valueChanges.pipe(
        debounceTime(LeiConfig.delayBeforeSearchingWithAutoSearchFields),
        distinctUntilChanged()
      ),
      this.iptRate$
    ).pipe(
      distinctUntilChanged(([leftBrokerRate, leftIpT], [rightBrokerRate, rightIpt]) => {
        return leftBrokerRate === rightBrokerRate && leftIpT === rightIpt;
      })
    )
      .subscribe(([brokerRate, ipt]) => {
        if (brokerRate) {
          let brokerRateNumber = +brokerRate;
          let brokerIncIPT = brokerRateNumber + (brokerRateNumber * ipt / 100);

          this.brokerIncIPT.setValue(brokerIncIPT.toFixed(2));
          this.minimumSaleRate.setValue(brokerIncIPT.toFixed(2));

          let intermediaryCommissionValue = brokerRateNumber - (+this.underwriterRate.value);

          this.intermediaryCommission.setValue(intermediaryCommissionValue.toFixed(2));
        }
      });

    this.underwriterRate.valueChanges.subscribe(underWrite => {
      let underWriteNewValue = +underWrite;
      let intermediaryCommissionValue = (+this.brokerRate.value) - underWriteNewValue;
      this.intermediaryCommission.setValue(intermediaryCommissionValue.toFixed(2));
    });
  }

  bindingProductSubProductLevelOfCover(productName, subProductName, levelOfCoverName) {
    this.productControl.setValue(productName);
    this.subProductControl.setValue(subProductName);
    this.levelOfCoverControl.setValue(levelOfCoverName);
  }

  bindingDataForUpdate(pricing: any) {
    this.priceType.setValue(pricing.isCustom ? PriceType.Custom : PriceType.Standard, { emitEvent: false });

    this.brokerRate.setValue(parseFloat(pricing.brokerRate).toFixed(2), { emitEvent: false });

    this.brokerIncIPT.setValue(parseFloat(pricing.brokerRateIncludeIPT).toFixed(2), { emitEvent: false });

    this.minimumSaleRate.setValue(parseFloat(pricing.minimumSaleRate).toFixed(2), { emitEvent: false });

    this.maximumRetailRate.setValue(pricing.maximumRetailRate ? parseFloat(pricing.maximumRetailRate).toFixed(2) : '', { emitEvent: false });

    this.underwriterRate.setValue(parseFloat(pricing.underwriterRate).toFixed(2), { emitEvent: false });

    this.intermediaryCommission.setValue(parseFloat(pricing.intermediaryCommission).toFixed(2), { emitEvent: false });

    this.status.setValue(pricing.isActive ? Status.Active : Status.Inactive, { emitEvent: false });

    this.effectiveDateFrom.setValue(pricing.effectiveDateFrom, { emitEvent: false });

    this.effectiveDateTo.setValue(pricing.effectiveDateTo, { emitEvent: false });

    this.form.markAsUntouched();

    this.effectiveDateTo.updateValueAndValidity();
    this.effectiveDateFrom.updateValueAndValidity();
  }

  reset() {
    this.updateMode = false;
    this.isReadonly = false;
    this.disableControls(false);
    this.isEffectiveFromDisabled = false;

    setTimeout(async () => {
      this.formDirective.resetForm();

      this.bindingProductSubProductLevelOfCover(this.levelOfCover.productName, this.levelOfCover.subProductName, this.levelOfCover.name);

      if (this.branchPricingMode) {
        this.priceType.setValue(PriceType.Custom, { emitEvent: false });
        this.priceType.disable({ emitEvent: false });
      } else {
        this.priceType.setValue(PriceType.Standard, { emitEvent: false });
      }

      this.status.setValue(Status.Active);

      let pricing = await this._httpService.getDataAsync<PricingItem>(this._api.getCurrentPricingByLevelOfCover(this.levelOfCover.id, this.defaultEffectiveFrom.format(FORMAT_ONLY_DATE)));
      if (pricing && this.branchPricingMode) {
        this.maximumRetailRate.setValue(pricing.maximumRetailRate, { emitEvent: false });
        this.underwriterRate.setValue(pricing.underwriterRate, { emitEvent: false });
      }

      this.form.markAsUntouched();
      this.form.markAsPristine();
    }, 0);
  }

  disableControls(option: boolean) {
    if (option) {
      this.brokerRate.disable({ emitEvent: false });
      this.minimumSaleRate.disable();
      this.maximumRetailRate.disable();
      this.underwriterRate.disable();
      this.status.disable({ emitEvent: false });
      this.effectiveDateFrom.disable({ emitEvent: false });
      this.effectiveDateTo.disable({ emitEvent: false });
      this.intermediaryCommission.disable();
      this.brokerIncIPT.disable();
    }
    else {
      this.brokerRate.enable({ emitEvent: false });
      this.minimumSaleRate.enable();
      this.maximumRetailRate.enable();
      this.underwriterRate.enable();
      this.status.enable({ emitEvent: false });
      this.effectiveDateFrom.enable({ emitEvent: false });
      this.effectiveDateTo.enable({ emitEvent: false });
      this.intermediaryCommission.enable();
      this.brokerIncIPT.enable();
    }

  }

  disableFromEditPrice() {
    this.brokerRate.disable({ emitEvent: false });
    this.minimumSaleRate.disable();
    this.maximumRetailRate.disable();
    this.underwriterRate.disable();
  }


  async save(isClose: boolean) {
    if (this.form.valid) {
      this.disableControls(false);

      if (this.branchPricingMode && this.brokerBranchId) {
        await this.saveBrokerPricing(isClose);
      }
      else {
        let message = 'You have changed the Underwriter Rate for this product, this will change the Underwriter Rate for all standard and custom pricing for this product.';
        let htmlContent = '<p>Would you like to continue?</p>'

        if (!this.updateMode || (this.updatePricing && this.updatePricing.underwriterRate != this.underwriterRate.value)) {
          this._dialogService.openConfirmationDialog({ title: TitleMessages.confirmationTitle, message: message, htmlContent: htmlContent }).subscribe(async result => {
            if (result) {
              await this.savePricing(isClose);
            }
          });
        }
        else {
          await this.savePricing(isClose);
        }
      }
    }
  }

  async savePricing(isClose: boolean) {
    let pricing: PricingItem = {
      id: this.updateMode ? this.updatePricing.id : undefined,
      rowVersion: this.updateMode ? this.updatePricing.rowVersion : undefined,
      levelOfCoverId: this.updateMode ? this.updatePricing.levelOfCoverId : this.levelOfCover.id,
      description: this.updateMode ? this.updatePricing.levelOfCover.name : this.levelOfCover.name, //description = level of cover name
      priceType: 'Standard',
      brokerRate: +this.brokerRate.value,
      brokerRateIncludeIPT: +this.brokerIncIPT.value,
      minimumSaleRate: +this.minimumSaleRate.value,
      maximumRetailRate: this.maximumRetailRate.value,
      underwriterRate: +this.underwriterRate.value,
      intermediaryCommission: +this.intermediaryCommission.value,
      isActive: this.status.value,
      effectiveDateFrom: moment(this.effectiveDateFrom.value).format(FORMAT_ONLY_DATE),
      effectiveDateTo: moment(this.effectiveDateTo.value ? this.effectiveDateTo.value : this.defaultEffectiveTo).format(FORMAT_ONLY_DATE),
      changedByUserName: undefined,
      changedAt: undefined,
      levelOfCover: this.updateMode ? this.updatePricing.levelOfCover : undefined
    };

    let confirmMessage = 'Your changes have been successfully saved.';
    try {
      if (!this.updateMode) {
        pricing.id = await this._httpService.postDataAsync<string>(this._api.createPricing(), pricing);
      }
      else {
        this.updatePricing = await this._httpService.putDataAsync<PricingItem>(this._api.updatePricing(), pricing);
      }

      this._dialogService.openSuccessDialogConfirm({ title: TitleMessages.successTitle, message: confirmMessage, disableClose: true }).subscribe(result => {
        if (result) {
          if (isClose) {
            this.dialogRef.close(true);
          }
          else {
            this.reloadHistory$.next(true);
            this.reset();
          }
        }
      });
    }
    finally {
    }
  }

  async saveBrokerPricing(isClose: boolean) {
    if (!this.updateBranchPricing.isCustom && this.priceType.value === PriceType.Custom) { //Create new custom price
      this.updateMode = false;
    }

    let pricing: BranchPricingItem = {
      id: this.updateMode ? this.updateBranchPricing.id : undefined,
      rowVersion: this.updateMode ? this.updateBranchPricing.rowVersion : undefined,
      brokerBranchId: this.brokerBranchId,
      levelOfCoverId: this.updateMode ? this.updateBranchPricing.levelOfCoverId : this.levelOfCover.id,
      description: this.updateMode ? this.updateBranchPricing.levelOfCover.name : this.levelOfCover.name,
      isCustom: this.priceType.value === PriceType.Custom ? true : false,
      brokerRate: +this.brokerRate.value,
      brokerRateIncludeIPT: +this.brokerIncIPT.value,
      minimumSaleRate: +this.minimumSaleRate.value,
      maximumRetailRate: this.maximumRetailRate.value,
      underwriterRate: +this.underwriterRate.value,
      intermediaryCommission: +this.intermediaryCommission.value,
      isActive: this.status.value,
      effectiveDateFrom: moment(this.effectiveDateFrom.value).format(FORMAT_ONLY_DATE),
      effectiveDateTo: moment(this.effectiveDateTo.value ? this.effectiveDateTo.value : this.defaultEffectiveTo).format(FORMAT_ONLY_DATE),
      changedByUserName: undefined,
      changedAt: undefined,
      levelOfCover: this.updateMode ? this.updateBranchPricing.levelOfCover : undefined
    };

    const confirmMessage = 'Your changes have been successfully saved.';
    try {
      if (!this.updateMode) {
        pricing.id = await this._httpService.postDataAsync<string>(this._api.createBranchPricing(this.brokerBranchId), pricing);
      } else {
        this.updateBranchPricing = await this._httpService.putDataAsync<BranchPricingItem>(this._api.updateBranchPricing(this.brokerBranchId), pricing);
      }

      this._dialogService.openSuccessDialogConfirm({ title: TitleMessages.successTitle, message: confirmMessage, disableClose: true }).subscribe(result => {
        if (result) {
          if (isClose) {
            this.dialogRef.close(true);
          } else {
            this.reloadHistory$.next(true);
            this.reset();
          }
        }
      });
    }
    finally {
    }
  }

  async validateEffectiveDateFrom(enterdDate: any) {
    if (this.effectiveDateTo.value && moment(enterdDate, FORMAT_ONLY_DATE) > moment(this.effectiveDateTo.value, FORMAT_ONLY_DATE)) {
      this._dialogService.openErrorDialog({
        title: TitleMessages.warningTitle,
        message: 'The effective to date entered is less than the effective from date entered. Please check and re-enter the date!'
      });
      this.effectiveDateFrom.setValue('', { emitEvent: false });
      return;
    }
  }

  async validateEffectiveDateTo(enterdDate: any) {
    if (this.effectiveDateFrom.value && moment(this.effectiveDateFrom.value, FORMAT_ONLY_DATE) > moment(enterdDate, FORMAT_ONLY_DATE)) {
      this._dialogService.openErrorDialog({
        title: TitleMessages.warningTitle,
        message: 'The effective to date entered is less than the effective from date entered. Please check and re-enter the date!'
      });
      this.effectiveDateTo.setValue('', { emitEvent: false });
      return;
    }
  }

  onCloseDialog(isReload: boolean) {
    if (this.form.dirty) {
      this._dialogService.openConfirmationDialog({
        title: TitleMessages.confirmationTitle,
        message: 'Are you sure you want to continue. Your changes will be lost?'
      }).subscribe(result => {
        if (result) {
          this.dialogRef.close(isReload);
        }
      });
    }
    else {
      this.dialogRef.close(isReload);
    }
  }

  async getIptRate(effectiveDate: string) {
    try {
      let iptRate = await this._httpService.getDataAsync<IptRateSetting>(this._api.getValidIptRate(effectiveDate));
      const iptRateValue = iptRate ? iptRate.value : DEFAULT_IPT_RATE;
      this.iptRate.setValue(iptRateValue);
      this.iptRate$.next(iptRateValue);
    }
    catch (ex) {
      if (ex.status === 424 && ex.error === "IPT Rate is not found.") {
        this.iptRate.setValue('');
      }
    }
  }

  convertFloatValue(controlName: string, value: any) {
    if (value) {
      this[controlName].setValue(parseFloat(value).toFixed(2), { emitEvent: false });
    }
    else {
      this[controlName].setValue('', { emitEvent: false });
    }
  }
}
