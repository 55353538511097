<mat-card>
  <mat-card-header>
    <mat-card-title><strong>User Access Reports</strong></mat-card-title>
    <mat-card-subtitle>View user access reports here.</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content [formGroup]="filterForm">
    <mat-form-field class="select-type">
      <mat-label> Broker Group Name</mat-label>
      <input matInput [matAutocomplete]="autobbG" #autoCompleteInputBbg formControlName="brokerGroup" required
        placeholder="Enter Broker Group Name" matTooltip="Start typing to search">
      <button class="close-icon" *ngIf="!searchGroupIsEmpty" (click)="resetGroup()"><mat-icon>clear</mat-icon></button>
      <mat-icon class="search-icon" matSuffix svgIcon="magnify"></mat-icon>
      <mat-error *ngIf="brokerGroup.invalid">You must select a value from the list options</mat-error>
      <mat-autocomplete [autoActiveFirstOption]="true" #autobbG="matAutocomplete" md-menu-class="autocomplete">
        <mat-option *ngFor="let option of filteredBrokerGroupOptions | async" [value]="option.name"
          (onSelectionChange)="getSelectedBrokerGroup($event,option)">
          <span>{{ option.name}}</span>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field class="select-type">
      <mat-label> Broker Name</mat-label>
      <input matInput [matAutocomplete]="autoB" #autoCompleteInputB formControlName="broker" required
        placeholder="Enter Broker Name" matTooltip="Start typing to search">
      <button class="close-icon" *ngIf="!searchBrokerIsEmpty"
        (click)="resetBroker()"><mat-icon>clear</mat-icon></button>
      <mat-icon class="search-icon" matSuffix svgIcon="magnify"></mat-icon>
      <mat-error *ngIf="broker.invalid">You must select a value from the list options</mat-error>
      <mat-autocomplete [autoActiveFirstOption]="true" #autoB="matAutocomplete" md-menu-class="autocomplete">
        <mat-option *ngFor="let option of filteredBrokerOptions | async" [value]="option.name"
          (onSelectionChange)="getSelectedBroker($event,option)">
          <span>{{ option.name}}</span>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field class="select-type">
      <mat-label> Broker Branch Name</mat-label>
      <input matInput [matAutocomplete]="autobb" #autoCompleteInputBb formControlName="brokerBranch" required
        placeholder="Enter Broker Branch Name" matTooltip="Start typing to search">
      <button class="close-icon" *ngIf="!searchBrokerBranchIsEmpty"
        (click)="resetBrokerBranch()"><mat-icon>clear</mat-icon></button>
      <mat-icon class="search-icon" matSuffix svgIcon="magnify"></mat-icon>
      <mat-error *ngIf="brokerBranch.invalid">You must select a value from the list options</mat-error>
      <mat-autocomplete [autoActiveFirstOption]="true" #autobb="matAutocomplete" md-menu-class="autocomplete">
        <mat-option *ngFor="let option of filteredBrokerBranchOptions | async" [value]="option.name"
          (onSelectionChange)="getSelectedBrokerBranch($event,option)">
          <span>{{ option.name}}</span>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field class="select-type">
      <mat-label> Select status from dropdown </mat-label>
      <mat-select data-test="status" placeholder="Select status from dropdown" formControlName="status">
        <mat-option value=''>{{allOptionTxt}}</mat-option>
        <mat-option *ngFor="let status of Status.values()" [value]="Status[status] == 1 ? true : false">
          {{status}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <button [disabled]="!isValidSubmit" matTooltip="Submit" mat-raised-button color="primary" (click)="onSubmit()"
      class="btn-submit">
      Submit
    </button>
  </mat-card-content>
</mat-card>

<div class="not-found" *ngIf="notFoundMessage">{{notFoundMessage}}</div>
<mat-card>
  <div class="outline" [style]="getTableStyle()">
    <table mat-table matSort matSortActive="brokerName" matSortDirection="asc" matSortDisableClear #table
      [dataSource]="dataSource">
      <ng-container matColumnDef="brokerName">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Broker Name </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <div class="ellipsis" matTooltip={{row.brokerName}}>{{row.brokerName}}</div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="brokerBranch">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Broker Branch Name </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <div class="ellipsis" matTooltip={{row.brokerBranch}}>{{row.brokerBranch}}</div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="userName">
        <mat-header-cell *matHeaderCellDef mat-sort-header> User Name </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <div class="ellipsis" matTooltip={{row.userName}}>{{row.userName}}</div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="userEmail">
        <mat-header-cell *matHeaderCellDef mat-sort-header> User Email </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <div class="ellipsis" matTooltip={{row.userEmail}}>{{row.userEmail}} </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="firstLoginDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header> First Login Date </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.firstLoginDate | date: appConstants.dateConfig.date}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="lastLoginDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Last Login Date </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.lastLoginDate | date: appConstants.dateConfig.date}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="totalLogins">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Total Login </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.totalLogins}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="activeStatus">
        <mat-header-cell *matHeaderCellDef> Active Status </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.isActiveText}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="inActiveDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Inactive Date </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.inActiveDate | date: appConstants.dateConfig.date}} </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row matRipple *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </table>
    <div class="display-flex flex-direction-row footer">
      <div class="btn-group flex-50">
        <button class="btnExport" [disabled]="!(dataSource && dataSource.length > 0)" matTooltip="Export to Excel" mat-raised-button
          color="primary" (click)="exportExcel()">
          Export to Excel
        </button>
        <button class="btnExport" [disabled]="!(dataSource && dataSource.length > 0)" matTooltip="Export to CSV" mat-raised-button
          color="primary" (click)="exportCsv()">
          Export to CSV
        </button>
        <button class="btnExport" [disabled]="!(dataSource && dataSource.length > 0)" matTooltip="Export to PDF" mat-raised-button
          color="primary" (click)="exportPdf()">
          Export to PDF
        </button>
      </div>
      <div class="paging flex-50">
        <mat-paginator [length]="totalRows" [pageIndex]="pagingConstant.pageIndex"
          [pageSize]="pagingConstant.pageSizeLarger" [pageSizeOptions]="pagingConstant.pageSizeOptionsLarger"
          showFirstLastButtons [disabled]="!isValidSubmit">
        </mat-paginator>
      </div>
    </div>

  </div>
</mat-card>
