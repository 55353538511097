<div class="detail">
  <div *ngIf="isShow" class="include-items" [formGroup]="filterForm">
    <label id="include-inactive-items" class="label-include"><span><strong>Only Error Records:</strong></span>
    </label>
    <mat-radio-group formControlName="isOnlyError">
      <mat-radio-button data-test="yesRadio" value="true" [checked]="isOnlyError.value === true">Yes
      </mat-radio-button>
      <mat-radio-button data-test="noRadio" value="false" [checked]="isOnlyError.value === false">No
      </mat-radio-button>
    </mat-radio-group>
  </div>
  <div class="not-found"><span class="not-found-txt">{{notFound}}</span></div>

  <div [style]="getStyle()">
    <table class="sub-table" mat-table [dataSource]="data">

      <ng-container matColumnDef="dateImport">
        <th mat-header-cell *matHeaderCellDef> Date Import File Received </th>
        <td mat-cell *matCellDef="let element"> {{element.importedDate| date: appConstants.dateConfig.date}} </td>
      </ng-container>

      <ng-container matColumnDef="fileName">
        <th mat-header-cell *matHeaderCellDef> Import File Name</th>
        <td mat-cell *matCellDef="let element"> {{element.importedFileName}} </td>
      </ng-container>

      <ng-container matColumnDef="productName">
        <th mat-header-cell *matHeaderCellDef> Product Name</th>
        <td mat-cell *matCellDef="let element"> {{element.productName}} </td>
      </ng-container>

      <ng-container matColumnDef="policyNumber">
        <th mat-header-cell *matHeaderCellDef> Policy Number</th>
        <td mat-cell *matCellDef="let element"> {{element.policyNumber}} </td>
      </ng-container>

      <ng-container matColumnDef="policyHolder">
        <th mat-header-cell *matHeaderCellDef> Policy Holder</th>
        <td mat-cell *matCellDef="let element"> {{element.policyHolder}} </td>
      </ng-container>

      <ng-container matColumnDef="transactionType">
        <th mat-header-cell *matHeaderCellDef> Transaction Type</th>
        <td mat-cell *matCellDef="let element"> {{element.transactionType}} </td>
      </ng-container>

      <ng-container matColumnDef="inceptionDate">
        <th mat-header-cell *matHeaderCellDef> Inception Date </th>
        <td mat-cell *matCellDef="let element"> {{element.inceptionDate| date: appConstants.dateConfig.date}} </td>
      </ng-container>

      <ng-container matColumnDef="errorDescription">
        <th mat-header-cell *matHeaderCellDef> Error Description </th>
        <td mat-cell *matCellDef="let element"> {{element.errorDescription}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumnsub"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsub;"></tr>
    </table>

    <div class="display-flex flex-direction-row footer">
      <div class="btn-group flex-50">
        <button matTooltip="Export to Excel" mat-raised-button color="primary" class="btn-submit" (click)="exportExcel()">
          Export to Excel
        </button>
        <button matTooltip="Export to CSV" mat-raised-button color="primary" class="btn-submit" (click)="exportCSV()">
          Export to CSV
        </button>
      </div>

      <div class="paging flex-50">
        <mat-paginator [length]="totalRows" [pageIndex]="pagingConstant.pageIndex"
          [pageSize]="pagingConstant.pageSizeLarger" [pageSizeOptions]="pagingConstant.pageSizeOptionsLarger"
          showFirstLastButtons>
        </mat-paginator>
      </div>
    </div>
  </div>
</div>
