import { Component, ViewChild } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { tap } from 'rxjs';
import { ReportsHistoryLine } from 'src/app/models/reports-history-response';
import { ReportHistoryService } from 'src/app/services/report-history.service';
import { ApiEndPoints } from 'src/app/shared/config/api-end-points';
import { AppConstants, NO_RESULTS_FOUND, PagingConstant, TitleMessages } from 'src/app/shared/constants/constants';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { HttpBaseService } from 'src/app/shared/services/http-base.service';
import { DeclarationsReportComponent } from '../declarations-report/declarations-report.component';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Provider } from 'src/app/models/enums/provider.enum';
import { FilterResponse } from 'src/app/models/user-access-report-response';
import { SatPopover } from '@ncstate/sat-popover';
import { Utility } from 'src/app/utils/utility';

@Component({
  selector: 'app-reports-history',
  templateUrl: './reports-history.component.html',
  styleUrls: ['./reports-history.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ]
})
export class ReportsHistoryComponent {

  displayedColumns = [
    'expandbutton',
    'period',
    'upload'
  ];
  displayedColumnsItem = [
    'provider',
    'insurer',
    'product',
    'subProduct',
    'createdBy',
    'createdAt',
    'view',
    'delete',
  ];

  public appConstants = AppConstants;
  pagingConstant = PagingConstant;
  pageSizeOptions: number[] = PagingConstant.pageSizeOptions;
  totalRows: number = 0;
  notFoundMessage = NO_RESULTS_FOUND;
  providerList: FilterResponse[] = [];

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(SatPopover) popover: any;

  dataSource!: ReportsHistoryLine[];
  expandedElement: string[] = [];

  constructor(private readonly _httpService: HttpBaseService,
    private readonly _dialogService: DialogService,
    private readonly _reportHistoryService: ReportHistoryService,
    private readonly _api: ApiEndPoints,) {

  };

  async ngOnInit() {
    this.providerList = await this._reportHistoryService.getProvider();
  }

  reload() {
    this.loadData();
  }

  loadData() {
    const pageIndex = this.paginator.pageIndex;
    const pageSize = this.paginator.pageSize;
    this._reportHistoryService.getReportHistories(pageIndex, pageSize)
      .subscribe(report => {
        this.dataSource = report.items;
        this.totalRows = report.total;
      });
  }

  toggleRow(row: ReportsHistoryLine) {
    const index = this.expandedElement.findIndex(x => x == row.id);
    if (index === -1) {
      this.expandedElement.push(row.id);
    } else {
      this.expandedElement.splice(index, 1);
    }
  }

  popoverOpen(popover: SatPopover, row: ReportsHistoryLine) {
    const index = this.expandedElement.indexOf(row.id);
    if (index > -1) {
      this.expandedElement.splice(index, 1);
    }
    setTimeout(() => {
      popover.open();
    }, 200);//to make upload display after table collapsed
  }

  isExpanded(row: ReportsHistoryLine): string {
    if (!this.expandedElement) {
      return 'collapsed';
    }
    if (
      this.expandedElement.findIndex(x => x == row.id) !== -1
    ) {
      return 'expanded';
    }
    return 'collapsed';
  }

  downloadPayment(historyItemId: string, period: Date) {
    const periodMonth = Utility.getMonthName(period);
    const fileName = `PaymentRequisition ${periodMonth}_${new Date().toLocaleDateString("en-UK")}`;

    let url = this._api.exportPaymentFile(historyItemId);
    this._httpService.downloadFile(url).subscribe(
      (data: BlobPart) => {
        const blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        let fileURL = window.URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.style.display = "none";
        link.href = fileURL;
        link.download = fileName;
        link.target = '_blank';
        link.click();

        setTimeout(() => {
          link.remove();
          window.URL.revokeObjectURL(fileURL);
        }, 100);

      }
    );
  }

  viewDeclaration(historyItemId: string, provider: string) {
    let isArag = provider != this.providerList.find(x => x.id == (Provider.LegalProtectionGroup).toString()).name;
    let configDialog = new MatDialogConfig();
    configDialog = {
      position: {
        top: '10px',
        right: '10px',
        left: '10px'
      },
      height: '98%',
      width: '98vw',
      panelClass: 'history-dialog',
    };

    if (isArag) {
      this._reportHistoryService.getARAGDeclarationFromHistory(historyItemId)
        .subscribe(report => {
          this._dialogService.openComponentDialog(DeclarationsReportComponent, { isDialog: true, source: report, historyItemId: historyItemId, isLPG: false }, configDialog).subscribe(async result => {
          });
        });
    }
    else {
      this._reportHistoryService.getLPGDeclarationFromHistory(historyItemId)
        .subscribe(report => {
          this._dialogService.openComponentDialog(DeclarationsReportComponent, { isDialog: true, source: report, historyItemId: historyItemId, isLPG: true }, configDialog).subscribe(async result => {
          });
        });
    }
  }

  deleteHistoryItem(historyItemId: string) {
    let confirmMessage = 'Are you sure you want to remove?';
    const primaryButtons = 'Yes';
    const secondaryButtons = 'No';
    this._dialogService.openConfirmationDialog({ title: TitleMessages.confirmationTitle, message: confirmMessage, primaryButtons: primaryButtons, secondaryButtons: secondaryButtons }).subscribe(
      async (result) => {
        if (result) {
          let deleteResult = await this._reportHistoryService.deleteHistoryItem(historyItemId)
          if (deleteResult) {
            this._dialogService.openSuccessDialogConfirm({ title: TitleMessages.successTitle, message: "Document has been removed successfully" }).subscribe(async result => {
              if (deleteResult) {
                this.reload();
              }
            });
          }
        }
      });
  }

  getTableStyle(data: any) {
    let display = data.length == 0 ? 'none' : 'block';
    return {
      'display': display
    };
  }

  ngAfterViewInit() {
    this.loadData();

    this.paginator.page
      .pipe(
        tap(() => this.loadData())
      )
      .subscribe();
  }
}
