<main >
  <mat-card >
    <mat-card-header >
      <mat-card-title><strong>Reports History</strong></mat-card-title>
      <mat-card-subtitle>Maintain reports history here.</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content >
      <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort matSortActive="name"
        matSortDirection="asc" matSortDisableClear>

        <ng-container matColumnDef="expandbutton">
          <th mat-header-cell *matHeaderCellDef>
          </th>
          <td mat-cell *matCellDef="let row">
            <button mat-button class="btn-table-row">
              <mat-icon svgIcon="unfold-more-horizontal"></mat-icon>
            </button>
          </td>
        </ng-container>

        <ng-container matColumnDef="period">
          <th mat-header-cell *matHeaderCellDef>Reporting Period </th>
          <td mat-cell *matCellDef="let row">{{row.period | date: 'MMMM - yyyy'}}</td>
        </ng-container>

        <ng-container matColumnDef="upload">
          <th mat-header-cell *matHeaderCellDef> Document Upload </th>
          <td mat-cell *matCellDef="let row">
            <div>
              <button mat-button class="btn-action" [satPopoverAnchor]="popover" (click)="popoverOpen(popover, row)">
                <mat-icon svgIcon="cloud-upload-outline" colour="primary"></mat-icon>
              </button>
            </div>
            <sat-popover #popover hasBackdrop xAlign="start" yAlign="start">
              <app-upload-report [id]="row.id" [title]="row.period | date: 'MMMM - yyyy'"
                (reload)="reload()"></app-upload-report>
            </sat-popover>
          </td>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let row" [attr.colspan]="displayedColumns.length">
            <div class="element-detail" [@detailExpand]="isExpanded(row)">
              <div class="detail">
                <div class="not-found" *ngIf="row.reportHistoryItems.length == 0">{{notFoundMessage}}</div>
                <mat-table [dataSource]="row.reportHistoryItems" multiTemplateDataRows
                  [style]="getTableStyle(row.reportHistoryItems)">

                  <ng-container matColumnDef="provider">
                    <mat-header-cell *matHeaderCellDef>Provider</mat-header-cell>
                    <mat-cell *matCellDef="let item">{{item.provider}}</mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="insurer">
                    <mat-header-cell *matHeaderCellDef>Insurer</mat-header-cell>
                    <mat-cell *matCellDef="let item">{{item.insurers}}</mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="product">
                    <mat-header-cell *matHeaderCellDef>Product</mat-header-cell>
                    <mat-cell *matCellDef="let item">{{item.productNames}}</mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="subProduct">
                    <mat-header-cell *matHeaderCellDef>Sub Product</mat-header-cell>
                    <mat-cell *matCellDef="let item">{{item.subProductNames}}</mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="view">
                    <mat-header-cell *matHeaderCellDef>View</mat-header-cell>
                    <mat-cell *matCellDef="let item">
                      <div class="view">
                        <div class="link">
                          <a href="javascript:void(0)" class="btn"
                            (click)="viewDeclaration(item.id, item.provider)">
                            Declaration Report
                          </a>
                        </div>
                        <div class="link">
                          <a href="javascript:void(0)" class="btn" (click)="downloadPayment(item.id, row.period)">
                            Payment Requisition
                          </a>
                        </div>
                      </div>
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="createdBy">
                    <mat-header-cell *matHeaderCellDef>Created By</mat-header-cell>
                    <mat-cell *matCellDef="let item">{{item.createdByUserName}}</mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="createdAt">
                    <mat-header-cell *matHeaderCellDef>Created At</mat-header-cell>
                    <mat-cell *matCellDef="let item">{{item.createdAt | date: 'dd/MM/yyyy HH:mm'}}</mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="delete">
                    <mat-header-cell *matHeaderCellDef> Remove </mat-header-cell>
                    <mat-cell *matCellDef="let item">
                      <button mat-button class="btn-action" (click)="deleteHistoryItem(item.id)" matTooltip="Remove">
                        <mat-icon svgIcon="delete" colour="primary"></mat-icon>
                      </button>
                    </mat-cell>
                  </ng-container>

                  <mat-header-row *matHeaderRowDef="displayedColumnsItem"></mat-header-row>
                  <mat-row *matRowDef="let row; columns: displayedColumnsItem;"></mat-row>
                </mat-table>
              </div>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="element-row" (click)="toggleRow(row)"></tr>

        <tr mat-row *matRowDef="let row; columns: ['expandedDetail'];" class="detail-row"></tr>

      </table>

      <div class="paging">
        <mat-paginator [length]="totalRows" [pageIndex]="pagingConstant.pageIndex"
          [pageSize]="pagingConstant.pageSizeLarger" [pageSizeOptions]="pagingConstant.pageSizeOptionsLarger"
          showFirstLastButtons>
        </mat-paginator>
      </div>
    </mat-card-content>

  </mat-card>
</main>
