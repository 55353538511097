import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function onlyNumberValdator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const numberRegex = /^\d+$/;
        const isValid = numberRegex.test(control.value);
        return isValid ? null : { number: true }

    };

}

export function validateNumber(control: AbstractControl) {
    const numberValue = (control.value || 0);
    return isNaN(numberValue * 1) ?
        { invalidNumber: { value: control.value } } :
        null;
};

export function positiveNumberValidator(control: AbstractControl) {
  const isNotOk = Number(control.value) < 0;
 if(control.value == null) return { nonPositive: { value: control.value } };
  return isNotOk ?
      { nonPositive: { value: control.value } } :
      null;
};
