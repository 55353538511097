import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Utility } from 'src/app/utils/utility';
import { MaxLengthSearch } from '../../constants/constants';

@Component({
  selector: 'app-shared-search',
  templateUrl: './shared-search.component.html',
  styleUrls: ['./shared-search.component.scss']
})
export class SharedSearchComponent implements OnInit {

  @Output() onSearch = new EventEmitter<string>();
  @Output() onValueChange = new EventEmitter<string>();
  @Input() placeholderText: string = "Search";

  searchForm: FormGroup;
  MaxLengthSearch = MaxLengthSearch;

  constructor(private formBuilder: FormBuilder) {
    this.searchForm = this.formBuilder.group({
      searchString: ['', [Validators.maxLength(MaxLengthSearch.maxLength)]]
    });
  }

  ngOnInit(): void {
  }

  async search() {
    if(this.searchForm.invalid) return;
    this.onValueChange.emit(this.searchString.value);
    this.onSearch.emit(this.searchString.value);
  }

  get searchStringIsEmpty() {
    return Utility.isNullOrWhiteSpace(this.searchString.value);
  }

  get searchString() {
    return this.searchForm.get('searchString') as AbstractControl;
  }

  reset() {
    this.searchString.setValue('');
    this.search();
  }

  setSearchString(value) {
    this.searchString.setValue(value);
  }

}
