import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { distinctUntilChanged, switchMap, catchError, map, debounceTime } from 'rxjs/operators';
import { ServerResponse } from '../models/server-response';
import { ApiEndPoints } from '../shared/config/api-end-points';
import { AddressServerResponse } from '../models/address-server-response';
import { LeiConfig } from '../shared/constants/constants';

@Injectable({
  providedIn: 'root'
})
export class AddressService {

  constructor(private readonly api: ApiEndPoints, private readonly http: HttpClient) { }

  public getAddressFromPostcode(postcode: Observable<string>) {
    return postcode.pipe(
      debounceTime(LeiConfig.delayBeforeSearchingWithAutoSearchFields),
      distinctUntilChanged(),
      switchMap(registrationValue => {
        if (registrationValue && registrationValue.length && this.validateSpecialCharacter(registrationValue)) {
          return this.requestAddressDetailsFromApi(registrationValue).pipe(
            catchError(() => of({
              requestWasSuccessful: false
            } as ServerResponse<AddressServerResponse[]>))
          );
        } else {
          return of({
            requestWasSuccessful: false
          } as ServerResponse<AddressServerResponse[]>);
        }
      })
    );
  }

  private requestAddressDetailsFromApi(registration: string) {
    return this.http.get<AddressServerResponse[]>(
      this.api.getAddressInformationByPostcode(registration)
    ).pipe(
      map(response => ({
        requestWasSuccessful: true,
        responseData: response,
      } as ServerResponse<AddressServerResponse[]>))
    );
  }

  validateSpecialCharacter(postcode: string): boolean {
    const specialCharacters = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    
    if (specialCharacters.test(postcode)) {            
      return false
    } else {
      return true;
    }
  }
}
