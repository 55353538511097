<mat-card appearance="outlined" class="mat-elevation-z2">
  <mat-card-header>
    <mat-card-title>Alert Details</mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <div [formGroup]="alertFormGroup">
      <section>
        <mat-form-field>
          <mat-label>Title</mat-label>
          <input [required]="true" data-test="alert-title-input" matInput placeholder="Title" formControlName="title">
          <mat-error *ngIf="title.errors?.required || title.errors?.whitespace">Title is required</mat-error>
          <mat-error *ngIf="title.errors?.maxlength">Title can be max 250 characters long</mat-error>
        </mat-form-field>
      </section>
      <section>
        <mat-form-field>
          <mat-label>Message</mat-label>
          <textarea [required]="true" data-test="alert-message-input" 
          matInput placeholder="Message"
          cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="1"
            cdkAutosizeMaxRows="5"
          formControlName="message">
        </textarea>
          <mat-error *ngIf="message.errors?.required || message.errors?.whitespace">Message is required</mat-error>
          <mat-error *ngIf="message.errors?.maxlength">Message can be max 3000 characters long</mat-error>
        </mat-form-field>
      </section>
      <section>
        <mat-form-field>
          <mat-label>Priority</mat-label>
          <mat-select data-test="alert-priority-select" required placeholder="Priority" formControlName="priority">
            <mat-option *ngFor="let priority of Priorites.values()" [value]="Priorites[priority]">
              {{priority}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="priority.invalid">Priority is required</mat-error>
        </mat-form-field>
      </section>
    </div>
  </mat-card-content>
  <mat-card-actions>
      <section>
          <div >
            <button mat-raised-button color="primary" [disabled]="!alertFormGroup.valid" (click)="sendAlert()">Send</button>
            <button mat-raised-button color="primary" (click)="backToHome()" >Cancel</button>
          </div>
        </section>
  </mat-card-actions>
</mat-card>
