import { Component, OnInit } from '@angular/core';
import { LoaderService } from '../../services/loader.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {

  private _loading: boolean;
  get loading(){
    return this._loading;
  }
  constructor(private loaderService: LoaderService) {
    this.loaderService.isLoading.subscribe((v) => { 
      this._loading = v;
    });

  }
  ngOnInit() {
  }

}
