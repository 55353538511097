import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map, share, tap } from 'rxjs/operators';

import { ApiEndPoints } from '../shared/config/api-end-points';
import { EmailTemplate } from '../models/email-template';
import { EmailTemplateResponse } from '../models/email-template-response';
import { EmailTemplateTypeResponse } from '../models/email-template-type-response';

@Injectable({
  providedIn: 'root'
})
export class EmailTemplateService {

  private emailTemplateResponses$!: Observable<EmailTemplateResponse[]>;
  private emailTemplateTypeResponses$!: Observable<EmailTemplateTypeResponse[]>;

  constructor(private httpClient: HttpClient, private apiEndPoints: ApiEndPoints) { }


  getEmailTemplates() {
    if (!this.emailTemplateResponses$) {
      this.emailTemplateResponses$ = this.httpClient.get<EmailTemplateResponse[]>(this.apiEndPoints.getEmailTemplates())
        .pipe(share());
    }

    return this.emailTemplateResponses$;
  }

  getEmailTemplateTypes() {
    if (!this.emailTemplateTypeResponses$) {
      this.emailTemplateTypeResponses$ = this.httpClient.get<EmailTemplateTypeResponse[]>(this.apiEndPoints.getEmailTemplateTypes())
        .pipe(share());
    }

    return this.emailTemplateTypeResponses$;
  }

  update(emailTemplate: EmailTemplate): Observable<any> {

    return this.httpClient.put(this.apiEndPoints.updateEmailTemplate(), emailTemplate)
      .pipe (
        tap (
            // Log the result or error
            // data => console.dir(data),
            // error => console.log(error)
         )
       );
  }
}
