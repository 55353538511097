import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SellableLevelOfCover } from 'src/app/models/sellable-level-of-cover';

@Component({
  selector: 'app-ipt-confirm-popup',
  templateUrl: './ipt-confirm-popup.component.html',
  styleUrls: ['./ipt-confirm-popup.component.css']
})
export class IptConfirmPopupComponent implements OnInit {

  iptConfirm: FormGroup;
  pricing: SellableLevelOfCover;
  constructor(public dialogRef: MatDialogRef<IptConfirmPopupComponent>,
              public formBuilder: FormBuilder,
              @Inject(MAT_DIALOG_DATA) public data: any) {
      this.pricing = data;
      this.iptConfirm = this.formBuilder.group({
       confirm: ['', [Validators.required]]
      });
    }

  confirm(){
    return this.iptConfirm.get('confirm') as AbstractControl;
  }
  ngOnInit() {
  }

  closeDialog() {
    this.dialogRef.close();
  }
  moneytary(number: any) {
    return `£${parseFloat(number).toFixed(2)}`;
  }
}
