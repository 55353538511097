<mat-card>
  <mat-card-header>
    <mat-card-title><strong>Policy Import Reports</strong></mat-card-title>
    <mat-card-subtitle>View policy import reports here.</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <div [formGroup]="formGroup">
      <mat-form-field class="blue-form-field title-form">
        <mat-label>Broker Name</mat-label>
        <mat-select #matSelect data-test="productSelect" placeholder="Broker Name" panelClass="custom-width-panel"
          formControlName="brokerForm" (selectionChange)="toggleSelectionBroker($event.value)"
          [matTooltip]="actualSelected" multiple>
          <mat-select-trigger>{{actualSelected}}</mat-select-trigger>
          <mat-option [value]='allOptionTxt' #allSelected>{{allOptionTxt}}</mat-option>
          <mat-option *ngFor="let broker of brokerList" [value]="broker.key">
            {{ broker.name }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="brokerForm.errors?.required">Broker Name is required</mat-error>
      </mat-form-field>

      <mat-form-field class="date-input blue-form-field title-form">
        <mat-label class="">Date From:</mat-label>
        <input data-test="dateFromDatePicker" [matDatepicker]="dateFromComponent" [required]="true" matNativeControl
          spellcheck="false" matInput placeholder="Date From:" formControlName="dateFrom" [min]="minDateFrom"
          [max]="maxDateFrom">
        <mat-datepicker-toggle matSuffix [for]="dateFromComponent"></mat-datepicker-toggle>
        <mat-datepicker #dateFromComponent></mat-datepicker>
        <mat-hint>dd/mm/yyyy</mat-hint>
        <mat-error *ngIf="dateFrom.errors?.matDatepickerMin">Date is before the minimum date.</mat-error>
        <mat-error *ngIf="dateFrom.errors?.matDatepickerMax">Date is over 60 days in the future.</mat-error>
        <mat-error *ngIf="dateFrom.errors?.matDatepickerParse || dateFrom.errors?.invalid">The date is
          invalid.</mat-error>
        <mat-error *ngIf="!dateFrom.errors?.matDatepickerParse && dateFrom.errors?.required">Date From is
          required.</mat-error>
      </mat-form-field>

      <mat-form-field class="date-input blue-form-field title-form">
        <mat-label class="">Date To:</mat-label>
        <input data-test="dateToDatePicker" [matDatepicker]="dateToComponent" [required]="true" matNativeControl
          spellcheck="false" matInput placeholder="Date To:" formControlName="dateTo" [min]="minDateTo"
          [max]="maxDateTo">
        <mat-datepicker-toggle matSuffix [for]="dateToComponent"></mat-datepicker-toggle>
        <mat-datepicker #dateToComponent></mat-datepicker>
        <mat-hint>dd/mm/yyyy</mat-hint>
        <mat-error *ngIf="dateTo.errors?.matDatepickerMin">Date is before the minimum date.</mat-error>
        <mat-error *ngIf="dateTo.errors?.matDatepickerMax">Date is over 60 days in the future.</mat-error>
        <mat-error *ngIf="dateTo.errors?.matDatepickerParse || dateTo.errors?.invalid">The date is
          invalid.</mat-error>
        <mat-error *ngIf="!dateTo.errors?.matDatepickerParse && dateTo.errors?.required">Date To is
          required.</mat-error>
      </mat-form-field>
      <br>
      <div class="btn-group">
        <button [disabled]="!isValidSubmit" matTooltip="Submit" mat-raised-button color="primary" class="btn-submit"
          (click)="onSubmit()">
          Submit
        </button>

        <button [disabled]="!(isValidSubmit && dataSource.length > 0)" matTooltip="Export to Excel" mat-raised-button
          color="primary" class="btn-submit" (click)="exportExcel()">
          Export to Excel
        </button>
        <button [disabled]="!(isValidSubmit && dataSource.length > 0)" matTooltip="Export to CSV" mat-raised-button
          color="primary" class="btn-submit" (click)="exportCSV()">
          Export to CSV
        </button>
      </div>

    </div>
  </mat-card-content>

</mat-card>

<div class="not-found" *ngIf="!isShow">{{notFoundMessage}}</div>

<mat-card *ngIf="isShow">
  <mat-card-content>
    <table mat-table [dataSource]="dataSource" multiTemplateDataRows>

      <ng-container matColumnDef="expandbutton">
        <th mat-header-cell *matHeaderCellDef>
        </th>
        <td mat-cell *matCellDef="let broker">
          <button mat-button class="btn-table-row">
            <mat-icon svgIcon="unfold-more-horizontal"></mat-icon>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="brokerName">
        <th mat-header-cell *matHeaderCellDef> Broker Name </th>
        <td mat-cell *matCellDef="let broker">{{broker.brokerNameDescription}}</td>
      </ng-container>

      <ng-container matColumnDef="totalFiles">
        <th mat-header-cell *matHeaderCellDef> Total Import Files Received </th>
        <td mat-cell *matCellDef="let broker"> {{broker.totalImportFilesReceived}} </td>
      </ng-container>

      <ng-container matColumnDef="totalRecords">
        <th mat-header-cell *matHeaderCellDef> Total Records Received </th>
        <td mat-cell *matCellDef="let broker"> {{broker.totalRecordsReceived}} </td>
      </ng-container>

      <ng-container matColumnDef="totalErrors">
        <th mat-header-cell *matHeaderCellDef> Total Errors </th>
        <td mat-cell *matCellDef="let broker"> {{broker.totalErrors}} </td>
      </ng-container>

      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let broker" [attr.colspan]="displayedColumns.length">
          <div class="example-element-detail" [@detailExpand]="isExpanded(broker)">
            <app-policy-import-report-detail [brokerName]="broker.brokerName"
              [dateFrom]="dateFrom.value.format('YYYY-MM-DD')" [dateTo]="dateTo.value.format('YYYY-MM-DD')"
              [brokerNameExpanded]="brokerNameExpanded">

            </app-policy-import-report-detail>
          </div>

        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="example-element-row" (click)="toggleRow(row)">
      </tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail'];" class="example-detail-row"></tr>

    </table>
  </mat-card-content>
</mat-card>
