import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject, tap } from 'rxjs';
import { MLEIReportLine } from 'src/app/models/claims-made-report-responses/mlei-response';
import { ExportFileType } from 'src/app/models/enums/report-type.enum';
import { AppConstants, NO_RESULTS_FOUND, PagingConstant } from 'src/app/shared/constants/constants';

@Component({
  selector: 'app-mlei-table',
  templateUrl: './mlei-table.component.html',
  styleUrls: ['./mlei-table.component.scss']
})
export class MleiTableComponent {

  @Input() data$: BehaviorSubject<any>;
  @Output() export = new EventEmitter<ExportFileType>();

  @Output() loadData = new EventEmitter<any>();

  dataSource = new MatTableDataSource<MLEIReportLine>([]);
  public appConstants = AppConstants;
  notfound = '';
  pagingConstant = PagingConstant;



  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns = [
    'claimType',
    'paymentType',
    'paidValue',
    'paidDate',
    'claimantType',
    'claimantName',
    'claimReference',
    'solicitorReference',
    'solicitor',
    'claimOutcome',
    'claimSettleTrack',
    'concludedDate',
    'product',
    'subProduct',
    'brokerBranchName',
    'inceptionDate',
    'certificateNumber'
  ];
  totalRows: number = 0;

  constructor() {
  }

  get notFoundMessage() {
    return this.notfound;
  }

  ngOnInit(): void {
    this.data$.subscribe(x => {
      if (x) {
        this.dataSource = new MatTableDataSource<MLEIReportLine>(x.lines);
        this.totalRows = x.total;
        this.checkNotFoundResults(this.totalRows);
      }
    });
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    this.sort.sortChange
      .pipe(
        tap(() => this.load())
      )
      .subscribe();

    this.paginator.page
      .pipe(
        tap(() => this.load())
      )
      .subscribe();
  }

  load() {
    this.loadData.emit({
      pageIndex: this.paginator.pageIndex,
      pageSize: this.paginator.pageSize,
      sortField: this.sort.active,
      sortDirection: this.sort.direction,
    })
  }

  exportExcel() {
    this.export.emit(ExportFileType.Excel);
  }

  exportCsv() {
    this.export.emit(ExportFileType.Csv);
  }

  exportPdf() {
    this.export.emit(ExportFileType.Pdf);
  }

  checkNotFoundResults(totalRecords) {
    this.notfound = totalRecords === 0 ? NO_RESULTS_FOUND : '';
  }

  getTableStyle() {
    let display = this.totalRows == 0 ? 'none' : 'block';
    return {
      'display': display
    };
  }
}
