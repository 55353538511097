<mat-card appearance="outlined">
  <mat-card-header>
    <mat-card-title><strong>Stationery Maintenance</strong></mat-card-title>
    <mat-card-subtitle>Manage stationery maintenance here.</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <button mat-button class="btn-table-row btn-create" (click)="createStationary()" matTooltip="Create new Stationery">
      <mat-icon>add</mat-icon>
    </button>
    <div class="include-items" [formGroup]="filterForm">
      <label id="include-inactive-items" class="label-include"><span><strong>Include Inactive Items:</strong></span>
      </label>
      <mat-radio-group formControlName="isIncludedInactive">
        <mat-radio-button data-test="noRadio" value="false" [checked]="isIncludedInactive.value === false">No
        </mat-radio-button>
        <mat-radio-button data-test="yesRadio" value="true" [checked]="isIncludedInactive.value === true">Yes
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <table mat-table [dataSource]="dataSource" matSort matSortActive="description" matSortDirection="asc"
      matSortDisableClear>

      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
        <td mat-cell *matCellDef="let row">{{row.description}}</td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let row">{{getStatus(row.status)}}</td>
      </ng-container>

      <ng-container matColumnDef="statusNotes">
        <th mat-header-cell *matHeaderCellDef>Status Notes</th>
        <td mat-cell *matCellDef="let row">{{row.statusNotes}}</td>
      </ng-container>

      <ng-container matColumnDef="minOrderQuantity">
        <th mat-header-cell *matHeaderCellDef>Minimum Order Quantity</th>
        <td mat-cell *matCellDef="let row">{{row.minOrderQuantity}}</td>
      </ng-container>

      <ng-container matColumnDef="maxOrderQuantity">
        <th mat-header-cell *matHeaderCellDef>Maximum Order Quantity</th>
        <td mat-cell *matCellDef="let row">{{row.maxOrderQuantity}}</td>
      </ng-container>

      <ng-container matColumnDef="incrementQuantity">
        <th mat-header-cell *matHeaderCellDef>Increment Quantity</th>
        <td mat-cell *matCellDef="let row">{{row.incrementQuantity}}</td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Edit</th>
        <td mat-cell *matCellDef="let row">
          <mat-icon class="edit-icon" svgIcon="pencil" (click)="editStationary(row)" matTooltip="Edit"></mat-icon>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <div>
      <span class="not-found-text">{{notFoundMessage}}</span>
      <mat-paginator [length]="dataSource.totalRecords" [pageIndex]="pagingConstant.pageIndex"
        [pageSize]="pagingConstant.pageSizeLarger" [pageSizeOptions]="pageSizeOptions" showFirstLastButtons>
      </mat-paginator>
    </div>
  </mat-card-content>
</mat-card>