<mat-card appearance="outlined" class="mat-elevation-z2">
  <mat-card-header>
    <mat-card-title><strong>System Settings</strong></mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" [(selectedIndex)]="tabIndex" (selectedTabChange)="onTabChanged($event)">
      <mat-tab *ngIf="!hoDesignUser" label="IPT Rates">
        <app-ipt-rate-setting #editIPTRate></app-ipt-rate-setting>
      </mat-tab>
      <mat-tab label="Manage Email Content">
        <app-email-templates></app-email-templates>
      </mat-tab>
      <mat-tab *ngIf="!hoDesignUser" label="Master Settings">
        <app-master-setting #editMasterSetting></app-master-setting>
      </mat-tab>
    </mat-tab-group>

  </mat-card-content>
</mat-card>
