import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiEndPoints } from '../shared/config/api-end-points';
import { map } from 'rxjs/operators';
import { PagingConstant } from 'src/app/shared/constants/constants';
import { Insurer } from '../models/insurer';
import { PaginationResponse } from '../models/pagination-response';
import { FilterResponse } from '../models/user-access-report-response';

@Injectable({
  providedIn: "root",
})
export class InsurerService {
  constructor(private http: HttpClient, private api: ApiEndPoints) {}

  getInsurers(
    searchString: string,
    subProductId: string,
    isIncludeInactiveRecords: boolean,
    sortField: string,
    sortDirection: string = "asc",
    pageIndex = PagingConstant.pageIndex,
    pageSize = PagingConstant.pageSize
  ): Observable<PaginationResponse<Insurer>> {
    return this.http
      .get<PaginationResponse<Insurer>>(
        this.api.getAllInsurerPaging(
          searchString,
          subProductId,
          isIncludeInactiveRecords,
          sortField,
          sortDirection,
          pageIndex,
          pageSize
        )
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }


}
