<mat-card appearance="outlined" >
  <mat-card-header >
    <mat-card-title><strong>Changes History</strong></mat-card-title>
    <mat-card-subtitle>Search for change history by data type</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content class="search-form">
    <section>
      <ng-container [formGroup]="searchForm">
        <section>
          <mat-form-field>
            <mat-label>Select a data type for searching</mat-label>
            <mat-select data-test="entityNameSearchInput" required placeholder="Select a data type for searching"
              formControlName="entityName">
              <mat-option *ngFor="let entity of auditEntities" [value]="entity">
                {{entity}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="entityName.invalid">Data type for searching is required</mat-error>
          </mat-form-field>
          <mat-form-field class="customSearchCriteria">
            <mat-label>{{searchTermPlaceholder}}</mat-label>
            <input [required]="true" data-test="certificateNumberSearchInput" spellcheck="false" matInput
              placeholder="{{searchTermPlaceholder}}" formControlName="certificateNumber"
              matTooltip="{{searchTermPlaceholder}}" (keyup.enter)="search()">
            <mat-error *ngIf="certificateNumber.hasError('required')">Enter search criteria is required</mat-error>
            <mat-error *ngIf="certificateNumber.hasError('maxlength')">{{MaxLengthSearch.errorMessage}}</mat-error>
            <button class="close-icon" *ngIf="!searchStringIsEmpty"
              (click)="reset()"><mat-icon>clear</mat-icon></button>
          </mat-form-field>
          <button mat-raised-button color="primary" [disabled]="!searchForm.valid" (click)="search()">Search</button>
          <mat-error *ngIf="notfound">No results found.</mat-error>
        </section>
      </ng-container>

    </section>
    <section *ngIf="auditSearchResults && !notfound" class="masterGrid">
      <table mat-table [dataSource]="auditSearchResults">
        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef>{{headerSearchCol}}</th>
          <td mat-cell *matCellDef="let row" matTooltip="Click to view change history">{{row.description}} </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="auditSearchResultDisplayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: auditSearchResultDisplayedColumns;"
          [ngClass]="{hovered: row.hovered, highlighted: masterSelection.isSelected(row)}"
          (mouseover)="row.hovered = true" (mouseout)="row.hovered = false" (click)="searchResultRowClick(row)"></tr>
      </table>
    </section>
  </mat-card-content>
</mat-card>
<section class="auditSearchForm" *ngIf="auditReport">
  <h4>History</h4> <span>{{historySummary}}</span>
</section>

<mat-card appearance="outlined" *ngIf="auditReport">
  <div class="display-flex flex-direction-row">
    <mat-card-content class="flex-50">
      <section *ngIf="auditReport" class="audit-report">
        <table mat-table [dataSource]="auditReport.changeHistories" class="res">
          <ng-container matColumnDef="changedBy">
            <th mat-header-cell *matHeaderCellDef>Changed By</th>
            <td mat-cell *matCellDef="let row">{{row.changedBy}}</td>
          </ng-container>
          <ng-container matColumnDef="changedSummary">
            <th mat-header-cell *matHeaderCellDef>Changed Summary</th>
            <td mat-cell *matCellDef="let row">
              <div class="cell-scroll">{{Utility.removeTags(row.changedSummary)}}</div>
            </td>
          </ng-container>
          <ng-container matColumnDef="auditAt">
            <th mat-header-cell *matHeaderCellDef>Changed Date</th>
            <td class="audit-report" mat-cell *matCellDef="let row">{{row.auditAt | date:
              appconstants.dateConfig.dateAndTime}}</td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"
            [ngClass]="{hovered: row.hovered, highlighted: detailSelection.isSelected(row)}"
            (mouseover)="row.hovered = true" (mouseout)="row.hovered = false" (click)="onClick(row)"
            matTooltip="Click to view changes in detail"></tr>
        </table>
      </section>
      <section class="search-form" *ngIf="auditReport.changeHistories?.length===0">
        <span>No change history exists for the record selected</span>
      </section>
    </mat-card-content>
    <mat-divider [vertical]="true"></mat-divider>
    <mat-card-content class="flex-50 no-padding">
      <section *ngIf="policyAudit">
        <app-audit-trail-detail [policyAudit]="policyAudit"></app-audit-trail-detail>
      </section>
    </mat-card-content>
  </div>
</mat-card>
