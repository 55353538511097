import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { CollectionViewer } from '@angular/cdk/collections';
import { catchError, finalize } from 'rxjs/operators';
import { StationaryMaintenance } from './stationary-maintenance';
import { StationaryMaintenanceService } from '../services/stationary-maintenance.service';
import { PaginationResponse } from './pagination-response';

export class StationaryMaintenanceDataSource implements DataSource<StationaryMaintenance> {

    private stationarysSubject = new BehaviorSubject<StationaryMaintenance[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);
    public totalRecords:number;

    public loading$ = this.loadingSubject.asObservable();
    public stationarysSubject$ = this.stationarysSubject.asObservable();

    constructor(private stationaryService: StationaryMaintenanceService) { }

    connect(collectionViewer: CollectionViewer): Observable<StationaryMaintenance[]> {
        return this.stationarysSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.stationarysSubject.complete();
        this.loadingSubject.complete();
    }

    getStationaryMaintenances(isIncludeInactive: boolean, sortField = '', sortDirection = 'asc', pageIndex = 1, pageSize = 5) {

        this.loadingSubject.next(true);

        this.stationaryService
          .getStationaryMaintenances(
            isIncludeInactive,
            sortField,
            sortDirection,
            pageIndex,
            pageSize
          )
          .pipe(
            catchError(() => of([])),
            finalize(() => this.loadingSubject.next(false))
          )
          .subscribe((st) => {
            var rs = st as PaginationResponse<StationaryMaintenance>;
            this.totalRecords = rs.total;
            this.stationarysSubject.next(rs.items as any);
          });
    }
}
