import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, interval } from 'rxjs';
import { ApiEndPoints } from '../shared/config/api-end-points';
import { map } from 'rxjs/operators';
import { StationaryMaintenance } from '../models/stationary-maintenance';
import { PagingConstant } from 'src/app/shared/constants/constants';
import { PaginationResponse } from '../models/pagination-response';

@Injectable({
  providedIn: "root",
})
export class StationaryMaintenanceService {
  constructor(private http: HttpClient, private api: ApiEndPoints) {}

  getStationaryMaintenances(
    isIncludeInactive: boolean,
    sortField = "",
    sortDirection = "asc",
    pageIndex = PagingConstant.pageIndex,
    pageSize = PagingConstant.pageSize
  ): Observable<PaginationResponse<StationaryMaintenance>> {
    return this.http
      .get<PaginationResponse<StationaryMaintenance>>(
        this.api.getStationariesRecords(
          isIncludeInactive,
          sortField,
          sortDirection,
          pageIndex,
          pageSize
        )
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }
}
