<mat-card-header>
  <mat-card-title><strong>{{title}}</strong></mat-card-title>
</mat-card-header>
<mat-card-content [formGroup]="formGroup">
  <div>
    <mat-form-field class="blue-form-field title-form">
      <mat-label>Provider</mat-label>
      <mat-select #matProvider data-test="matProvider" placeholder="Provider" panelClass="custom-width-panel"
        formControlName="provider" [matTooltip]="matProvider.triggerValue">
        <mat-option *ngFor="let provider of providerList" [value]="provider.id">
          {{ provider.name }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="provider.errors?.required">Provider is required</mat-error>
    </mat-form-field>

    <mat-form-field class="blue-form-field title-form">
      <mat-label>Insurer Name</mat-label>
      <mat-select #matInsurer data-test="directionSelect" placeholder="Insurer Name" panelClass="custom-width-panel"
        formControlName="insurerName" multiple [matTooltip]="actualInsurer" (closed)="onCloseInsurer()" (selectionChange)="toggleSelectionInsurer($event.value)">
        <mat-select-trigger>{{actualInsurer}}</mat-select-trigger>
        <mat-option [value]='allInsurerOption' #allInsurer >{{allOptionTxt}}</mat-option>
        <mat-option *ngFor="let insurerName of insurerNames" [value]="insurerName" >
          {{ insurerName.name }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="insurerName.errors?.required">Insurer name is required</mat-error>
    </mat-form-field>

    <mat-form-field class="blue-form-field title-form">
      <mat-label>Product</mat-label>
      <mat-select #matProduct data-test="productSelect" placeholder="Product" panelClass="custom-width-panel"
        formControlName="product" multiple [matTooltip]="actualProduct" (closed)="onCloseProduct()" (selectionChange)="toggleSelectionProduct($event.value)">
        <mat-select-trigger>{{actualProduct}}</mat-select-trigger>
        <mat-option [value]='allOptionTxt' #allProduct>{{allOptionTxt}}</mat-option>
        <mat-option *ngFor="let product of productsList" [value]="product.id" >
          {{ product.name }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="product.errors?.required">Product is required</mat-error>
    </mat-form-field>
    <br>
    <mat-form-field class="blue-form-field title-form">
      <mat-label>Sub-Product</mat-label>
      <mat-select #matSelectCover data-test="coverSelect" placeholder="Sub-Product"
        panelClass="custom-width-panel" formControlName="cover" multiple [matTooltip]="actualSelectedCover" (selectionChange)="toggleSelectionCover($event.value)">
        <mat-select-trigger>{{actualSelectedCover}}</mat-select-trigger>
        <mat-option [value]='allOptionTxt' #allSelectedCover>{{allOptionTxt}}</mat-option>
        <mat-option *ngFor="let cover of coversList" [value]="cover.id">
          {{ cover.name }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="cover.errors?.required">Sub-Product is required</mat-error>
    </mat-form-field>
    <mat-form-field class="blue-form-field title-form upload-field">
      <mat-label>Declaration File</mat-label>
      <input placeholder="Declaration Report File" matInput type="search" readonly formControlName="file">
      <mat-icon class="search-button" matSuffix svgIcon="upload" (click)="file.click()"></mat-icon>
      <mat-error>Declaration File is required</mat-error>
    </mat-form-field>
    <input #file type="file" [hidden]="true"
      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      (change)="onSelectFile($event.target.files)">

    <button [disabled]="!isValidSubmit" matTooltip="Upload" mat-raised-button color="primary" class="btn-submit"
      (click)="onSubmit()">
      Upload
    </button>
  </div>
</mat-card-content>
