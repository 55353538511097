import { Vehicle } from './vehicle';

export class SellableLevelOfCover {
    id: string;
    iptRate: number;
    maximumRetailRate: number;
    brokerNet: number;
    clientGrossPremium: number;
    iptAmount: number;
    levelOfCoverName: string;
    lovDisplayName: string;
    numberOfVehicles: number;
    policyCoverDetailId: string;
    postCode: string;
    productName: string;
    vehicle: Vehicle;

    customBrokerNet: number;
    customIptAmount: number;
    customClientGrossPremium: number;

    invalidPricing:boolean;
    errorMessage:string;
    isNewLoc: boolean;

    constructor(obj: any) {
        this.id = obj.id ? obj.id : obj.levelOfCoverId;
        this.iptRate = obj.iptRate;
        this.maximumRetailRate = obj.maximumRetailRate;
        this.brokerNet = obj.brokerNet || obj.brokerNet === 0 ? obj.brokerNet : obj.netPriceToBroker;
        this.clientGrossPremium = obj.clientGrossPremium;
        this.iptAmount = obj.clientGrossPremium === 0 ? 0 : obj.iptAmount;
        this.levelOfCoverName = obj.levelOfCoverName ? obj.levelOfCoverName : obj.description;
        this.lovDisplayName = obj.lovDisplayName ? obj.lovDisplayName : (obj.vehicle ? (obj.levelOfCoverName + ' - ' + obj.vehicle.registration) : obj.description);
        this.policyCoverDetailId = obj.policyCoverDetailId;
        this.invalidPricing = obj.invalidPricing;
        this.errorMessage = obj.errorMessage;

        this.customBrokerNet = this.brokerNet;
        this.customIptAmount = this.iptAmount;
        this.customClientGrossPremium = this.clientGrossPremium;
        this.vehicle = {
            id: obj.vehicle ? obj.vehicle.id : '',
            make: obj.vehicle ? obj.vehicle.make : '',
            model: obj.vehicle ? obj.vehicle.model : '',
            colour: obj.vehicle ? obj.vehicle.colour : '',
            year: obj.vehicle ? obj.vehicle.year : '',
            numberSeat: obj.vehicle ? obj.vehicle.numberSeat : '',
            registration: obj.vehicle ? obj.vehicle.registration : '',
            tonnage: obj.vehicle ? obj.vehicle.tonnage : '',
            vehicleType: obj.vehicle ? obj.vehicle.vehicleType : '',
            personalUse: obj.vehicle ? obj.vehicle.personalUse : '',
          } as Vehicle
    }

    public set formattedNetPriceToCustomer(value: string) {
        this.customClientGrossPremium = roundToTwoDecimal( removeNoneNumericalCharactersExceptMinusAndPoint(value));
    }

    public get formattedClientGrossPremium() {
      return convertFloatToStringWithTwoDecimalPlaces(this.customClientGrossPremium);
    }

    public get formattedGrossPriceToCustomer() {
        const ipt = removeNoneNumericalCharactersExceptMinusAndPoint(this.totalIPT);
        return convertFloatToStringWithTwoDecimalPlaces(this.clientGrossPremium + ipt);
    }

    get isValidGrossPremium() {
      const maximumRate = this.getMaximumRetailRate;
      return this.customClientGrossPremium <= (maximumRate != 0 ? maximumRate : 1000); // #33934 - £1,000
    }

    public set formattedGrossPriceToCustomer(value: string) {
        const price = removeNoneNumericalCharactersExceptMinusAndPoint(value);
        this.customIptAmount = this.getIptAmount(price);
        if (price === 0) {
            this.customBrokerNet = 0;
        } else {
            this.customBrokerNet = (price - this.customIptAmount);
        }
        this.customClientGrossPremium = roundToTwoDecimal(price) ;
    }

    public get formattedMaximumRetailRate() {
      const maximumRate = this.getMaximumRetailRate;
      return maximumRate != 0 ? convertFloatToStringWithTwoDecimalPlaces(maximumRate) : '';

    }

    public get totalIPT() {
        if (this.customIptAmount === -1) {
            return convertFloatToStringWithTwoDecimalPlaces(this.customBrokerNet * this.getIptRate(this.iptRate));
        }
        return convertFloatToStringWithTwoDecimalPlaces(this.customIptAmount);
    }

    public get totalOriginalIPT() {
        if (this.customIptAmount === -1) {
            return convertFloatToStringWithTwoDecimalPlaces(this.brokerNet * this.getIptRate(this.iptRate));
        }
        return convertFloatToStringWithTwoDecimalPlaces(this.iptAmount);
    }

    public get originalBrokerRate() {
        return convertFloatToStringWithTwoDecimalPlaces(this.brokerNet);
    }

    public get getMaximumRetailRate() {
        return this.maximumRetailRate || 0;
    }

    public get originalGrossPriceToCustomer() {
        return convertFloatToStringWithTwoDecimalPlaces(this.brokerNet + (this.brokerNet * this.getIptRate(this.iptRate)));
    }

    private getIptRate(rate): number {
        if (rate == null) {
            return 12; // default Ipt
        }
        return rate;
    }

    getIptAmount(grossPrice: number) {
        if (grossPrice > 0) {
            const iptRate = this.getIptRate(this.iptRate);
            const newNetPremium = (grossPrice / (iptRate + 100)) * 100;
            return roundToTwoDecimal((grossPrice - newNetPremium)) ;
        }

        return 0;
    }
}

function convertFloatToStringWithTwoDecimalPlaces(value: number) {
    const theValue = value;
    if (value === undefined) {
        return '0';
    }
    return value.toFixed(2);
}

function removeNoneNumericalCharactersExceptMinusAndPoint(value: string) {
    return parseFloat(value.replace(/[^\d.-]/g, '')) || 0;
}

function roundToTwoDecimal(num: number)
{
    return +(Math.round(Number(num + 'e+' + 2)) +
      'e-' + 2);
}
