import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { Observable, lastValueFrom, map, startWith, tap } from 'rxjs';
import { UserAccessReportLine, FilterResponse } from 'src/app/models/user-access-report-response';
import { ExportFileType } from 'src/app/models/enums/report-type.enum';
import { Status } from 'src/app/models/enums/status.enum';
import { ReportService } from 'src/app/services/report.service';
import { AppConstants, NO_RESULTS_FOUND, PagingConstant } from 'src/app/shared/constants/constants';
import { HttpBaseService } from 'src/app/shared/services/http-base.service';
import { Utility } from 'src/app/utils/utility';

@Component({
  selector: 'app-user-access-report',
  templateUrl: './user-access-report.component.html',
  styleUrls: ['./user-access-report.component.scss']
})
export class UserAccessReportComponent implements OnInit, AfterViewInit {


  allOptionTxt: string = 'All';
  noGroupOptionTxt: string = 'No Broker Group';

  filterForm = this.formBuilder.group({
    status: [''],
    brokerGroup: ['', [Validators.required]],
    broker: ['', [Validators.required]],
    brokerBranch: ['', [Validators.required]]
  });

  filteredBrokerBranchOptions: Observable<FilterResponse[]>;
  filteredBrokerOptions: Observable<FilterResponse[]>;
  filteredBrokerGroupOptions: Observable<FilterResponse[]>;
  brokerBranchOptions: FilterResponse[] = [];
  brokerOptions: FilterResponse[] = [];
  brokerGroupOptions: FilterResponse[] = [];


  notfound = '';
  get notFoundMessage() {
    return this.notfound;
  }
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild('autoCompleteInputBbg', { read: MatAutocompleteTrigger }) triggerbbg: MatAutocompleteTrigger;
  @ViewChild('autoCompleteInputB', { read: MatAutocompleteTrigger }) triggerb: MatAutocompleteTrigger;
  @ViewChild('autoCompleteInputBb', { read: MatAutocompleteTrigger }) triggerbb: MatAutocompleteTrigger;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('autobbG') autobbG: MatAutocomplete;
  @ViewChild('autoB') autoB: MatAutocomplete;
  @ViewChild('autobb') autobb: MatAutocomplete;

  brokerBranchId = '';
  brokerId = '';
  brokerGroupID = '';
  currentBrokerBranchName = '';
  currentBrokerName = '';
  currentBrokerGroupName = '';

  noGroup: boolean = false;
  pagingConstant = PagingConstant;
  pageSizeOptions: number[] = PagingConstant.pageSizeOptions;
  totalRows: number = 0;
  Status = Status;

  dataSource!: UserAccessReportLine[];
  public appConstants = AppConstants;

  displayedColumns = [
    'brokerName',
    'brokerBranch',
    'userName',
    'userEmail',
    'firstLoginDate',
    'lastLoginDate',
    'totalLogins',
    'activeStatus',
    'inActiveDate'
  ];

  get status() {
    return this.filterForm.get('status') as AbstractControl;
  }
  get brokerGroup() {
    return this.filterForm.get('brokerGroup') as AbstractControl;
  }

  get broker() {
    return this.filterForm.get('broker') as AbstractControl;
  }
  get brokerBranch() {
    return this.filterForm.get('brokerBranch') as AbstractControl;
  }

  get isValidSubmit() {
    return !this.filterForm.invalid;
  }

  get searchGroupIsEmpty() {
    return this.brokerGroup && Utility.isNullOrWhiteSpace(this.brokerGroup.value);
  }

  get searchBrokerIsEmpty() {
    return this.broker && Utility.isNullOrWhiteSpace(this.broker.value);
  }

  get searchBrokerBranchIsEmpty() {
    return this.brokerBranch && Utility.isNullOrWhiteSpace(this.brokerBranch.value);
  }


  constructor(private readonly formBuilder: FormBuilder,
    private readonly _reportService: ReportService,
    private readonly httpService: HttpBaseService,
    private readonly router: Router) {

  }

  async ngOnInit(): Promise<void> {
    this.brokerGroupOptions = await lastValueFrom(this._reportService.getUserAccessReportFilterBrokerGroups());
    this.brokerGroupOptions.unshift({ id: '', name: this.noGroupOptionTxt });
    this.brokerGroupOptions.unshift({ id: '', name: this.allOptionTxt });


    this.filteredBrokerBranchOptions = this.brokerBranch.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '', this.brokerBranchOptions)),
    );

    this.filteredBrokerOptions = this.broker.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '', this.brokerOptions)),
    );

    this.filteredBrokerGroupOptions = this.brokerGroup.valueChanges.pipe(
      startWith(''),
      map(value => this._filterBrokerGroup(value || '', this.brokerGroupOptions)),
    );

  }

  onSubmit() {
    this.paginator.pageIndex = 0;
    this.paginator.pageSize = PagingConstant.pageSizeOptions[1];
    this.loadData();
  }

  ngAfterViewInit(): void {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    this.sort.sortChange
      .pipe(
        tap(() => this.loadData())
      )
      .subscribe();


    this.paginator.page
      .pipe(
        tap(() => this.loadData())
      )
      .subscribe();

    this.triggerbbg.panelClosingActions.subscribe((e) => {
      if (!(e && e.source)) {
        // clear value if is not from the filtered list
        if (this.brokerGroup.value === this.currentBrokerGroupName) {
          this.brokerGroup.setValue(this.currentBrokerGroupName);
        }
        else {
          this.brokerGroup.setValue('');
          this.resetAutoInput(this.autobbG);
        }
        this.triggerbbg.closePanel();
      }
    });

    this.triggerb.panelClosingActions.subscribe((e) => {
      if (!(e && e.source)) {
        // clear value if is not from the filtered list
        if (this.broker.value === this.currentBrokerName) {
          this.broker.setValue(this.currentBrokerName);
        } else {
          this.broker.setValue('');
          this.resetAutoInput(this.autoB);
        }
        this.triggerb.closePanel();
      }
    });

    this.triggerbb.panelClosingActions.subscribe((e) => {
      if (!(e && e.source)) {
        // clear value if is not from the filtered list
        if (this.brokerBranch.value === this.currentBrokerBranchName) {
          this.brokerBranch.setValue(this.currentBrokerBranchName);
        } else {
          this.brokerBranch.setValue('');
          this.resetAutoInput(this.autobb);
        }
        this.triggerbb.closePanel();
      }
    });
  }

  loadData() {
    this._reportService.getUserAccessReport(this.brokerGroupID, this.brokerId, this.brokerBranchId, this.noGroup, this.status.value
      , this.sort.active
      , this.sort.direction
      , this.paginator.pageIndex
      , this.paginator.pageSize).subscribe(x => {
        this.dataSource = x.userAccessReportLines;
        this.totalRows = x.total;
        this.checkNotFoundResults(this.totalRows);
      });
  }


  exportPdf() {
    this.export(ExportFileType.Pdf);
  }

  exportCsv() {
    this.export(ExportFileType.Csv);
  }

  exportExcel() {
    this.export(ExportFileType.Excel);
  }

  export(type: ExportFileType) {
    let url = this._reportService.exportUserAccessReport(this.brokerGroupID, this.brokerId, this.brokerBranchId, this.noGroup, this.status.value, type);
    this.httpService.downloadFile(url).subscribe(
      (data: BlobPart) => {
        const blob = new Blob([data], { type: this.getContentType(type) });
        let fileURL = window.URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.style.display = "none";
        link.href = fileURL;
        link.download = "UserAccessReport " + new Date().toLocaleDateString("en-UK");
        link.target = '_blank';
        link.click();

        setTimeout(() => {
          link.remove();
          window.URL.revokeObjectURL(fileURL);
        }, 100);

      }
    );
  }

  getContentType(fileType: ExportFileType) {
    switch (fileType) {
      case ExportFileType.Pdf:
        return "application/pdf";

      case ExportFileType.Csv:
        return "text/csv";

      case ExportFileType.Excel:
        return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

      default:
        return '';
    }
  }

  checkNotFoundResults(totalRecords) {
    this.notfound = totalRecords === 0 ? NO_RESULTS_FOUND : '';
  }

  private _filter(value: string, source: FilterResponse[]): FilterResponse[] {
    const filterValue = value.toLowerCase();
    if (value == this.allOptionTxt) {
      return source;
    }
    let data = source.filter(option => option.name.toLowerCase().includes(filterValue)
      || option.name == this.allOptionTxt);
    return data;
  }


  private _filterBrokerGroup(value: string, source: FilterResponse[]): FilterResponse[] {
    const filterValue = value.toLowerCase();
    if (value == this.allOptionTxt) {
      return source;
    }
    let data = source.filter(option => option.name.toLowerCase().includes(filterValue)
      || option.name == this.allOptionTxt
      || option.name == this.noGroupOptionTxt);
    return data;
  }

  getSelectedBrokerGroup(event: any, value: FilterResponse) {
    if (event.isUserInput && (this.currentBrokerGroupName.toLowerCase() != value.name.toLowerCase() || this.brokerGroupID != value.id)) {
      this.brokerGroupID = value.id;
      this.resetTable();
      this.currentBrokerGroupName = value.name;
      this.brokerGroup.setValue(value.name)
      let isGroup = true;
      if (value.name == this.noGroupOptionTxt) {
        isGroup = false;
        this.noGroup = true;
      }
      else{
        this.noGroup = false;
      }

      this._reportService.getUserAccessReportFilterBroker(value.id, isGroup).subscribe(
        x => {
          this.brokerOptions = x;
          this.brokerOptions.unshift({ id: '', name: this.allOptionTxt });
          this.clearBroker();
          this.brokerBranchOptions = [];
          this.clearBrokerBranch();
        }
      );
    }

  }
  getSelectedBroker(event: any, value: FilterResponse) {
    if (event.isUserInput && (this.currentBrokerName.toLowerCase() != value.name.toLowerCase() || this.brokerId != value.id)) {
      this.brokerId = value.id;
      this.resetTable();
      this.currentBrokerName = value.name
      this.broker.setValue(value.name);
      if (this.brokerGroup.value == '') {
        this.brokerGroup.setValue(this.allOptionTxt);
        this.currentBrokerGroupName = this.allOptionTxt;
      }
      if (this.broker.value == '') {
        this.broker.setValue(this.allOptionTxt);
        this.currentBrokerName = this.allOptionTxt;
      }

      this._reportService.getUserAccessReportFilterBrokerBranch(value.id, this.brokerGroupID, true).subscribe(
        x => {
          this.brokerBranchOptions = x;
          this.brokerBranchOptions.unshift({ id: '', name: this.allOptionTxt });
          this.clearBrokerBranch();
        }
      );
    }
  }

  getSelectedBrokerBranch(event: any, value: FilterResponse) {
    if (event.isUserInput && (this.currentBrokerBranchName.toLowerCase() != value.name.toLowerCase() || this.brokerBranchId != value.id)) {
      this.brokerBranchId = value.id;
      this.resetTable();
      this.currentBrokerBranchName = value.name
      this.brokerBranch.setValue(value.name);
      if (this.brokerGroup.value == '') {
        this.brokerGroup.setValue(this.allOptionTxt);
        this.currentBrokerGroupName = this.allOptionTxt;
      }

    }
  }

  resetGroup() {
    this.clearBrokerGroup();
    this.brokerOptions = [];
    this.clearBroker();
    this.brokerBranchOptions = [];
    this.clearBrokerBranch()
    this.resetTable();
  }

  resetBroker() {
    this.clearBroker();
    this.brokerBranchOptions = [];
    this.clearBrokerBranch()
    this.resetTable();
  }

  resetBrokerBranch(){
    this.clearBrokerBranch();
    this.resetTable();
  }

  resetTable(){
    this.dataSource = [];
    this.paginator.pageIndex = 0;
    this.paginator.pageSize = PagingConstant.pageSizeOptions[1];
    this.totalRows = 0;
  }

  getTableStyle() {
    let display = this.totalRows == 0 ? 'none' : 'block';
    return {
      'display': display
    };
  }

  clearBrokerGroup() {
    this.brokerGroupID = '';
    this.brokerGroup.setValue('');
    this.currentBrokerGroupName = '';
    this.resetAutoInput(this.autobbG);
  }

  clearBroker() {
    this.brokerId = '';
    this.broker.setValue('');
    this.currentBrokerName = '';
    this.resetAutoInput(this.autoB);
  }

  clearBrokerBranch() {
    this.brokerBranchId = '';
    this.brokerBranch.setValue('');
    this.currentBrokerBranchName = '';
    this.resetAutoInput(this.autobb);
  }

  resetAutoInput(auto: MatAutocomplete) {
    auto.options.forEach((item) => {
      item.deselect();
    });
  }

}
