import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators, AbstractControl, FormGroup } from '@angular/forms';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { HttpBaseService } from 'src/app/shared/services/http-base.service';
import { ApiEndPoints } from 'src/app/shared/config/api-end-points';
import { SubProduct } from 'src/app/models/sub-product';
import { Product } from 'src/app/models/product';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TitleMessages } from 'src/app/shared/constants/constants';
import { noWhitespace } from 'src/app/validators/no-whitespace.validator';

@Component({
  selector: 'app-sub-product-popup',
  templateUrl: './sub-product-popup.component.html',
  styleUrls: ['./sub-product-popup.component.scss']
})

export class SubProductPopupComponent {
  updateSubProduct: SubProduct;
  updateMode = false;
  form: FormGroup;

  statuses = [
    { value: true, name: 'Active' },
    { value: false, name: 'Inactive' },
  ];

  product: Product;

  saveSuccessMessage: string = '';

  constructor(
    private readonly _httpService: HttpBaseService,
    private readonly _api: ApiEndPoints,
    public dialogService: DialogService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<SubProductPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.updateSubProduct = data.subProduct;
    this.product = data.product;
    this.createForm();
    if (this.updateSubProduct) {
      this.updateMode = true;
      this.initDataForUpdate(this.updateSubProduct);
    }
  }

  createForm() {
    this.form = this.formBuilder.group({
      name: ['', [Validators.required, noWhitespace]],
      description: [''],
      order: ['', [Validators.required]],
      status: [true, [Validators.required]],
    });
  }

  get name() {
    return this.form.get('name') as AbstractControl;
  }

  get description() {
    return this.form.get('description') as AbstractControl;
  }

  get order() {
    return this.form.get('order') as AbstractControl;
  }

  get status() {
    return this.form.get('status') as AbstractControl;
  }

  initDataForUpdate(subProduct: SubProduct) {
    if (subProduct) {
      this.name.setValue(subProduct.name);
      this.description.setValue(subProduct.description);
      this.status.setValue(subProduct.isActive);
      this.order.setValue(subProduct.order);
    }
  }

  async onSave() {
    await this.save(true);
  }

  async save(closeDialog: boolean) {
    if (this.form.valid) {
      let subProduct: SubProduct = {
        id: this.updateMode ? this.updateSubProduct.id : undefined,
        rowVersion: this.updateMode ? this.updateSubProduct.rowVersion : undefined,
        productId: this.product.id,
        name: this.name.value,
        isActive: this.status.value,
        order: this.order.value,
        description: this.updateMode ? this.description.value : this.name.value,
        documents: [],
        levelOfCovers: [],
        insurers:[],
        product: undefined
      };

      if (!this.updateMode) {
        await this._httpService.postDataAsync<string>(this._api.createSubProduct(), subProduct);
      }
      else {
        await this._httpService.putDataAsync<SubProduct>(this._api.updateSubProduct(), subProduct);
      }

      this.dialogService.openSuccessDialogConfirm({ title: TitleMessages.successTitle, message: 'The Sub Product has been saved successfully' }).subscribe(result => {
        if (result) {
          if (closeDialog) {
            this.dialogRef.close(true);
          }
        }
      });
      this.form.markAsPristine();
    }
    else {
      return;
    }
  }

  onCloseDialog(isReload: boolean) {
    if (this.form.dirty) {
      this.dialogService.openConfirmationDialog({ title: TitleMessages.confirmationTitle, message: 'Are you sure you want to continue. Your changes will be lost?' }).subscribe(result => {
        if (result) {
          this.dialogRef.close(isReload);
        }
      });
    }
    else {
      this.dialogRef.close(isReload);
    }
  }

  checkOrderValue(){
    if (this.order.value === 0){
      this.order.setValue('');
      this.order.markAsTouched();
    }
  }
}

