import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { CollectionViewer } from '@angular/cdk/collections';
import { catchError, finalize } from 'rxjs/operators';
import { BrokerBranch, BranchInformation } from './broker';
import { BrokerBranchService } from '../services/broker-branch.service';
import { PaginationResponse } from './pagination-response';


export class BrokerBranchDataSource implements DataSource<BranchInformation> {
  private BrokerBranchSubject = new BehaviorSubject<BranchInformation[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public totalRecords: number;

  public loading$ = this.loadingSubject.asObservable();
  public brokerSubject$ = this.BrokerBranchSubject.asObservable();

  constructor(private brokerBranchService: BrokerBranchService) {}

  connect(collectionViewer: CollectionViewer): Observable<BranchInformation[]> {
    return this.BrokerBranchSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.BrokerBranchSubject.complete();
    this.loadingSubject.complete();
  }

  getBrokerBranchList(
    searchString: string,
    isIncludeInactive: boolean,
    sortField: string,
    sortDirection = "asc",
    pageIndex = 1,
    pageSize = 5
  ) {
    this.loadingSubject.next(true);

    this.brokerBranchService
      .getBrokerBranchesList(
        searchString,
        isIncludeInactive,
        sortField,
        sortDirection,
        pageIndex,
        pageSize
      )
      .pipe(
        catchError(() => of([])),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe((st) => {
        var rs = st as PaginationResponse<BranchInformation>;
        this.totalRecords = rs.total;
        this.BrokerBranchSubject.next(rs.items as any);
      });
  }

  getBrokerBrancesByBrokerId(
    brokerId: string,
    searchString: string,
    isIncludeInactive: boolean,
    sortField: string,
    sortDirection = "asc",
    pageIndex = 1,
    pageSize = 5
  ) {
    this.loadingSubject.next(true);

    this.brokerBranchService
      .getBrokerBranchesByBrokerId(
        brokerId,
        searchString,
        isIncludeInactive,
        sortField,
        sortDirection,
        pageIndex,
        pageSize
      )
      .pipe(
        catchError(() => of([])),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe((st) => {
        var rs = st as PaginationResponse<BrokerBranch>;
        this.totalRecords = rs.total;
        this.BrokerBranchSubject.next(rs.items as any);
      });
  }
}
