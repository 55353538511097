import { Injectable } from '@angular/core';
import { ApiEndPoints } from '../shared/config/api-end-points';
import { Observable } from 'rxjs';
import { LevelOfCoverValidationGroup } from '../models/level-of-cover-validation-item';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { PagingConstant } from 'src/app/shared/constants/constants';
import { LevelOfCoverInformation, LevelOfCover } from '../models/level-of-cover';
import { PaginationResponse } from '../models/pagination-response';

@Injectable({
  providedIn: "root",
})
export class LevelOfCoverService {
  constructor(private http: HttpClient, private api: ApiEndPoints) {}

  getLevelOfCoverValidationsByFilters(
    productId: string,
    subProductId: string,
    loCId: string,
    searchString: string,
    isIncludedInactive: boolean,
    sortField = "",
    sortDirection = "asc",
    pageIndex = PagingConstant.pageIndex,
    pageSize = PagingConstant.pageSize
  ): Observable<PaginationResponse<LevelOfCoverValidationGroup>> {
    return this.http
      .get<PaginationResponse<LevelOfCoverValidationGroup>>(
        this.api.getLevelOfCoverValidationsPagingByFilters(
          productId,
          subProductId,
          loCId,
          searchString,
          isIncludedInactive,
          sortField,
          sortDirection,
          pageIndex,
          pageSize
        )
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  getLevelOfCoversByFilters(
    productId: string,
    subProductId: string,
    loCId: string,
    searchString: string,
    isIncludedInactive: boolean,
    sortField = "",
    sortDirection = "asc",
    pageIndex = PagingConstant.pageIndex,
    pageSize = PagingConstant.pageSize
  ): Observable<PaginationResponse<LevelOfCoverInformation>> {
    return this.http
      .get<PaginationResponse<LevelOfCoverInformation>>(
        this.api.getLevelOfCoversPagingByFilters(
          productId,
          subProductId,
          loCId,
          searchString,
          isIncludedInactive,
          sortField,
          sortDirection,
          pageIndex,
          pageSize
        )
      )
      .pipe(map((res) => res));
  }

  public getValidLevelOfCovers(
    productId: string,
    type: string,
    inceptionDate: string,
    conditions: any
  ) {
    const url = this.api.getValidLevelOfCover(
      productId,
      type,
      inceptionDate,
      conditions
    );
    return this.http.get<LevelOfCover[]>(url);
  }
}
