<div class="wrapper">
  <div appearance="outlined" [formGroup]="filterForm" class="outline">
    <mat-form-field class="select-type-4">
      <mat-label> Broker Group Name</mat-label>
      <input matInput [matAutocomplete]="autobbG" #autoCompleteInputBbg formControlName="brokerGroup" required
        placeholder="Enter Broker Group Name" matTooltip="Start typing to search">
      <button class="close-icon" *ngIf="!searchGroupIsEmpty" (click)="resetGroup()"><mat-icon>clear</mat-icon></button>
      <mat-icon class="search-icon" matSuffix svgIcon="magnify"></mat-icon>
      <mat-error *ngIf="brokerGroup.invalid">You must select a value from the list options</mat-error>
      <mat-autocomplete [autoActiveFirstOption]="true" #autobbG="matAutocomplete" md-menu-class="autocomplete">
        <mat-option *ngFor="let option of filteredBrokerGroupOptions | async" [value]="option.name"
          (onSelectionChange)="getSelectedBrokerGroup($event,option)">
          <span>{{option.name}}</span>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field class="select-type">
      <mat-label> Broker Branch Name *</mat-label>
      <input matInput [matAutocomplete]="autobb" formControlName="brokerBranch" #autoCompleteInputBb
        placeholder="Enter Broker Branch Name" matTooltip="Start typing to search" class="brokerBranch"
        (blur)="onBlurBrokerBranch()">
      <button class="close-icon" *ngIf="!searchIsEmpty" (click)="reset()"><mat-icon>clear</mat-icon></button>
      <mat-icon class="search-icon" matSuffix svgIcon="magnify"></mat-icon>
      <mat-autocomplete [autoActiveFirstOption]="true" #autobb="matAutocomplete" md-menu-class="autocomplete">
        <mat-option *ngFor="let option of filteredBrokerBranchOptions | async" [value]="option.name"
          (onSelectionChange)="selectBrokerBranch($event,option)" [disabled]="option.isDisable">
          <span>{{ option.name}}</span>
        </mat-option>
      </mat-autocomplete>
      <mat-error *ngIf="brokerBranch.hasError('invalid')">You must select a value from the list options</mat-error>
    </mat-form-field>
    <br>

    <section class="selectedBB">
      <h4 *ngIf="selectedBranchOptions.length > 0"> Selected Broker Branch </h4>
      <span *ngIf="reachLimit" class="warnin-message">Reached limitation of 20 broker branches.</span>
      <mat-chip-listbox>
        <mat-chip *ngFor="let source of selectedBranchOptions" (removed)="remove(source)">
          {{source.name}}
          <button [singleClick]="false" matChipRemove>
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip>
      </mat-chip-listbox>
    </section>
    <mat-form-field class="select-type-4">
      <mat-label> Sub Product</mat-label>
      <mat-select #matSelect data-test="subProductId" formControlName="subProduct" placeholder="Sub Product" multiple
        [matTooltip]="actualSelectedSubProduct" (selectionChange)="toggleSelectionSub($event.value)" >
        <mat-select-trigger>{{actualSelectedSubProduct}}</mat-select-trigger>
        <mat-option [value]='allOptionTxt' #allSelectedSub >{{allOptionTxt}}</mat-option>
        <mat-option *ngFor="let p of subProducts" [value]="p.id" >
          {{p.name}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="subProduct.invalid">You must select a value from the list options</mat-error>

    </mat-form-field>

    <mat-form-field class="select-type">
      <mat-label> Level of Cover</mat-label>
      <mat-select #matSelectLoc data-test="subProductId" formControlName="levelOfCover" placeholder="Level of Cover"
        multiple [matTooltip]="actualSelectedLoc" (selectionChange)="toggleSelectionLoC($event.value)" >
        <mat-select-trigger>{{actualSelectedLoc}}</mat-select-trigger>
        <mat-option [value]='allOptionTxt' #allSelectedLoC >{{allOptionTxt}}</mat-option>
        <mat-option *ngFor="let p of levelOfCovers" [value]="p.id">
          {{p.name}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="levelOfCover.invalid">You must select a value from the list options</mat-error>

    </mat-form-field>

    <mat-form-field class="select-type-3">
      <mat-label> Custom Pricing</mat-label>
      <mat-select data-test="customPricing" formControlName="custompricing">
        <mat-option value=''>All</mat-option>
        <mat-option value='true'>Active</mat-option>
        <mat-option value='false'>Inactive</mat-option>
      </mat-select>
    </mat-form-field>
    <button [disabled]="!isValidSubmit" matTooltip="Submit" mat-raised-button color="primary" class="btn-submit"
      (click)="onSubmit()">
      Submit
    </button>
    <br>
    <div class="not-found">{{notFoundMessage}}</div>
    <br>
    <div [style]="getTableStyle()">
      <table mat-table [dataSource]="dataSource" matSort matSortActive="brokerBranchName" matSortDirection="asc"
        matSortDisableClear >

        <ng-container matColumnDef="brokerGroupName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Broker Group </th>
          <td mat-cell *matCellDef="let element"> {{element.brokerGroupName}} </td>
        </ng-container>

        <ng-container matColumnDef="brokerBranchName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Broker Branch </th>
          <td mat-cell *matCellDef="let element"> {{element.brokerBranchName}} </td>
        </ng-container>

        <ng-container matColumnDef="subProductName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Sub Product</th>
          <td mat-cell *matCellDef="let element"> {{element.subProductName}} </td>
        </ng-container>

        <ng-container matColumnDef="levelOfCoverName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Level of Cover</th>
          <td mat-cell *matCellDef="let element"> {{element.levelOfCoverName}} </td>
        </ng-container>

        <ng-container matColumnDef="standardBrokerRate">
          <th mat-header-cell *matHeaderCellDef> Broker Rate </th>
          <td mat-cell *matCellDef="let element"> {{element.standardBrokerRate |currency:'GBP':'symbol'}} </td>
        </ng-container>

        <ng-container matColumnDef="standardUnderwriterRate">
          <th mat-header-cell *matHeaderCellDef> Underwriter Rate </th>
          <td mat-cell *matCellDef="let element"> {{element.standardUnderwriterRate |currency:'GBP':'symbol'}} </td>
        </ng-container>

        <ng-container matColumnDef="standardMaximumRetailRate">
          <th mat-header-cell *matHeaderCellDef>Net Maximum Retail Rate </th>
          <td mat-cell *matCellDef="let element"> {{element.standardMaximumRetailRate |currency:'GBP':'symbol'}} </td>
        </ng-container>

        <ng-container matColumnDef="standardEffectiveDateFrom">
          <th mat-header-cell *matHeaderCellDef> Effective Date From</th>
          <td mat-cell *matCellDef="let element"> {{element.standardEffectiveDateFrom| date:
            appConstants.dateConfig.date}} </td>
        </ng-container>

        <ng-container matColumnDef="customBrokerRate">
          <th mat-header-cell *matHeaderCellDef> Broker Rate </th>
          <td mat-cell *matCellDef="let element"> {{element.customBrokerRate |currency:'GBP':'symbol'}} </td>
        </ng-container>

        <ng-container matColumnDef="customUnderwriterRate">
          <th mat-header-cell *matHeaderCellDef> Underwriter Rate </th>
          <td mat-cell *matCellDef="let element"> {{element.customUnderwriterRate |currency:'GBP':'symbol'}} </td>
        </ng-container>


        <ng-container matColumnDef="customMaximumRetailRate">
          <th mat-header-cell *matHeaderCellDef>Net Maximum Retail Rate </th>
          <td mat-cell *matCellDef="let element"> {{element.customMaximumRetailRate |currency:'GBP':'symbol'}} </td>
        </ng-container>

        <ng-container matColumnDef="customEffectiveDateFrom">
          <th mat-header-cell *matHeaderCellDef> Effective Date From </th>
          <td mat-cell *matCellDef="let element"> {{element.customEffectiveDateFrom| date: appConstants.dateConfig.date}}
          </td>
        </ng-container>

        <ng-container matColumnDef="header-row-group-0">
          <th mat-header-cell *matHeaderCellDef [style.text-align]="center" [attr.colspan]="1">
          </th>
        </ng-container>

        <ng-container matColumnDef="header-row-group-1">
          <th mat-header-cell *matHeaderCellDef [style.text-align]="center" [attr.colspan]="1">
          </th>
        </ng-container>
        <ng-container matColumnDef="header-row-group-2">
          <th mat-header-cell *matHeaderCellDef [style.text-align]="center" [attr.colspan]="1">
          </th>
        </ng-container>
        <ng-container matColumnDef="header-row-group-3">
          <th mat-header-cell *matHeaderCellDef [style.text-align]="center" [attr.colspan]="1">
          </th>
        </ng-container>

        <ng-container matColumnDef="header-row-first-group">
          <th mat-header-cell *matHeaderCellDef [style.text-align]="center" [attr.colspan]="4">
            Standard
          </th>
        </ng-container>

        <ng-container matColumnDef="header-row-second-group">
          <th mat-header-cell *matHeaderCellDef [attr.colspan]="4"> Custom </th>
        </ng-container>

        <tr mat-header-row
          *matHeaderRowDef="['header-row-group-0','header-row-group-1','header-row-group-2','header-row-group-3','header-row-first-group', 'header-row-second-group']">
        </tr>
        <tr mat-header-row *matHeaderRowDef="displayedColumnsub"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsub;"></tr>
      </table>
      <div class="display-flex flex-direction-row footer">
        <div class="btn-group flex-50">
          <button class="btnExport" matTooltip="Export to Excel" mat-raised-button color="primary" (click)="exportExcel()"
            [disabled]="!isValidSubmit">
            Export to Excel
          </button>
          <button class="btnExport" matTooltip="Export to CSV" mat-raised-button color="primary" (click)="exportCsv()"
            [disabled]="!isValidSubmit">
            Export to CSV
          </button>
          <button class="btnExport" matTooltip="Export to PDF" mat-raised-button color="primary" (click)="exportPdf()"
            [disabled]="!isValidSubmit">
            Export to PDF
          </button>
        </div>
        <div class="flex-50 paging">
          <mat-paginator [length]="total" [pageIndex]="pagingConstant.pageIndex" [pageSize]="pagingConstant.pageSizeLarger"
            [pageSizeOptions]="pagingConstant.pageSizeOptionsLarger" showFirstLastButtons>
          </mat-paginator>
        </div>
      </div>
    </div>

  </div>


</div>
