import { Component, OnInit } from '@angular/core';
import { AmendedItem } from 'src/app/models/amended-policy';
import { HttpBaseService } from 'src/app/shared/services/http-base.service';
import { ApiEndPoints } from 'src/app/shared/config/api-end-points';
import { Validators, FormBuilder, AbstractControl } from '@angular/forms';
import moment from 'moment';
import { FORMAT_ONLY_DATE } from 'src/app/shared/constants/constants';

@Component({
  selector: 'app-settings',
  templateUrl: './amendments.component.html',
  styleUrls: ['./amendments.component.scss']
})
export class AmendmentsComponent implements OnInit {

  loadingData = false;

  displayedColumns: string[] = ['auditedAt', 'reason'];
  data: AmendedItem[] = [];

  defaultDateFrom = moment(new Date());
  defaultDateTo = moment(this.defaultDateFrom, 'DD-MM-YYYY').add(31, 'days');

  inputForm = this.formBuilder.group({
    dateFrom: [this.defaultDateFrom, [Validators.required]],
    dateTo: [this.defaultDateTo, [Validators.required]]
  });

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly _api: ApiEndPoints,
    private readonly _httpService: HttpBaseService) {}

  public get dateFrom() {
    return this.inputForm.get('dateFrom') as AbstractControl;
  }

  public get dateTo() {
    return this.inputForm.get('dateTo') as AbstractControl;
  }

  ngOnInit() {
    this.loadData();
  }

  async loadData() {
    let dateFrom = this.defaultDateFrom.format(FORMAT_ONLY_DATE);
    let dateTo = this.defaultDateTo.format(FORMAT_ONLY_DATE);
    this.loadingData = true;
    this.data = await this._httpService.getDataAsync<AmendedItem[]>(this._api.getAmendmentsList(dateFrom, dateTo));
    this.loadingData = false;
  }

  async filter(){
    let dateFrom = this.dateFrom.value.format(FORMAT_ONLY_DATE);
    let dateTo = this.dateTo.value.format(FORMAT_ONLY_DATE);
    this.loadingData = true;
    this.data = await this._httpService.getDataAsync<AmendedItem[]>(this._api.getAmendmentsList(dateFrom, dateTo));
    this.loadingData = false;
  }
}
