export enum HirePeriodUnits {
    Days = 0,
    Months = 1,
    Years = 2
}

export namespace HirePeriodUnits {
    export function values() {
        return Object.keys(HirePeriodUnits).filter((type) => isNaN(<any>type) && type !== 'values');
    }
}