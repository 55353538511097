import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserInfo } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { MessageForBroker, TitleMessages } from 'src/app/shared/constants/constants';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { Utility } from 'src/app/utils/utility';

@Component({
  selector: 'app-user-detail-popup',
  templateUrl: './user-detail-popup.component.html',
  styleUrls: ['./user-detail-popup.component.scss']
})
export class UserDetailPopupComponent implements OnInit {
  user: UserInfo;
  constructor(
    private readonly authService: AuthService,
    public dialogRef: MatDialogRef<UserDetailPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly _dialogService: DialogService) {
    this.user = data.user;
    this.dialogRef.addPanelClass('width-user-details');
  }

  close() {
    this.dialogRef.close();
  }

  ngOnInit() {
  }

  formatPhoneNumber(phonenumber) {
    phonenumber = phonenumber.split(' ').join("");
    const formatted = Utility.formatPhoneNumber(phonenumber);
    return formatted;
  }

  logout() {
    
    this._dialogService.openConfirmationDialog({title: TitleMessages.confirmationTitle, message: MessageForBroker.messageLogout}).subscribe(result => {
      if(result){
        this.authService.logout();
      }else{
        this.dialogRef.close(true);
      }
    });
  }
}
